// @flow

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import fileDownload from 'js-file-download';
import Typography from '@mui/material/Typography';
import DataTable from 'react-data-table-component';
import CircularProgress from '@mui/material/CircularProgress';
import { withStyles } from 'tss-react/mui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import Tooltip from '@mui/material/Tooltip';

import { ForwardRef } from 'components';
import { log } from 'utils/jsUtils';
import MDPBackend from 'services/MDPBackend';
import BatchDetailsModal from './BatchDetailsModal';
import styles from './Styles/UploadDem.Styles';

type Props = {
  classes: Object
};

const FileUploadHistory = (props: Props): React.Node => {
  const { classes } = props;
  const [loading, setLoading] = useState(false);
  const [loadingFile, setLoadingFile] = useState(false);
  const [fileUploads, setFileUploads] = useState([]);
  const [batchName, setBatchName] = useState('');

  const getFileUploads = async () => {
    setLoading(true);

    try {
      const response = await MDPBackend.getFileUploads();

      const fileUploads = JSON.parse(response.data.body).data;
      log('[getFileUploads] fileUploads: ', fileUploads);

      setFileUploads(fileUploads);
    } catch (error) {
      log('[getFileUploads] error: ', error);
    } finally {
      setLoading(false);
    }
  };

  const downloadFile = async (fileId, fileName) => {
    setLoadingFile(true);

    try {
      const response = await MDPBackend.downloadUserUploadedFile(fileId);

      const signedUrl = JSON.parse(response.data.body).url;
      log('[downloadFile] signedUrl: ', signedUrl);

      const signedUrlResponse = await axios.get(signedUrl, {
        responseType: 'blob'
      });

      const blob = signedUrlResponse.data;
      const name = fileName ? fileName : 'file';

      fileDownload(blob, name);
    } catch (error) {
      log('[downloadFile] error: ', error);
    } finally {
      setLoadingFile(false);
    }
  };

  const showBatchDetails = async (name) => {
    setBatchName(name);
  };

  const columns = [
    {
      name: 'Batch Details',
      selector: (row) => row['Batch Details'],
      sortable: true,
      wrap: true,
      cell: (row) => (
        <>
          {row['Batch Name'] && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Tooltip title="Show Batch Results">
                <div
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => showBatchDetails(row['Batch Name'])}
                  style={{ textDecoration: 'underline', cursor: 'pointer' }}
                >
                  Show Logs
                </div>
              </Tooltip>
            </div>
          )}
        </>
      )
    },
    {
      name: 'File Name',
      selector: (row) => row['File Name'],
      sortable: true,
      wrap: true
    },
    {
      name: 'Uploaded at',
      selector: (row) => row['Uploaded at'],
      sortable: true,
      wrap: true
    },
    {
      name: 'Uploader',
      selector: (row) => row['Uploader'],
      sortable: true,
      wrap: true
    },
    {
      name: 'Status',
      selector: (row) => row['Status'],
      sortable: true,
      wrap: true
    },
    {
      name: 'Batch Name',
      selector: (row) => row['Batch Name'],
      sortable: true,
      wrap: true
    },
    {
      name: 'Actions',
      selector: (row) => row['Actions'],
      sortable: true,
      wrap: true,
      // eslint-disable-next-line react/display-name
      cell: (row) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Tooltip title="Download file">
            <div
              target="_blank"
              rel="noreferrer"
              onClick={() => downloadFile(row['Actions'], row['File Name'])}
            >
              <ForwardRef>
                <FontAwesomeIcon
                  icon={solid('download')}
                  className={classes.icon}
                  style={{
                    opacity: loadingFile ? 0.5 : 1,
                    pointerEvents: loadingFile ? 'none' : 'auto'
                  }}
                />
              </ForwardRef>
            </div>
          </Tooltip>
        </div>
      )
    }
  ];

  useEffect(() => {
    getFileUploads();
  }, []);

  return (
    <>
      <div className={classes.uploadHistoryWrapper}>
        <Typography variant="h2" className={classes.title}>
          File Upload History
        </Typography>
        <div className={classes.uploadHistoryTable}>
          {loading ? (
            <CircularProgress />
          ) : (
            <DataTable
              columns={columns}
              data={fileUploads}
              noHeader
              responsive
            />
          )}
        </div>
      </div>
      {batchName && (
        <BatchDetailsModal
          batchName={batchName}
          handleClose={() => setBatchName('')}
        />
      )}
    </>
  );
};

export default withStyles(FileUploadHistory, styles);
