// @flow

const styles = (theme: Object) => ({
  root: {
    height: theme.layout.footer.height,
    width: '100%',
    borderTop: 'solid 1px #CCC',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    fontSize: '0.9rem',
    zIndex: 999,
    background: 'white'
  },
  content: {
    margin: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(1)
    }
  },
  footerLink: {
    margin: `${theme.spacing(1)} ${theme.spacing(3)}`,
    textDecoration: 'none',
    color: '#000'
  }
});

export default styles;
