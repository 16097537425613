// @flow

import React, { useState, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import axios from 'axios';
import * as R from 'ramda';
import DataTable from 'react-data-table-component';
import dayjs from 'dayjs';
import fileDownload from 'js-file-download';
import { unparse } from 'papaparse';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { ForwardRef } from 'components';
import MDPBackend from 'services/MDPBackend';
import { withStyles } from 'tss-react/mui';
import { log } from 'utils/jsUtils';
import RafScoreModal from './Modals/RafScoreModal';
import DemographicsModal from './Modals/DemographicsModal';
import RawCompendiumsModal from './Modals/RawCompendiumsModal';
import RafDetailsModal from 'containers/PatientLookup/RafDetailsModal';
import styles from './Styles/QueryTable.Style';

type Props = {
  classes: Object,
  queries: Array<Object>,
  totalRows: Number,
  loading: Boolean,
  setUrlQueryParams: () => void,
  parseSearchParams: () => void
};

const QueryTable = (props: Props) => {
  const {
    queries,
    classes,
    totalRows,
    loading,
    parseSearchParams,
    setUrlQueryParams
  } = props;

  const [clickedRow, setClickedRow] = useState({});
  const [loadingActions, setLoadingActions] = useState(false);
  const [loadingCSV, setLoadingCSV] = useState(false);
  const [rafModalOpen, setRafModalOpen] = useState(false);
  const [rafScores, setRafScores] = useState(null);
  const [rafModalData, setRafModalData] = useState(false);
  const [demographicsModalData, setDemographicsModalData] = useState(null);
  const [queryId, setQueryId] = React.useState(null);
  const [rawCompendiumsModalOpen, setRawCompendiumsModalOpen] = useState(false);

  const [searchParams] = useSearchParams();

  const params = useMemo(
    () =>
      Array.from(searchParams).reduce(
        (acc, [key, value]) => ({ ...acc, [key]: value }),
        {}
      ),
    [searchParams]
  );

  const handleClose = () => {
    setRafModalOpen(false);
    setRafScores(null);
    setDemographicsModalData(null);
  };

  const downloadCDAAsPDF = (row) => async (e) => {
    console.log('[downloadCompendium] for row.queryId: ', row.queryId);
    e.preventDefault();
    e.stopPropagation();

    const res = await MDPBackend.getLatestPatientEcda(
      row.patient_id,
      row.queryId,
      'pdf'
    );
    log('[downloadCompendium] res: ', res);
    const { data } = res;
    const { body } = data;
    const jsonFile = JSON.parse(body);
    log('[downloadCompendium] jsonFile: ', jsonFile);

    const { file_url } = jsonFile;

    const signedUrlRes = await axios.get(file_url, { responseType: 'blob' });
    const blob = signedUrlRes.data;

    fileDownload(
      blob,
      `${row.patient_last_name}-${row.patient_first_name}-${row.patient_id}.pdf`
    );
  };

  const exportCSV = async () => {
    setLoadingCSV(true);

    const searchString = parseSearchParams(params);

    try {
      const response = await MDPBackend.exportCSV(searchString);
      log('[exportCSV] response: ', response);

      const parsedBody = JSON.parse(response.data.body);
      log('[exportCSV] parsedBody: ', parsedBody);

      const list = parsedBody.list;

      const csv = unparse(list);

      const file = new Blob([csv], { type: 'text/plain' });

      const element = document.createElement('a');

      element.href = URL.createObjectURL(file);
      element.download = 'mdp_queries.csv';
      element.click();

      setLoadingCSV(false);
    } catch (error) {
      log('[exportCSV] error: ', error);
      setLoadingCSV(false);
    }
  };

  /* eslint-disable react/prop-types */
  const StatusIcon = ({ status }) => {
    let icon,
      color = '';

    if (status.includes('Not started')) {
      icon = solid('circle-pause');
      color = 'grey';
    }
    if (status.includes('Started')) {
      icon = solid('clock');
      color = 'orange';
    }
    if (status.includes('Completed')) {
      icon = solid('circle-check');
      color = 'green';
    }
    if (status.includes('Error')) {
      icon = solid('circle-xmark');
      color = 'grey';
    }
    if (status.includes('Aborted')) {
      icon = solid('cancel');
      color = 'grey';
    }
    if (status.includes('Skipped')) {
      icon = solid('forward');
      color = 'grey';
    }

    return icon ? (
      <Tooltip title={status === 'Error' ? 'Unavailable' : status}>
        <div>
          <ForwardRef>
            <FontAwesomeIcon
              icon={icon}
              className={`${classes.statusIcon} ${color}`}
            />
          </ForwardRef>
        </div>
      </Tooltip>
    ) : (
      <span></span>
    );
  };

  const openDemographicsModal = (row) => async (e) => {
    console.log(
      '[openDemographicsModal] for row.demographics: ',
      row.demographics
    );
    e.preventDefault();
    e.stopPropagation();

    setDemographicsModalData(row.demographics);
  };

  const getQueryFileUpload = async (row, fileName) => {
    log('[getQueryFileUpload] row: ', row);
    setLoadingActions(true);

    const queryId = row.queryId;

    try {
      const response = await MDPBackend.getQueryFileUpload(queryId);
      log('[getQueryFileUpload] response: ', response);

      const signedUrl = JSON.parse(response.data.body).url;
      log('[getQueryFileUpload] signedUrl: ', signedUrl);

      const signedUrlResponse = await axios.get(signedUrl, {
        responseType: 'blob'
      });

      const fileBlob = signedUrlResponse.data;
      const _fileName = fileName
        ? fileName
        : `${row.patient_first_name}_${row.patient_last_name}_attachment.zip`;

      fileDownload(fileBlob, _fileName);
    } catch (error) {
      log('[getQueryFileUpload] error: ', error);
    } finally {
      setLoadingActions(false);
    }
  };

  const showRafDetails = (row) => {
    setRafModalData(row.rafScoreDeltaCalculated);
  };

  const handleCloseRawCompendiumsModalOpen = () => {
    setRawCompendiumsModalOpen(false);
    setQueryId(null);
  };

  const handlePipelineEventComendiumsOpen = (queryId) => {
    setQueryId(queryId);
    setRawCompendiumsModalOpen(true);
  };

  // useEffect(() => {
  //   const { perPage } = params;
  //   if (perPage) {
  //     setRowsPerPage(parseInt(perPage, 10));
  //   } else {
  //     // setRowsPerPage(50);
  //   }
  // }, [params]);

  const queryColumns =
    !queries || !queries.length
      ? []
      : Object.keys(R.omit(['icdCodesDeltaArr', 'cdaFile'], queries[0])).map(
          (col) => {
            switch (col) {
              case 'demographics':
                return {
                  name: '',
                  id: col,
                  selector: (row) => row[col],
                  sortable: true,
                  wrap: true,
                  // eslint-disable-next-line react/display-name
                  cell: (row) => (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%'
                      }}
                      onClick={(e) => openDemographicsModal(row)(e)}
                    >
                      <Tooltip title="Display demographics">
                        <ForwardRef>
                          <FontAwesomeIcon
                            icon={solid('info-circle')}
                            className={`${classes.icon} grey`}
                          />
                        </ForwardRef>
                      </Tooltip>
                    </div>
                  ),
                  style: { minWidth: 100 }
                };
              case 'createdDt':
                return {
                  id: col,
                  name: 'Created at',
                  selector: (row) => row[col],
                  sortable: true,
                  wrap: true,
                  format: (row) =>
                    dayjs(row.createdDt).format('MMM DD YYYY hh:mmA')
                };
              case 'patient_id':
                return {
                  id: col,
                  name: 'ID',
                  selector: (row) => row[col],
                  sortable: true,
                  wrap: true
                };
              case 'patient_first_name':
                return {
                  id: col,
                  name: 'First Name',
                  selector: (row) => row[col],
                  sortable: true,
                  wrap: true
                };
              case 'patient_last_name':
                return {
                  id: col,
                  name: 'Last Name',
                  selector: (row) => row[col],
                  sortable: true,
                  wrap: true
                };
              case 'status':
                return {
                  id: col,
                  name: 'Status',
                  selector: (row) => row[col],
                  sortable: true,
                  wrap: true,
                  // eslint-disable-next-line react/display-name
                  cell: (row) => <StatusIcon fullWidth status={row.status} />
                };
              case 'totalChartFilesFound':
                return {
                  id: col,
                  name: 'Total Chart Files',
                  selector: (row) => row[col],
                  sortable: true,
                  wrap: true,
                  // eslint-disable-next-line react/display-name
                  cell: (row) => {
                    return (
                      <div className={classes.flex}>
                        <StatusIcon status={row.totalChartFilesFound.status} />
                        {String(row.totalChartFilesFound.value)}
                      </div>
                    );
                  }
                };
              case 'next_apt_date':
                return {
                  id: col,
                  name: 'Next Apt Date',
                  selector: (row) => row[col],
                  sortable: true,
                  wrap: true
                };
              case 'provider_group_name':
                return {
                  id: col,
                  name: 'Provider Group',
                  selector: (row) => row[col],
                  sortable: true,
                  wrap: true
                };
              case 'batchName':
                return {
                  id: col,
                  name: 'Batch',
                  selector: (row) => row[col],
                  sortable: true,
                  wrap: true
                };
              case 'cdaFile':
                return {
                  id: col,
                  name: 'C-CDA',
                  selector: (row) => row[col],
                  sortable: true,
                  wrap: true,
                  // eslint-disable-next-line react/display-name
                  cell: (row) => {
                    const hasChartFiles =
                      parseInt(row.totalChartFilesFound.value) > 0;

                    return (
                      <>
                        <StatusIcon status={row.cdaFile.status} />
                        {row.cdaFile.urlXml ? (
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center'
                            }}
                          >
                            <Tooltip
                              title={`View${
                                hasChartFiles ? ' ' : ' empty '
                              }CDA online`}
                            >
                              <div
                                target="_blank"
                                rel="noreferrer"
                                onClick={() => {
                                  setClickedRow(row);
                                  window.open(row.cdaFile.urlXml);
                                }}
                              >
                                <ForwardRef>
                                  <FontAwesomeIcon
                                    icon={
                                      hasChartFiles
                                        ? solid('file-medical')
                                        : solid('file')
                                    }
                                    className={`${classes.icon} ${
                                      hasChartFiles ? '' : 'grey'
                                    }`}
                                  />
                                </ForwardRef>
                              </div>
                            </Tooltip>
                            {row.cdaFile.urlPdf ? (
                              <Tooltip
                                title={`Download${
                                  hasChartFiles ? ' ' : ' empty '
                                }CDA as PDF`}
                              >
                                <div
                                  target="_blank"
                                  rel="noreferrer"
                                  onClick={(e) => {
                                    setClickedRow(row);
                                    downloadCDAAsPDF(row)(e);
                                  }}
                                >
                                  <ForwardRef>
                                    <FontAwesomeIcon
                                      icon={solid('file-pdf')}
                                      className={`${classes.icon} ${
                                        hasChartFiles ? '' : 'grey'
                                      }`}
                                    />
                                  </ForwardRef>
                                </div>
                              </Tooltip>
                            ) : null}
                          </div>
                        ) : (
                          <div></div>
                        )}
                      </>
                    );
                  }
                };
              case 'compendium':
                return {
                  id: col,
                  name: 'Compendium',
                  selector: (row) => row[col],
                  sortable: true,
                  wrap: true,
                  // eslint-disable-next-line react/display-name
                  cell: (row) => {
                    return (
                      <>
                        <StatusIcon status={row.compendium.status} />
                        {row.compendium.url ? (
                          <>
                            <Tooltip title="Open Compendium">
                              <div
                                target="_blank"
                                rel="noreferrer"
                                onClick={() => {
                                  setClickedRow(row);
                                  window.open(row.compendium.url);
                                }}
                              >
                                <ForwardRef>
                                  <FontAwesomeIcon
                                    icon={solid('file-invoice')}
                                    className={classes.icon}
                                  />
                                </ForwardRef>
                              </div>
                            </Tooltip>
                          </>
                        ) : (
                          <div></div>
                        )}
                      </>
                    );
                  }
                };
              case 'knownIcdCodes':
                return {
                  id: col,
                  name: 'Known ICD Codes',
                  selector: (row) => row[col],
                  sortable: true,
                  wrap: true,
                  // eslint-disable-next-line react/display-name
                  cell: (row) => {
                    return (
                      <div style={{ maxHeight: 70, overflowY: 'auto' }}>
                        {row.knownIcdCodes}
                      </div>
                    );
                  }
                };
              case 'icdCodesDelta':
                return {
                  id: col,
                  name: 'ICD Codes Delta',
                  selector: (row) => row[col],
                  sortable: true,
                  wrap: true,
                  // eslint-disable-next-line react/display-name
                  cell: (row) => {
                    return (
                      <div style={{ maxHeight: 70, overflowY: 'auto' }}>
                        {row.icdCodesDelta}
                      </div>
                    );
                  }
                };
              case 'rafScoreDeltaCalculated':
                return {
                  id: col,
                  name: 'RAF Delta',
                  selector: (row) => row[col],
                  sortable: true,
                  wrap: true,
                  // eslint-disable-next-line react/display-name
                  cell: (row) => {
                    return (
                      <Tooltip title={'RAF Details'}>
                        <button
                          onClick={() => showRafDetails(row)}
                          className={classes.rafBtn}
                        >
                          {row.rafScoreDeltaCalculated.rafScore}
                        </button>
                      </Tooltip>
                    );
                  }
                };
              case 'actions':
                return {
                  id: col,
                  name: 'Actions',
                  selector: (row) => row[col],
                  sortable: false,
                  wrap: true,
                  cell: (row) => {
                    return (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {row.actions.hasISResultFiles && (
                          <Tooltip title="View Raw InferScience Compendiums">
                            <div
                              style={{
                                cursor: 'pointer',
                                width: 30
                              }}
                              onClick={() => {
                                setClickedRow(row);
                                handlePipelineEventComendiumsOpen(row.queryId);
                              }}
                            >
                              <span>NLP</span>
                            </div>
                          </Tooltip>
                        )}
                        {row.actions.fileAttachment ? (
                          <Tooltip title={row.actions.fileAttachment}>
                            <div
                              target="_blank"
                              rel="noreferrer"
                              onClick={() =>
                                getQueryFileUpload(
                                  row,
                                  row.actions.fileAttachment
                                )
                              }
                            >
                              <ForwardRef>
                                <FontAwesomeIcon
                                  icon={solid('file-download')}
                                  className={classes.icon}
                                  style={{
                                    pointerEvents: loadingActions
                                      ? 'none'
                                      : 'auto',
                                    opacity: loadingActions ? 0.5 : 1
                                  }}
                                />
                              </ForwardRef>
                            </div>
                          </Tooltip>
                        ) : (
                          <></>
                        )}
                      </div>
                    );
                  }
                };
              default:
                return {
                  id: col,
                  name: col,
                  selector: (row) => row[col],
                  sortable: true,
                  wrap: true
                };
            }
          }
        );
  console.log('qColumns', queryColumns);
  const dataColumns = queryColumns.filter(
    (col) => col.name !== 'queryId' && col.name !== 'rafsArr'
  );

  const handlePageChange = (page) => {
    setUrlQueryParams({ page });
  };

  const handleSort = (column, sortDirection) => {
    if (column.id) {
      setUrlQueryParams({ sortBy: column.id, sortByOrder: sortDirection });
    }
  };

  const handleRowsPerPage = (newPerPage) => {
    setUrlQueryParams({ perPage: newPerPage });
  };

  const customStyles = {
    cells: {
      style: {
        padding: '0px 10px'
      }
    }
  };

  const conditionalRowStyles = {
    when: (row) => {
      return row.queryId === clickedRow.queryId;
    },
    style: {
      background: '#DDDDDD'
    }
  };

  return (
    <>
      <div className={classes.tableActionButtons}>
        <Link to="/queries/new" style={{ textDecoration: 'none' }}>
          <Button
            className={classes.newQueryButton}
            color="primary"
            variant="outlined"
          >
            Start a new query
          </Button>
        </Link>
        <Button disabled={loadingCSV} onClick={exportCSV}>
          Export to CSV{' '}
          {loadingCSV ? (
            <CircularProgress
              style={{ width: 15, height: 15, marginLeft: 10 }}
            />
          ) : null}
        </Button>
      </div>
      <DataTable
        customStyles={customStyles}
        columns={dataColumns}
        data={queries}
        noHeader
        progressPending={loading}
        progressComponent={<CircularProgress style={{ margin: '30px 0' }} />}
        persistTableHead
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        paginationRowsPerPageOptions={[10, 20, 50, 100, 200, 500]}
        paginationPerPage={params.perPage || 50}
        onChangeRowsPerPage={handleRowsPerPage}
        onChangePage={handlePageChange}
        sortServer
        onSort={handleSort}
        defaultSortAsc={params.sortByOrder === 'asc'}
        defaultSortFieldId={params.sortBy}
        responsive
        className={classes.dataTable}
        highlightOnHover
        conditionalRowStyles={[conditionalRowStyles]}
        onRowClicked={(row) => setClickedRow(row)}
      />
      <RawCompendiumsModal
        handleClose={handleCloseRawCompendiumsModalOpen}
        open={rawCompendiumsModalOpen}
        queryId={queryId}
      />
      {rafModalOpen && rafScores ? (
        <RafScoreModal
          handleClose={handleClose}
          open={rafModalOpen}
          rafScores={rafScores}
        />
      ) : null}
      {demographicsModalData ? (
        <DemographicsModal
          handleClose={handleClose}
          data={demographicsModalData}
        />
      ) : null}
      {rafModalData && (
        <RafDetailsModal
          data={rafModalData}
          handleClose={() => setRafModalData(null)}
        />
      )}
    </>
  );
};

export default (withStyles(QueryTable, styles): any);
