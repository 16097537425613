// @flow

import React from 'react';
import { Helmet } from 'react-helmet-async';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { withStyles } from 'tss-react/mui';

import PageTemplate from 'layout/PageTemplate';
import styles from './Styles/ApiKeys.Styles';

type Props = {
  classes: Object
};

const ApiKeys = (props: Props): React.Node => {
  const { classes } = props;

  return (
    <PageTemplate>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Developer API Keys</title>
      </Helmet>
      <Paper className={classes.container}>
        <div className={classes.header}>
          <Typography variant="h1">Developer API Keys</Typography>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.requestBtn}
          >
            Request new API key
          </Button>
        </div>
        <div className={classes.apiKeyList}>
          <Table className={classes.table} aria-label="simple table">
            <TableBody>
              <TableRow className={classes.recordsRow}>
                <TableCell component="th" scope="row">
                  <Typography variant="h6">API Key</Typography>
                </TableCell>
                <TableCell component="th" scope="row">
                  <Typography variant="h6">Created</Typography>
                </TableCell>
                <TableCell component="th" scope="row">
                  <Typography variant="h6">Status</Typography>
                </TableCell>
                <TableCell component="th" scope="row"></TableCell>
              </TableRow>

              <TableRow className={classes.recordsRow}>
                <TableCell component="td" scope="row">
                  **************************
                </TableCell>
                <TableCell component="td" scope="row">
                  4 hours ago
                </TableCell>
                <TableCell component="td" scope="row">
                  Active
                </TableCell>
                <TableCell align="right" className={classes.infoSecondary}>
                  <DeleteOutlinedIcon className={classes.deleteBtn} />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      </Paper>
    </PageTemplate>
  );
};

export default withStyles(ApiKeys, styles);
