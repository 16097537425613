// @flow

import React from 'react';
import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useGetPatientHCCsQuery } from 'api/api';
import { useLocation, useSearchParams } from 'react-router-dom';
import queryString from 'query-string';
import { format } from 'date-fns';
import axios from 'axios';
import fileDownload from 'js-file-download';
import { useDispatch, useSelector } from 'react-redux';
import Container from '@mui/material/Container';
import MDPBackend from 'services/MDPBackend';
import { log } from 'utils/jsUtils';
import PatientHCCs from './PatientHCCs';
import HCCFilter from './HCCFilter';
import { useOutletContext } from 'react-router-dom';
import { showToastMsg } from 'features/toast-message-slice';
import { setCurrentNlpSource } from 'features/nlp-slice';

const RELOAD_PATIENT_HCCS_INTERVAL = 10000 * 60; // 1 minute
const DEFAULT_SEARCH_PERIOD_NAME = 'All';
const DEFAULT_CUSTOMER_FACING_NLP = 'IS_PDF';

const parseSearchParams = (searchParams) => new URLSearchParams(searchParams);

const HCCPage = () => {
  const { patientId, loadingPatientData } = useOutletContext();
  const { user } = useSelector((state) => state.user);
  const [patientHccs, setPatientHccs] = useState(null);
  const [pickOpenEncounterToAcceptICD, setPickOpenEncounterToAcceptICD] =
    useState(false);
  const [encounters, setEncounters] = useState([]);
  const [hccStats, setHccStats] = useState({
    currentCodesCount: 0,
    currentSourcesCount: 0,
    totalCodesCount: 0,
    totalSourcesCount: 0,
    lastUpdated: ''
  });
  const [isReveleerTeam, setIsReveleerTeam] = useState(false);
  const [loadingCSV, setLoadingCSV] = useState(false);
  const [loadingCSVRevTeam, setLoadingCSVRevTeam] = useState(false);
  const [nlpSources, setNlpSources] = useState([]);
  const [customerFacingNLP, setCustomerFacingNLP] = useState('');
  const [nlpSource, setNlpSource] = useState(DEFAULT_CUSTOMER_FACING_NLP);
  const halOrganization = useSelector((state) => state.halOrganization);
  const location = useLocation();
  const dispatch = useDispatch();

  const {
    data,
    error,
    isLoading: patientHccLoading,
    isFetching: patientHccFetching,
    refetch: patientHccRefetch
  } = useGetPatientHCCsQuery(
    {
      patientId,
      searchString: location.search
    },
    {
      pollingInterval: halOrganization.isAddingEvidence
        ? 0
        : RELOAD_PATIENT_HCCS_INTERVAL,
      skipPollingIfUnfocused: true
    }
  );

  const setPatientHCCsStatesFromData = () => {
    if (data) {
      setPatientHccs(data.codes);
      setNlpSources(data.nlpSourcesAvailable);
      setCustomerFacingNLP(data.customerFacingNLP);
      setNlpSource(data.nlpSource);
      setPickOpenEncounterToAcceptICD(data.pickOpenEncounterToAcceptICD);
      setEncounters(data.encounters);
      setHccStats({
        currentCodesCount: data.currentCodesCount,
        currentSourcesCount: data.currentSourcesCount,
        totalCodesCount: data.totalCodesCount,
        totalSourcesCount: data.totalSourcesCount,
        lastUpdated: data.compendiumLastUpdated
      });
      setIsReveleerTeam(data.isReveleerTeam);
      dispatch(setCurrentNlpSource(data.nlpSource));
    }
  };

  const [searchParams, setSearchParams] = useSearchParams({
    evidenceFrom: '',
    evidenceTo: '',
    searchPeriod: DEFAULT_SEARCH_PERIOD_NAME,
    nlpSource: nlpSource
  });

  const exportCodesToCSV = async () => {
    log('exporting...');
    setLoadingCSV(true);

    const searchString = parseSearchParams(searchParams);

    try {
      const response = await MDPBackend.exportPatientHCCsByPatient(
        patientId,
        searchString
      );
      log('[exportCodesToCSV] response: ', response);

      const parsedResponse = JSON.parse(response.data.body);

      const signedUrl = parsedResponse.url;

      const signedUrlResponse = await axios.get(signedUrl, {
        responseType: 'blob'
      });

      const blob = signedUrlResponse.data;

      fileDownload(blob, 'patient_hccs.csv');
    } catch (error) {
      log('[exportCSV] error: ', error);
    } finally {
      setLoadingCSV(false);
    }
  };

  const exportCodesToCSVRevTeam = async () => {
    log('exporting...');
    setLoadingCSVRevTeam(true);

    const searchParams = queryString.parse(location.search);
    const searchString = parseSearchParams({
      ...searchParams,
      revTeam: 'true'
    });

    try {
      const response = await MDPBackend.exportPatientHCCsByPatient(
        patientId,
        searchString
      );
      log('[exportCodesToCSV] response: ', response);

      const parsedResponse = JSON.parse(response.data.body);

      const signedUrl = parsedResponse.url;

      const signedUrlResponse = await axios.get(signedUrl, {
        responseType: 'blob'
      });

      const blob = signedUrlResponse.data;

      fileDownload(blob, 'patient_hccs.csv');
    } catch (error) {
      log('[exportCSV] error: ', error);
    } finally {
      setLoadingCSVRevTeam(false);
    }
  };

  useEffect(() => {
    if (error) {
      dispatch(
        showToastMsg({
          open: true,
          message: error.message ? error.message : 'Network Error',
          level: 'error',
          duration: 5000
        })
      );
    }
  }, [error]);

  useEffect(() => {
    setPatientHCCsStatesFromData();
  }, [data]);

  return (
    <Container
      maxWidth="none"
      className={`${
        user && user.role !== 'payer' ? '!pl-[86px] sm:!pl-[193px]' : ''
      } mt-5`}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Patient HCC</title>
      </Helmet>
      <div>
        <div className="mb-2.5">
          <HCCFilter
            loading={
              loadingPatientData || patientHccLoading || patientHccFetching
            }
            nlpSources={nlpSources}
            customerFacingNLP={customerFacingNLP}
            searchParams={searchParams}
            setSearchParams={setSearchParams}
          />
        </div>
        <div className="flex items-start justify-between">
          <div>
            <span>
              <strong>
                Showing {hccStats.currentCodesCount} out of{' '}
                {hccStats.totalCodesCount} ICD-10
                {hccStats.totalCodesCount === 1 ? '' : 's'},{' '}
                {hccStats.currentSourcesCount} source
                {hccStats.currentSourcesCount === 1 ? '' : 's'} out of{' '}
                {hccStats.totalSourcesCount}
              </strong>
            </span>
          </div>
          <div className="text-right">
            {hccStats.lastUpdated ? (
              <>
                <span>
                  {format(new Date(hccStats.lastUpdated), 'MMM dd yyyy p (z)')}
                </span>
                <br />
                <span className="text-[0.8rem]">Compendium last updated</span>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div>
          <PatientHCCs
            patientId={patientId}
            patientHccLoading={loadingPatientData || patientHccLoading}
            patientHccs={patientHccs}
            pickOpenEncounterToAcceptICD={pickOpenEncounterToAcceptICD}
            encounters={encounters}
            patientHccRefetch={patientHccRefetch}
            exportCodesToCSV={exportCodesToCSV}
            loadingCSV={loadingCSV}
            exportCodesToCSVRevTeam={exportCodesToCSVRevTeam}
            loadingCSVRevTeam={loadingCSVRevTeam}
            isReveleerTeam={isReveleerTeam}
            nlpSource={nlpSource}
          />
        </div>
      </div>
    </Container>
  );
};

export default HCCPage;
