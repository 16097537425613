// @flow

const styles = (theme: Object) => ({
  root: { marginBottom: theme.spacing(2) },
  searchFieldById: {
    width: '100%',
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  searchFieldByFirstName: {
    width: '100%',
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  searchFieldByLastName: {
    width: '100%',
    marginBottom: theme.spacing(1)
  },
  searchForm: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      display: 'block'
    }
  },
  resetBtn: {
    marginLeft: 20,
    minWidth: 120,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      margin: `${theme.spacing(1)} 0`
    }
  },
  refreshBtn: {
    marginLeft: 10,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      margin: `${theme.spacing(1)} 0`
    }
  },
  filters: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'block'
    }
  },
  results: {
    marginTop: theme.spacing(3),
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  outputFilter: {
    width: '100%',
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      margin: `${theme.spacing(1)} 0`,
      maxWidth: '100%'
    }
  },
  batchFilter: {
    width: '100%',
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      margin: `${theme.spacing(1)} 0`,
      maxWidth: '100%'
    }
  },
  searchPeriodFilter: {
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      margin: `${theme.spacing(1)} 0`
    }
    // marginRight: theme.spacing(2)
  },
  searchPeriodDatePickerWrapper: {
    width: '100%',

    [theme.breakpoints.down('xs')]: {
      margin: `${theme.spacing(1)} 0`
    }
  },
  searchPeriodDatePicker: {
    width: '50%',
    [theme.breakpoints.down('xs')]: {
      margin: `${theme.spacing(1)} 0`
    }
  }
});

export default styles;
