// @flow

import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Controller } from 'react-hook-form';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';

type Props = {
  control: Object,
  className: String,
  label: String,
  name: String,
  required: Boolean,
  simple: Boolean,
  customOptions: Array,
  setValue: () => void
};

const AutoComplete = (props: Props) => {
  const {
    control,
    className,
    label,
    name,
    required,
    simple,
    customOptions,
    setValue
  } = props;
  const [inputValue, setInputValue] = useState('');

  let options = {
    componentRestrictions: { country: 'us' }
  };

  if (name === 'patient_city' || name === 'patient_zip') {
    options.types = ['(regions)'];
  }

  const { placePredictions, getPlacePredictions, isPlacePredictionsLoading } =
    usePlacesService({
      apiKey: process.env.GOOGLE_MAPS_API_KEY,
      options: options
    });

  useEffect(() => {
    let timeout;
    if (!customOptions) {
      timeout = setTimeout(
        () => getPlacePredictions({ input: inputValue }),
        300
      );
    }
    return () => clearTimeout(timeout);
  }, [inputValue, setValue]);

  const handleSuggestion = (data) => {
    if (!data || !data.description) return;

    const addressArr = data.description.split(',');

    const address = addressArr[0];
    const city = addressArr[1];
    // const state = addressArr[2];

    if (address) {
      setValue('patient_address', address, {
        shouldValidate: true,
        shouldTouch: true,
        shouldDirty: true
      });
    }
    if (city) {
      setValue('patient_city', city, {
        shouldValidate: true,
        shouldTouch: true,
        shouldDirty: true
      });
    }
    // if (state) {
    //   setValue('patient_state', state, {
    //     shouldValidate: true,
    //     shouldTouch: true,
    //     shouldDirty: true
    //   });
    // }
  };

  return (
    <Controller
      render={({ field }) => (
        <Autocomplete
          /* eslint-disable react/prop-types */
          onChange={(_, data) => {
            if (name === 'patient_address' && setValue) {
              handleSuggestion(data);
            } else {
              field.onChange(
                typeof data === 'string'
                  ? data
                  : data && data.structured_formatting
                  ? data.structured_formatting.main_text
                  : ''
              );
            }
          }}
          value={field.value ? field.value : inputValue}
          options={customOptions ? customOptions : placePredictions}
          getOptionLabel={(option) => {
            if (!simple) {
              return typeof option === 'string' ? option : option.description;
            } else {
              return typeof option === 'string'
                ? option
                : option.structured_formatting.main_text;
            }
          }}
          freeSolo
          loading={isPlacePredictionsLoading}
          includeInputInList
          filterSelectedOptions
          renderOption={(props, option) => (
            <Grid {...props} container alignItems="center">
              <Grid item>
                <LocationOnIcon style={{ marginRight: 10 }} />
              </Grid>
              <Grid item xs>
                {typeof option === 'string'
                  ? option
                  : option.structured_formatting.main_text}
                {!simple ? (
                  <Typography variant="body2" color="textSecondary">
                    {typeof option === 'string'
                      ? option
                      : option.structured_formatting.secondary_text}
                  </Typography>
                ) : null}
              </Grid>
            </Grid>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label ? label : 'Street, suite, city, state, zip'}
              variant="outlined"
              className={className}
              onChange={(e) => {
                setInputValue(e.target.value);
                field.onChange(e.target.value);
              }}
              required={required}
              value={field.value ? field.value : inputValue}
            />
          )}
        />
      )}
      name={name ? name : 'address'}
      control={control}
      rules={{ required: required ? `${label} is required` : false }}
    />
  );
};

export default AutoComplete;
