// @flow

import React, { useState, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import * as R from 'ramda';
import { Amplify } from 'aws-amplify';
import {
  format,
  sub,
  parseISO,
  startOfWeek,
  startOfMonth,
  startOfQuarter,
  startOfYear,
  subQuarters,
  endOfWeek,
  endOfMonth,
  endOfQuarter,
  endOfYear
} from 'date-fns';

import { withStyles } from 'tss-react/mui';
import {
  Button,
  TextField,
  Typography,
  Tooltip,
  IconButton,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';

// New Material UI v5 pkg
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import NewTextField from '@mui/material/TextField';
import enLocale from 'date-fns/locale/en-US';

import { AMPLIFY_CONFIG } from 'settings/aws-config';
import MDPBackend from 'services/MDPBackend';
import { log } from 'utils/jsUtils';

import styles from './Styles/QueryFilters.Style';

Amplify.configure(AMPLIFY_CONFIG);

const outputs = ['All CDAs', 'Non-empty CDAs', 'Empty CDAs', 'Compendium'];

const dates = {
  today: {
    from: format(new Date(), 'yyyy-MM-dd'),
    to: format(new Date(), 'yyyy-MM-dd')
  },
  thisWeek: {
    from: format(startOfWeek(new Date()), 'yyyy-MM-dd'),
    to: format(new Date(), 'yyyy-MM-dd')
  },
  thisMonth: {
    from: format(startOfMonth(new Date()), 'yyyy-MM-dd'),
    to: format(new Date(), 'yyyy-MM-dd')
  },
  thisQuarter: {
    from: format(startOfQuarter(new Date()), 'yyyy-MM-dd'),
    to: format(new Date(), 'yyyy-MM-dd')
  },
  thisYear: {
    from: format(startOfYear(new Date()), 'yyyy-MM-dd'),
    to: format(new Date(), 'yyyy-MM-dd')
  },
  yesterday: {
    from: format(sub(new Date(), { days: 1 }), 'yyyy-MM-dd'),
    to: format(sub(new Date(), { days: 1 }), 'yyyy-MM-dd')
  },
  lastWeek: {
    from: format(startOfWeek(sub(new Date(), { weeks: 1 })), 'yyyy-MM-dd'),
    to: format(endOfWeek(sub(new Date(), { weeks: 1 })), 'yyyy-MM-dd')
  },
  lastMonth: {
    from: format(startOfMonth(sub(new Date(), { months: 1 })), 'yyyy-MM-dd'),
    to: format(endOfMonth(sub(new Date(), { months: 1 })), 'yyyy-MM-dd')
  },
  lastQuarter: {
    from: format(startOfQuarter(subQuarters(new Date(), 1)), 'yyyy-MM-dd'),
    to: format(endOfQuarter(subQuarters(new Date(), 1)), 'yyyy-MM-dd')
  },
  lastYear: {
    from: format(startOfYear(sub(new Date(), { years: 1 })), 'yyyy-MM-dd'),
    to: format(endOfYear(sub(new Date(), { years: 1 })), 'yyyy-MM-dd')
  }
};

const searchPeriods = [
  {
    name: 'Today',
    value: 'today'
  },
  {
    name: 'This week',
    value: 'thisWeek'
  },
  {
    name: 'This month',
    value: 'thisMonth'
  },
  {
    name: 'This quarter',
    value: 'thisQuarter'
  },
  {
    name: 'This year',
    value: 'thisYear'
  },
  {
    name: 'Yesterday',
    value: 'yesterday'
  },
  {
    name: 'Last week',
    value: 'lastWeek'
  },
  {
    name: 'Last month',
    value: 'lastMonth'
  },
  {
    name: 'Last quarter',
    value: 'lastQuarter'
  },
  {
    name: 'Last year',
    value: 'lastYear'
  }
];

type Props = {
  classes: Object,
  loading: Boolean,
  setLoading: () => void,
  setStatsLoading: () => void,
  setQueries: () => void,
  setTotalRows: () => void,
  setUrlQueryParams: () => void,
  totalRows: Number,
  setQueryStats: () => void,
  parseSearchParams: () => void
};

const QueryFilters = (props: Props): React.Node => {
  const {
    classes,
    loading,
    setLoading,
    setQueries,
    setTotalRows,
    setUrlQueryParams,
    totalRows,
    setQueryStats,
    parseSearchParams,
    setStatsLoading
  } = props;

  const [batchNames, setBatchNames] = useState([]);

  const [searchParams, setSearchParams] = useSearchParams();

  const params = useMemo(
    () =>
      Array.from(searchParams).reduce(
        (acc, [key, value]) => ({ ...acc, [key]: value }),
        {}
      ),
    [searchParams]
  );

  const [searchStr, setSearchStr] = useState('');
  const [createdQueryPeriod, setCreatedQueryPeriod] = useState({
    createdFrom: '',
    periodName: '',
    createdTo: ''
  });

  // const location = useLocation();
  // const navigate = useNavigate();

  const getQueriesByOrg = async (searchParams) => {
    const searchString = parseSearchParams(searchParams);
    setLoading(true);

    try {
      const response = await MDPBackend.getQueriesByOrg(searchString);
      const parsedResponse = JSON.parse(response.data.body);

      console.log('QparsedResponse:: ', parsedResponse);

      const data = parsedResponse.data;

      setQueries(data);
      setTotalRows(parsedResponse.total);
    } catch (err) {
      log('[getQueriesByOrg] err: ', err);
    } finally {
      setLoading(false);
    }
  };

  const getQueriesByOrgStats = async (searchParams) => {
    const searchString = parseSearchParams(searchParams);
    setStatsLoading(true);

    try {
      const response = await MDPBackend.getQueriesByOrgStats(searchString);
      const parsedResponse = JSON.parse(response.data.body);

      console.log('parsedResponse:: ', parsedResponse);

      const totalRows = parsedResponse.total;
      const avgRafUplift = parsedResponse.avgRafUplift;
      const totalCompendiums = parsedResponse.totalCompendiums;
      const totalCDAs = parsedResponse.totalCDAs;
      const totalCompletedQueries = parsedResponse.totalCompletedQueries;
      const totalCharts = parsedResponse.totalCharts;

      const totalCDAsPercentage = ((totalCDAs / totalRows) * 100 || 0).toFixed(
        2
      );
      const totalCompendiumsPercentage = (
        (totalCompendiums / totalRows) * 100 || 0
      ).toFixed(2);
      const totalCompletedQueriesPercentage = (
        (totalCompletedQueries / totalRows) * 100 || 0
      ).toFixed(2);

      const totalChartsPercentage = (
        (totalCharts / totalRows) * 100 || 0
      ).toFixed(2);

      setQueryStats({
        total: totalRows,
        avgRafUplift: avgRafUplift,
        totalCompendiums: totalCompendiums,
        totalCompendiumsPercentage: `${totalCompendiumsPercentage}%`,
        totalCharts: totalCharts,
        totalChartsPercentage: `${totalChartsPercentage}%`,
        totalCDAs: totalCDAs,
        totalCDAsPercentage: `${totalCDAsPercentage}%`,
        totalCompletedQueries: totalCompletedQueries,
        totalCompletedQueriesPercentage: `${totalCompletedQueriesPercentage}%`
      });
    } catch (err) {
      log('[getQueriesByOrg] err: ', err);
    } finally {
      setStatsLoading(false);
    }
  };

  const refresh = async () => {
    await getQueriesByOrg(params);
    await getQueriesByOrgStats(params);
  };

  const resetSearchParams = () => {
    setSearchStr('');
    setDatesBasedOnPeriodName('');

    setSearchParams({ batchName: batchNames[0]?.batchName });
  };

  const handleSearchStrValue = (e) => {
    e.preventDefault();

    setSearchStr(e.target.value);
  };

  const handleSearch = (e) => {
    e.preventDefault();

    setUrlQueryParams({ searchStr });
  };

  const handleOutputFilter = (e) => {
    const output = e.target.value;
    // console.log('[handleHasOutputFilterChange] output: ', output);

    setUrlQueryParams({ output });
  };

  const handleBatchNameFilter = (e) => {
    const batchName = e.target.value;
    // console.log('[handleHasOutputFilterChange] batchName: ', batchName);

    const batch = batchNames.find((b) => b.batchName === batchName) || {
      batchName: ''
    };

    const createdFrom = batch.createdDt
      ? format(
          new Date(batch.createdDt).setDate(
            new Date(batch.createdDt).getDate() - 1
          ),
          'yyyy-MM-dd'
        )
      : dates.thisYear.from;

    setUrlQueryParams({
      batchName: batch.batchName,
      createdFrom,
      createdTo: dates.today.from
    });
  };

  const setDatesBasedOnPeriodName = (periodName) => {
    // console.log('[setDatesBasedOnPeriodName] periodName: ', periodName);
    const selectedPeriod = R.find(R.propEq('name', periodName))(searchPeriods);
    // console.log('[setDatesBasedOnPeriodName] selectedPeriod: ', selectedPeriod);
    if (typeof selectedPeriod !== 'undefined' && selectedPeriod !== null) {
      const createdFrom = dates[selectedPeriod.value].from;
      const createdTo = dates[selectedPeriod.value].to;
      // console.log('[setDatesBasedOnPeriodName] periodName: ', periodName);
      // console.log('[setDatesBasedOnPeriodName] createdFrom: ', createdFrom);
      // console.log('[setDatesBasedOnPeriodName] createdTo: ', createdTo);
      setCreatedQueryPeriod({
        periodName,
        createdFrom,
        createdTo
      });

      setUrlQueryParams({
        periodName,
        createdFrom,
        createdTo: dates.today.from
      });
    }
  };

  const onCreatedInputChange = (e) => {
    setDatesBasedOnPeriodName(e.target.value);
    // console.log('onCreatedInputChange: ', e.target);
    // const selectedPeriod = R.find(R.propEq('name', e.target.value))(
    //   searchPeriods
    // );
    // console.log('[onCreatedInputChange] selectedPeriod: ', selectedPeriod);
    // const periodName = selectedPeriod.name;
    // const createdFrom = dates[selectedPeriod.value];
    // console.log('onCreatedInputChange: periodName: ', periodName);
    // console.log('onCreatedInputChange: createdFrom: ', createdFrom);
    // setCreatedQueryPeriod({
    //   periodName,
    //   createdFrom,
    //   createdTo: '' // TODO
    // });
  };
  const onCreatedFromPickerChange = (value) => {
    console.log('[onCreatedFromPickerChange]... value: ', value);
    // console.log('parsed date: ', format(toDate(new Date(value)), 'yyyy-MM-dd'));
    const createdFrom = format(value, 'yyyy-MM-dd');

    setCreatedQueryPeriod({
      ...createdQueryPeriod,
      periodName: '',
      createdFrom: createdFrom
    });

    setUrlQueryParams({
      periodName: '',
      createdFrom
    });

    refresh();
  };
  const onCreatedToPickerChange = (value) => {
    // console.log('[onCreatedToPickerChange] value: ', value);
    const createdTo = format(value, 'yyyy-MM-dd');
    setCreatedQueryPeriod({ ...createdQueryPeriod, to: createdTo });
    setUrlQueryParams({ createdTo });
  };

  const getBatchNames = async () => {
    try {
      const response = await MDPBackend.getBatchNames();
      const parsedBody = JSON.parse(response.data.body);

      console.log('[getBatchNames] parsedBody: ', parsedBody);

      const batchNames = parsedBody.batchNames;
      setUrlQueryParams({
        batchName: searchParams.has('batchName')
          ? params.batchName
          : batchNames[0].batchName
      });
      setBatchNames(batchNames);
    } catch (err) {
      console.log('[getBatchNames] err: ', err);
    }
  };

  useEffect(() => {
    getBatchNames();
  }, []);

  useEffect(() => {
    // console.log('[useEffect(location.search)] params: ', params);

    if (params.searchStr) {
      setSearchStr(params.searchStr);
    }

    if (params.createdFrom || params.createdTo) {
      setCreatedQueryPeriod({
        createdFrom: params.createdFrom,
        createdTo: params.createdTo,
        periodName: params.periodName
      });
    } else if (params.periodName) {
      setDatesBasedOnPeriodName(params.periodName);
    }
    if (searchParams.has('batchName')) {
      refresh();
    }
  }, [params, searchParams]);

  // console.log('[render] createdQueryPeriod: ', createdQueryPeriod);
  return (
    <div className={classes.root}>
      <form className={classes.searchForm} onSubmit={handleSearch}>
        <TextField
          label="Search by Patient first name, last name, DOB, ID, or query ID"
          variant="outlined"
          className={classes.searchFieldById}
          value={searchStr}
          onChange={handleSearchStrValue}
          disabled={loading}
        />
        <Button
          className={classes.resetBtn}
          onClick={() => resetSearchParams()}
          disabled={loading}
        >
          Reset Filters
        </Button>
        <input
          type="submit"
          style={{ visibility: 'hidden', width: 0, height: 0 }}
        />
      </form>
      <div className={classes.filters}>
        <FormControl className={classes.outputFilter} disabled={loading}>
          <InputLabel variant="outlined" id="output-label">
            Filter by Output
          </InputLabel>
          <Select
            variant="outlined"
            id="output"
            value={params?.output || ''}
            onChange={handleOutputFilter}
            input={
              <OutlinedInput id="select-multiple-chip" label="output-label" />
            }
          >
            {outputs
              ? outputs.map((src, idx) => (
                  <MenuItem key={idx} value={src}>
                    {src}
                  </MenuItem>
                ))
              : null}
          </Select>
        </FormControl>
        <FormControl className={classes.searchPeriodFilter} disabled={loading}>
          <InputLabel variant="outlined" id="period-label">
            Query created within
          </InputLabel>
          <Select
            labelId="period-label"
            variant="outlined"
            id="search"
            value={createdQueryPeriod.periodName || ''}
            onChange={onCreatedInputChange}
            input={
              <OutlinedInput id="select-multiple-chip" label="period-label" />
            }
          >
            {searchPeriods.map((period, idx) => (
              <MenuItem key={idx} value={period.name}>
                {period.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className={classes.filters}>
        <FormControl className={classes.batchFilter} disabled={loading}>
          <InputLabel variant="outlined" id="output-label">
            Filter by Batch
          </InputLabel>
          <Select
            variant="outlined"
            id="output"
            value={params?.batchName || ''}
            onChange={handleBatchNameFilter}
            input={
              <OutlinedInput id="select-multiple-chip" label="output-label" />
            }
          >
            <MenuItem key="blank" value="">
              None
            </MenuItem>
            {batchNames
              ? batchNames.map((batch, idx) => (
                  <MenuItem key={idx} value={batch.batchName}>
                    {batch.batchName}
                  </MenuItem>
                ))
              : null}
          </Select>
        </FormControl>
        <form
          className={classes.searchPeriodDatePickerWrapper}
          onSubmit={handleSearch}
        >
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={enLocale}
          >
            <DatePicker
              label={'Query created from'}
              value={parseISO(createdQueryPeriod.createdFrom)}
              onChange={onCreatedFromPickerChange}
              renderInput={(rParams) => (
                <NewTextField
                  {...rParams}
                  type="outlined"
                  error={false}
                  className={classes.searchPeriodDatePicker}
                />
              )}
              disabled={loading}
              // inputFormat="yyyy-MM-dd"
            />
          </LocalizationProvider>
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={enLocale}
          >
            <DatePicker
              label="Query created to"
              value={parseISO(createdQueryPeriod.createdTo)}
              onChange={onCreatedToPickerChange}
              renderInput={(rParams) => (
                <NewTextField
                  {...rParams}
                  type="outlined"
                  error={false}
                  className={classes.searchPeriodDatePicker}
                />
              )}
              disabled={loading}
              // inputFormat="yyyy-MM-dd"
            />
          </LocalizationProvider>
        </form>
      </div>
      <div className={classes.results}>
        <Typography variant="body1" className={classes.queriesFound}>
          {totalRows === 0 ? ' No' : totalRows}
          {totalRows === 1 ? ' query' : ' queries'} found
        </Typography>
        <Tooltip title="Refresh results">
          <IconButton className={classes.refreshBtn} onClick={() => refresh()}>
            <RefreshIcon />
          </IconButton>
        </Tooltip>
        {loading ? (
          <CircularProgress size={20} style={{ marginLeft: 20 }} />
        ) : null}
      </div>
    </div>
  );
};

export default withStyles(QueryFilters, styles);
