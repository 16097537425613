// @flow

import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import * as R from 'ramda';

const boxStyle = {
  position: 'absolute',
  width: '100%',
  maxWidth: 1200,
  padding: '2rem 2rem',
  height: '100%',
  maxHeight: 800,
  backgroundColor: 'white',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  overflow: 'auto',
  outline: 'none',
  borderRadius: '1rem'
};

const DemographicsModal = (props: Props) => {
  const { handleClose, data } = props;

  if (!data) return <div></div>;

  return (
    <div>
      <Modal
        open={!!data}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box style={boxStyle}>
          <Typography variant="h1" style={{ marginBottom: 20 }}>
            Demographics for Query ID: {data.queryId}
          </Typography>
          {Object.keys(R.omit(['queryId'], data)).map((key, idx) => (
            <div key={idx} style={{ marginBottom: 10 }}>
              {key}: <strong>{data[key]}</strong>
            </div>
          ))}
        </Box>
      </Modal>
    </div>
  );
};

export default DemographicsModal;
