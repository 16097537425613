"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _CDAMainViewer = require("./CDAMainViewer");

Object.keys(_CDAMainViewer).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _CDAMainViewer[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _CDAMainViewer[key];
    }
  });
});

var _FileUpload = require("./FileUpload");

Object.keys(_FileUpload).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _FileUpload[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _FileUpload[key];
    }
  });
});

var _XMLViewer = require("./XMLViewer");

Object.keys(_XMLViewer).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _XMLViewer[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _XMLViewer[key];
    }
  });
});

var _PDFViewer = require("./PDFViewer");

Object.keys(_PDFViewer).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _PDFViewer[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _PDFViewer[key];
    }
  });
});

var _AttachmentViewer = require("./AttachmentViewer");

Object.keys(_AttachmentViewer).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _AttachmentViewer[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _AttachmentViewer[key];
    }
  });
});