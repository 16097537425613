// @flow

import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { RedocStandalone } from 'redoc';
import Button from '@mui/material/Button';

import { withStyles } from 'tss-react/mui';
import { useDispatch } from 'react-redux';

import PageTemplate from 'layout/PageTemplate';
import MDPBackend from 'services/MDPBackend';
import { log } from 'utils/jsUtils';

import RequestApiKeyDialog from './RequestApiKeyDialog';

import styles from './Styles/Docs.Styles';

import './redoc.css';
import { showToastMsg } from 'features/toast-message-slice';

type Props = {
  classes: Object
};

const ApiDocs = (props: Props) => {
  const { classes } = props;
  const [documentation, setDocumentation] = useState(null);
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [requestApiKeyDialogOpen, setRequestApiKeyDialogOpen] = useState(false);

  const dispatch = useDispatch();

  const requestApiKeys = async (payload) => {
    setLoadingRequest(true);

    try {
      const response = await MDPBackend.requestApiKeys(payload);

      const parsedResponseBody = JSON.parse(response.data.body);

      dispatch(
        showToastMsg({
          open: true,
          message: parsedResponseBody.message,
          level: 'success',
          duration: 5000
        })
      );

      setLoadingRequest(false);
    } catch (error) {
      log('[requestApiKeys] error: ', error);
      dispatch(
        showToastMsg({
          open: true,
          message: 'An error occured',
          level: 'error',
          duration: 5000
        })
      );
      setLoadingRequest(false);
    } finally {
      setRequestApiKeyDialogOpen(false);
    }
  };

  const getDoc = async () => {
    const response = await MDPBackend.getApiDocumentation();
    const parsedData = JSON.parse(response.data.body).data;
    const parsedDocument = JSON.parse(parsedData);

    setDocumentation(parsedDocument);
  };

  const handleDialogOpen = () => {
    setRequestApiKeyDialogOpen(true);
  };

  const handleDialogClose = () => {
    setRequestApiKeyDialogOpen(false);
  };

  useEffect(() => {
    getDoc();
  }, []);

  return (
    <PageTemplate>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Docs</title>
      </Helmet>
      <div style={{ marginTop: 67 }}>
        {documentation ? (
          <>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.requestBtn}
              onClick={handleDialogOpen}
              disabled={loadingRequest}
            >
              {loadingRequest ? 'Requesting...' : 'Request API keys'}
            </Button>
            <RedocStandalone spec={documentation} />
          </>
        ) : (
          'Loading...'
        )}
      </div>
      <RequestApiKeyDialog
        open={requestApiKeyDialogOpen}
        onClose={handleDialogClose}
        requestApiKeys={requestApiKeys}
      />
    </PageTemplate>
  );
};

export default withStyles(ApiDocs, styles);
