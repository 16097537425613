import { createSlice } from '@reduxjs/toolkit';

const initialState = null;

const currentOrganizationSlice = createSlice({
  name: 'currentOrganization',
  initialState,
  reducers: {
    setCurrentOrganization(state, action) {
      return action.payload;
    }
  }
});

export const { setCurrentOrganization } = currentOrganizationSlice.actions;
export default currentOrganizationSlice.reducer;
