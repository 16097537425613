// @flow

import { createTheme } from '@mui/material/styles';

const headerHeight = 90;

const theme: Object = createTheme();

theme.palette = {
  ...theme.palette,
  background: {
    paper: '#fff',
    default: '#fafafa'
  },
  primary: {
    lighter: '#D9EEF2',
    light: '#B2DEE5',
    main: '#008299',
    dark: '#006475',
    contrastText: '#fff'
  },
  secondary: {
    light: '#007085',
    main: '#007085',
    dark: '#007085'
  },
  success: {
    light: '#E6F4EF',
    main: '#4AB289',
    dark: '#337A5E',
    contrastText: '#fff'
  },
  error: {
    light: '#F2CBCA',
    main: '#E3534F',
    dark: '#C6241F',
    contrastText: '#fff'
  }
};

theme.layout = {
  responsiveScreenHeight: 'var(--vh, 1vh) * 100',
  header: {
    height: 64
  },
  footer: {
    height: 100
  }
};

theme.mdportals = {
  toolbar: {
    height: headerHeight,
    minHeight: headerHeight,
    [theme.breakpoints.down('sm')]: {
      minHeight: headerHeight + theme.spacing(1)
    }
  }
};

theme.typography = {
  ...theme.typography,
  root: {
    fontSize: '1.05rem',
    opacity: 1,
    // color: '#FFFFFF',
    fontFamily: ['SourceSansPro', 'Roboto Condensed', 'sans-serif'].join(',')
  },
  h1: {
    ...theme.typography.h1,
    fontSize: '1.7rem',
    opacity: 1,
    fontWeight: 600,
    letterSpacing: '0.6px',
    fontFamily: ['SourceSansPro', 'Roboto Condensed', 'sans-serif'].join(','),
    whiteSpace: 'pre-wrap',
    [theme.breakpoints.down('xs')]: {
      fontSize: '2rem'
    }
  },
  h2: {
    ...theme.typography.h2,
    fontSize: '1.3rem',
    fontWeight: 600,
    marginBottom: theme.spacing(1),
    fontFamily: ['SourceSansPro', 'Roboto Condensed', 'sans-serif'].join(','),
    whiteSpace: 'pre-wrap',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.7rem'
    }
  },
  h3: {
    ...theme.typography.h3,
    fontSize: '1rem',
    fontWeight: 600,
    fontFamily: ['SourceSansPro', 'Roboto Condensed', 'sans-serif'].join(','),
    whiteSpace: 'pre-wrap',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.7rem'
    }
  },
  h4: {
    ...theme.typography.h4,
    fontSize: '1.8rem',
    fontWeight: 600,
    fontFamily: ['SourceSansPro', 'Roboto Condensed', 'sans-serif'].join(','),
    whiteSpace: 'pre-wrap',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.3rem'
    }
  },
  h5: {
    ...theme.typography.h5,
    fontSize: '1.5rem',
    fontWeight: 600,
    fontFamily: ['SourceSansPro', 'Roboto Condensed', 'sans-serif'].join(','),
    whiteSpace: 'pre-wrap',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.3rem'
    }
  },
  h6: {
    ...theme.typography.h6,
    fontSize: '0.98rem',
    fontWeight: 600,
    fontFamily: ['SourceSansPro', 'Roboto Condensed', 'sans-serif'].join(','),
    whiteSpace: 'pre-wrap'
  },
  body1: {
    // color: '#FFFFFF',
    fontSize: '1.05rem',
    fontWeight: 400,
    opacity: 1,
    fontFamily: ['SourceSansPro', 'RobotoCondensed', 'sans-serif'].join(',')
  },
  body2: {
    // color: '#FFFFFF',
    fontWeight: 400,
    fontSize: '1.05rem',
    fontFamily: ['SourceSansPro', 'RobotoCondensed', 'sans-serif'].join(','),
    whiteSpace: 'normal'
  },
  button: {
    fontSize: '1.05rem',
    fontFamily: ['SourceSansPro', 'RobotoCondensed', 'sans-serif'].join(','),
    fontWeight: 600
  },
  pre: {
    fontFamily: ['SourceSansPro', 'RobotoCondensed', 'sans-serif'].join(','),
    whiteSpace: 'pre-wrap'
  }
};

theme.overrides = {
  MuiAppBar: {
    colorPrimary: {
      backgroundColor: '#363636'
    },
    colorSecondary: {
      backgroundColor: 'transparent',
      color: 'black'
    }
  },
  // MuiTableCell: {
  //   root: {

  //   }
  MuiTableRow: {
    root: {
      verticalAlign: 'top'
    }
  }
};

export default theme;
