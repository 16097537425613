import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

const organizationsSlice = createSlice({
  name: 'organizations',
  initialState,
  reducers: {
    setOrganizations(state, action) {
      return action.payload;
    }
  }
});

export const { setOrganizations } = organizationsSlice.actions;
export default organizationsSlice.reducer;
