// @flow

import React from 'react';
import { Link } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import { withStyles } from 'tss-react/mui';
import { IconButton, Typography } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import PageTemplate from 'layout/PageTemplate';
import { AMPLIFY_CONFIG } from 'settings/aws-config';
import { QUERIES } from 'settings/constants';
import QueryForm from './QueryForm';
import styles from './Styles/NewQuery.Style';

Amplify.configure(AMPLIFY_CONFIG);

type Props = {
  classes: Object
};

const Queries = (props: Props): React.Node => {
  const { classes } = props;

  return (
    <PageTemplate>
      <div className={classes.wrapper}>
        <div className={classes.container}>
          <div>
            <div className={classes.title}>
              <Link
                to={QUERIES}
                className={classes.goBackBtn}
                style={{ textDecoration: 'none' }}
              >
                <IconButton>
                  <ArrowBack />
                </IconButton>
              </Link>
              <Typography variant="h1" className={classes.title}>
                Start a new query
              </Typography>
            </div>
            <QueryForm />
          </div>
        </div>
      </div>
    </PageTemplate>
  );
};

export default withStyles(Queries, styles);
