// @flow

const styles = (theme: Object) => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%'
  },
  container: {
    marginTop: 100,
    margin: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(1)
    },
    display: 'block'
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 30
  },
  goBackBtn: {
    marginRight: 10
  },
  formCtr: {
    display: 'block',
    maxWidth: 500,
    margin: '0 auto',
    marginTop: theme.spacing(3)
  },
  formSubTitle: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(1)
  },
  txtField: {
    width: '100%',
    marginTop: theme.spacing(1)
  },
  optionField: { width: '100%', marginTop: theme.spacing(1) },
  verifyBtn: {
    marginTop: theme.spacing(2),
    width: '100%',
    padding: 12
  },
  submitBtn: {
    marginTop: theme.spacing(3),
    width: '100%',
    maxWidth: 500,
    padding: 12
  },
  uploadBtn: {
    marginTop: theme.spacing(2),
    width: '100%'
  },
  fieldsToVerify: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2)
  },
  fieldsWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%'
  },
  row: { display: 'flex', marginBottom: theme.spacing(1) },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    color: 'white',
    border: 'none',
    backgroundColor: theme.palette.background.secondary,
    height: 32,
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(0.5),
    whiteSpace: 'nowrap',
    borderRadius: 16,
    padding: `0 ${theme.spacing(1)}`,
    fontSize: '0.8rem'
  },
  box: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    width: '100%'
  },
  boxRow: {
    flex: '0 0 auto',
    display: 'flex',
    justifyContent: 'center',
    borderStyle: 'dashed',
    borderColor: '#eeeeee',
    cursor: 'pointer'
  },
  uploadIcon: {
    '.MuiSvgIcon-root': {
      '.d': {
        fontSize: '2.5rem'
      }
    }
  }
});

export default styles;
