// @flow

import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const GainSight = () => {
  const { user } = useSelector((state) => state.user);
  const currentOrganization = useSelector((state) => state.currentOrganization);
  const location = useLocation();

  useEffect(() => {
    if (user && currentOrganization && window.aptrinsic) {
      const organizationName = currentOrganization.name;
      window.aptrinsic(
        'identify',
        {
          id: user._id,
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
          roleName: user.role,
          signUpDate: user.createdDt
        },
        {
          id: organizationName,
          name: organizationName,
          Program: 'EHR Web'
        }
      );
    }
  }, [user, currentOrganization, location]);

  return <></>;
};

export default GainSight;
