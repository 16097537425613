// @flow

import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { ForwardRef } from 'components';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import SourceFeedback from './SourceFeedback';
import MDPBackend from 'services/MDPBackend';

type SourceHistoryListProps = {
  factName: String,
  hccId: String,
  patientId: String,
  classes: Object,
  setPdfViwerSrc: Function
};

const SourceHistoryList = (props: SourceHistoryListProps) => {
  const { factName, hccId = '', patientId, classes, setPdfViwerSrc } = props;
  const [sourceHistory, setSourceHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state) => state.user);
  const currentNlpSource = useSelector(
    (state) => state.nlpSource.currentNlpSource
  );
  console.log('[SourceHistoryList] props: ', props);
  console.log('[SourceHistoryList] patientId: ', patientId);

  const getSourceHistory = async () => {
    console.log('[getSourceHistory]... ');

    setLoading(true);

    console.log('[getSourceHistory] patientId: ', patientId);

    try {
      const response = await MDPBackend.getSourceHistory(
        patientId,
        hccId,
        factName
      );
      const parsedResponse = JSON.parse(response.data.body);

      console.log('[getSourceHistory] parsedResponse: ', parsedResponse);

      setSourceHistory(parsedResponse?.data);
    } catch (error) {
      console.log('[getSourceHistory] error: ', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log('[SourceHistoryList] useEffect... ');
    getSourceHistory();
  }, []);

  return (
    <>
      {loading ? (
        <>
          <CircularProgress style={{ marginTop: 20 }} size={25} />
        </>
      ) : sourceHistory?.length ? (
        <>
          {sourceHistory.map((src, idx) => (
            <div key={idx} className={classes.sourceCard}>
              {src.date ? (
                <div className={classes.dateWrapper}>
                  <Typography className={classes.date}>{src.date}</Typography>
                </div>
              ) : null}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-start'
                }}
              >
                {src.factValue ? (
                  <div style={{ flex: 1 }}>
                    <Typography className={classes.factValue}>
                      {currentNlpSource === 'HAL' ||
                      currentNlpSource === 'BENCHMARK'
                        ? `${src.factValue}`
                        : `Supportive Evidence: ${src.factValue}`}
                    </Typography>
                  </div>
                ) : null}
                {user?.isReveleerTeam && (
                  <Grid
                    item
                    sm={2}
                    md={1}
                    style={{ marginLeft: 'auto', flexShrink: 0 }}
                  >
                    <SourceFeedback
                      userFeedback={src.userFeedback}
                      sourceId={src._id}
                    />
                  </Grid>
                )}
              </div>

              {src.excerpt ? (
                <div className={classes.excerpt}>
                  <FontAwesomeIcon
                    style={{ marginRight: 5, color: 'grey' }}
                    icon={solid('quote-left')}
                  />
                  <Typography>{src.excerpt}</Typography>
                </div>
              ) : null}

              {src.annotations ? (
                <div className={classes.annotations}>
                  {Object.keys(src.annotations).map((annotationName) => (
                    <>
                      {src.annotations[annotationName] ? (
                        <TextField
                          size="small"
                          sx={{
                            '& .MuiInputBase-input.Mui-disabled': {
                              WebkitTextFillColor: '#000000',
                              fontSize: '0.9rem',
                              background: 'rgba(0, 150, 136, 0.05)',
                              textAlign: 'left'
                            }
                          }}
                          style={{
                            width:
                              src.annotations[annotationName].length * 6 + 70
                          }}
                          className={classes.annotation}
                          label={annotationName}
                          value={src.annotations[annotationName]}
                          disabled
                        />
                      ) : null}
                    </>
                  ))}
                  {src.sourceLocation.section ? (
                    <div className={classes.annotation}>
                      <TextField
                        size="small"
                        sx={{
                          '& .MuiInputBase-input.Mui-disabled': {
                            WebkitTextFillColor: '#000000',
                            fontSize: '0.9rem!important',
                            background: 'rgba(51, 122, 94, 0.05)',
                            textAlign: 'left'
                          },
                          width: src.sourceLocation.section.length * 6 + 70
                        }}
                        className={classes.annotation}
                        label={`Section`}
                        value={src.sourceLocation.section}
                        disabled
                      />
                    </div>
                  ) : null}
                </div>
              ) : null}

              {src.links.length ? (
                <div style={{ marginTop: '1rem' }}>
                  <div style={{ display: 'inline-flex' }}>
                    {src.links.map((sourceLink, k) =>
                      sourceLink.label === 'PDF' ? (
                        <Button
                          variant="outlined"
                          key={k}
                          className={classes.externalLinkBtn}
                          onClick={() => setPdfViwerSrc(src)}
                        >
                          {sourceLink.label}
                          <ForwardRef>
                            <FontAwesomeIcon
                              icon={solid('external-link')}
                              className={classes.icon}
                            />
                          </ForwardRef>
                        </Button>
                      ) : (
                        <Button
                          variant="outlined"
                          key={k}
                          className={classes.externalLinkBtn}
                          href={sourceLink.url}
                          target="_blank"
                        >
                          {sourceLink.label}
                          <ForwardRef>
                            <FontAwesomeIcon
                              icon={solid('external-link')}
                              className={classes.icon}
                            />
                          </ForwardRef>
                        </Button>
                      )
                    )}
                  </div>
                </div>
              ) : null}
            </div>
          ))}
        </>
      ) : null}
    </>
  );
};

export default SourceHistoryList;
