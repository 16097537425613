import { configureStore } from '@reduxjs/toolkit';
import loadingReducer from '../features/loading-slice';
import toastMsgReducer from '../features/toast-message-slice';
import userReducer from '../features/user-slice';
import organizationsReducer from '../features/organizations-slice';
import currentOrganizationReducer from '../features/currentOrganization-slice';
import halOrganzationReducer from '../features/halOrganization-slice';
import { setupListeners } from '@reduxjs/toolkit/query';
import { apiSlice } from '../api/api';
import loginReducer from 'features/login-slice';
import nlpSourceReducer from 'features/nlp-slice';

const reduxStore = configureStore({
  reducer: {
    user: userReducer,
    organizations: organizationsReducer,
    currentOrganization: currentOrganizationReducer,
    halOrganization: halOrganzationReducer,
    nlpSource: nlpSourceReducer,
    login: loginReducer,
    loading: loadingReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
    toastMsg: toastMsgReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware)
});

setupListeners(reduxStore.dispatch);

export default reduxStore;
