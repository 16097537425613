// @flow

import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import InnerHTML from 'dangerously-set-html-content';
import { withStyles } from 'tss-react/mui';
import CircularProgress from '@mui/material/CircularProgress';
import MDPBackend from 'services/MDPBackend';
import { log } from 'utils/jsUtils';
import styles from './Styles/HccCompendium.Styles';
import { showToastMsg } from 'features/toast-message-slice';

type Props = {
  classes: Object
};

const HccCompendium = (props: Props): React.Node => {
  const { classes } = props;
  const [hccCompendiumHtml, setHccCompendiumHtml] = useState(null);
  const [loading, setLoading] = useState(false);

  const params = useParams();
  const location = useLocation();

  /* eslint-disable-next-line no-unused-vars */
  const dispatch = useDispatch();

  const getPatientHccCompendium = async (patientId) => {
    log('[getPatientHccCompendium] requesting....');
    log('[getPatientHccCompendium] patientId: ', patientId);

    const params = queryString.parse(location.search);

    try {
      const response = await MDPBackend.getPatientHccCompendium(
        patientId,
        params.v
      );

      return response.data;
    } catch (error) {
      log('[getPatientHccCompendium] error: ', error);
    }
  };

  const renderPatientHccCompendium = async () => {
    setLoading(true);
    const { patientId } = params;

    const response = await getPatientHccCompendium(patientId);
    log('getPatientHccCompendium response: ', response);

    if (response.statusCode !== 200) {
      const responseMsg = response.body;
      dispatch(
        showToastMsg({
          open: true,
          message: responseMsg ? responseMsg : 'Network Error',
          level: 'error',
          duration: 5000
        })
      );
      setLoading(false);
    } else {
      const parsedUrl = JSON.parse(response.body).data;
      const htmlResponse = await axios.get(parsedUrl, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': '*'
        },
        withCredentials: false
      });
      setHccCompendiumHtml(htmlResponse.data);
    }
  };

  useEffect(() => {
    renderPatientHccCompendium();
  }, [location.search]);

  return (
    <>
      {hccCompendiumHtml ? (
        <InnerHTML html={hccCompendiumHtml} />
      ) : loading ? (
        <div className={classes.messageCentered}>
          <div style={{ display: 'block', textAlign: 'center' }}>
            Requesting Patient HCC Compendium, please wait.
            <br />
            <br />
            <CircularProgress />
          </div>
        </div>
      ) : null}
    </>
  );
};

export default withStyles(HccCompendium, styles);
