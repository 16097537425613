"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.HeaderWrapper = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/esm/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _templateObject;

var HeaderWrapper = _styledComponents.default.div(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  padding: 1.5rem;\n\n  h2 {\n    font-weight: 500;\n    color: #636363;\n    line-height: 1.2;\n    font-size: 1.2rem;\n    width: 100%;\n    text-align: center;\n    margin-bottom: 3rem;\n  }\n\n  .table {\n    display: grid;\n    grid-template-columns: repeat(2, 1fr);\n    row-gap: 1.5rem;\n    column-gap: 2rem;\n    color: #6e6b7b;\n\n    @media (max-width: 500px) {\n      grid-template-columns: repeat(1, 1fr);\n    }\n  }\n\n  .table-col {\n    display: grid;\n    grid-template-columns: 200px auto;\n    background: transparent !important;\n    border: none;\n\n    @media (max-width: 768px) {\n      grid-template-columns: repeat(1, 1fr);\n      row-gap: 1rem;\n    }\n  }\n\n  svg {\n    margin-right: 0.4rem;\n  }\n\n  .table-col-body {\n    background: transparent !important;\n    border: none;\n    padding: 0;\n    display: flex;\n    align-items: center;\n    word-break: break-word;\n  }\n\n  .header-key {\n    font-weight: 400;\n    font-size: 0.8rem;\n  }\n\n  .header-content {\n    font-weight: 500;\n  }\n\n  @media (max-width: 768px) {\n    h2 {\n      font-size: 1.1rem;\n    }\n  }\n"])));

exports.HeaderWrapper = HeaderWrapper;