// @flow

const styles = (theme: Object) => ({
  root: {
    marginTop: 75,
    padding: '15px 30px',
    margin: '0 auto'
  },
  container: {},
  title: {},
  queriesTitle: {
    display: 'flex',
    width: '100%',
    justifyContent: 'left',
    alignItems: 'center',
    marginBottom: theme.spacing(4)
  },
  newQueryButton: {
    marginLeft: 20,
    marginTop: theme.spacing(0.5)
  }
});

export default styles;
