// @flow

import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import queryString from 'query-string';
import axios from 'axios';
import Stack from '@mui/material/Stack';
import SearchIcon from '@mui/icons-material/Search';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import fileDownload from 'js-file-download';
import { withStyles } from 'tss-react/mui';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import PageTemplate from 'layout/PageTemplate';
import { AMPLIFY_CONFIG } from 'settings/aws-config';
import MDPBackend from 'services/MDPBackend';
import { log } from 'utils/jsUtils';
import PatientTable from './PatientTable';
import PatientStats from './PatientStats';
import ExportHCCModal from './ExportHCCModal';
import ExportHCCModalRev from './ExportHCCModalRev';
import styles from './Styles/PatientSearch.Style';
import { useDispatch } from 'react-redux';
import { showToastMsg } from 'features/toast-message-slice';

const STATES = [
  'AL',
  'AK',
  'AS',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'DC',
  'FM',
  'FL',
  'GA',
  'GU',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MH',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'MP',
  'OH',
  'OK',
  'OR',
  'PW',
  'PA',
  'PR',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VI',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY'
];
const DEFAULT_PAGE = 1;
const DEFAULT_PER_PAGE = 50;
const DEFAULT_SORT_BY_ORDER = 'desc';

Amplify.configure(AMPLIFY_CONFIG);
dayjs.extend(relativeTime);

type Props = {
  classes: Object
};

const PatientSearch = (props: Props): React.Node => {
  const { classes } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = queryString.parse(location.search);
  const [loading, setLoading] = useState(false);
  const [patientData, setPatientData] = useState(null);
  const [totalPatients, setTotalPatients] = useState(0);
  const [exportModalOpen, setExportModalOpen] = useState(false);
  const [exportModalRevOpen, setExportModalRevOpen] = useState(false);
  const [batchNames, setBatchNames] = useState([]);
  const [loadingCSV, setLoadingCSV] = useState(false);
  const [loadingHCCsToCSV, setLoadingHCCsToCSV] = useState(false);
  const [loadingHCCsToCSVRev, setLoadingHCCsToCSVRev] = useState(false);
  const [page, setPage] = useState(parseInt(params.page) || DEFAULT_PAGE);
  const [perPage, setPerPage] = useState(
    parseInt(params.perPage) || DEFAULT_PER_PAGE
  );
  const [sortBy, setSortBy] = useState(params.sortBy || '');
  const [sortByOrder, setSortByOrder] = useState(
    params.sortByOrder || DEFAULT_SORT_BY_ORDER
  );
  const [searchValue, setSearchValue] = useState(params.q || '');
  const [states, setStates] = useState(
    params.state ? params.state.split(',') : []
  );
  const [city, setCity] = useState(params.city || '');
  const [zip, setZip] = useState(params.zip || '');
  const [provider, setProvider] = useState(params.provider || '');
  const [isReveleerTeam, setIsReveleerTeam] = useState(false);
  const statsKey = useRef(0);
  const lastSearchParams = useRef(null);

  const setUrlQueryParams = (params) => {
    const currentParams = queryString.parse(location.search);
    const searchString = new URLSearchParams({
      ...currentParams,
      ...params
    })
      .toString()
      .replace(/\w+=&/g, '')
      .replace(/&\w+=$/, '');
    navigate(
      {
        search: searchString
      },
      { replace: true }
    );
  };

  const handleResetFilters = async () => {
    statsKey.current++;
    setPage(DEFAULT_PAGE);
    setSearchValue('');
    setStates([]);
    setCity('');
    setZip('');
    setProvider('');

    getAllPatients({
      page: DEFAULT_PAGE,
      perPage: DEFAULT_PER_PAGE,
      sortBy,
      sortByOrder: DEFAULT_SORT_BY_ORDER,
      q: '',
      city: '',
      zip: '',
      state: [],
      provider: ''
    });
  };

  const handleSubmit = (initialLoad = false) => {
    statsKey.current++;
    let initialPage = page;

    if (!initialLoad) {
      setPage(DEFAULT_PAGE);
      initialPage = DEFAULT_PAGE;
    }

    getAllPatients({
      page: initialPage,
      perPage,
      sortBy,
      sortByOrder,
      q: searchValue,
      city,
      zip,
      state: states,
      provider
    });
  };

  const handlePagePerPageSortByOrSortByOrderChange = () => {
    getAllPatients({
      ...lastSearchParams.current,
      page,
      perPage,
      sortBy,
      sortByOrder
    });
  };

  const getAllPatients = async (searchParams) => {
    lastSearchParams.current = searchParams;
    setLoading(true);

    try {
      setUrlQueryParams(searchParams);
      const searchString = new URLSearchParams(searchParams);
      const res = await MDPBackend.getAllPatientsByOrg(searchString);
      const parsedResponse = JSON.parse(res.data.body);
      const data = parsedResponse.data;
      setPatientData(
        data.map((el) => ({ ...el, id: el.id.id, _id: el.id._id }))
      );
      setTotalPatients(parsedResponse.totalPatients);
      setIsReveleerTeam(parsedResponse.isReveleerTeam);
    } catch (error) {
      dispatch(
        showToastMsg({
          open: true,
          message: 'Something went wrong',
          level: 'error',
          duration: 5000
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const tryGetExportedPatients = async (requestId, retries) => {
    console.log('[tryGetExportedPatients] retry: ', retries);
    await new Promise((resolve) => setTimeout(resolve, 5 * 1000)); // wait 5 seconds

    try {
      const response = await MDPBackend.getPatientExport(requestId);
      const { body } = response.data;
      const parsedBody = JSON.parse(body);
      console.log('[tryGetExportedPatients] parsedBody: ', parsedBody);
      const { url: signedUrl } = parsedBody;

      if (signedUrl) {
        return parsedBody;
      } else if (!signedUrl && retries > 0) {
        return tryGetExportedPatients(requestId, retries - 1);
      } else {
        throw new Error('Max number of attempt reached');
      }
    } catch (error) {
      console.log('[tryGetExportedPatients] error: ', error);
      return null;
    }
  };

  const exportPatientsToCSV = async ({
    sortBy = '',
    sortByOrder = '',
    q = '',
    state = '',
    city = '',
    zip = '',
    provider = ''
  } = {}) => {
    const searchParams = {
      sortBy,
      sortByOrder,
      q,
      city,
      zip,
      state,
      provider
    };
    setLoadingCSV(true);
    const searchString = new URLSearchParams(searchParams).toString();

    try {
      const response = await MDPBackend.exportPatientsToCSV(searchString);
      log('[exportPatientsToCSV] response: ', response);
      const parsedBody = JSON.parse(response.data.body);
      const requestId = parsedBody.requestId;

      if (requestId) {
        dispatch(
          showToastMsg({
            open: true,
            message:
              // eslint-disable-next-line max-len
              'Exporting Patients. This can take up to 5 minutes. File will download automatically when ready. Do not exit this page.',
            level: 'success',
            duration: 5000
          })
        );
        const exportedPatientsResponse = await tryGetExportedPatients(
          requestId,
          60
        );
        console.log(
          '[exportPatientsToCSV] exportedPatientsResponse: ',
          exportedPatientsResponse
        );
        const { url: signedUrl } = exportedPatientsResponse;
        const signedUrlResponse = await axios.get(signedUrl, {
          responseType: 'blob'
        });
        const blob = signedUrlResponse.data;
        const fileName = 'patients.csv';
        fileDownload(blob, fileName);
      } else {
        throw new Error(`Failed to create request: ${response}`);
      }
    } catch (error) {
      log('[exportPatientsToCSV] error: ', error);
    } finally {
      setLoadingCSV(false);
    }
  };

  useEffect(() => {
    if (!lastSearchParams.current) {
      handleSubmit(true);
    } else {
      if (
        lastSearchParams.current.page !== page ||
        lastSearchParams.current.perPage !== perPage ||
        lastSearchParams.current.sortBy !== sortBy ||
        lastSearchParams.current.sortByOrder !== sortByOrder
      ) {
        handlePagePerPageSortByOrSortByOrderChange();
      }
    }
  }, [page, perPage, sortBy, sortByOrder]);

  const handleExportHCCsToCSV = async () => {
    setLoadingHCCsToCSV(true);
    await getBatchNames();
    setLoadingHCCsToCSV(false);
    setExportModalOpen(true);
  };

  const handleExportHCCsToCSVRev = async () => {
    setLoadingHCCsToCSVRev(true);
    await getBatchNames();
    setLoadingHCCsToCSVRev(false);
    setExportModalRevOpen(true);
  };

  const handleModalClose = () => {
    setExportModalOpen(false);
    setExportModalRevOpen(false);
  };

  const getBatchNames = async () => {
    try {
      if (batchNames.length) return;
      const response = await MDPBackend.getBatchNames();
      const parsedBody = JSON.parse(response.data.body);
      setBatchNames(parsedBody.batchNames);
    } catch (error) {
      dispatch(
        showToastMsg({
          open: true,
          message:
            error?.response?.body ?? error.message ?? 'Something went wrong',
          level: 'error',
          duration: 5000
        })
      );
    }
  };

  return (
    <PageTemplate>
      <Container maxWidth={false}>
        {lastSearchParams.current && (
          <PatientStats
            key={statsKey.current}
            searchValue={searchValue}
            states={states}
            city={city}
            zip={zip}
            provider={provider}
          />
        )}
        <form className={classes.paperRoot}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12} sm={12}>
              <TextField
                label="Search by Patient ID, first name, last name, or DOB"
                variant="outlined"
                className={classes.searchField}
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                disabled={loading}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormControl className={classes.stateFilter} disabled={loading}>
                <InputLabel variant="outlined" id="state-label">
                  State
                </InputLabel>
                <Select
                  variant="outlined"
                  id="state"
                  multiple
                  value={states}
                  onChange={(e) => setStates(e.target.value)}
                  MenuProps={{ style: { maxHeight: 400 } }}
                  input={
                    <OutlinedInput
                      label="state-label"
                      id="select-multiple-chip"
                    />
                  }
                  renderValue={(selected) => (
                    <div className={classes.chips}>
                      {selected
                        ? selected.map((value) => (
                            <span className={classes.chip} key={value}>
                              {STATES.find(
                                (st) => st.toLowerCase() === value.toLowerCase()
                              )}
                            </span>
                          ))
                        : null}
                    </div>
                  )}
                >
                  {STATES.map((state) => (
                    <MenuItem key={state} value={state}>
                      <Checkbox checked={states.indexOf(state) > -1} />
                      <ListItemText primary={state} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="City"
                variant="outlined"
                className={classes.searchField}
                value={city}
                onChange={(e) => setCity(e.target.value)}
                disabled={loading}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Zip"
                variant="outlined"
                value={zip}
                className={classes.searchField}
                onChange={(e) => setZip(e.target.value)}
                disabled={loading}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Provider Group"
                variant="outlined"
                value={provider}
                className={classes.searchField}
                onChange={(e) => setProvider(e.target.value)}
                disabled={loading}
              />
            </Grid>
          </Grid>
          <Stack spacing={2} direction="row" className="mt-2">
            <FormControl>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                startIcon={<SearchIcon />}
                onClick={() => handleSubmit(false)}
                disabled={loading}
              >
                Search
              </Button>
            </FormControl>
            <FormControl>
              <Button
                color="secondary"
                variant="outlined"
                startIcon={<RestartAltIcon />}
                onClick={handleResetFilters}
                disabled={loading}
              >
                Reset Filters
              </Button>
            </FormControl>
          </Stack>
        </form>
        <div className={classes.patientList}>
          {loading ? (
            <div className={classes.loadingCentered}>
              <CircularProgress />
            </div>
          ) : (
            <>
              <div className={classes.actionBtns}>
                <div>
                  <Button
                    disabled={loadingHCCsToCSV}
                    onClick={handleExportHCCsToCSV}
                  >
                    Export HCCs to CSV
                    {loadingHCCsToCSV ? (
                      <CircularProgress
                        style={{ width: 15, height: 15, marginLeft: 10 }}
                      />
                    ) : null}
                  </Button>
                  {isReveleerTeam ? (
                    <Button
                      disabled={loadingHCCsToCSVRev}
                      onClick={handleExportHCCsToCSVRev}
                      sx={{ marginLeft: 1 }}
                    >
                      [REV TEAM] Export HCCs to CSV
                      {loadingHCCsToCSVRev ? (
                        <CircularProgress
                          style={{ width: 15, height: 15, marginLeft: 10 }}
                        />
                      ) : null}
                    </Button>
                  ) : null}
                </div>
                <Button
                  disabled={loadingCSV}
                  onClick={() =>
                    exportPatientsToCSV(queryString.parse(location.search))
                  }
                >
                  Export Patients to CSV
                  {loadingCSV ? (
                    <CircularProgress
                      style={{ width: 15, height: 15, marginLeft: 10 }}
                    />
                  ) : null}
                </Button>
              </div>
              {patientData && (
                <PatientTable
                  loading={loading}
                  patientData={patientData}
                  totalRows={totalPatients}
                  page={page}
                  perPage={perPage}
                  sortBy={sortBy}
                  sortByOrder={sortByOrder}
                  setPerPage={setPerPage}
                  setPage={setPage}
                  setSortBy={setSortBy}
                  setSortByOrder={setSortByOrder}
                />
              )}
            </>
          )}
        </div>
      </Container>
      <ExportHCCModal
        handleClose={handleModalClose}
        exportModalOpen={exportModalOpen}
        batchNames={batchNames}
      />
      <ExportHCCModalRev
        handleClose={handleModalClose}
        exportModalOpen={exportModalRevOpen}
        batchNames={batchNames}
      />
    </PageTemplate>
  );
};

export default withStyles(PatientSearch, styles);
