// @flow

import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import MDPBackend from 'services/MDPBackend';
import styles from '../../Styles/Patient.Style';
import { setIsLoadingVectorResults } from 'features/halOrganization-slice';

type Props = {
  classes: Object,
  patientId: String,
  setHasSuggestions: () => void,
  setSourceData: () => void,
  patientData: Object,
  loadingPatientData: Boolean,
  totalIndexedDocuments: Number | null
};

const PatientSearch = (props: Props): React.Node => {
  const {
    classes,
    patientId,
    setHasSuggestions,
    patientData,
    totalIndexedDocuments
  } = props;

  const [loadingSearch, setLoadingSearch] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [total, setTotal] = useState(null);
  const searchRef = useRef(null);
  const dispatch = useDispatch();
  const isVectorSearchEnabled = useSelector(
    (state) => state.halOrganization.isVectorSearchEnabled
  );

  const searchProvider = isVectorSearchEnabled ? 'HAL' : 'OpenSearch';

  const searchPatientDocuments = async (searchTerm) => {
    console.log('Patient Data: ', patientData);
    if (!searchValue) {
      setSearchValue('');
      setSuggestions([]);
      setTotal(null);
      setHasSuggestions(false);

      return;
    }

    console.log('[searchPatientDocuments] patientId: ', patientId);
    console.log('[searchPatientDocuments] searchTerm: ', searchTerm);
    console.log('[searchPatientDocuments] searchProvider: ', searchProvider);
    setLoadingSearch(true);

    if (searchProvider === 'HAL') {
      dispatch(setIsLoadingVectorResults(true));
    }

    try {
      const response = await MDPBackend.searchPatientDocuments(
        patientId,
        searchTerm,
        searchProvider
      );

      console.log('[searchPatientDocuments] response: ', response);

      const data = JSON.parse(response.data.body).data;
      console.log('[searchPatientDocuments] data: ', data);

      const total = {
        totalMatches: data.totalMatches ? data.totalMatches : 0,
        totalFiles: data.totalFiles ? data.totalFiles : 0
      };

      const suggestions = data.suggestions ? data.suggestions : [];

      setTotal(total);
      setSuggestions(suggestions);
      setLoadingSearch(false);
      setHasSuggestions(true);
    } catch (error) {
      console.log('[searchPatientDocuments] error: ', error);
      setLoadingSearch(false);
    } finally {
      if (searchProvider === 'HAL') {
        dispatch(setIsLoadingVectorResults(false));
      }
    }
  };

  useEffect(() => {
    const closeSearch = (event) => {
      if (event.key === 'Escape') {
        setSearchValue('');
        searchRef.current.blur();
      }
    };

    document.addEventListener('keydown', closeSearch, false);

    return () => {
      document.removeEventListener('keydown', closeSearch, false);
    };
  }, [setSearchValue]);

  const handleSearchInput = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setSearchValue(e.target.value);
  };

  useEffect(() => {
    const timeout = setTimeout(() => searchPatientDocuments(searchValue), 300);

    return () => clearTimeout(timeout);
  }, [searchValue]);

  const isPDF = (contentType) => {
    const pdfType = 'application/pdf';

    return contentType === pdfType;
  };

  const createFilePath = (fileId, fileName, section, searchString) => {
    if (!fileId) return '';

    const cleanSearchString = searchString
      ? searchString.replace(/(\<em\>|\<\/em\>)/g, '')
      : '';

    const encodedSearchString = encodeURIComponent(cleanSearchString);
    const encodedFileName = fileName ? encodeURIComponent(fileName) : '';

    // eslint-disable-next-line max-len
    let path;

    if (searchProvider === 'HAL') {
      path = `/viewer/${patientData.id}?f=${fileId}&q=${encodedSearchString}&title=${encodedFileName}`;
    } else {
      path = `/viewer/${patientId}?f=${fileId}&q=${encodedSearchString}&title=${encodedFileName}`;
    }

    console.log('[createFilePath] path: ', path);

    return path;
  };

  const requestAndOpenPDF = async (fileHash, page) => {
    console.log('[requestAndOpenPDF] fileHash: ', fileHash);
    console.log('[requestAndOpenPDF] page: ', page);

    let parsedPage = '';

    if (page) {
      parsedPage = page.replace('Page ', '');
    }

    let filePath;

    if (searchProvider === 'HAL') {
      filePath = `/patient/${patientData.id}/files?file=${fileHash}&previewPage=${parsedPage}`;
    } else {
      filePath = `/patient/${patientId}/files?file=${fileHash}&previewPage=${parsedPage}`;
    }

    window.open(filePath, '_blank');
  };

  /* eslint-disable react/prop-types */
  const SuggestionCard = ({ suggestion }) => {
    return (
      <Paper
        className={classes.suggestionCard}
        variant="elevation"
        elevation={1}
      >
        <div className={classes.suggestionHeader}>
          <a
            onClick={() => {
              if (isPDF(suggestion.contentType)) {
                requestAndOpenPDF(suggestion.fileHash, 0);
              } else {
                window.open(
                  createFilePath(suggestion.fileHash, suggestion.fileName),
                  '_blank'
                );
              }
            }}
            target="_blank"
            rel="noreferrer"
            style={{
              color: 'black',
              fontWeight: 600
            }}
          >
            {suggestion.fileName}
          </a>
          <span>
            <strong>{suggestion.createdDt}</strong>
          </span>
        </div>
        <div className={classes.suggestionBody}>
          {Object.keys(suggestion.highlights).map((key, i) => (
            <div key={i} className={classes.section}>
              <Typography
                className={classes.highlightSectionHeader}
                variant="h3"
              >
                {key}
              </Typography>
              <div className={classes.sectionHighlights}>
                {suggestion.highlights[key].map((highlight, j) => (
                  <div key={j}>
                    {isPDF(suggestion.contentType) ? (
                      <a
                        onClick={() =>
                          requestAndOpenPDF(suggestion.fileHash, key)
                        }
                        target="_blank"
                        rel="noreferrer"
                        className={classes.highlight}
                        style={{ color: 'black' }}
                        dangerouslySetInnerHTML={{ __html: highlight }}
                      ></a>
                    ) : (
                      <a
                        href={createFilePath(
                          suggestion.fileHash,
                          suggestion.fileName,
                          key,
                          highlight
                        )}
                        target="_blank"
                        rel="noreferrer"
                        className={classes.highlight}
                        style={{ color: 'black' }}
                        dangerouslySetInnerHTML={{ __html: highlight }}
                      ></a>
                    )}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </Paper>
    );
  };

  return (
    <Container className="!px-0" maxWidth="none">
      <div className="searchWrapper">
        <div className="patient-demographics">
          {patientData ? (
            <>
              <div className="md:flex sm:block">
                {patientData.firstName && patientData.lastName ? (
                  <Typography variant="h1" style={{ fontWeight: 600 }}>
                    {patientData.firstName} {patientData.lastName}
                  </Typography>
                ) : null}
                {patientData.isInactive ? (
                  <div className="flex items-center justify-left md:ml-2">
                    <Typography
                      className="text-red"
                      sx={{
                        fontWeight: 600
                      }}
                    >
                      INACTIVE
                    </Typography>
                  </div>
                ) : (
                  ''
                )}
              </div>
              <div>
                <Typography>
                  Date of Birth: <strong>{patientData.dob}</strong>
                </Typography>
                <Typography>
                  Patient ID: <strong>{patientData.id}</strong>
                </Typography>
              </div>
            </>
          ) : null}
        </div>
        {isVectorSearchEnabled ? (
          <TextField
            className={'vectorSearchField'}
            label={'Search all raw patient chart files...'}
            variant="outlined"
            value={searchValue}
            inputRef={searchRef}
            onChange={handleSearchInput}
          />
        ) : (
          <TextField
            className={'documentSearchField'}
            label={
              !totalIndexedDocuments
                ? 'No indexed documents found. Index documents to enable search'
                : 'Search all raw patient chart files...'
            }
            variant="outlined"
            value={searchValue}
            inputRef={searchRef}
            onChange={handleSearchInput}
            disabled={!totalIndexedDocuments}
          />
        )}
        <div className="suggestionsWrapper">
          {total ? (
            <Typography
              style={{
                marginLeft: 15,
                marginTop: 25,
                fontSize: 18,
                marginBottom: 25
              }}
              variant="h3"
            >
              {total.totalMatches} results - {total.totalFiles} files
            </Typography>
          ) : null}
          {loadingSearch ? (
            <div className="loadingContainer">
              <CircularProgress />
            </div>
          ) : suggestions && suggestions.length ? (
            <div className={classes.suggestionsContainer}>
              {suggestions.map((suggestion, idx) => (
                <SuggestionCard key={idx} suggestion={suggestion} />
              ))}
            </div>
          ) : null}
        </div>
      </div>
    </Container>
  );
};

export default withStyles(PatientSearch, styles);
