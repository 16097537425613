import React from 'react';
import { Link } from 'react-router-dom';

const InvalidUrl = () => (
  <div>
    <h1>{'Page not found.'}</h1>
    <Link to="/">{'Click here to get back home'}</Link>
  </div>
);

export default InvalidUrl;
