// @flow

import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { format } from 'date-fns';
import enLocale from 'date-fns/locale/en-US';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import CircularProgress from '@mui/material/CircularProgress';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddSourceDialogContent from './AddSourceDialogContent';
import { useDispatch, useSelector } from 'react-redux';
import { showToastMsg } from 'features/toast-message-slice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { ForwardRef } from 'components';
import MDPBackend from 'services/MDPBackend';
import {
  useAddPatientIcdMutation,
  useLazySearchIcdsQuery,
  useAddSourceToPatientHccMutation,
  useLazySearchCiInputsQuery,
  useGetBenchmarkEvidenceForPatientMutation
} from 'api/api';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
  debounce,
  Divider,
  Switch,
  FormControlLabel,
  Box
} from '@mui/material';
import { DEFAULT_HCC_VERSION } from '../PatientHCCs';

const MIN_DATE = new Date('1970-01-01');
const MAX_DATE = new Date();

const annotations = [
  'Medication',
  'Lab Value',
  'Diagnosis',
  'Test',
  'Mention',
  'Procedures',
  'SDoH'
];
const frozenAnnotations = ['Medication', 'Mention'];
const operators = ['=', '>', '>=', '<', '<='];
const defaultSource = {
  factName: '',
  annotation: '',
  measure: '',
  operator: '',
  factValue: ''
};

type AddIcdModalProps = {
  open: Boolean,
  currentIcdCodes: Array,
  cmsVersion: String,
  patientId: String,
  patientHccRefetch: Function,
  handleClose: () => void,
  nlpSource: String,
  classes: Object
};

const AddIcdModal = (props: AddIcdModalProps) => {
  const {
    open,
    handleClose,
    currentIcdCodes,
    patientId,
    cmsVersion,
    patientHccRefetch,
    nlpSource,
    classes
  } = props;
  const [searchIcds, { data: options, isLoading: loading }] =
    useLazySearchIcdsQuery();
  const [addPatientIcd, { isLoading }] = useAddPatientIcdMutation();
  const [addSourceToPatientHcc, { isLoadingAddSource }] =
    useAddSourceToPatientHccMutation();
  const dispatch = useDispatch();
  const currentOrganization = useSelector((state) => state.currentOrganization);
  const [searchingCiInputs, setSearchingCiInputs] = useState({});
  const [searching, setSearching] = useState(false);
  const [body, setBody] = useState(null);
  const [clickedIcdCode, setClickedIcdCode] = useState(null);
  const [clickedSource, setClickedSource] = useState(null);
  const [showAddEvidence, setShowAddEvidence] = useState(false);
  const [sourcesInput, setSourcesInput] = useState(defaultSource);
  const [searchValue, setSearchValue] = useState('');
  const [excerptSearchValue, setExcerptSearchValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [initEvidence, setInitEvidence] = useState(false);
  const [evidencePairsArray, setEvidencePairsArray] = useState([]);
  const [hideInputs, setHideInputs] = useState(true);
  const [isSemanticSearch, setIsSemanticSearch] = useState(true);
  const [evidenceDate, setEvidenceDate] = useState('');
  const [searchingForExcerpt, setSearchingForExcerpt] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [activeInputField, setActiveInputField] = useState(null);
  const [ciInputOptions, setCiInputOptions] = useState([]);
  const [searchCiInputs, { data: inputOptions }] = useLazySearchCiInputsQuery();

  const [autocompleteKey, setAutocompleteKey] = useState(0);

  const [isBenchmarkView, setIsBenchmarkView] = useState(false);
  const [savedBenchmarkEvidence, setSavedBenchmarkEvidence] = useState([]);
  const [selectedBenchmarkEvidence, setSelectedBenchmarkEvidence] =
    useState('');
  const [isSearchingBenchmarkEvidence, setIsSearchingBenchmarkEvidence] =
    useState(false);
  const [benchmarkEvidence, setBenchmarkEvidence] = useState([]);
  const [benchmarkEvidenceSearchTerm, setBenchmarkEvidenceSearchTerm] =
    useState('');
  const [isBenchmarkEvidenceDropdownOpen, setIsBenchmarkEvidenceDropdownOpen] =
    useState(false);

  const [getBenchmarkEvidence, { data: benchmarkEvidenceForPatient }] =
    useGetBenchmarkEvidenceForPatientMutation();

  const isFrozen = useMemo(
    () => frozenAnnotations.includes(sourcesInput.annotation) || false,
    [sourcesInput]
  );

  const delayedIcdSearch = debounce(async (value) => {
    setSearching(true);
    await searchIcds({ searchValue: value, cmsVersion });
    setSearching(false);
  }, 300);

  useEffect(() => {
    if (evidencePairsArray.length === 0) {
      setHideInputs(false);
    }

    if (inputOptions) {
      setCiInputOptions(inputOptions);
    }
  }, [evidencePairsArray, inputOptions]);

  useEffect(() => {
    if (benchmarkEvidenceForPatient) {
      console.log('Fetched benchmark evidence: ', benchmarkEvidenceForPatient);
      setBenchmarkEvidence(benchmarkEvidenceForPatient);
      setIsBenchmarkEvidenceDropdownOpen(true); // Force dropdown open after data is updated
      setAutocompleteKey((prevKey) => prevKey + 1);
    }
  }, [benchmarkEvidenceForPatient]);

  const resetInputValues = () => {
    setClickedSource(null);
    setSuggestions(null);
    setSearchValue(null);
    setExcerptSearchValue(null);
    setActiveInputField(null);
    setCiInputOptions([]);
    setSourcesInput(defaultSource);
    setIsEditing(false);
    setEvidenceDate(null);
    setHideInputs(true);
  };

  const handleCloseDialog = () => {
    setClickedSource(null);
    setClickedIcdCode(null);
    setSuggestions(null);
    setSearchValue(null);
    setExcerptSearchValue(null);
    setSourcesInput(defaultSource);
    setHideInputs(true);
    setEvidencePairsArray([]);
    setActiveInputField(null);
    setCiInputOptions([]);
    setEvidenceDate(null);
    setIsEditing(false);
    setBody(null);
    setIsBenchmarkView(false);
    setBenchmarkEvidence([]);
    setBenchmarkEvidenceSearchTerm('');
    setSelectedBenchmarkEvidence([]);
    setSavedBenchmarkEvidence([]);
    handleClose();
  };

  const handleCiInputChange = (name) => (event, newValue) => {
    console.log('handleCiInputChange newValue: ', newValue);

    if (newValue) {
      setSourcesInput((prev) => ({
        ...prev,
        [name]: newValue.value
      }));

      setCiInputOptions([]);
    }
  };

  const delayedCiInputSearch = debounce(async (value, fieldName) => {
    console.log('Calling CiInputSearch for value: ', value);
    setSearchingCiInputs((prev) => ({ ...prev, [fieldName]: true }));
    await searchCiInputs({
      searchValue: value,
      ciInputType: '',
      ciInputField: activeInputField
    });
    setSearchingCiInputs((prev) => ({ ...prev, [fieldName]: false }));
  });

  // Handler for input change
  const handleSearch = (event, value) => {
    delayedIcdSearch(value);
  };

  const handleAddIcd = (event, value) => {
    if (value) {
      const {
        icdCode,
        icdCodeDescription,
        hccCategory,
        hccCategoryDescription,
        cmsVersion
      } = value;

      setClickedIcdCode(icdCode);
      setHideInputs(false);

      setBody((prev) => ({
        ...prev,
        icdCode,
        icdCodeDescription,
        hccCategory,
        hccCategoryDescription,
        cmsVersion: cmsVersion || DEFAULT_HCC_VERSION,
        actionPayload: { status: 'identified' },
        nlpSource
      }));
    }
  };

  const getNewEvidenceList = () => {
    let allEvidence = [];
    if (savedBenchmarkEvidence.length > 0) {
      console.log('We have benchmark evidence: ', savedBenchmarkEvidence);
      const benchmarkEvidence = prepareSelectedBenchmarkEvidence();

      allEvidence = [...allEvidence, ...benchmarkEvidence];
    }

    if (evidencePairsArray.length > 0) {
      allEvidence = [...allEvidence, ...evidencePairsArray];
    }
    return allEvidence;
  };

  const handleSubmit = async () => {
    try {
      body.sources = getNewEvidenceList();

      console.log('Body to send: ', body);

      const response = await addPatientIcd({
        patientId,
        body
      }).unwrap();

      if (response.success) {
        dispatch(
          showToastMsg({
            open: true,
            message: 'ICD added successfully',
            level: 'success',
            duration: 5000
          })
        );
        patientHccRefetch();
        handleCloseDialog();
      } else {
        throw new Error(response.message || response);
      }
    } catch (error) {
      dispatch(
        showToastMsg({
          open: true,
          message: error?.message ?? 'adding failed',
          level: 'error',
          duration: 5000
        })
      );
    }
  };

  const handleSearchTypeToggle = (event) => {
    setIsSemanticSearch(event.target.checked);
  };

  const handleClinicalIndicatorChange = (event) => {
    const { name, value } = event.target;
    const obj = { [name]: value };

    if (name === 'annotation' && frozenAnnotations.includes(value)) {
      obj.operator = '=';
    }

    if (name !== 'annotation' && name !== 'operator') {
      setActiveInputField(name);
      delayedCiInputSearch(value, name);
    }

    setSourcesInput((prev) => ({
      ...prev,
      ...obj
    }));
  };

  const handleSearchInput = (event) => {
    const value = event.target.value;
    setExcerptSearchValue(value);

    if (value.trim() === '') {
      setSuggestions([]);
      setClickedSource(null);
      return;
    }

    debouncedExcerptSearch(value);
  };

  const debouncedExcerptSearch = useCallback(
    debounce(async (value) => {
      if (!value) {
        console.log('Search field is empty, not searching.');
        setSearchingForExcerpt(false);
        setExcerptSearchValue('');
        setSuggestions([]);
        setClickedSource(null);
        return;
      }

      console.log('Searching for: ', value);

      const searchProvider = isSemanticSearch ? 'HAL' : 'OpenSearch';

      console.log('Search Provider is: ', searchProvider);

      setSearchingForExcerpt(true);
      try {
        const response = await MDPBackend.searchPatientDocuments(
          patientId,
          value,
          searchProvider
        );

        console.log('[searchPatientDocuments] response: ', response);

        const data = JSON.parse(response.data.body).data;
        console.log('[searchPatientDocuments] data: ', data);

        const total = {
          totalMatches: data.totalMatches ? data.totalMatches : 0,
          totalFiles: data.totalFiles ? data.totalFiles : 0
        };

        const suggestions = data.suggestions ? data.suggestions : [];
        let pageContentsArray;

        if (searchProvider === 'OpenSearch') {
          console.log('Suggestions Open Search: ', suggestions);

          const removeHtmlTags = (text) => {
            return text.replace(/<[^>]*>/g, '');
          };

          const extractMappedFields = (responses) => {
            return responses.flatMap((response) => {
              return Object.entries(response.highlights).flatMap(
                ([section, excerpts]) => {
                  return excerpts.map((excerpt) => {
                    const cleanedExcerpt = removeHtmlTags(excerpt);
                    return {
                      contentType: response.contentType,
                      fileHash: response.fileHash,
                      fileName: response.fileName,
                      section: section,
                      pageContent: cleanedExcerpt // Cleaned excerpt with HTML tags removed
                    };
                  });
                }
              );
            });
          };

          const mappedSuggestions = extractMappedFields(suggestions);
          pageContentsArray = mappedSuggestions;
        } else {
          pageContentsArray = suggestions.flatMap(
            (suggestion) =>
              suggestion.highlights?.['Page Content'].map((content) => ({
                pageContent: content,
                contentType: suggestion.contentType,
                fileName: suggestion.fileName,
                fileHash: suggestion.fileHash
              })) || []
          );
        }

        setSuggestions(pageContentsArray);

        const isSelectedOptionValid = pageContentsArray.some(
          (option) => option.pageContent === clickedSource?.pageContent
        );
        if (!isSelectedOptionValid) {
          setClickedSource(null);
        }
      } catch (error) {
        console.log('[searchPatientDocuments] error: ', error);
      } finally {
        setSearchingForExcerpt(false);
      }
    }, 1000),
    [isSemanticSearch]
  );

  const handleSelectSource = (event, value) => {
    const selectedSource = suggestions.find(
      (option) => option.pageContent === event.target.value
    );
    setClickedSource(selectedSource);
  };

  const createFilePath = (fileId, fileName, searchString) => {
    if (!fileId) return '';

    const cleanSearchString = searchString
      ? searchString.replace(/(\<em\>|\<\/em\>)/g, '')
      : '';

    const encodedSearchString = encodeURIComponent(cleanSearchString);
    const encodedFileName = fileName ? encodeURIComponent(fileName) : '';

    // eslint-disable-next-line max-len
    let path = `/viewer/${patientId}?f=${fileId}&q=${encodedSearchString}&title=${encodedFileName}`;

    return path;
  };

  const saveEvidencePair = () => {
    const clinicalIndicator = {
      type: sourcesInput.annotation,
      name: sourcesInput.factName,
      measure: sourcesInput.measure,
      unit: sourcesInput.unit,
      operator: sourcesInput.operator,
      value: sourcesInput.factValue,
      date: evidenceDate ? format(evidenceDate, 'yyyy-MM-dd') : null
    };

    const excerpt = {
      fileName: clickedSource.fileName,
      pageNb: clickedSource.page ?? null,
      sectionTitle: clickedSource.section ?? null,
      hash: clickedSource.fileHash,
      contentType: clickedSource.contentType,
      excerpt: clickedSource.pageContent
    };

    const evidencePair = {
      clinicalIndicator: clinicalIndicator,
      excerpt: excerpt
    };

    console.log('Evidence Pair Saved: ', evidencePair);

    if (isEvidenceValid(evidencePair)) {
      resetInputValues();
      setEvidencePairsArray((prev) => [...prev, evidencePair]);
    } else {
      dispatch(
        showToastMsg({
          open: true,
          message: 'You forgot to set a date',
          level: 'error',
          duration: 5000
        })
      );
    }
  };

  const isEvidenceValid = (evidencePair) => {
    if (!evidencePair.clinicalIndicator.date) {
      return false;
    }
    return true;
  };

  const handleAddMoreEvidence = () => {
    setHideInputs(false);
  };

  const handleAddEvidence = () => {
    setHideInputs(false);
  };

  const handleEditEvidence = (index) => {
    const evidenceToEdit = evidencePairsArray[index];

    console.log('Evidence to edit: ', evidenceToEdit);
    setIsEditing(true);
    setSourcesInput({
      factName: evidenceToEdit.clinicalIndicator.name,
      annotation: evidenceToEdit.clinicalIndicator.type,
      measure: evidenceToEdit.clinicalIndicator.measure,
      unit: evidenceToEdit.clinicalIndicator.unit,
      operator: evidenceToEdit.clinicalIndicator.operator,
      factValue: evidenceToEdit.clinicalIndicator.value,
      date: evidenceToEdit.clinicalIndicator.date
    });

    setClickedSource({
      fileHash: evidenceToEdit.excerpt.hash,
      fileName: evidenceToEdit.excerpt.fileName,
      pageContent: evidenceToEdit.excerpt.excerpt,
      contentType: evidenceToEdit.excerpt.contentType,
      section: evidenceToEdit.excerpt.section ?? null
    });

    setEvidencePairsArray((prev) => prev.filter((_, idx) => idx !== index));

    setHideInputs(false);
  };

  const handleDeleteEvidence = (index) => {
    setEvidencePairsArray((prev) => prev.filter((_, idx) => idx !== index));
  };

  const getInputFieldLabel = (field) => {
    if (field === 'CI Name') {
      return isEditing ? sourcesInput.factName : field;
    }

    if (field === 'Measure') {
      return isEditing ? sourcesInput.measure : field;
    }

    if (field === 'Unit') {
      return isEditing ? sourcesInput.unit : field;
    }

    if (field === 'Value') {
      return isEditing ? sourcesInput.factValue : field;
    }
  };

  // BENCHMARK EVIDENCE
  const prepareSelectedBenchmarkEvidence = () => {
    return savedBenchmarkEvidence.map((ev) => {
      const clinicalIndicator = {
        type: ev.clinicalIndicator.type,
        name: ev.clinicalIndicator.name,
        measure: ev.clinicalIndicator.measure,
        unit: ev.clinicalIndicator.unit,
        operator: ev.clinicalIndicator.operator,
        value: ev.clinicalIndicator.value,
        date: ev.clinicalIndicator.date
      };

      const excerpt = {
        documentId: ev.excerpt.documentId ?? null,
        fileName: ev.excerpt.fileName,
        pageNb: ev.excerpt.page ?? null,
        sectionTitle: ev.excerpt.sectionTitle ?? null,
        hash: ev.excerpt.hash,
        contentType: ev.excerpt.contentType,
        excerpt: ev.excerpt.excerpt
      };

      const evidencePair = {
        clinicalIndicator: clinicalIndicator,
        excerpt: excerpt
      };

      return evidencePair;
    });
  };

  const saveBenchmarkEvidence = () => {
    for (let ev of selectedBenchmarkEvidence) {
      const clinicalIndicator = {
        type: ev.type,
        name: ev.name,
        measure: ev.measure,
        unit: ev.unit,
        operator: ev.operator,
        value: ev.value,
        date: ev.date
      };

      const excerpt = {
        documentId: ev.documentId ?? null,
        fileName: ev.fileName,
        pageNb: ev.page ?? null,
        sectionTitle: ev.sectionTitle ?? null,
        hash: ev.hash,
        contentType: ev.contentType,
        excerpt: ev.excerpt
      };

      const evidencePair = {
        clinicalIndicator: clinicalIndicator,
        excerpt: excerpt
      };

      console.log('Saved benchmark evidence:', evidencePair);

      setSavedBenchmarkEvidence((prev) => [...prev, evidencePair]);
    }
  };

  const debouncedBenchmarkEvidenceSearch = useCallback(
    debounce(async (value) => {
      if (!value) {
        console.log(
          '[debouncedBenchmarkEvidenceSearch] Search field is empty, not searching.'
        );
        setBenchmarkEvidence([]);
        return;
      }

      console.log('[debouncedBenchmarkEvidenceSearch]  Searching for: ', value);

      setIsSearchingBenchmarkEvidence(true);
      try {
        const evidence = await getBenchmarkEvidence({
          patientId: patientId,
          searchTerm: value
        }).unwrap();

        if (!evidence) {
          dispatch(
            showToastMsg({
              open: true,
              message: 'No benchmark evidence found for patient',
              level: 'error',
              duration: 5000
            })
          );
        } else if (evidence && evidence.length === 0) {
          dispatch(
            showToastMsg({
              open: true,
              message: 'Did not find evidence matching your search',
              level: 'error',
              duration: 5000
            })
          );
        }
      } catch (error) {
        console.log('[debouncedBenchmarkEvidenceSearch] error: ', error);
        dispatch(
          showToastMsg({
            open: true,
            message: 'Error occured for this patient',
            level: 'error',
            duration: 5000
          })
        );
      } finally {
        setIsSearchingBenchmarkEvidence(false);
      }
    }, 1000),
    []
  );

  const handleSelectBenchmarkEvidence = (event) => {
    const selectedBenchmarkEvidenceId = event.target.value;
    const selectedBenchmarkEv = benchmarkEvidence.find(
      (ev) => ev._id === selectedBenchmarkEvidenceId
    );

    setSelectedBenchmarkEvidence(selectedBenchmarkEv);
  };

  const handleBenchmarkEvidenceChange = (event) => {
    const value = event.target.value;

    setBenchmarkEvidenceSearchTerm(value);

    if (!value) {
      console.log('NO VALUE FOR BENCHMARK EVIDENCE SEARCH!');
      setBenchmarkEvidenceSearchTerm(null);
    } else {
      console.log('VALUE FOR BENCHMARK EVIDENCE SEARCH: ', value);

      debouncedBenchmarkEvidenceSearch(value);
    }
  };

  const showBenchmarkEvidenceDetails = (evidence) => {
    return (
      <Box display="flex" flexDirection="column" p={2}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}
        >
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{
              marginRight: '5px'
            }}
          >
            <strong>CI Type: </strong>
            {evidence.type}
          </Typography>

          <Typography
            variant="body2"
            color="textSecondary"
            sx={{
              marginRight: '5px'
            }}
          >
            <strong>CI Name: </strong>
            {evidence.name}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{
              marginRight: '5px'
            }}
          >
            <strong>Measure: </strong>
            {evidence.measure}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{
              marginRight: '5px'
            }}
          >
            <strong>Unit: </strong>
            {evidence.unit}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{
              marginRight: '5px'
            }}
          >
            <strong>Operator: </strong>
            {evidence.operator}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            <strong>Value: </strong>
            {evidence.value}
          </Typography>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="body2" color="textSecondary">
            <strong>Excerpt: </strong>
            {evidence.excerpt}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            <strong>Filename: </strong>
            {evidence.fileName}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            <strong>Section: </strong>
            {evidence.sectionTitle}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            <strong>Date: </strong>
            {evidence.date}
          </Typography>
        </div>
      </Box>
    );
  };

  const showExcerptDetails = (option) => {
    return (
      <Box display="flex" flexDirection="column" p={2}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="body2" color="textSecondary">
            <strong>Section: </strong>
            {option.section}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            <strong>Excerpt: </strong>
            {option.pageContent}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            <strong>Filename: </strong>
            {option.fileName}
          </Typography>
        </div>
      </Box>
    );
  };

  const handleDeleteBenchmarkEvidence = (index) => {
    setSavedBenchmarkEvidence((prev) => prev.filter((_, idx) => idx !== index));
  };
  // -------------------------------------------

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className=""
      PaperProps={{
        className: '!min-w-[320px] md:!min-w-[1200px] !min-h-[700px]'
      }}
    >
      <DialogTitle className="!font-bold">Add New ICD</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item sm={12}>
            <Autocomplete
              style={{ marginTop: '10px' }}
              options={options || []}
              getOptionLabel={(option) =>
                `${option.icdCode} - ${option.icdCodeDescription} ${
                  option.hccCategory ? `-- HCC: ${option.hccCategory}` : ''
                } ${
                  option.hccCategoryDescription
                    ? ` (${option.hccCategoryDescription})`
                    : ''
                }`
              }
              isOptionDisabled={(option) => {
                return currentIcdCodes.includes(option?.icdCode);
              }}
              onChange={handleAddIcd}
              onInputChange={handleSearch}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={loading || searching ? 'Searching...' : 'Search ICD'}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {(loading || searching) && (
                          <CircularProgress size={20} />
                        )}
                        {params.InputProps.endAdornment}
                      </>
                    )
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
        <div className="mt-3">
          {clickedIcdCode && (
            <Grid item sm={12}>
              <>
                <Autocomplete
                  multiple
                  key={autocompleteKey}
                  open={isBenchmarkEvidenceDropdownOpen}
                  onOpen={() => setIsBenchmarkEvidenceDropdownOpen(true)}
                  onClose={() => setIsBenchmarkEvidenceDropdownOpen(false)}
                  options={benchmarkEvidence || []}
                  getOptionLabel={(option) => option.excerpt}
                  isOptionEqualToValue={(option, value) =>
                    option._id === value._id
                  }
                  onInputChange={handleBenchmarkEvidenceChange}
                  onChange={(event, newValue) => {
                    console.log('Selected values: ', newValue);
                    setSelectedBenchmarkEvidence(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={`Search benchmark evidence`}
                      name="benchmark-evidence"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {isSearchingBenchmarkEvidence && (
                              <CircularProgress size={20} />
                            )}
                            {params.InputProps.endAdornment}
                          </>
                        )
                      }}
                    />
                  )}
                  renderOption={(props, option) => (
                    <li {...props} key={option._id}>
                      {showBenchmarkEvidenceDetails(option)}
                    </li>
                  )}
                />

                <Box mt={2}>
                  {selectedBenchmarkEvidence.length > 0 && (
                    <div>
                      {selectedBenchmarkEvidence.map((ev, index) => (
                        <Box key={index} mb={2}>
                          <Grid container spacing={2} alignItems="center">
                            <Grid item xs={8}>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'space-between'
                                }}
                              >
                                <Typography
                                  variant="body2"
                                  sx={{
                                    marginRight: '5px'
                                  }}
                                >
                                  <strong>CI Type: </strong>
                                  {ev.type}
                                </Typography>

                                <Typography
                                  variant="body2"
                                  sx={{
                                    marginRight: '5px'
                                  }}
                                >
                                  <strong>CI Name: </strong>
                                  {ev.name}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  sx={{
                                    marginRight: '5px'
                                  }}
                                >
                                  <strong>Measure: </strong>
                                  {ev.measure}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  sx={{
                                    marginRight: '5px'
                                  }}
                                >
                                  <strong>Unit: </strong>
                                  {ev.unit}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  sx={{
                                    marginRight: '5px'
                                  }}
                                >
                                  <strong>Operator: </strong>
                                  {ev.operator}
                                </Typography>
                                <Typography variant="body2">
                                  <strong>Value: </strong>
                                  {ev.value}
                                </Typography>
                              </div>

                              <Typography variant="body1">
                                <strong>Excerpt:</strong> {ev.excerpt}
                              </Typography>
                              <Typography variant="body1">
                                <strong>Filename:</strong> {ev.fileName}
                              </Typography>
                              {ev.sectionTitle && (
                                <Typography variant="body1">
                                  <strong>Section:</strong> {ev.sectionTitle}
                                </Typography>
                              )}

                              {ev.date && (
                                <Typography variant="body1">
                                  <strong>Date:</strong> {ev.date}
                                </Typography>
                              )}
                              <Button
                                variant="outlined"
                                className={classes.externalLinkBtn}
                                sx={{
                                  fontSize: '0.85rem'
                                }}
                                onClick={() => {
                                  window.open(
                                    createFilePath(
                                      ev.hash,
                                      ev.fileName,
                                      ev.excerpt
                                    ),
                                    '_blank'
                                  );
                                }}
                                target="_blank"
                              >
                                XML
                                <ForwardRef>
                                  <FontAwesomeIcon
                                    icon={solid('external-link')}
                                    className={classes.icon}
                                  />
                                </ForwardRef>
                              </Button>
                            </Grid>
                          </Grid>
                        </Box>
                      ))}
                    </div>
                  )}
                </Box>
                {selectedBenchmarkEvidence.length > 0 && (
                  <div className="flex">
                    <Button
                      variant="outlined"
                      className={classes.saveEvidenceBtn}
                      onClick={() => {
                        saveBenchmarkEvidence();
                        setSelectedBenchmarkEvidence([]);
                      }}
                    >
                      Save Evidence
                    </Button>
                  </div>
                )}
              </>
              {savedBenchmarkEvidence.length > 0 && (
                <div
                  style={{
                    marginBottom: '15px',
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%'
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      color: 'darkgreen',
                      marginBottom: '20px',
                      marginTop: '20px'
                    }}
                  >
                    <strong>SAVED EVIDENCE</strong>
                  </Typography>
                  {savedBenchmarkEvidence.map((evidence, i) => (
                    <Grid item xs={12} key={i}>
                      <div>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between'
                          }}
                        >
                          <Typography
                            variant="body2"
                            sx={{
                              marginRight: '5px'
                            }}
                          >
                            <strong>CI Type: </strong>
                            {evidence.clinicalIndicator.type}
                          </Typography>

                          <Typography
                            variant="body2"
                            sx={{
                              marginRight: '5px'
                            }}
                          >
                            <strong>CI Name: </strong>
                            {evidence.clinicalIndicator.name}
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{
                              marginRight: '5px'
                            }}
                          >
                            <strong>Measure: </strong>
                            {evidence.clinicalIndicator.measure}
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{
                              marginRight: '5px'
                            }}
                          >
                            <strong>Unit: </strong>
                            {evidence.clinicalIndicator.unit}
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{
                              marginRight: '5px'
                            }}
                          >
                            <strong>Operator: </strong>
                            {evidence.clinicalIndicator.operator}
                          </Typography>
                          <Typography variant="body2">
                            <strong>Value: </strong>
                            {evidence.clinicalIndicator.value}
                          </Typography>

                          <IconButton
                            onClick={() => handleDeleteBenchmarkEvidence(i)}
                          >
                            <DeleteIcon color="error" />
                          </IconButton>
                        </div>

                        <Typography variant="body1">
                          <strong>Excerpt:</strong> {evidence.excerpt.excerpt}
                        </Typography>
                        <Typography variant="body1">
                          <strong>Filename:</strong> {evidence.excerpt.fileName}
                        </Typography>
                        {evidence.excerpt.sectionTitle && (
                          <Typography variant="body1">
                            <strong>Section:</strong>{' '}
                            {evidence.excerpt.sectionTitle}
                          </Typography>
                        )}

                        {evidence.clinicalIndicator.date && (
                          <Typography variant="body1">
                            <strong>Date:</strong>{' '}
                            {evidence.clinicalIndicator.date}
                          </Typography>
                        )}

                        <Divider style={{ margin: '20px 0' }} />
                      </div>
                    </Grid>
                  ))}
                </div>
              )}
            </Grid>
          )}
        </div>
      </DialogContent>
      <DialogActions className="flex !justify-end flex-row-reverse">
        <Button
          className=" !py-1 !text-black !text-[13px]"
          onClick={handleCloseDialog}
        >
          Cancel
        </Button>
        <Button
          className="!py-1 !text-black !text-[13px]"
          sx={{
            ':not(:disabled)': {
              backgroundColor: 'rgba(76, 175, 80, 0.4)',
              '&:hover': {
                backgroundColor: 'rgba(76, 175, 80, 0.4)'
              }
            }
          }}
          variant="contained"
          disabled={isLoading || !body?.hccCategory}
          onClick={handleSubmit}
        >
          Confirm
          {isLoading && <CircularProgress size={20} />}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddIcdModal;
