"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ListWrapper = exports.AutoCompleteWrapper = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/esm/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _templateObject, _templateObject2;

var AutoCompleteWrapper = _styledComponents.default.div(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  width: 100%;\n\n  input {\n    background: none;\n    box-shadow: none;\n    width: 100%;\n    outline: none;\n    height: auto;\n    transition: all 0.3s ease-out;\n    border: none;\n    padding: 1.5rem 3.6rem;\n    line-height: 16px;\n    border-radius: 0.357rem;\n    font-size: 1rem;\n    font-weight: 400;\n    color: #6e6b7b;\n    overflow: visible;\n    margin: 0;\n  }\n"])));

exports.AutoCompleteWrapper = AutoCompleteWrapper;

var ListWrapper = _styledComponents.default.div(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\n  position: absolute;\n  background: #fff;\n  list-style: none;\n  padding-left: 0;\n  border-radius: 0.5rem;\n  max-height: 450px;\n  height: auto;\n  width: 100%;\n  position: absolute;\n  margin-top: 0.5rem;\n  list-style-type: none;\n  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11),\n    0 5px 15px 0 rgba(0, 0, 0, 0.08);\n\n  overflow-x: hidden;\n  overflow-y: scroll;\n\n  .suggestion-item {\n    padding: 0.9rem 1rem;\n    color: #6e6b7b;\n\n    a {\n      color: #6e6b7b;\n    }\n\n    &.suggestion-category {\n      font-size: 0.85rem;\n      color: $text-muted;\n      text-transform: uppercase;\n      letter-spacing: 0.6px;\n      margin-top: 0.75rem;\n      margin-bottom: 0;\n      line-height: 1.5;\n      display: flex;\n      align-items: center;\n      text-decoration: underline;\n    }\n\n    .highlighted {\n    }\n\n    svg {\n      width: 1rem;\n      height: 1rem;\n      margin-right: 0.5rem;\n    }\n\n    &.active,\n    &:hover:not(.suggestion-category):not(.no-result) {\n      background-color: #f1f1f1;\n      color: #6e6b7b;\n      cursor: pointer;\n    }\n\n    &.no-result {\n      display: flex;\n      align-items: center;\n\n      span {\n        margin-left: 0.5rem;\n      }\n    }\n  }\n"])));

exports.ListWrapper = ListWrapper;