import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentNlpSource: ''
};

const nlpSourceSlice = createSlice({
  name: 'nlpSource',
  initialState,
  reducers: {
    setCurrentNlpSource(state, action) {
      console.log('Setting nlp source to: ', action.payload);
      state.currentNlpSource = action.payload;
    }
  }
});

export const { setCurrentNlpSource } = nlpSourceSlice.actions;
export default nlpSourceSlice.reducer;
