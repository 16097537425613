// @flow

import React, { useState } from 'react';
import { withStyles } from 'tss-react/mui';
import { useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { log } from 'utils/jsUtils';
import MDPBackend from 'services/MDPBackend';
import styles from '../../../Styles/PatientHCCs.Style';
import { showToastMsg } from 'features/toast-message-slice';

type InternalNoteModalProps = {
  classes: Object,
  data: Object,
  handleClose: () => void,
  patientHccRefetch: () => void
};

const InternalNoteModal = (props: InternalNoteModalProps) => {
  const { classes, data, handleClose, patientHccRefetch } = props;

  const dispatch = useDispatch();

  const { hcc } = data;

  const [loading, setLoading] = useState(false);

  const [internalNotes, setInternalNotes] = useState('');

  const addInternalNote = async (hccId, internalNotes) => {
    setLoading(true);

    console.log('[addInternalNote] hccId: ', hccId);
    console.log('[addInternalNote] internalNotes: ', internalNotes);

    const payload = { notes: internalNotes };

    try {
      if (!internalNotes) {
        throw new Error('No internal notes provided');
      }

      const response = await MDPBackend.addInternalNote(hccId, payload);

      const parsedResponse = JSON.parse(response.data.body);
      log('[addInternalNote] parsedResponse: ', parsedResponse);

      if (!parsedResponse?.success) {
        throw new Error(parsedResponse);
      }

      dispatch(
        showToastMsg({
          open: true,
          message: 'Internal note added successfully',
          level: 'success',
          duration: 5000
        })
      );

      handleClose();
      patientHccRefetch();
    } catch (error) {
      log('[addInternalNote] error: ', error);

      dispatch(
        showToastMsg({
          open: true,
          message:
            error?.response?.body ?? error.message ?? 'Something went wrong',
          level: 'error',
          duration: 5000
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setInternalNotes(e.target.value);
  };

  return (
    <Modal open={!!data} onClose={handleClose} aria-labelledby="actions-modal">
      <Box className={classes.actionModal}>
        <div className={classes.actionModalHeader}>
          <Typography
            id="actions-modal"
            variant="h2"
            component="h2"
            className={classes.hccCategory}
          >
            HCC {hcc.hccCategory}
            {hcc.hccCategoryDescription
              ? ` (${hcc.hccCategoryDescription})`
              : ''}
          </Typography>
          <Typography id="actions-modal" variant="h2" component="h2">
            <span className={classes.icdCodeDescription}>{hcc.icdCode}</span> -{' '}
            {hcc.icdCodeDescription}
          </Typography>
        </div>
        <div>
          <InputLabel className={classes.inputLabel} htmlFor="notes" required>
            Internal Note
          </InputLabel>
          <TextField
            multiline
            fullWidth
            className={classes.actionModalText}
            value={internalNotes}
            onChange={handleChange}
            minRows={11}
            maxRows={11}
          />
        </div>
        <div>
          <Button
            disabled={loading}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();

              addInternalNote(data.hccId, internalNotes);
            }}
            className={`${classes.actionBtnSubmit} ${classes.actionBtn} ${classes.addInternalNote}`}
          >
            {loading ? 'Loading...' : 'Add internal note'}
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default withStyles(InternalNoteModal, styles);
