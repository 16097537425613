"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ListWrapper = exports.ListItemWrapper = exports.ListItem = exports.DropdownWrapper = exports.AttachedDocsWrapper = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/esm/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;

var AttachedDocsWrapper = _styledComponents.default.div(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  height: 100%;\n\n  h3 {\n    text-align: center;\n    padding-bottom: 1.5rem;\n  }\n\n  .attachment-list {\n    margin-bottom: 1.5rem;\n    overflow-y: scroll;\n    height: 90%;\n  }\n\n  .attachment-viewer {\n    height: 100%;\n  }\n\n  iframe {\n    position: relative;\n    width: 100%;\n    height: 80%;\n  }\n"])));

exports.AttachedDocsWrapper = AttachedDocsWrapper;

var DropdownWrapper = _styledComponents.default.div(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\n  position: relative;\n  width: auto;\n"])));

exports.DropdownWrapper = DropdownWrapper;

var ListWrapper = _styledComponents.default.div(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2.default)(["\n  position: absolute;\n  left: 0;\n  top: 0;\n  right: auto;\n  width: 100%;\n  margin-top: 0.5rem;\n  transform: translate3d(0px, 45px, 0px);\n  border-radius: 0.358rem;\n  box-shadow: 0 5px 25px rgb(34 41 47 / 10%);\n  padding: 0.5rem 0;\n  margin: 0 0 0;\n  background-color: #fff;\n  background-clip: padding-box;\n  border: 1px solid rgba(34, 41, 47, 0.05);\n  z-index: 9999;\n  overflow-x: hidden;\n  overflow-y: auto;\n  max-height: 400px;\n"])));

exports.ListWrapper = ListWrapper;

var ListItem = _styledComponents.default.a(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2.default)(["\n  background: transparent;\n  cursor: pointer;\n  width: 100%;\n  padding: 0.65rem 1.28rem;\n  clear: both;\n  text-align: inherit;\n  background-color: transparent;\n  border: 0;\n  font-weight: 400;\n  color: #6e6b7b;\n  white-space: wrap;\n  display: flex;\n\n  &:hover {\n    background-color: rgba(115, 103, 240, 0.12);\n  }\n"])));

exports.ListItem = ListItem;

var ListItemWrapper = _styledComponents.default.div(_templateObject5 || (_templateObject5 = (0, _taggedTemplateLiteral2.default)(["\n  background-color: transparent;\n  color: #7367f0;\n  display: flex;\n  align-items: center;\n  border: 1px solid #7367f0;\n  padding: 0.7rem 1rem;\n  border-radius: 0.358rem;\n  margin: 1rem 0;\n  transition: all 0.5s ease;\n  cursor: pointer;\n  white-space: wrap;\n  overflow: hidden;\n  width: 100%;\n  word-break: break-all;\n\n  span {\n    margin-left: 1rem;\n  }\n\n  img {\n    width: 2rem;\n    height: 2rem;\n    user-select: none;\n  }\n\n  &:hover {\n    background: #f8f8f8;\n  }\n"])));

exports.ListItemWrapper = ListItemWrapper;