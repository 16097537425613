// Page Links
export const HOME = '/';
export const SIGNUP = '/signup';
export const SIGNUP_AAHCM = '/signup-aahcm';
export const LOGIN = '/login';
export const VIEWER = '/viewer/:patientId';
export const RESET_PASSWORD = '/reset-password';
export const UPLOAD_DEMOGRAPHICS = '/upload';
export const API_KEYS = '/api-keys';
export const PATIENT_SEARCH = '/patients';
export const TERMS_OF_USE = '/tos';
export const PRIVACY_POLICY = '/privacy';
export const HIPAA_COMPLIANCE = '/hipaa';
export const ACTIVATE_ACCOUNT = '/activate';
export const API_DOCS = '/docs';
export const HCC_COMPENDIUM = '/hcc_compendium/:patientId';
export const QUERIES = '/queries';
export const NEW_QUERY = '/queries/new';
export const PATIENT_PAGE = '/patient/:patientId';
export const EVENTS = '/events';
export const TEAM_MANAGEMENT = '/team';
