"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PDFWrapper = exports.MenuWrapper = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/esm/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _templateObject, _templateObject2;

var MenuWrapper = _styledComponents.default.div(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  width: 100%;\n  padding: 1rem 1rem;\n"])));

exports.MenuWrapper = MenuWrapper;

var PDFWrapper = _styledComponents.default.div(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\n  position: absolute;\n  width: 100%;\n  height: 85%;\n  overflow: hidden;\n\n  iframe {\n    width: 100%;\n    height: 100%;\n  }\n"])));

exports.PDFWrapper = PDFWrapper;