// @flow

import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import { withStyles } from 'tss-react/mui';
import CircularProgress from '@mui/material/CircularProgress';
import dayjs from 'dayjs';
import fileDownload from 'js-file-download';
import CDAViewer from '@mdp/cda-viewer/dist/App';
import MDPBackend from 'services/MDPBackend';
import { log } from 'utils/jsUtils';
import styles from './Styles/ViewerAdapter.Styles';
import { showToastMsg } from 'features/toast-message-slice';

type Props = {
  classes: Object
};

const ViewerAdapter = (props: Props): React.Node => {
  const { classes } = props;
  const [ehrRecord, setEhrRecord] = useState(null);
  const [patientId, setPatientId] = useState('');
  const [queryId, setQueryId] = useState('');
  const [cdaType, setCdaType] = useState('');

  const location = useLocation();

  const [exportCDAAsPDFLoading, setExportCDAAsPDFLoading] = useState({
    1: false,
    2: false,
    3: false,
    4: false
  });

  const params = useParams();

  const dispatch = useDispatch();

  const getLatestPatientEcda = async (patientId) => {
    const params = queryString.parse(location.search);

    const searchStr = new URLSearchParams(params).toString();

    try {
      const res = await MDPBackend.getLatestPatientEcda(patientId, searchStr);
      log('[getLatestPatientEcda] res: ', res);

      const { data } = res;

      if (data.statusCode !== 500) {
        const { body } = data;
        const signedUrl = JSON.parse(body).file_url;
        const xmlResponse = await axios.get(signedUrl);

        const patientId = JSON.parse(body).patientId;
        console.log('patientId::: ', patientId);
        setPatientId(patientId);

        const parsedBody = JSON.parse(body);

        const dataAsOf = parsedBody.queryCreatedDt;
        let dataAsOfFormatted;
        if (dataAsOf) {
          dataAsOfFormatted = dayjs(dataAsOf).format('YYYY/MM/DD');
          console.log('dataAsOfFormatted:::: ', dataAsOfFormatted);
        }

        const queryId = parsedBody.queryId;
        console.log('queryId:::: ', queryId);
        setQueryId(queryId);

        const cdaType = parsedBody.cdaType;
        console.log('cdaType:::: ', cdaType);
        setCdaType(cdaType);

        if (typeof xmlResponse.data !== 'undefined') {
          // eslint-disable-next-line max-len
          // const hccCompendiumUrl = `${window.location.protocol}//${window.location.host}/hcc_compendium/${patientId}`;
          setEhrRecord({
            recordXml: xmlResponse.data,
            dataAsOf: dataAsOfFormatted
          });
        }
      } else {
        const errorMsg = JSON.parse(data.body);
        dispatch(
          showToastMsg({
            open: true,
            message: errorMsg ? errorMsg : 'Something went wrong',
            level: 'error',
            duration: 5000
          })
        );
      }
    } catch (err) {
      console.log('[getLatestPatientEcda] err: ', err);
    }
  };

  const tryGetExportedPDF = async (requestId, retries) => {
    console.log('[tryGetExportedPDF] retry: ', retries);
    await new Promise((resolve) => setTimeout(resolve, 10 * 1000)); // wait 10 seconds

    try {
      const response = await MDPBackend.getExportCDAAsPDF(requestId);
      const { body } = response.data;
      const parsedBody = JSON.parse(body);

      console.log('[tryGetExportedPDF] parsedBody: ', parsedBody);

      const { url: signedUrl } = parsedBody;

      if (signedUrl) {
        return parsedBody;
      } else if (!signedUrl && retries > 0) {
        return tryGetExportedPDF(requestId, retries - 1);
      } else {
        throw new Error('Max number of attempt reached');
      }
    } catch (error) {
      console.log('[tryGetExportedPDF] error: ', error);
      return null;
    }
  };

  const exportCDAAsPDF = async (config = {}) => {
    console.log('[exportCDAAsPDF] config: ', config);
    console.log('[exportCDAAsPDF] queryId: ', queryId);

    const searchParams = queryString.parse(location.search);

    searchParams.q = queryId !== 'undefined' ? queryId : '';
    searchParams.p = params.patientId ? params.patientId : '';

    const searchParamsString = new URLSearchParams(searchParams).toString();
    console.log('[exportCDAAsPDF] searchParamsString:: ', searchParamsString);

    try {
      const response = await MDPBackend.exportCDAAsPDF(
        {
          ...config,
          type: cdaType
        },
        searchParamsString
      );
      const { body } = response.data;
      const parsedBody = JSON.parse(body);

      const requestId = parsedBody.requestId;

      console.log('[exportCDAAsPDF] requestId: ', requestId);

      if (requestId) {
        dispatch(
          showToastMsg({
            open: true,
            message:
              // eslint-disable-next-line max-len
              'Exporting PDF. This can take up to 5 minutes. File will download automatically when ready. Do not exit this page.',
            level: 'success',
            duration: 5000
          })
        );

        const exportedPDFResponse = await tryGetExportedPDF(requestId, 30);
        console.log(
          '[exportCDAAsPDF] exportedPDFResponse: ',
          exportedPDFResponse
        );

        const {
          url: signedUrl,
          patientId,
          patientFirstName,
          patientLastName
        } = exportedPDFResponse;

        const signedUrlResponse = await axios.get(signedUrl, {
          responseType: 'blob'
        });

        const blob = signedUrlResponse.data;

        const fileName = `${patientLastName}_${patientFirstName}_${patientId}.pdf`;

        fileDownload(blob, fileName);
      } else {
        throw new Error(`Failed to create request: ${response}`);
      }
    } catch (error) {
      console.log('[exportCDAAsPDF] error: ', error);
      dispatch(
        showToastMsg({
          open: true,
          message:
            'Something went wrong. Please contact support at support@mdportals.com',
          level: 'error',
          duration: 5000
        })
      );
    }
  };

  const getNewJWTToken = async () => {
    const currentSession = await Auth.currentSession();
    const tok = currentSession.getIdToken().getJwtToken();
    return tok;
  };

  const authenticatedAxios = (url) => {
    return getNewJWTToken().then((jwtToken) => {
      // Create instance
      // inject local headers, if running locally
      let localHeaders = {};
      if (process.env.REACT_APP_ENV === 'dev') {
        // localHeaders['x-mdp-email'] = 'test@email.com';
      }

      const defaultOptions = {
        baseURL: url,
        headers: {
          'Content-Type': 'application/json',
          ...localHeaders
        }
      };
      let instance = axios.create(defaultOptions);
      // Set the AUTH token for any request
      instance.defaults.headers.common['Authorization'] = `Bearer ${
        jwtToken ? jwtToken : ''
      }`;
      return instance;
    });
  };

  const getPatientAttachment = async (url) => {
    const response = await authenticatedAxios(url).then((authAxios) => {
      return authAxios.get();
    });

    if (response) {
      const parsedResponse = JSON.parse(response.data.body);
      const signedUrl = parsedResponse.data;

      console.log('signedUrl: ', signedUrl);

      return signedUrl;
    }
  };
  console.log('paramss', patientId);
  useEffect(() => {
    const { patientId } = params;
    console.log('patientId', patientId);
    if (patientId) {
      getLatestPatientEcda(patientId);
    }
  }, [location.search]);

  return (
    <>
      <Helmet>
        <title>eCDA | {patientId}</title>
      </Helmet>
      <>
        {ehrRecord ? (
          <CDAViewer
            ehrRecord={ehrRecord}
            getPatientAttachment={getPatientAttachment}
            exportCDAAsPDF={exportCDAAsPDF}
            exportCDAAsPDFLoading={exportCDAAsPDFLoading}
            setExportCDAAsPDFLoading={setExportCDAAsPDFLoading}
          />
        ) : (
          <div className={classes.messageCentered}>
            <div style={{ display: 'block', textAlign: 'center' }}>
              Requesting patient record, please wait.
              <br />
              <br />
              <CircularProgress />
            </div>
          </div>
        )}
      </>
    </>
  );
};

export default withStyles(ViewerAdapter, styles);
