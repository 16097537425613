"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Logo = exports.FileUploadWrapper = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/esm/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _templateObject, _templateObject2;

var FileUploadWrapper = _styledComponents.default.div(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 1rem;\n  width: 100%;\n  height: 90vh;\n\n  .upload-container {\n    padding: 5rem;\n    outline: 2px dashed #7367f0;\n    cursor: pointer;\n    max-width: 600px;\n  }\n\n  .sample-container {\n    margin-top: 2rem;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n\n    button {\n      margin-left: 1rem;\n    }\n  }\n"])));

exports.FileUploadWrapper = FileUploadWrapper;

var Logo = _styledComponents.default.img(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\n  display: flex;\n  margin: 2rem auto;\n  width: 200px;\n  height: auto;\n"])));

exports.Logo = Logo;