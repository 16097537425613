// @flow

import React from 'react';
import { useEffect } from 'react';
import dayjs from 'dayjs';
import { Controller } from 'react-hook-form';
import { TextField, MenuItem } from '@mui/material';
import { withStyles } from 'tss-react/mui';

import styles from './Styles/NewQuery.Style';

type Props = {
  classes: Object,
  queryField: Object,
  control: Object,
  setValue: () => void
};

const isValidDobFormat = (date) => {
  return dayjs(date).format('YYYY-MM-DD') === date
    ? true
    : 'DOB format must be YYYY-MM-DD';
};

const TextInputField = (props: Props) => {
  const { classes, queryField, control, setValue } = props;

  useEffect(() => {}, [setValue]);

  return (
    <>
      <Controller
        name={queryField.name}
        control={control}
        rules={{
          required: queryField.required
            ? `${queryField.label} is required`
            : false,
          validate: queryField.name === 'patient_dob' ? isValidDobFormat : null
        }}
        render={({ field }) => (
          <TextField
            {...field}
            select={queryField.type === 'option'}
            label={queryField.label}
            variant="outlined"
            className={classes.txtField}
            required={queryField.required}
            value={field.value || ''}
          >
            <MenuItem value=""></MenuItem>
            {queryField.options &&
              queryField.options.map((option, idx) => (
                <MenuItem key={idx} value={option.value}>
                  {option.name}
                </MenuItem>
              ))}
          </TextField>
        )}
      />
    </>
  );
};

export default withStyles(TextInputField, styles);
