// @flow

const styles = (theme: Object) => ({
  pageRoot: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column'
  },
  body: {
    minHeight: `calc(100vh - ${theme.layout.header.height}px - ${theme.layout.footer.height}px)`,
    flexGrow: 1
  }
});

export default styles;
