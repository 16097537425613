// @flow

import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import ChartFiles from '../../components/ChartFiles';

const FileBrowserPage = () => {
  const { patientId, hasSuggestions } = useOutletContext();

  console.log('fileBrowser', patientId, hasSuggestions);

  return (
    <>
      {' '}
      <Helmet>
        <meta charSet="utf-8" />
        <title>Patient Chart Files</title>
      </Helmet>
      <ChartFiles patientId={patientId} />
    </>
  );
};

export default FileBrowserPage;
