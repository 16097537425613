// @flow

import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { ForwardRef } from 'components';
import { WORKFLOW_ACTION_SLUG_SUFFIXES, getButtonClass } from './PatientHCCs';

type HccSettingsButtonProps = {
  hcc: Object,
  classes: Object,
  setActionToConfirm: Function,
  setInternalNoteToConfirm: Function
};

const HccSettingsButton = (props: HccSettingsButtonProps) => {
  const { hcc, setActionToConfirm, setInternalNoteToConfirm, classes } = props;
  const hccId = hcc._id;
  const actions = hcc.actions;
  const icdCode = hcc.icdCode;

  type CustomMenuItemProps = {
    label: String,
    slug: String,
    hcc: Object,
    disabled: Boolean
  };

  const CustomMenuItem = (props: CustomMenuItemProps) => {
    const { slug, disabled, label, hcc } = props;

    return (
      <Button
        key={slug}
        disabled={disabled}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setActionToConfirm({
            slug,
            label,
            hcc
          });
        }}
        className={`${classes.actionBtn} ${getButtonClass(slug, classes)}`}
      >
        {label}
      </Button>
    );
  };

  return (
    <div className="relative flex items-center z-9991">
      {actions.map((a) =>
        a.slug.endsWith(WORKFLOW_ACTION_SLUG_SUFFIXES.INTERNAL_NOTE) ? (
          <Tooltip title="Add internal Note" key={a.slug}>
            <ForwardRef>
              <FontAwesomeIcon
                style={{ color: 'black', height: '20px' }}
                icon={regular('comment-alt')}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setInternalNoteToConfirm({
                    hccId,
                    icdCode,
                    hcc
                  });
                }}
              />
            </ForwardRef>
          </Tooltip>
        ) : (
          <CustomMenuItem
            hcc={hcc}
            hccId={hccId}
            key={a.slug}
            slug={a.slug}
            label={a.label}
          />
        )
      )}
    </div>
  );
};

export default HccSettingsButton;
