// @flow

import React from 'react';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { withStyles } from 'tss-react/mui';

import styles from './Styles/Footer.Style';

type Props = {
  classes: Object,
  children: Object // menu items injected by HOC
};

const Footer = (props: Props) => {
  const { classes } = props;

  return (
    <Grid className={classes.root} container direction={'row'}>
      <Grid align="center" item xs={12}>
        <Link to="/privacy" className={classes.footerLink}>
          Privacy Policy
        </Link>
        <Link to="/tos" className={classes.footerLink}>
          Terms Of Use
        </Link>
        <Link to="/hipaa" className={classes.footerLink}>
          HIPAA Compliance
        </Link>
      </Grid>
    </Grid>
  );
};

export default withStyles(Footer, styles);
