// @flow

const styles = (theme: Object) => ({
  container: {
    marginTop: '10vh',
    maxWidth: 600,
    margin: '0 auto',
    padding: `${theme.spacing(4)} ${theme.spacing(4)} ${theme.spacing(
      6
    )} ${theme.spacing(4)}`
  },
  logo: {
    margin: theme.spacing(2)
  },
  formCtr: {
    marginTop: theme.spacing(8),
    width: 500,
    margin: '0 auto',
    marginBottom: theme.spacing(8)
  },
  formSubtitle: {
    marginTop: theme.spacing(2),
    fontWeight: 600,
    textAlign: 'left'
  },
  txtField: {
    width: '100%',
    marginTop: theme.spacing(1)
  },
  submitBtn: {
    marginTop: theme.spacing(1)
  },
  doNotRefresh: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    fontSize: '1rem'
  },
  formFooter: {
    marginTop: theme.spacing(2),
    fontSize: '0.9rem'
  }
});
export default styles;
