// @flow
/* eslint-disable react/display-name */

import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import axios from 'axios';
import fileDownload from 'js-file-download';
import MDPBackend from '../../../services/MDPBackend';

const NLP_SOURCE_REV = 'REV';
const RESPONSE_TYPE_BLOB = 'blob';

const boxStyle = {
  position: 'absolute',
  width: '100%',
  maxWidth: 1200,
  padding: '1rem',
  height: '100%',
  maxHeight: 800,
  backgroundColor: 'white',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  overflow: 'auto',
  outline: 'none'
};

type CompendiumDownloadButtonProps = {
  row: Object
};

const RawCompendiumsModal = (props: Props) => {
  const { handleClose, open, queryId } = props;
  const [rawCompendiums, setRawCompendiums] = useState([]);
  const [loading, setLoading] = useState(false);

  const getRawInfsCompendiums = async (queryId) => {
    setLoading(true);

    try {
      const response = await MDPBackend.getRawInfsCompendiums(queryId);
      console.log('[getRawInfsCompendiums] response: ', response);
      const parsedResponse = JSON.parse(response.data.body).data;
      console.log('[getRawInfsCompendiums] parsedResponse: ', parsedResponse);
      setLoading(false);
      setRawCompendiums(parsedResponse);
    } catch (err) {
      console.log('[getRawInfsCompendiums] err: ', err);
      setLoading(false);
    }
  };

  const downloadCompendiumS3File = async (queryId, requestId, responseType) => {
    const response = await MDPBackend.downloadRawInfsCompendium(
      queryId,
      requestId
    );
    console.log('[downloadCompendium] response: ', response);
    const signedUrl = JSON.parse(response.data.body).data;
    const fileResponse = await axios.get(signedUrl, {
      responseType: responseType
    });

    return fileResponse.data;
  };

  const CompendiumDownloadButton = (props: CompendiumDownloadButtonProps) => {
    const { row } = props;
    const [loadingDownload, setLoadingDownload] = useState(false);
    const fileExtension = row.nlpSource === NLP_SOURCE_REV ? 'json' : 'html';

    const downloadCompendium = async (queryId, requestId) => {
      setLoadingDownload(true);

      try {
        const compendiumS3File = await downloadCompendiumS3File(
          queryId,
          requestId,
          RESPONSE_TYPE_BLOB
        );
        fileDownload(compendiumS3File, `${row.fileName}.${fileExtension}`);
        setLoadingDownload(false);
      } catch (err) {
        console.log('[downloadCompendium] err: ', err);
        setLoadingDownload(false);
      }
    };

    return (
      <Button
        onClick={() => downloadCompendium(queryId, row.requestId)}
        disabled={loadingDownload}
      >
        {loadingDownload
          ? 'Downloading...'
          : `Download ${fileExtension.toUpperCase()}`}
      </Button>
    );
  };

  useEffect(() => {
    console.log('Modal queryId: ', queryId);
    if (queryId && open) {
      getRawInfsCompendiums(queryId);
    }
  }, [queryId]);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box style={boxStyle}>
          {loading ? (
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Loading...
            </Typography>
          ) : rawCompendiums && rawCompendiums.length ? (
            <div>
              <table style={{ width: '100%' }}>
                <thead>
                  <tr>
                    <th style={{ textAlign: 'left', width: '40%' }}>
                      File Name
                    </th>
                    <th style={{ textAlign: 'left' }}>Status</th>
                    <th style={{ textAlign: 'left' }}>NLP Source</th>
                    <th style={{ textAlign: 'left' }}>Details</th>
                    <th style={{ textAlign: 'left' }}>Compendium</th>
                  </tr>
                </thead>
                <tbody>
                  {rawCompendiums.map((row, key) => {
                    return (
                      <tr key={key}>
                        <td style={{ paddingTop: 20 }}>
                          <a
                            // eslint-disable-next-line max-len
                            href={`https://ehr.mdportals.com/patient/${row.patientId}/files?fileHash=${row.fileHash}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {row.fileName}
                          </a>
                          {row.fileHash && <div>Hash: {row.fileHash}</div>}
                        </td>
                        <td style={{ paddingTop: 20 }}>{row.status}</td>
                        <td style={{ paddingTop: 20 }}>{row.nlpSource}</td>
                        <td style={{ paddingTop: 20 }}>{row.details}</td>
                        <td style={{ paddingTop: 20 }}>
                          {row.hasCompendium && (
                            <CompendiumDownloadButton row={row} />
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : null}
        </Box>
      </Modal>
    </div>
  );
};

export default RawCompendiumsModal;
