// @flow

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Grid from '@mui/material/Grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import MDPBackend from 'services/MDPBackend';
import { ForwardRef } from 'components';
import PdfFileRenderer from './PdfFileRenderer';
import AceEditor from 'react-ace';
import 'ace-builds/webpack-resolver';
import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/ext-language_tools';
import { useDispatch } from 'react-redux';
import { showToastMsg } from 'features/toast-message-slice';

const renderFileName = (filename) => {
  if (!filename) return '';
  const MAX_CHARS = 100;

  if (filename.length <= MAX_CHARS) return filename;
  const extension = filename.slice(filename.lastIndexOf('.'));
  const truncatedName = filename.slice(0, MAX_CHARS - extension.length - 3);

  return truncatedName + '...' + extension;
};
type Props = {
  source: null | Object,
  patientId: String
};

const PdfFileViewer = (props: Props) => {
  const { source, patientId } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [fileData, setFileData] = useState(null);
  const [fileBin, setFileBin] = useState(null);
  const [showFileMetaDataDebug, setShowFileMetaDataDebug] = useState(false);
  const [pdfFullHeight, setPdfFullHeight] = useState(false);
  const [hasError, setHasError] = useState(false);

  const downloadFile = async (url) => {
    const response = await axios.get(url, {
      responseType: 'blob'
    });

    return response.data;
  };

  const getHccSourceDocument = async (source) => {
    setLoading(true);
    setHasError(false);

    try {
      const response = await MDPBackend.getHccSourceDocument(
        patientId,
        source?.sourceLocation.hash
      );
      console.log('[getHccSourceDocument] response: ', response);
      const fileData = JSON.parse(response.data.body);
      console.log('fileData: ', fileData);
      setFileData(fileData);

      const fileBlob = await downloadFile(fileData.previewUrl);
      setFileBin(new Blob([fileBlob], { type: 'application/pdf' }));
    } catch (error) {
      setHasError(true);
      dispatch(
        showToastMsg({
          open: true,
          message:
            error && error.message ? error.message : 'Unexpected Error occured',
          level: 'error',
          duration: 5000
        })
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getHccSourceDocument(source);
  }, []);

  if (loading || !fileData) {
    return (
      <div className="flex justify-center w-full">
        <CircularProgress />
      </div>
    );
  }

  if (!source) return null;

  return (
    <Grid
      container
      direction="column"
      style={{ height: '100%', flexWrap: 'nowrap' }}
    >
      {!pdfFullHeight && (
        <Grid item>
          <Typography
            variant="h1"
            className="file-info-title !mb-2.5 !font-semibold"
          >
            {renderFileName(fileData.name)}
          </Typography>
        </Grid>
      )}
      {fileData.metaDataPreview && !pdfFullHeight && (
        <Grid item>
          <div className="relative flex flex-wrap [&_p]:text-base">
            {Object.keys(fileData.metaDataPreview).map((key, idx) => (
              <div
                className="file-info-row mb-[10px] flex items-center basis-1/2"
                key={idx}
              >
                <Typography variant="body1" className="file-info-key w-[200px]">
                  {key}:
                </Typography>
                <Typography
                  variant="body1"
                  className="font-semibold file-info-value"
                >
                  {fileData.metaDataPreview[key]}
                </Typography>
              </div>
            ))}
          </div>
        </Grid>
      )}
      <Grid item>
        {fileData.cdaUrl && !pdfFullHeight && (
          <Button
            className="!mr-2.5"
            onClick={() => window.open(fileData.cdaUrl, '_blank')}
            color="primary"
            variant="contained"
          >
            View CDA{' '}
            <ForwardRef>
              <FontAwesomeIcon
                style={{ marginLeft: 10 }}
                icon={solid('arrow-up-right-from-square')}
              />
            </ForwardRef>
          </Button>
        )}
        {fileData.metaDataDebug && !pdfFullHeight && (
          <Button
            onClick={() => setShowFileMetaDataDebug((prevState) => !prevState)}
            variant="outlined"
          >
            {showFileMetaDataDebug ? 'Hide' : 'Show'} File Meta Data
          </Button>
        )}
      </Grid>
      {showFileMetaDataDebug && fileData.metaDataDebug && !pdfFullHeight && (
        <Grid item>
          <AceEditor
            width="100%"
            placeholder="Query Data"
            mode="json"
            theme="monokai"
            name="query-data"
            fontSize={14}
            showPrintMargin={true}
            showGutter={true}
            highlightActiveLine={true}
            value={JSON.stringify(fileData.metaDataDebug, null, 2)}
            setOptions={{
              readOnly: true,
              maxLines: Infinity
            }}
          />
        </Grid>
      )}
      {!hasError ? (
        <PdfFileRenderer
          fileBin={fileBin}
          fileName={fileData.name}
          previewPage={
            source?.sourceLocation?.page
              ? parseInt(source?.sourceLocation?.page)
              : null
          }
          searchText={source?.excerpt}
          pdfFullHeight={pdfFullHeight}
          setPdfFullHeight={setPdfFullHeight}
        />
      ) : (
        'No PDF available'
      )}
    </Grid>
  );
};

export default PdfFileViewer;
