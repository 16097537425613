// @flow

import React, { useState, useMemo } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import ModalBox from 'components/ModalBox';
import PdfFileViewer from '../../components/FileViewer/PdfFileViewer';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { withStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import styles from '../../Styles/PatientHCCs.Style';
import HipaaLogModal from './Modals/HipaaLogModal';
import ActionModal from './Modals/ActionModal';
import InternalNoteModal from './Modals/InternalNoteModal';
import HccSourceCard from './HccSourceCard';
import { useSelector } from 'react-redux';
import AddIcdModal from './Modals/AddIcdModal';
import { useLazyGeneratePatientSummaryQuery } from 'api/api';

const PATIENT_SUMMAR_HEADER_CLASSNAME =
  'uppercase !font-bold !text-[1.5rem] text-mineShaft/60 font-dmSans leading-13 tracking-[-0.03em]';
export const DEFAULT_HCC_VERSION = 'V28';
export const WORKFLOW_ACTION_SLUG_SUFFIXES = {
  QUERY_PROVIDER: 'query_provider',
  DENY: 'deny',
  INTERNAL_NOTE: 'internal_note',
  CONFIRM: 'confirm',
  BACK_TO_CODER: 'back_to_coder'
};

export const getButtonClass = (slug, classes) => {
  switch (true) {
    case slug.endsWith(WORKFLOW_ACTION_SLUG_SUFFIXES.QUERY_PROVIDER):
      return classes.query_provider;
    case slug.endsWith(WORKFLOW_ACTION_SLUG_SUFFIXES.DENY):
      return classes.deny;
    case slug.endsWith(WORKFLOW_ACTION_SLUG_SUFFIXES.INTERNAL_NOTE):
      return classes.addInternalNote;
    case slug.endsWith(WORKFLOW_ACTION_SLUG_SUFFIXES.CONFIRM):
      return classes.confirm;
    case slug.endsWith(WORKFLOW_ACTION_SLUG_SUFFIXES.BACK_TO_CODER):
      return classes.back_to_coder;
  }

  return '';
};

type Props = {
  classes: Object,
  patientId: String,
  patientHccLoading: Boolean,
  patientHccs: Array<Object>,
  pickOpenEncounterToAcceptICD: Boolean,
  encounters: Array<Object>,
  patientHccRefetch: () => void,
  exportCodesToCSV: () => void,
  loadingCSV: String,
  isReveleerTeam: Boolean,
  exportCodesToCSVRevTeam: () => void,
  loadingCSVRevTeam: Boolean,
  nlpSource: String
};

const BENCHMARK_NLP_SOURCE = 'BENCHMARK';

const PatientHCCs = (props: Props): React.Node => {
  const {
    classes,
    patientHccLoading,
    patientHccs,
    patientHccRefetch,
    patientId,
    loadingCSV,
    exportCodesToCSV,
    pickOpenEncounterToAcceptICD,
    encounters,
    exportCodesToCSVRevTeam,
    loadingCSVRevTeam,
    isReveleerTeam,
    nlpSource
  } = props;
  const currentOrganization = useSelector((state) => state.currentOrganization);
  const [actionToConfirm, setActionToConfirm] = useState(null);
  const [internalNoteToConfirm, setInternalNoteToConfirm] = useState(null);
  const [hipaaLogHcc, setHipaaLogHcc] = useState(null);
  const [pdfViwerSrc, setPdfViwerSrc] = useState(null);
  const [openIcdModal, setOpenIcdModal] = useState(false);
  const [currentSection, setCurrentSection] = useState('');

  const openHipaaLog = (e, hcc) => {
    e.preventDefault();
    e.stopPropagation();
    setHipaaLogHcc(hcc);
  };

  const closeHipaaLog = () => {
    setHipaaLogHcc(null);
  };

  const handleActionModalClose = () => {
    setActionToConfirm(null);
  };

  const handleInternalNoteModalClose = () => {
    setInternalNoteToConfirm(null);
  };

  const currentIcdCodes = useMemo(() => {
    if (!patientHccs) return [];
    const allCategories = Object.values(patientHccs);

    const allICDCodes = allCategories.flatMap((category) =>
      Object.values(category).flatMap((item) =>
        item.map((detail) => detail.icdCode)
      )
    );

    const uniqueCodes = Array.from(new Set(allICDCodes));

    return uniqueCodes;
  }, [patientHccs]);

  const Main = () => {
    const [
      generatePatientSummary,
      { data: patientSummary, isFetching: patientSummaryLoading }
    ] = useLazyGeneratePatientSummaryQuery();

    const handleGeneratePatientSummary = async () => {
      await generatePatientSummary(patientId);
    };

    return (
      <>
        {patientHccLoading ? (
          <div className={classes.center}>
            <CircularProgress />
          </div>
        ) : patientHccs ? (
          <>
            <div style={{ marginBottom: 40 }} className="">
              <Button
                onClick={exportCodesToCSV}
                disabled={loadingCSV}
                variant="outlined"
              >
                {loadingCSV ? 'Exporting...' : 'Export to CSV'}
              </Button>
              {isReveleerTeam ? (
                <>
                  <Button
                    onClick={exportCodesToCSVRevTeam}
                    disabled={loadingCSVRevTeam}
                    variant="outlined"
                    sx={{ marginLeft: 1 }}
                  >
                    {loadingCSVRevTeam
                      ? 'Exporting...'
                      : '[REV Team] Export to CSV'}
                  </Button>
                  <Button
                    onClick={handleGeneratePatientSummary}
                    disabled={patientSummaryLoading}
                    variant="outlined"
                    sx={{ marginLeft: 1 }}
                  >
                    {patientSummaryLoading
                      ? 'Generating...'
                      : 'Generate Patient Summary'}
                  </Button>
                </>
              ) : null}
            </div>

            {patientSummary && (
              <div className="mb-5">
                <Typography className={PATIENT_SUMMAR_HEADER_CLASSNAME}>
                  Patient Summary
                </Typography>
                <Box component="section" className="mt-1 mb-3">
                  <Typography variant="body1" className="whitespace-pre-line">
                    {patientSummary}
                  </Typography>
                </Box>
              </div>
            )}

            {Object.keys(patientHccs).map((section, index) => (
              <div key={section} className="mb-5">
                <div className="flex justify-between">
                  {/* eslint-disable max-len */}
                  <div className="flex">
                    <Typography className="uppercase !font-bold !text-[2rem] text-mineShaft/60 font-dmSans leading-13 tracking-[-0.03em]">
                      {section}
                    </Typography>
                    {index === 0 &&
                      isReveleerTeam &&
                      currentOrganization &&
                      currentOrganization.config.enableWorkflowActions
                        ?.enable &&
                      BENCHMARK_NLP_SOURCE === nlpSource && (
                        <Button
                          className="!ml-2 !text-black"
                          onClick={() => {
                            setCurrentSection(section);
                            setOpenIcdModal(true);
                          }}
                        >
                          ADD ICD
                        </Button>
                      )}
                  </div>
                </div>
                <div className="mt-3">
                  {patientHccs[section] ? (
                    Object.keys(patientHccs[section]).length ? (
                      <>
                        {Object.keys(patientHccs[section]).map((hcc) => (
                          <HccSourceCard
                            key={hcc}
                            _key={`${section}-${hcc}`}
                            hccLabel={hcc}
                            patientHccs={patientHccs[section][hcc]}
                            classes={classes}
                            setActionToConfirm={setActionToConfirm}
                            setInternalNoteToConfirm={setInternalNoteToConfirm}
                            setPdfViwerSrc={setPdfViwerSrc}
                            openHipaaLog={openHipaaLog}
                            patientId={patientId}
                          />
                        ))}
                      </>
                    ) : (
                      <span style={{ fontSize: '1rem' }}>No results found</span>
                    )
                  ) : null}
                </div>
              </div>
            ))}
          </>
        ) : (
          <span style={{ fontSize: 14 }}>No results found</span>
        )}
      </>
    );
  };

  return (
    <>
      {actionToConfirm ? (
        <ActionModal
          data={actionToConfirm}
          pickOpenEncounterToAcceptICD={pickOpenEncounterToAcceptICD}
          encounters={encounters}
          handleClose={handleActionModalClose}
          patientHccRefetch={patientHccRefetch}
        />
      ) : null}
      {internalNoteToConfirm ? (
        <InternalNoteModal
          data={internalNoteToConfirm}
          handleClose={handleInternalNoteModalClose}
          patientHccRefetch={patientHccRefetch}
        />
      ) : null}
      {React.useMemo(
        () => (
          <Main />
        ),
        [patientHccLoading, patientHccs, loadingCSV, loadingCSVRevTeam]
      )}
      <HipaaLogModal
        open={hipaaLogHcc !== null}
        hcc={hipaaLogHcc}
        handleClose={closeHipaaLog}
      />
      {pdfViwerSrc && (
        <Modal
          open={!!pdfViwerSrc}
          onClose={() => setPdfViwerSrc(null)}
          aria-labelledby="pdf-viewer-modal"
          aria-describedby="pdf-viewer-modal"
        >
          <ModalBox
            closeButton={
              <Button
                className="!absolute right-2 top-2 !text-red-600"
                onClick={() => setPdfViwerSrc(null)}
              >
                <CloseIcon />
              </Button>
            }
          >
            <PdfFileViewer
              closeModal={() => setPdfViwerSrc(null)}
              source={pdfViwerSrc}
              patientId={patientId}
            />
          </ModalBox>
        </Modal>
      )}
      <AddIcdModal
        open={openIcdModal}
        patientId={patientId}
        currentSection={currentSection}
        cmsVersion={
          currentOrganization?.config?.cmsVersion || DEFAULT_HCC_VERSION
        }
        currentIcdCodes={currentIcdCodes}
        patientHccRefetch={patientHccRefetch}
        nlpSource={nlpSource}
        handleClose={() => setOpenIcdModal(false)}
        classes={classes}
      />
    </>
  );
};

export default withStyles(PatientHCCs, styles);
