import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  loadingModule: ''
};

const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    setIsLoading(state, action) {
      // it's ok to do this because inner makes it immutable under the hood
      state.isLoading = action.payload.isLoading;
      state.loadingModule = action.payload.loadingModule;
    }
  }
});

export const { setIsLoading } = loadingSlice.actions;
export default loadingSlice.reducer;
