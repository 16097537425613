// @flow

import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import { Helmet } from 'react-helmet-async';
import { withStyles } from 'tss-react/mui';
import PageTemplate from 'layout/PageTemplate';
import { AMPLIFY_CONFIG } from 'settings/aws-config';
import styles from './Styles/Queries.Style';
import QueryFilters from './QueryFilters';
import QueryTable from './QueryTable';
import QueryStats from './QueryStats';

Amplify.configure(AMPLIFY_CONFIG);

type Props = {
  classes: Object
};

const Queries = (props: Props): React.Node => {
  const { classes } = props;

  const [queries, setQueries] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [loading, setLoading] = useState(false);
  const [statsLoading, setStatsLoading] = useState(false);
  const [, setSearchParams] = useSearchParams();

  const [queryStats, setQueryStats] = useState({
    total: 0,
    avgRafUplift: 0,
    totalCompendiums: 0
  });

  const setUrlQueryParams = (param) => {
    setSearchParams((prev) => {
      Object.keys(param).forEach((key) => {
        prev.set(key, param[key]);
      });

      return prev;
    });
  };

  const parseSearchParams = ({
    page = 1,
    perPage = 50,
    sortBy = 'createdDt',
    sortByOrder = 'desc',
    searchStr = '',
    output = '',
    createdFrom = '',
    createdTo = '',
    batchName = ''
  } = {}) => {
    const searchParams = {
      page,
      perPage,
      sortBy,
      sortByOrder,
      searchStr,
      output,
      createdFrom,
      createdTo,
      batchName
    };

    const searchString = new URLSearchParams(searchParams);

    return searchString;
  };

  return (
    <PageTemplate>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Queries</title>
      </Helmet>
      <div className={classes.root}>
        <QueryFilters
          setLoading={setLoading}
          setStatsLoading={setStatsLoading}
          setQueries={setQueries}
          setUrlQueryParams={setUrlQueryParams}
          loading={loading}
          totalRows={totalRows}
          setQueryStats={setQueryStats}
          parseSearchParams={parseSearchParams}
          setTotalRows={setTotalRows}
        />
        <QueryStats queryStats={queryStats} loading={statsLoading} />
        <QueryTable
          queries={queries}
          totalRows={totalRows}
          loading={loading}
          setUrlQueryParams={setUrlQueryParams}
          parseSearchParams={parseSearchParams}
        />
      </div>
    </PageTemplate>
  );
};

export default withStyles(Queries, styles);
