// @flow

import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const boxStyle = {
  position: 'absolute',
  width: '100%',
  maxWidth: 1200,
  padding: '2rem 2rem',
  height: '100%',
  maxHeight: 800,
  backgroundColor: 'white',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  overflow: 'auto',
  outline: 'none',
  borderRadius: '1rem'
};

const RafDetailsModal = (props: Props) => {
  const { handleClose, data } = props;

  if (!data) return <div></div>;
  const { rafScore, breakDown } = data;

  console.log('data:::: ', data);

  return (
    <div>
      <Modal
        open={!!data}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box style={boxStyle}>
          <Typography
            variant="h1"
            style={{
              marginBottom: 20,
              borderBottom: '1px solid black',
              fontWeight: '700'
            }}
          >
            RAF Details
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="left" style={{ width: '20%' }}>
                    HCC Category
                  </TableCell>
                  <TableCell align="left" style={{ width: '50%' }}>
                    ICD Codes
                  </TableCell>
                  <TableCell align="right" style={{ width: '30%' }}>
                    RAF Score
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {breakDown.map((el) => (
                  <TableRow key={el.hccCategory}>
                    <TableCell align="left">{el.hccCategory}</TableCell>
                    <TableCell align="left">{el.icdCodes.join(', ')}</TableCell>
                    <TableCell align="right">{el.rafScore}</TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell align="left">
                    <strong>Total RAF Delta</strong>
                  </TableCell>
                  <TableCell />
                  <TableCell align="right">
                    <strong>{rafScore}</strong>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Modal>
    </div>
  );
};

export default RafDetailsModal;
