"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = require("react");

var useScrollToTop = function useScrollToTop() {
  (0, _react.useLayoutEffect)(function () {
    window.scrollTo(0, 0);
  }, []);
};

var _default = useScrollToTop;
exports.default = _default;