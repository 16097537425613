import { createSlice } from '@reduxjs/toolkit';

const storageShouldBeLoggedIn = () =>
  window.localStorage.getItem('shouldBeLoggedIn') === 'true' ? true : false;

const storageUserConfirmed = () =>
  window.localStorage.getItem('userConfirmed') === 'true' ? true : false;

const initialState = {
  shouldBeLoggedIn: storageShouldBeLoggedIn(),
  userConfirmed: storageUserConfirmed()
};

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setLogin(state) {
      window.localStorage.setItem('shouldBeLoggedIn', true);
      state.shouldBeLoggedIn = true;
    },
    setLogout(state) {
      window.localStorage.setItem('shouldBeLoggedIn', false);
      state.shouldBeLoggedIn = false;
    },
    setUserConfirmed(state, action) {
      state.userConfirmed = action.payload;
    }
  }
});

export const { setLogin, setLogout, setUserConfirmed } = loginSlice.actions;
export default loginSlice.reducer;
