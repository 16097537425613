// @flow

import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';

import { useSelector } from 'react-redux';

const AppLoadingSpinner = () => {
  const { isLoading: isAppLoading, loadingModule } = useSelector(
    (state) => state.loading
  );

  const reducer = (isLoading, moduleKey) =>
    !isLoading ? loadingModule[moduleKey] : true;

  return (
    <>
      {isAppLoading ||
      (loadingModule && Object.keys(loadingModule).reduce(reducer, false)) ? (
        <LinearProgress />
      ) : null}
    </>
  );
};

export default AppLoadingSpinner;
