// @flow

const styles = (theme: Object) => ({
  root: {},
  patientDemographics: {
    minHeight: 150,
    padding: 30,
    marginBottom: theme.spacing(2)
  },
  patientHCCs: {
    minHeight: 150,
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(3)
  },
  demographicsData: {
    marginTop: theme.spacing(1)
  },
  loadingContainer: {
    padding: 30,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  icon: {
    color: theme.palette.secondary.dark,
    cursor: 'pointer',
    fontSize: '1.2rem',
    marginLeft: 10,

    '&.grey': { color: '#C6C6C6' }
  },
  files: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
  chartDocumentsList: {
    maxWidth: 500,
    marginTop: theme.spacing(3)
  },
  documentSearch: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2)
  },
  suggestionCard: {
    padding: '20px 15px',
    marginTop: theme.spacing(2)
  },
  suggestionHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: 20,

    '& a': {
      color: 'black'
    }
  },
  highlight: {
    fontStyle: 'italic',
    fontSize: 14,
    marginTop: theme.spacing(1),
    textDecoration: 'underline',
    cursor: 'pointer',

    '& em': {
      background: 'yellow'
    }
  },
  highlightSectionHeader: {
    fontWeight: 600,
    marginTop: theme.spacing(3)
  }
});

export default styles;
