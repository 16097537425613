// https://github.com/aws-amplify/amplify-cli/issues/3643#issuecomment-747677931

export const AMPLIFY_CONFIG = {
  aws_project_region: process.env.REACT_APP_AWS_PROJECT_REGION,
  aws_cognito_region: process.env.REACT_APP_AWS_PROJECT_REGION,
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_USERPOOLWEBCLIENTID,
  oauth: {},
  Auth: {
    // 'identityPoolId': process.env.REACT_APP_AWS_AUTH_IDENTITYPOOLID,
    region: process.env.REACT_APP_AWS_PROJECT_REGION,
    identityPoolRegion: process.env.REACT_APP_AWS_PROJECT_REGION,
    userPoolId: process.env.REACT_APP_AWS_USER_POOLS_ID,
    userPoolWebClientId: process.env.REACT_APP_USERPOOLWEBCLIENTID
  },
  API: {
    endpoints: [
      {
        name: 'MDPBackendDev',
        endpoint: process.env.REACT_APP_API_ENDPOINT,
        region: process.env.REACT_APP_AWS_PROJECT_REGION
      },
      {
        name: 'MDPBackendDevPublic',
        endpoint: process.env.REACT_APP_API_ENDPOINT,
        region: process.env.REACT_APP_AWS_PROJECT_REGION
      }
    ]
  }
};
