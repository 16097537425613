// @flow

import React, { useState, useCallback, useRef, useLayoutEffect } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import Tooltip from '@mui/material/Tooltip';
import useResizeObserver from '@react-hook/resize-observer';
import CircularProgress from '@mui/material/CircularProgress';
import { IconButton, Grid, Typography } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker';

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const useWidth = (target) => {
  const [width, setWidth] = useState(null);

  useLayoutEffect(() => {
    setWidth(target.current.getBoundingClientRect().width);
  }, [target]);

  useResizeObserver(target, (entry) => setWidth(entry.contentRect.width));

  return width;
};

type Props = {
  fileBin: Blob,
  fileName: String,
  previewPage: Number | null,
  searchText?: String | null,
  pdfFullHeight: Boolean,
  setPdfFullHeight: () => void
};

const PdfFileRenderer = (props: Props) => {
  const {
    fileName,
    fileBin,
    previewPage,
    searchText,
    pdfFullHeight,
    setPdfFullHeight
  } = props;
  const [scale, setScale] = useState(1);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(null);
  const pdfWrapper = useRef(null);
  const width = useWidth(pdfWrapper);

  const onDocumentLoadSuccess = useCallback(({ numPages }) => {
    setNumPages(numPages);
    setPageNumber(
      previewPage && previewPage > 0 && previewPage <= numPages
        ? previewPage
        : 1
    );
  }, []);

  const downloadDocument = useCallback(() => {
    const link = document.createElement('a');
    link.href = URL.createObjectURL(fileBin);
    link.download = `${fileName}.pdf`;
    link.click();
  }, []);

  const changePage = useCallback((offset) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }, []);

  const previousPage = useCallback(() => {
    changePage(-1);
  }, []);

  const nextPage = useCallback(() => {
    changePage(1);
  }, []);

  const changeScale = useCallback((offset) => {
    setScale((prevScale) => prevScale + offset);
  }, []);

  const decreaseScale = useCallback(() => {
    changeScale(-0.1);
  }, []);

  const increaseScale = useCallback(() => {
    changeScale(0.1);
  }, []);

  const highlightText = useCallback(() => {
    const searchTextLowerCase = searchText.toLowerCase();
    const replaceRegExp = new RegExp(searchText, 'gi');
    const selector = '.react-pdf__Page__textContent [role="presentation"]';

    document.querySelectorAll(selector).forEach((element) => {
      const spanTextContent = element.textContent.trim().toLowerCase();
      if (spanTextContent === '') return;

      // searchText is 'larger' than spanTextContent
      if (searchTextLowerCase.includes(spanTextContent)) {
        element.innerHTML = `<mark>${element.innerHTML}</mark>`;

        return;
      }

      // spanTextContent is 'larger' than searchText
      if (spanTextContent.includes(searchTextLowerCase)) {
        element.innerHTML = element.innerHTML.replace(
          replaceRegExp,
          `<mark>${searchText}</mark>`
        );

        return;
      }
    });
  }, []);

  return (
    <>
      <Grid item style={{ margin: 'auto' }}>
        <Typography variant="body2">
          <Tooltip title="Previous Page">
            <span>
              <IconButton
                aria-label="previous page"
                disabled={pageNumber <= 1}
                onClick={previousPage}
              >
                <ArrowBackIosIcon />
              </IconButton>
            </span>
          </Tooltip>
          {pageNumber || (numPages ? 1 : '--')} / {numPages || '--'}
          <Tooltip title="Next Page">
            <span>
              <IconButton
                aria-label="next page"
                disabled={pageNumber >= numPages}
                onClick={nextPage}
              >
                <ArrowForwardIosIcon />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Zoom Out">
            <span>
              <IconButton
                aria-label="zoom out"
                disabled={scale <= 0.5}
                onClick={decreaseScale}
              >
                <ZoomOutIcon />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Zoom In">
            <span>
              <IconButton
                aria-label="zoom in"
                disabled={scale >= 2}
                onClick={increaseScale}
              >
                <ZoomInIcon />
              </IconButton>
            </span>
          </Tooltip>
          {pdfFullHeight ? (
            <Tooltip title="Exit Full Screen">
              <IconButton
                aria-label="exit full screen"
                onClick={() => setPdfFullHeight(false)}
              >
                <CloseFullscreenIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Switch to Full Screen">
              <IconButton
                aria-label="switch to full screen"
                onClick={() => setPdfFullHeight(true)}
              >
                <OpenInFullIcon />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="Download Document">
            <IconButton
              aria-label="download document"
              onClick={() => downloadDocument()}
            >
              <CloudDownloadIcon />
            </IconButton>
          </Tooltip>
        </Typography>
      </Grid>
      <Grid
        item
        style={{
          width: '100%',
          height: '100%',
          overflow: 'auto',
          border: '1px solid black'
        }}
        ref={pdfWrapper}
      >
        <Document
          style={{ flexGrow: '1', overflow: 'auto' }}
          file={fileBin}
          onLoadSuccess={onDocumentLoadSuccess}
          loading={<CircularProgress />}
        >
          {pageNumber && (
            <Page
              scale={scale}
              pageNumber={pageNumber}
              width={width}
              onRenderTextLayerSuccess={
                pageNumber === previewPage && searchText && searchText !== ''
                  ? highlightText
                  : null
              }
            />
          )}
        </Document>
      </Grid>
    </>
  );
};

export default PdfFileRenderer;
