// @flow

import PageTemplate from 'layout/PageTemplate';
import React from 'react';
import { Helmet } from 'react-helmet-async';

const ConsentPage = () => {
  return (
    <PageTemplate>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Consent</title>
      </Helmet>
    </PageTemplate>
  );
};

export default ConsentPage;
