// @flow

const styles = (theme: Object) => ({
  requestBtn: {
    margin: `${theme.spacing(3)} ${theme.spacing(2)} ${theme.spacing(
      4
    )} ${theme.spacing(2)}`,
    width: 225
  }
});

export default styles;
