// @flow
/* eslint-disable max-len */

import * as R from 'ramda';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Auth, Amplify } from 'aws-amplify';
import { Helmet } from 'react-helmet-async';
import { useForm, Controller } from 'react-hook-form';
import queryString from 'query-string';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { withStyles } from 'tss-react/mui';
import { AutoComplete, InputError } from 'components';
import PageTemplate from 'layout/PageTemplate';
import MDPBackend from 'services/MDPBackend';
import { useDispatch, useSelector } from 'react-redux';
import { AMPLIFY_CONFIG } from 'settings/aws-config';
import { UPLOAD_DEMOGRAPHICS } from 'settings/constants';
import { log, emailRegex } from 'utils/jsUtils';
import styles from './Styles/Signup.Styles';
import { setLogin, setUserConfirmed } from 'features/login-slice';
import { showToastMsg } from 'features/toast-message-slice';

Amplify.configure(AMPLIFY_CONFIG);

type Props = {
  classes: Object,
  location: Object
};

const isUserLoggedIn = async (): Boolean => {
  try {
    await Auth.currentSession();
    return true;
  } catch (e) {
    return false;
  }
};

const Signup = (props: Props): React.Node => {
  const { classes } = props;
  const {
    register,
    watch,
    handleSubmit,
    control,
    reset,
    formState: { errors }
  } = useForm();
  const [showConfirmSignup, setShowConfirmSignup] = useState(false);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const [preSelectedOrg, setPreSelectedOrg] = useState({
    name: null,
    id: null,
    token: null
  });
  const [preSelectedEmail, setPreSelectedEmail] = useState('');
  const [preSelectedClinicId, setPreSelectedClinicId] = useState(null);

  const dispatch = useDispatch();

  const { userConfirmed } = useSelector((state) => state.login);

  const checkEmail = async () => {
    const { invite: inviteToken } = queryString.parse(location.search);

    if (inviteToken) {
      const decodedPayload = JSON.parse(window.atob(inviteToken));

      if (decodedPayload) {
        setPreSelectedOrg({
          name: decodedPayload.organizationName,
          id: decodedPayload.organizationId,
          token: decodedPayload.token
        });
        setPreSelectedEmail(decodedPayload.email.replace(/\s/g, ''));
        setPreSelectedClinicId(decodedPayload.clinicId || null);

        // React-hook-form pre-set organization value
        reset({
          organization: decodedPayload.organizationName,
          email: decodedPayload.email.replace(/\s/g, '')
        });
      }
    }

    if (await isUserLoggedIn()) navigate(UPLOAD_DEMOGRAPHICS);
  };

  useEffect(() => {
    checkEmail();
  }, [location]);

  const signUp = async (data) => {
    if (!data) return;

    setLoading(true);

    const { email, password } = data;

    let organizationId;

    let registerNewUserPayload, registerNewUserMethod;

    if (preSelectedOrg.id && preSelectedOrg.token) {
      registerNewUserMethod = 'registerNewUserByInvite';
      const registerNewUserPayloadData = R.omit(
        ['password', 'passwordRetyped'],
        data
      );

      if (preSelectedClinicId) {
        registerNewUserPayloadData.clinicId = preSelectedClinicId;
      }

      registerNewUserPayload = [
        preSelectedOrg.id,
        preSelectedOrg.token,
        registerNewUserPayloadData
      ];
    } else {
      registerNewUserMethod = 'registerNewUser';
      registerNewUserPayload = [R.omit(['password', 'passwordRetyped'], data)];
    }

    try {
      MDPBackend[registerNewUserMethod](...registerNewUserPayload)
        .then(async (response) => {
          const { data: backendData } = response;

          window.localStorage.setItem('shouldBeLoggedIn', true);
          organizationId = backendData.organizationId;

          dispatch(
            showToastMsg({
              open: true,
              message: backendData.message,
              level: backendData.success ? 'success' : 'error',
              duration: 5000
            })
          );

          // Cognito signup
          await Auth.signUp({
            username: email.replace(/\s/g, ''),
            password: password,
            attributes: {
              email: email.replace(/\s/g, ''),
              'custom:organization': organizationId
            }
          });

          // Update the store with shouldBeLoggedIn State
          dispatch(setLogin());
          dispatch(setUserConfirmed(userConfirmed));

          setShowConfirmSignup(true);

          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          log('[registerNewUser] error: ', error);
          if (typeof error.response !== 'undefined') {
            log('error confirming sign up', error.response.data);
            dispatch(
              showToastMsg({
                open: true,
                message: error.response.data,
                level: 'error',
                duration: 5000
              })
            );
          } else if (typeof error === 'string') {
            dispatch(
              showToastMsg({
                open: true,
                message: error.message,
                level: 'error',
                duration: 5000
              })
            );
          } else {
            dispatch(
              showToastMsg({
                open: true,
                message: 'Network error. Please try again.',
                level: 'error',
                duration: 5000
              })
            );
          }
        });
    } catch (error) {
      log('error signing up:', error);
      setLoading(false);
    }
  };

  return (
    <PageTemplate simple>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Sign up</title>
      </Helmet>
      <div className={classes.container}>
        {/* <button onClick={testAPIBackend}>Test backend auth</button>
        <button onClick={testAPIBackendPublic}>Test backend public</button>
        <button onClick={logout}>Logout</button>
        <button onClick={() => login()}>Login</button> */}
        {!showConfirmSignup ? (
          <form
            key="signup"
            className={classes.formCtr}
            noValidate
            onSubmit={handleSubmit(signUp)}
          >
            <Typography variant="h1">
              {preSelectedOrg.name ? (
                <>Let&#39;s setup your credentials</>
              ) : (
                <>Members Signup</>
              )}
            </Typography>
            <Typography variant="body1" className={classes.formHint}>
              {preSelectedOrg.name ? (
                <> Proceed to accept your invite to {preSelectedOrg.name}</>
              ) : (
                <>
                  Please fill out the form and register in order to upload
                  Patient Demographics Data
                </>
              )}
            </Typography>
            <Typography variant="body1" className={classes.formSubtitle}>
              Provider Contact
            </Typography>
            <Controller
              name="organization"
              defaultValue=""
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Organization name"
                  variant="outlined"
                  className={classes.txtField}
                  disabled={preSelectedOrg.name}
                  required
                />
              )}
            />
            {errors.organization && (
              <InputError text="Organization name is required" />
            )}
            <Controller
              name="firstName"
              defaultValue=""
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="First name"
                  variant="outlined"
                  className={classes.txtField}
                  required
                />
              )}
            />
            {errors.firstName && <InputError text="First name is required" />}
            <Controller
              name="lastName"
              defaultValue=""
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Last name"
                  variant="outlined"
                  className={classes.txtField}
                  required
                />
              )}
            />
            {errors.lastName && <InputError text="Last name is required" />}
            <Controller
              name="specialty"
              defaultValue=""
              control={control}
              rules={{ required: false }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Specialty"
                  variant="outlined"
                  className={classes.txtField}
                />
              )}
            />
            <Controller
              name="npi"
              defaultValue=""
              control={control}
              rules={{ required: false }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="NPI"
                  variant="outlined"
                  className={classes.txtField}
                />
              )}
            />
            <Controller
              name="dea"
              defaultValue=""
              control={control}
              rules={{ required: false }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Dea"
                  variant="outlined"
                  className={classes.txtField}
                />
              )}
            />
            <Controller
              name="title"
              defaultValue=""
              control={control}
              rules={{ required: false }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Title"
                  variant="outlined"
                  className={classes.txtField}
                />
              )}
            />
            <Controller
              name="phone"
              defaultValue=""
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Phone"
                  variant="outlined"
                  className={classes.txtField}
                  required
                />
              )}
            />
            {errors.phone && <InputError text="Phone is required" />}
            <AutoComplete
              control={control}
              className={classes.txtField}
              required
            />
            {errors.address && (
              <InputError text="Street, suite, city, state, zip is required" />
            )}
            <Typography variant="body1" className={classes.formSubtitle}>
              Login credentials
            </Typography>
            <Controller
              name="email"
              defaultValue=""
              control={control}
              rules={{
                required: true,
                pattern: {
                  value: emailRegex,
                  message: 'Please enter a valid email address'
                }
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Email"
                  variant="outlined"
                  className={classes.txtField}
                  disabled={preSelectedEmail}
                  required
                />
              )}
            />
            {errors.email && (
              <InputError
                text={
                  errors.email.message
                    ? errors.email.message
                    : 'Email address is required'
                }
              />
            )}
            <Controller
              name="password"
              defaultValue=""
              control={control}
              rules={{
                required: true,
                minLength: {
                  value: 8,
                  message: 'Password must have at least 8 characters'
                },
                pattern: {
                  value: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/,
                  message:
                    'Password must contain at least one digit, one upper and one lower case letter'
                }
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="New password"
                  variant="outlined"
                  type="password"
                  className={classes.txtField}
                  required
                />
              )}
              {...register('password')}
            />
            {errors.password && (
              <InputError
                text={
                  errors.password.message
                    ? errors.password.message
                    : 'Password is required'
                }
              />
            )}
            <Controller
              name="passwordRetyped"
              defaultValue=""
              control={control}
              rules={{
                required: true,
                minLength: {
                  value: 8,
                  message: 'Password must have at least 8 characters'
                }
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Retype password"
                  variant="outlined"
                  type="password"
                  className={classes.txtField}
                  required
                />
              )}
              {...register('passwordRetyped', {
                validate: (value) => {
                  return value === watch('password') || 'Passwords must match';
                }
              })}
            />
            {errors.passwordRetyped && (
              <InputError
                text={
                  errors.passwordRetyped.message
                    ? errors.passwordRetyped.message
                    : 'Password is required'
                }
              />
            )}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.submitBtn}
              disabled={loading}
            >
              {loading ? 'Loading...' : 'Register'}
            </Button>
            <Typography
              align="center"
              variant="body1"
              className={classes.formFooter}
            >
              Already registered? <a href="/login">Click here to login</a>
            </Typography>
          </form>
        ) : (
          <Paper className={classes.activateCtr}>
            <Typography variant="h1">
              Let&apos;s activate your account
            </Typography>
            <Typography variant="body1" className={classes.formSubtitle}>
              {
                'Please check your email (check spam also) and click the link included to activate your account.'
              }
            </Typography>
          </Paper>
        )}
      </div>
    </PageTemplate>
  );
};

export default withStyles(Signup, styles);
