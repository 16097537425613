// @flow

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth, Amplify } from 'aws-amplify';
import { Helmet } from 'react-helmet-async';
import { useForm, Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { withStyles } from 'tss-react/mui';

import PageTemplate from 'layout/PageTemplate';
import MDPBackend from 'services/MDPBackend';
import { AMPLIFY_CONFIG } from 'settings/aws-config';
import { log, emailRegex } from 'utils/jsUtils';
import { InputError } from 'components';

import styles from './Styles/ResetPassword.Styles';
import { useDispatch } from 'react-redux';
import { showToastMsg } from 'features/toast-message-slice';

Amplify.configure(AMPLIFY_CONFIG);

type Props = {
  classes: Object
};

const ResetPassword = (props: Props): React.Node => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [isPasswordRequested, setIsPasswordRequested] = useState(false);
  const { classes } = props;
  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm({
    shouldUnregister: true
  });

  const dispatch = useDispatch();

  const resetPassword = async (data) => {
    setLoading(true);

    const { email, code, newPassword } = data;

    try {
      await Auth.forgotPasswordSubmit(email, code, newPassword);
      await Auth.signIn(email, newPassword);
      await MDPBackend.confirmPasswordReset();
      await Auth.signOut({ global: true });

      dispatch(
        showToastMsg({
          open: true,
          message: 'Password reset successfully',
          level: 'success',
          duration: 5000
        })
      );

      navigate('/login');
    } catch (err) {
      log('[resetPassword] error: ', err);

      dispatch(
        showToastMsg({
          open: true,
          message:
            err && err.message ? err.message : 'Unexpected Error occured',
          level: 'error',
          duration: 5000
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const requestCode = async (data) => {
    setLoading(true);

    const { email } = data;

    try {
      Auth.forgotPassword(email)
        .then(() => {})
        .catch((err) => {
          log('[resetPassword] error: ', err);
        })
        .finally(() => {
          setLoading(false);
          setIsPasswordRequested(true);
        });
    } catch (error) {
      log('[requestCode] error: ', error);

      dispatch(
        showToastMsg({
          open: true,
          message: 'Something went wrong',
          level: 'error',
          duration: 5000
        })
      );
      setLoading(false);
    }
  };

  return (
    <PageTemplate simple>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Reset Password</title>
      </Helmet>
      <div className={classes.container}>
        <form
          key="reset"
          className={classes.formCtr}
          noValidate
          onSubmit={
            isPasswordRequested
              ? handleSubmit(resetPassword)
              : handleSubmit(requestCode)
          }
        >
          <Typography variant="h1">Reset your account password</Typography>
          {isPasswordRequested ? (
            <Typography variant="body1" className={classes.formHint}>
              Please check your mails. We just sent you a confirmation code.
              Please enter your received code and your new password below and
              click on &apos;Reset Password&apos;.
            </Typography>
          ) : (
            <Typography variant="body1" className={classes.formHint}>
              Please enter your email address below and click on &apos;Request
              Code&apos;.
            </Typography>
          )}
          <Controller
            name="email"
            defaultValue=""
            control={control}
            rules={{
              required: true,
              pattern: {
                value: emailRegex,
                message: 'Please enter a valid Email Address'
              }
            }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Email"
                variant="outlined"
                className={classes.txtField}
                disabled={isPasswordRequested}
              />
            )}
          />
          {errors.email && (
            <InputError
              text={
                errors.email.message
                  ? errors.email.message
                  : 'Email Address is required'
              }
            />
          )}
          {isPasswordRequested ? (
            <>
              <Controller
                name="code"
                defaultValue=""
                control={control}
                rules={{
                  required: isPasswordRequested
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Code"
                    variant="outlined"
                    className={classes.txtField}
                  />
                )}
              />
              {errors.code && (
                <InputError
                  text={
                    errors.code.message
                      ? errors.code.message
                      : 'Code is required'
                  }
                />
              )}
              <Controller
                name="newPassword"
                defaultValue=""
                control={control}
                rules={{
                  required: isPasswordRequested,
                  minLength: {
                    value: 8,
                    message: 'Password must have at least 8 characters'
                  },
                  pattern: {
                    value: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/,
                    message:
                      // eslint-disable-next-line max-len
                      'Password must contain at least one digit, one upper and one lower case letter'
                  }
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="New Password"
                    variant="outlined"
                    type="password"
                    className={classes.txtField}
                  />
                )}
              />
              {errors.newPassword && (
                <InputError
                  text={
                    errors.newPassword.message
                      ? errors.newPassword.message
                      : 'New Password is required'
                  }
                />
              )}
            </>
          ) : null}

          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submitBtn}
            disabled={loading}
          >
            {loading
              ? 'Loading...'
              : isPasswordRequested
              ? 'Reset Password'
              : 'Request Code'}
          </Button>

          <Typography variant="body1" className={classes.formFooter}>
            <a href="/login">Click here to login</a>
          </Typography>
        </form>
      </div>
    </PageTemplate>
  );
};

export default withStyles(ResetPassword, styles);
