/* eslint-disable max-len */
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { styled as styledMaterial } from '@mui/material/styles';
import styledComponents from 'styled-components';

export const SearchWrapper = styledComponents.div`
  background: white;
  width: 100%;
  padding: 20px 20px;
  position: relative;
  z-index: 10;

  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);

  .searchWrapper {
    .MuiFormControl-root {
      width: -webkit-fill-available;
    }
  }

  .patient-demographics {
    margin-top: 5px;
    margin-bottom: 20px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: right;
  }

  .suggestionsWrapper {
    overflow-y: auto;
    max-height: 60vh;
  }

  .loadingContainer {
    position: absolute;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background: white;
  }
`;

export const StyledContainer = styledMaterial(Container)`
  .root {
    margin-left: 50px;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .files-wrapper {
    margin-top: 50px;
    height: 100%;
  }

  .loadingContainer {
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }

  .documentSearchField {
    width: 100%;
    margin-top: 20px;
  }

  .suggestionsContainer {
    padding: 15px 15px;
  }
`;

export const FileListWrapper = styledMaterial(Box)`
  margin-left: 50px;
`;

export const FilterBarWrapper = styledComponents.div`
  margin-bottom: 30px;

  .sortBy {
    margin-top: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #1b77c0;

    span {
      height: 24px;
    }
  }
`;

export const DocumentTypeFilterWrapper = styledComponents.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 1.5rem;
`;
