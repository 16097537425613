// @flow

const styles = (theme: Object) => ({
  root: {},
  icon: {
    color: theme.palette.secondary.dark,
    cursor: 'pointer',
    fontSize: '1.2rem',
    marginLeft: 5,

    '&.grey': { color: '#C6C6C6' }
  },
  searchIcon: { marginLeft: 10, opacity: 0.7 },
  dataTable: {
    marginTop: theme.spacing(1),

    '.rdt_TableCol': {
      div: {
        whiteSpace: 'normal!important'
      }
    },

    '& .rdt_TableCell': {
      padding: '0 10px',
      borderLeft: '1px solid rgba(0,0,0,.04)'
    }
  },
  cell: { padding: '10px 0' },
  flex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
  },
  statusIcon: {
    fontSize: '1.2rem',
    color: 'white',
    marginRight: theme.spacing(1),

    '&.grey': { color: '#8E8E8E' },
    '&.red': { color: '#D0105F' },
    '&.yellow': { color: '#F4CB0E' },
    '&.green': { color: '#70AE98' },
    '&.orange': { color: '#ECBE7A' },

    opacity: 0.4
  },
  iconWrapper: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.spacing(1.5),
    padding: '0px 5px',
    width: 28,

    '&.fullWidth': { width: '100%', margin: 0 },

    '&.grey': { backgroundColor: '#8E8E8E' },
    '&.red': { backgroundColor: '#D0105F' },
    '&.yellow': { backgroundColor: '#F4CB0E' },
    '&.green': { backgroundColor: '#70AE98' },
    '&.orange': { backgroundColor: '#ECBE7A' }
  },
  tableActionButtons: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2)
  },
  rafBtn: {
    cursor: 'pointer',
    color: theme.palette.secondary.dark,
    textDecoration: 'underline',
    border: 'none',
    background: 'transparent'
  }
});

export default styles;
