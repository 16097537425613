// @flow

import axios from 'axios';
import { Auth } from 'aws-amplify';

const serverURL = process.env.REACT_APP_API_ENDPOINT;

const getNewJWTToken = async () => {
  const currentSession = await Auth.currentSession();
  const tok = currentSession.getIdToken().getJwtToken();
  return tok;
};

const authenticatedAxios = (user, baseURL = serverURL) => {
  return getNewJWTToken().then((jwtToken: ?string) => {
    // Create instance
    // inject local headers, if running locally
    let localHeaders = {};
    if (process.env.REACT_APP_ENV === 'dev') {
      localHeaders['x-mdp-email'] = 'test@email.com';
    }

    const defaultOptions = {
      baseURL: baseURL,
      headers: {
        'Content-Type': 'application/json',
        ...localHeaders
      }
    };
    let instance = axios.create(defaultOptions);
    // Set the AUTH token for any request
    instance.defaults.headers.common['Authorization'] = `Bearer ${
      jwtToken ? jwtToken : ''
    }`;
    return instance;
  });
};

const publicAxios = (url = '') => {
  const baseUrl = url !== '' ? url : serverURL;
  // Create instance
  const defaultOptions = {
    baseURL: baseUrl,
    headers: {
      'Content-Type': 'application/json'
    }
  };
  return axios.create(defaultOptions);
};

type AxiosResponse = {
  data: Object
};

const testNoAuth = (): Promise<AxiosResponse> => {
  const _publicAxios = publicAxios();
  return _publicAxios.get('/testnoauth');
};

const testAuth = (): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.get('/testauth');
  });
};

// const getBase64fromFile = (file) => {
//   return new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.readAsDataURL(file);
//     reader.onload = () => {
//       console.log('getBase64fromFile success.');
//       const spliced = reader.result.split(',');
//       const header = spliced[0];
//       spliced.shift();
//       resolve({
//         header: header,
//         body: spliced.join('')
//       });
//     };
//     reader.onerror = (err) => {
//       console.log('getBase64fromFile failed.');
//       reject(err);
//     };
//   });
// };

const uploadFile = (payload: Object): Promise<string> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.post('upload/demographics', payload);
  });
};

const downloadFile = (fileId): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.get(`/file/${fileId}`);
  });
};

const downloadTemplateFile = (fileKey): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.get(`/file/key/${fileKey}`);
  });
};

const registerNewUser = (payload): Promise<AxiosResponse> => {
  const _publicAxios = publicAxios();
  return _publicAxios.post('/users', payload);
};

const registerNewUserByInvite = (
  organizationId: String,
  token: String,
  payload: Object
): Promise<AxiosResponse> => {
  const _publicAxios = publicAxios();
  return _publicAxios.post(
    `/users?organizationId=${organizationId}&token=${token}`,
    payload
  );
};

const getAllPatientsByOrg = (searchString): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.get(`/patient/all?${searchString ? searchString : ''}`);
  });
};

const getPatientStats = (searchString): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.get(`/patient/stats?${searchString ? searchString : ''}`);
  });
};

const getAllEvents = (searchString): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.get(`/events?${searchString ? searchString : ''}`);
  });
};

const exportEventsToCSV = (searchString): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.get(`/events/export?${searchString ? searchString : ''}`);
  });
};

const getPatientById = (id: string): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.get(`/patient/${id}`);
  });
};

const requestPatientRecord = (
  payload: Object,
  type?: string
): Promise<AxiosResponse> => {
  authenticatedAxios().then((authAxios) => {
    return authAxios.post(
      `/patient/record${type ? `?type=${type}` : ''}`,
      payload
    );
  });
};

const getRecordRequests = (
  id: string,
  accessToken?: string
): Promise<AxiosResponse> => {
  const _publicAxios = publicAxios();
  return accessToken
    ? _publicAxios.get(`/patient/${id}/records?access_token=${accessToken}`)
    : authenticatedAxios().then((authAxios) =>
        authAxios.get(`/patient/${id}/records`)
      );
};

const getRecordRequestCCD = (
  recordRequestId: string,
  accessToken?: string
): Promise<AxiosResponse> => {
  const _publicAxios = publicAxios();
  return accessToken
    ? _publicAxios.get(
        `/record-request/${recordRequestId}/ccd-binary?access_token=${accessToken}`
      )
    : authenticatedAxios().then((authAxios) => {
        return authAxios.get(`/record-request/${recordRequestId}/ccd-binary`);
      });
};

const getApiDocumentation = (): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.get('/api/documentation');
  });
};

const getPatientHccCompendium = (
  patientId: String,
  version: String
): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.get(
      `patient/${patientId}/hcc_compendium?${version ? `v=${version}` : ''}`
    );
  });
};

const requestApiKeys = (payload): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.post('api/keys', payload);
  });
};

const getLatestPatientEcda = (
  patientId,
  queryString
): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.get(`/patient/${patientId}/ecda?${queryString}`);
  });
};

const getQueriesByOrg = (searchString): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.get(`/queries?${searchString ? searchString : ''}`);
  });
};

const getQueriesByOrgStats = (searchString): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.get(`/queries/stats?${searchString ? searchString : ''}`);
  });
};

const exportCSV = (searchString): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.get(`/queries/report?${searchString ? searchString : ''}`);
  });
};

const exportPatientsToCSV = (searchString): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.get(`/patient/export?${searchString ? searchString : ''}`);
  });
};

const downloadCompendium = (queryId): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.get(`/query/${queryId}/download/compendium`);
  });
};

const exportCDAAsPDF = (payload = {}, queryStr): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.post(`/export?${queryStr}`, payload);
  });
};

const getExportCDAAsPDF = (requestId): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.get(`/export/${requestId}`);
  });
};

const getBatchNames = (): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.get('/organization/batchNames');
  });
};

const getBatchRequests = (batchName): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.get(`/batchRequests/${batchName}`);
  });
};

const getRawInfsCompendiums = (queryId): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.get(`/${queryId}/rawCompendiums`);
  });
};

const downloadRawInfsCompendium = (
  queryId,
  requestId
): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.get(`/${queryId}/rawCompendiums?requestId=${requestId}`);
  });
};

const exportFailedQueries = (batchName): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.get(`/failed-queries/export/${batchName}`);
  });
};

const submitPatientQuery = (payload): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.post('/patient/query/client', payload);
  });
};

const getUploadURL = (payload): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.post('/upload/url', payload);
  });
};

const getPatientSuggestions = (searchString): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.get(`/patients?searchString=${searchString}`);
  });
};

const getPatientData = (patientId): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.get(`/patient/${patientId}`);
  });
};

const getPatientFileByHash = (patientId, fileHash): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.get(`/patient/${patientId}/document/${fileHash}`);
  });
};

const getPatientChartFiles = (
  patientId,
  searchString
): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.get(
      `/patient/${patientId}/document?${searchString ? searchString : ''}`
    );
  });
};

const searchPatientDocuments = (
  patientId,
  searchTerm,
  searchProvider
): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.get(
      `/patient/${patientId}/search?q=${searchTerm}&searchProvider=${searchProvider}`
    );
  });
};

const indexPatientDocumentsForSearch = (patientId): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.get(`/patient/${patientId}/document/index`);
  });
};

const getPatientHCCs = (patientId, searchString): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.get(
      `/patient/${patientId}/hcc?${searchString ? searchString : ''}`
    );
  });
};

const exportPatientHCCsByPatient = (
  patientId,
  searchString
): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.get(
      `/patient/${patientId}/hcc/csv?${searchString ? searchString : ''}`
    );
  });
};

const exportPatientHCCsByOrg = (searchString): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.get(`/hcc/export?${searchString ? searchString : ''}`);
  });
};

const getPatientHCCExport = (requestId): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.get(`/hcc/export/${requestId}`);
  });
};

const getPatientExport = (requestId): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.get(`/patient/export/${requestId}`);
  });
};

const getEventsExport = (requestId): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.get(`/events/export/${requestId}`);
  });
};

const getHccSourceDocument = (patientId, fileHash): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.get(`/patient/${patientId}/hcc/source/${fileHash}`);
  });
};

const switchOrganization = (organizationId): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.post('/organization/switch', {
      organizationId: organizationId
    });
  });
};

const getFileUploadUrl = (payload): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.post('/upload/url', payload);
  });
};

const getFileUploads = (): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.get('/uploads');
  });
};

const downloadUserUploadedFile = (fileId): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.get(`/uploads?f=${fileId}`);
  });
};

const getQueryFileUpload = (queryId): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.get(`/queries/${queryId}/upload`);
  });
};

const setIcdCode = (payload): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.put('/hcc', payload);
  });
};

const addInternalNote = (hccId, payload): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.post(`/hcc/${hccId}/note`, payload);
  });
};

const createAndSendInviteLink = (payload): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.post('admin/user/invite', payload);
  });
};

const resendInvite = (inviteId): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.get(`admin/user/invite/${inviteId}/resend`);
  });
};

const getInvites = (): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.get('/invites');
  });
};

const confirmPasswordReset = (): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.get('/confirm-password-reset');
  });
};

const getUser = (): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.get('/user');
  });
};

const clearUserForceLogout = (): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.get('/clearuserforcelogout');
  });
};

const getPatientChartIndexStatus = (patientId): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.get(`/patient/${patientId}/chart-index-status`);
  });
};

const getIcdSourcesAndNotes = (patientId, icdCode): Promise<AxiosResponse> => {
  console.log('getIcdSourcesAndNotes', patientId, icdCode);

  return authenticatedAxios().then((authAxios) => {
    return authAxios.get(`/patient/${patientId}/icd/${icdCode}`);
  });
};

const deleteIcdNote = (noteId): Promise<AxiosResponse> => {
  console.log('deleteIcdNote', noteId);

  return authenticatedAxios().then((authAxios) => {
    return authAxios.post(`/patient/icd/delete-note/${noteId}`);
  });
};

const getSourceHistory = (
  patientId,
  icdCode,
  factName
): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.get(
      `/patient/${patientId}/icd/${icdCode}/source-history${
        factName ? `?factName=${factName}` : ''
      }`
    );
  });
};

const getChangelogCompendiumsByHccId = (hccId): Promise<AxiosResponse> => {
  return authenticatedAxios().then((authAxios) => {
    return authAxios.get(`/changelogCompendium/${hccId}`);
  });
};

export default {
  testNoAuth,
  testAuth,
  uploadFile,
  downloadFile,
  downloadTemplateFile,
  registerNewUser,
  registerNewUserByInvite,
  getAllPatientsByOrg,
  getPatientStats,
  getPatientById,
  requestPatientRecord,
  getRecordRequests,
  getRecordRequestCCD,
  getApiDocumentation,
  getPatientHccCompendium,
  requestApiKeys,
  getLatestPatientEcda,
  getQueriesByOrg,
  downloadCompendium,
  exportCDAAsPDF,
  getExportCDAAsPDF,
  getBatchNames,
  getBatchRequests,
  getRawInfsCompendiums,
  downloadRawInfsCompendium,
  exportFailedQueries,
  submitPatientQuery,
  getUploadURL,
  getPatientSuggestions,
  exportCSV,
  exportPatientsToCSV,
  getPatientData,
  searchPatientDocuments,
  getPatientFileByHash,
  getPatientHCCs,
  getHccSourceDocument,
  exportPatientHCCsByOrg,
  switchOrganization,
  getPatientHCCExport,
  getPatientChartFiles,
  indexPatientDocumentsForSearch,
  getFileUploadUrl,
  getFileUploads,
  downloadUserUploadedFile,
  getQueryFileUpload,
  setIcdCode,
  getAllEvents,
  createAndSendInviteLink,
  getInvites,
  resendInvite,
  exportEventsToCSV,
  confirmPasswordReset,
  getUser,
  clearUserForceLogout,
  getPatientChartIndexStatus,
  addInternalNote,
  getSourceHistory,
  getChangelogCompendiumsByHccId,
  exportPatientHCCsByPatient,
  getPatientExport,
  getIcdSourcesAndNotes,
  deleteIcdNote,
  getQueriesByOrgStats,
  getEventsExport
};
