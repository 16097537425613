// @flow

const styles = (theme: Object) => ({
  container: {
    marginTop: 75
  },
  title: {
    marginBottom: theme.spacing(3),
    fontSize: '1.5rem'
  },
  hints: {
    marginTop: theme.spacing(3)
  },
  aahcmImage: {
    width: '100%'
  },
  templateButton: {
    width: '100%',
    margin: '30px auto'
  },
  downloadIcon: {
    marginRight: theme.spacing(1)
  },
  uploadHistoryWrapper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(5)
  },
  uploadHistoryTable: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  icon: {
    color: theme.palette.secondary.dark,
    cursor: 'pointer',
    fontSize: '1.2rem'
  },
  closeIcon: {
    color: '#000000',
    fontSize: '1.6rem'
  },
  closeButton: {
    position: 'absolute',
    top: ' 20px',
    right: '20px'
  }
});
export default styles;
