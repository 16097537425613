/* eslint-disable max-len */
// @flow

import React from 'react';
import Typography from '@mui/material/Typography';
import { withStyles } from 'tss-react/mui';

import PageTemplate from 'layout/PageTemplate';
import styles from './Styles/publicPages.Style';

type Props = {
  classes: Object
};

const TermsOfUse = (props: Props) => {
  const { classes } = props;

  return (
    <PageTemplate>
      <div className={classes.body}>
        <Typography variant="h2" align="center" paragraph>
          Terms Of Use
        </Typography>
        <Typography variant="h1" paragraph>
          User Agreement (Effective: April 19, 2021)
        </Typography>
        <Typography variant="body1" paragraph>
          THIS IS A LEGALLY BINDING AGREEMENT between MDPortals, Inc., Inc., a
          Delaware Corporation (“we” or “us”) and you, as a user of our on-line
          diagnostic test system (the “System”). BY CLICKING “SIGN UP” OR
          THROUGH THE CONTINUED USE OF THE SYSTEM, YOU ARE UNDERTAKING LEGAL
          OBLIGATIONS AND CONFERRING LEGAL RIGHTS. Please read this agreement
          carefully, and do not click “Sign up” or continue use of the System
          unless you agree fully with its terms. You and we are collectively
          referred to as the “Parties.”
        </Typography>
        <Typography variant="body1" paragraph>
          1. Definitions For the purposes of this Agreement, the terms set forth
          in this section have the meanings assigned to them below. Terms not
          defined below (whether or not capitalized) have the definitions given
          them in HIPAA, unless the context requires otherwise:
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          “Authorized Workforce” means those members of your Workforce who are
          individually authorized by you and us to have access to the System to
          assist you in providing treatment and obtaining payment for treatment,
          and to whom we have assigned a unique identifier for access to the
          System.
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          “Record Share” means the feature of the System through which we make
          Your Health Information available to other users of the System with
          your consent, or make Health Information of other users of the System
          available to you with their consent.
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          “Confidential Information” means any information concerning our
          business, financial affairs, current or future products or technology,
          trade secrets, workforce, customers, or any other information that is
          treated or designated by us as confidential or proprietary, or would
          reasonably be viewed as confidential or as having value to our
          competitors. Confidential Information shall not include information
          that we make publicly available or that becomes known to the general
          public other than as a result of a breach of an obligation by you.
          Confidential Information does not include individuals’ health
          information.
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          “De-identified Health Information” means health information that has
          been de-identified in accordance with the provisions of the Privacy
          Rule, and “De-Identify,” with respect to health information, means
          make it into De Identified Health Information.
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          “De-Identified Information” means De-Identified Health Information and
          De-Identified Personal Information.
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          “De-Identified Personal Information” means personal information from
          which a user’s name and other unique identifiers have been removed,
          and from which the user cannot reasonably be identified; and
          “De-Identify,” with respect to Personal Information, means to make it
          into De-Identified Personal Information.
        </Typography>
        <Typography variant="body1" paragraph>
          “HIPAA” means the administrative simplification provisions of the
          Health Insurance Portability and Accountability Act of 1996, and the
          regulations promulgated thereunder, including the Privacy Rule and the
          Security Rule.
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          “HITECH Act” means the Technology for Economic and Clinical Health Act
          of 2009, and regulations promulgated thereunder.
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          “Personal Information” means information that identifies you
          personally as a user of the System, and all information concerning you
          and your use of the System that is not Protected Health Information.
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          “Policies and Procedures” means our rules, regulations, policies and
          procedures for access to and use of the System, as changed from time
          to time and as posted electronically on our Internet web site.
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          “Privacy Rule” means the Standards for Privacy of Individually
          Identifiable Health Information at 45 CFR part 160 and part 164,
          subparts A and E.
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          “Protected Health Information” has the meaning given it in the Privacy
          Rule and includes all individually identifiable health information
          concerning your patients that you provide to the System.
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          “Security Rule” means the Security Standards for the Protection of
          Electronic Protected Health Information at 45 CFR part 160 and part
          164, subparts A and C.
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          “Services” means the services to which you have been granted access.
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          “System” means the electronic communication network from time to time
          operated by us, including all hardware provided by us, all software
          used or provided by us, and all such hardware and software installed
          at or accessed from your site, and all documentation provided by us in
          connection with the System, paper or electronic.
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          “Term” means the initial term and all renewal terms of this Agreement
          as provided in Section 16.
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          “User” means you and any other user of the System.
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          “User ID” means a unique user identification assigned to an individual
          User pursuant to Section 3.7.
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          “Workforce” means employees, agents and independent contractors.
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          “Your Health Information” means Protected Health Information that you
          or your Workforce enter into the System.
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          “Your Site” means the location you provided us upon registration, and
          such other location or locations as we may approve from time to time.
        </Typography>
        <Typography variant="body1" paragraph>
          2. Grant of Right to Use Services 2.1 We grant to you and you accept a
          non-exclusive, personal, nontransferable, limited right to have access
          to and to use the System, and a non-exclusive, personal,
          nontransferable, limited license to use any computer software
          furnished by us for access to or use of the System, for the purpose of
          obtaining the Services during the Term, subject to your full
          compliance with the terms and conditions set forth in this Agreement
          and with our Policies and Procedures. You will not: (a) use the System
          for time-sharing, rental or service bureau purposes; (b) make the
          System, in whole or in part, available to any other person, entity or
          business; (c) copy, reverse engineer, decompile or disassemble the
          System, in whole or in part, or otherwise attempt to discover the
          source code to the software used in the System; or (d) modify the
          Services or the System or associated software or combine the Services
          or the System with any other software or services not provided or
          approved by us. You will obtain no rights to the System except for the
          limited rights to use the System expressly granted by this Agreement.
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          2.2 The System includes certain third-party software and services,
          which may require that you enter into separate subscription or
          licensing agreements with third-party vendors. We may also make
          available optional services provided by third parties, such as
          billing, electronic ordering and clinical laboratory reporting
          services. You agree to comply with, and upon request to execute, such
          agreements as may be required for the use of such software or
          services, and to comply with the terms of any license or other
          agreement relating to third-party products included in the System or
          made accessible to you through the System. Your use of the System or
          of such third-party products or services will constitute your
          agreement to be bound by the terms of all licensing, subscription and
          similar agreements relating to such use.
        </Typography>
        <Typography variant="body1" paragraph>
          3. Access to the System 3.1 Verification. You agree that your use of
          the System is subject to verification by us of your identity and
          credentials as a health care practitioner, and to your ongoing
          qualification as such. You agree that we may use and disclose your
          Personal Information for such purposes, including (without limitation)
          making inquiry of third parties concerning your identity and
          professional and practice credentials. You authorize such third
          parties to disclose to us such information as we may request for such
          purposes, and you agree to hold them and us harmless from any claim or
          liability arising from the request for or disclosure of such
          information. You agree that we may terminate your access to or use of
          the System at any time if we are unable at any time to determine or
          verify your qualifications or credentials.
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          3.2 Permitted Uses. Subject to the terms of this Agreement, you may
          use Your Health Information for any purpose expressly permitted by
          applicable law. If you are granted access rights to another users
          Protected Health Information, you may use such information for
          treatment and for obtaining payment for treatment; provided that,
          except as expressly authorized in our Policies and Procedures, (i) you
          may access only information pertaining to individuals with whom you
          have a treatment relationship or for whom a provider who has a
          treatment relationship with the individual has requested a
          professional consultation from you, or from whom you have received
          authorization to use their health information; and (ii) you may use
          only the minimum necessary information for payment purposes. You agree
          that you will not access the System or use the Services for any other
          purposes. In particular:
        </Typography>
        <Typography variant="body1" paragraph>
          3.2.1.1 You will not reproduce, publish, or distribute content in
          connection with the System that infringes any third party’s trademark,
          copyright, patent, trade secret, publicity, privacy, or other personal
          or proprietary right;
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          3.2.1.2 You will comply with all applicable laws, including laws
          relating to maintenance of privacy, security, and confidentiality of
          patient and other health information and the prohibition on the use of
          telecommunications facilities to transmit illegal, obscene,
          threatening, libelous, harassing, or offensive messages, or otherwise
          unlawful material;
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          3.2.1.3 You will not: (a) abuse or misuse the System or the Services,
          including gaining or attempting to gain unauthorized access to the
          System, or altering or destroying information in the System except in
          accordance with accepted practices; (b) using the System or Services
          in a manner that interferes with other Users’ use of the System; or
          (c) using the System or the Services in any manner that violates our
          Policies and Procedures; (d) or use any ad blocking mechanism, device,
          or tool to prevent the placement of advertisements in the System or
          the Service.
        </Typography>
        <Typography variant="body1" paragraph>
          3.3 Clinical Support Information. We may provide information to assist
          you in clinical decision-making. This may include information and
          reminders concerning lab test results, drug interactions, allergies,
          dosages, as well as general health-care related information and
          resources. We may also provide forums for our users to exchange
          information. The information and materials available through this site
          are for informational and educational purposes only and are not
          intended to constitute professional advice, diagnosis or treatment, or
          to substitute for your professional judgment. Information may be
          placed on our Internet site by us and by third parties beyond our
          control. We are not responsible for the accuracy or completeness of
          information available from or through our site. You are not permitted
          to use our site to advise, diagnose, or otherwise treat users of this
          site. You assume full risk and responsibility for the use of
          information you obtain from or through this site, and you agree that
          MDPortals, Inc. is not responsible or liable for any claim, loss, or
          liability arising from the use of the information. We do not recommend
          or endorse any provider of health care or health-related products,
          items or services, and the appearance of materials on this site
          relating to any such products, items or services is not an endorsement
          or recommendation of them. You agree to review the definitions,
          functionality, and limitations of the System, and to make an
          independent determination of their suitability for your use. We and
          our suppliers and licensors disclaim all warranties, whether expressed
          or implied, including any warranty as to the quality, accuracy, and
          suitability of the information provided by the System for any purpose.
        </Typography>
        <Typography variant="body1" paragraph>
          3.4 Safeguards.
        </Typography>
        <Typography variant="body1" paragraph>
          3.4.1 You will implement and maintain appropriate administrative,
          physical and technical safeguards to protect information within the
          System from access, use or alteration from Your Site or using a User
          ID assigned to you or a member of your Workforce. Such safeguards
          shall comply with federal, state, and local requirements, including
          the Privacy Rule and the Security Rule, whether or not you are
          otherwise subject to HIPAA. You will maintain appropriate security
          with regard to all personnel, systems, and administrative processes
          used by you or members of your Workforce to transmit, store and
          process electronic health information through the use of the System.
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          3.4.2 You will immediately notify us of any breach or suspected breach
          of the security of the System of which you become aware, or any
          unauthorized use or disclosure of information within or obtained from
          the System, and you will take such action to mitigate the breach or
          suspected breach as we may direct, and will cooperate with us in
          investigating and mitigating the breach.
        </Typography>
        <Typography variant="body1" paragraph>
          3.5 Location of Access. You and your Authorized Workforce are
          authorized to access the System solely from Your Site, and from other
          sites from which you have received approval from us to access the
          System.
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          3.6 Compliance. You will comply with the terms of this Agreement, our
          Policies and Procedures, and all applicable laws and regulations. You
          will be solely responsible for the use of the System by you and your
          Workforce, and shall indemnify us and hold us harmless from any claim,
          cost or liability arising from such use, including reasonable
          attorneys’ fees.
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          3.7 User Identification. We authorize you and your Authorized
          Workforce to use the User IDs assigned to you by us. You acquire no
          ownership rights in any User ID, and User IDs may be revoked or
          changed at any time in our sole discretion. You will adopt and
          maintain reasonable and appropriate security precautions for User IDs
          to prevent their disclosure to or use by unauthorized persons. Each
          member of your Authorized Workforce shall have and use a unique
          identifier. You will use your best efforts to ensure that no member of
          your Workforce uses a User ID assigned to another person.
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          3.8 No Third-Party Access. Except as required by law, you will not
          permit any third party (other than your Authorized Workforce) to have
          access to the System or to use the Services without our prior written
          agreement. You will promptly notify us of any order or demand for
          compulsory disclosure of health information if the disclosure requires
          access to or use of the System. You will cooperate fully with us in
          connection with any such demand.
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          3.9 Your Workforce.
        </Typography>
        <Typography variant="body1" paragraph>
          3.9.1 You may permit your Authorized Workforce to use the System and
          the Services on your behalf, subject to the terms of this Agreement.
          You will
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          3.9.1.1 obtain a unique User ID from us for each member of your
          Authorized Workforce;
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          3.9.1.2 train all members of your Authorized Workforce in the
          requirements of this Agreement and the Policies and Procedures
          relating to their access to and use of the System and the Services,
          and ensure that they comply with such requirements;
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          3.9.1.3 take appropriate disciplinary action against any member of
          your workforce who violates the terms of this Agreement or the
          Policies and Procedures;
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          3.9.1.4 ensure that only you and your Authorized Workforce access the
          System from Your Site;
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          3.9.1.5 immediately notify us of the termination of employment of any
          member of your Authorized Workforce, or of your withdrawal of
          authorization for any such person to access the System.
        </Typography>
        <Typography variant="body1" paragraph>
          3.10 Patient Portal. You may make health information available to your
          patients through our Patient portal. You are solely responsible for
          the information that you make available through the Patient portal,
          for granting access rights to your patients, and for revoking access
          rights. You agree that you will not use the Patient portal to make
          available the health information of any person under the age of 18
          years. You acknowledge and agree that, if a patient of yours
          authorizes the disclosure of his or her health information to
          MDPortals, Inc. for inclusion in his or her personal health record,
          MDPortals, Inc. may, from time to time for as long as the
          authorization is in effect, transfer the patients health information
          from the health record MDPortals, Inc. maintains for you to a personal
          health record maintained separately by MDPortals, Inc. for the
          patient. Information in the separate personal health record is
          distinct from your patient health record, and is not subject to this
          User Agreement, or to our obligations to you as your business
          associate. Personal health record information of patients who do not
          authorize the disclosure of their health information to MDPortals,
          Inc. for inclusion in a separate personal health record will be held
          as part of the health record that MDPortals, Inc. maintains for you,
          and will be subject to the terms of this User Agreement and our
          business associate obligations.
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          3.11 Forums. We may offer forums for the exchange of information among
          our users. You agree to comply with all applicable forum rules. In
          particular, you understand that we do not assure the accuracy,
          reliability, confidentiality or security of information made available
          through the use of such forums. You acknowledge that any information
          you post in a forum or discussion group is available to the public,
          and may result in your receiving communications from others outside
          our site. You are responsible for safeguarding the privacy of your and
          your patients’ personal information when you participate in forums,
          discussion groups and the like. You agree not to disclose individually
          identifiable health information through such forums.
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          3.12 Compliance with Law. Subject to the provisions of section 17, you
          are solely responsible for ensuring that your use of the System and
          the Services (including making health information available through
          the System) complies with applicable law. You will not undertake or
          permit any unlawful use of the System, or take any action that would
          render the operation or use of the System by us or any other User
          unlawful. We offer no assurance that your use of the System and the
          Services under the terms of this Agreement will not violate any law or
          regulation applicable to you.
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          3.13 Professional Responsibility. You will be solely responsible for
          the professional and technical services you provide. We make no
          representations concerning the completeness, accuracy or utility of
          any information in the System, or concerning the qualifications or
          competence of individuals who placed it there. We have no liability
          for the consequences to you or your patients of your use of the System
          or the Services.
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          3.14 Cooperation. You will cooperate with us in the administration of
          the System, including providing reasonable assistance in evaluating
          the System and collecting and reporting data requested by us for
          purposes of administering the System.
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          3.15 Indemnification. You agree to indemnify, defend, and hold
          harmless us and other Users, and our and their affiliates, officers,
          directors, and agents, from and against any claim, cost or liability,
          including reasonable attorneys’ fees, arising out of: (a) the use of
          the System by you or your Workforce; (b) any breach by you or your
          Workforce of any representations, warranties or agreements contained
          in this Agreement; (c) the actions of any person gaining access to the
          System under a User ID assigned to you or a member of your Workforce;
          (d) the actions of anyone using a User ID, password or other unique
          identifier assigned to you or any member of your Workforce that
          adversely affects the System or any information accessed through the
          System; and (e) your negligent or willful misconduct, or that of any
          member of your Workforce.
        </Typography>
        <Typography variant="body1" paragraph>
          4. Use of Information
        </Typography>
        <Typography variant="body1" paragraph>
          4.1 Purpose of System. The purpose of the System is to facilitate the
          ordering of lab tests and the viewing and analysis of the results, the
          analysis of data from other health monitoring and other devices and
          other data as integrated from time to time, and (i) to make it
          available to you and your Authorized Workforce; (ii) to facilitate the
          sharing of individuals’ health information among Users, and (iii) to
          make health information available to your patients through the Patient
          portal. You may make Your Health Information accessible to other Users
          and to your patients through the System for these purposes. You
          authorize us, as your business associate, to use and disclose Your
          Health Information as follows, subject to the recipient’s agreement to
          comply with our Policies and Procedures and with applicable laws and
          regulations relating to the use and disclosure of health information,
          and subject also to the provisions of section 9:
        </Typography>
        <Typography variant="body1" paragraph>
          4.1.1 We will permit unrestricted access to Your Health Information to
          you and your Authorized Workforce.
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          4.1.2 We will permit access to Your Health Information to your
          patients to whom you have agreed to grant access through our Patient
          portal.
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          4.1.3 We will permit access to Your Health Information by health care
          providers and their business associates to whom you have consented to
          provide access for treatment and payment through the sharing/referral
          features of the System. We will obtain your consent before we make
          Your Health Information available to other providers. You acknowledge
          that once we have granted access rights to another provider, we have
          no control over the uses and disclosures that the provider makes of
          Your Health Information.
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          4.1.4 We may disclose or permit access to Your Health Information to
          health plans, health care clearinghouses, medical groups, independent
          practice associations and other parties responsible for payment and
          their business associates for the purpose of obtaining payment for
          services you provide, unless you advise us in writing that the patient
          has paid out of pocket in full for the service to which the Health
          Information relates, and has requested that it not be disclosed to his
          or her health plan.
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          4.1.5 We may De-Identify Your Health Information and Your Personal
          Information, and use and disclose De-Indentified Information as
          provided by Section 5 and Section 7.2.
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          4.1.6 We may create limited data sets from Your Health Information,
          and disclose them for any purpose for which you may disclose a limited
          data set; and you hereby authorize us to enter into data use
          agreements on your behalf for the use of limited data sets, in
          accordance with applicable law and regulation.
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          4.1.7 We may use Your Health Information in order to prepare analyses
          and reports, such as activity or quality-metrics reports, or any other
          reports the System makes available, in order to render these reports
          to You. Such reporting will be done in a manner that does not make any
          disclosure of Your Health Information that you would not be permitted
          to make.
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          4.1.8 We may use Your Health Information for the proper management and
          administration of the System and our business, and to carry out our
          legal responsibilities. We may also disclose Your Health Information
          for such purposes if the disclosure is required by law, or we obtain
          reasonable assurances from the recipient that it will be held
          confidentially and used or further disclosed only as required by law
          or for the purpose for which it was disclosed to the recipient, and
          the recipient notifies us of any instances of which it is aware in
          which the confidentiality of the information has been breached.
          Without limiting the foregoing, we may permit access to the system by
          our contracted system developers under appropriate confidentiality
          agreements.
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          4.1.9 We may use Your Health Information to contact your patients on
          your behalf for any purpose for which you would be permitted to
          contact them, including, without limitation: (a) For treatment,
          including sending appointment and requisition reminders; (b) For case
          management and care coordination, or to direct or recommend
          alternative treatments, therapies, health care providers or settings
          of care; (c) To request authorization on your behalf from your
          patients to use or disclose their health information for any purpose
          for which use or disclosure may be made with an appropriate
          authorization, including marketing purposes. You agree that we may
          also use and disclose your patient’s health information as permitted
          by any such authorization; and (d) To provide information about
          health-related products or services that you provide, or that we
          provide on your behalf as your business associate.
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          4.1.10 We may use or disclose Your Health Information for other
          purposes, as from time to time described in our Policies and
          Procedures; provided that we will not make or permit any such use or
          disclosure that would violate applicable law or regulation if made by
          you or your business associate. Except as provided in subsection 4.1.7
          and subsection 4.1.8, and notwithstanding any other provision of this
          section, we will not use or disclose Your Health Information in any
          manner that would violate the requirements of the Privacy Rule if done
          by you.
        </Typography>
        <Typography variant="body1" paragraph>
          4.2 Responsibility for Misuse by Other Users. You acknowledge that in
          granting access to the System for the purposes set forth in section
          4.1, we will rely on the assurances of the recipients of the
          information as to (i) their identity and credentials, (ii) the
          purposes for which they are accessing the system, and (iii) the nature
          and extent of the information to which they will have access. You
          acknowledge that, while the System will contain certain technical
          safeguards against misuse of the System, it will rely to a substantial
          extent on the representations and undertakings of Users. You agree
          that we will not be responsible for any unlawful access to or use of
          Your Health Information by any User resulting from the User’s
          misrepresentation to us, or breach of the User’s user agreement or our
          Policies and Procedures.
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          4.3 Specially Protected Information. We apply the standards of the
          Privacy Rule in permitting access to the System. You acknowledge that
          other federal and state laws impose additional restrictions on the use
          and disclosure of certain types of health information, or health
          information pertaining to certain classes of individuals. You agree
          that you are solely responsible for ensuring that Your Health
          Information may properly be disclosed for the purposes set forth in
          section 4.1, subject only to the restrictions of the Privacy Rule. In
          particular, you will:
        </Typography>
        <Typography variant="body1" paragraph>
          4.3.1 not make available through the System any information subject to
          any restriction on use or disclosure (whether arising from your
          agreement with the individual or under law), other than the general
          restrictions contained in the Privacy Rule;
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          4.3.2 obtain any necessary consents, authorizations or releases from
          individuals required for making their health information available
          through the System for the purpose set forth in section 4.1;
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          4.3.3 include such statements (if any) in your notice of privacy
          practices as may be required in connection with your use of the
          System;
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          4.3.4 not place in the System any information that the you know or
          have reason to believe is false or materially inaccurate.
        </Typography>
        <Typography variant="body1" paragraph>
          4.4 Share. With your consent, we will make your on-line heath record
          for any patient you designate accessible to any other user of the
          System whom you approve. You may revoke your consent with respect to
          any other user at any time. While your consent is in effect, an
          approved user may view and edit any health record you have designated
          for his or her use. If you revoke your consent, the approved user will
          continue to have the ability to view to the health record in the form
          in which it existed at the time you revoked your consent, but will not
          be able to view changes made to the record thereafter, and will not be
          able to edit the record. The same rules apply to your use of another
          user’s record who approves access for you. You and your Workforce are
          fully responsible for the information in any chart that you share. You
          and/or your Workforce should not share patient information that
          violates any state and/or federal laws, such as a positive HIV test
          result. In the future, MDPortals, Inc. may allow you to share only
          subsections of a patient chart or may allow a referee to share the
          patient chart with others, in either case, MDPortals, Inc. will obtain
          your consent before using allowing the use of such features. In any
          event, but especially in cases of potential fraud, misuse and/or abuse
          of the System, MDPortals, Inc. reserves the right, in its sole
          judgment, to revoke, remove, cancel or deny continued access to any
          health record or any Share request.
        </Typography>
        <Typography variant="body1" paragraph>
          5. Providing Physician Data to Payers
        </Typography>
        <Typography variant="body1" paragraph>
          Without limiting the provisions of Section 7.2, you agree that we may
          provide De-Identified Health Information and other information
          (including Your Personal Information and information concerning your
          practice to any medical group, independent practice association of
          physicians, health plan or other organization with which you have a
          contract to provide medical services, or to whose members or enrollees
          you provide medical services. Such information may identify you, but
          will not identify any individual to whom you provide services. Such
          information may include (without limitation) aggregate data concerning
          your patients, diagnoses, procedures, orders and the like.
        </Typography>
        <Typography variant="body1" paragraph>
          6. Product and Service Notifications.
        </Typography>
        <Typography variant="body1" paragraph>
          As expressly permitted by this Agreement or by our Policies and
          Procedures, unless we obtain your consent, we will not disclose to any
          third party any information that identifies you to enable the third
          party to market products or services to you directly.
        </Typography>
        <Typography variant="body1" paragraph>
          7. Intellectual Property Rights
        </Typography>
        <Typography variant="body1" paragraph>
          As expressly permitted by this Agreement or by our Policies and
          Procedures, unless we obtain your consent, we will not disclose to any
          third party any information that identifies you to enable the third
          party to market products or services to you directly.
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          7.1 Individually Identifiable Health Information. Except as provided
          in Section 7.2 (De-Identified Information), you retain all rights with
          regard to your Protected Health Information.
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          7.2 De-Identified Information. In consideration of our provision of
          the Services, you hereby transfer and assign to us all right, title
          and interest in and to all De-Identified Information that we make from
          Your Health Information or Your Personal Information pursuant to
          Section 4.1.5. You agree that we may use, disclose, market, license
          and sell De-Identified Information for any purpose without
          restriction, and that you have no interest in such information, or in
          the proceeds of any sale, license, or other commercialization thereof.
          You acknowledge that the rights conferred by this section are the
          principal consideration for the provision of the Services, without
          which we would not enter into this Agreement.
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          7.3 Other Works and Information. You agree that any information,
          material or work product you provide to this site, other than
          Protected Health Information and Personal Information which has not
          been De-Identified, is the exclusive property of MDPortals, Inc., and
          by submitting such content or material you assign to MDPortals, Inc.,
          all intellectual property rights in such content or material. You
          agree that we may use, disclose, market, license and sell such
          information and works, including derivative products, without
          restriction. This includes, for example, custom templates that you
          create using the System, and information (other than Protected Health
          Information or Personal Information which has not been De-Identified)
          that you contribute to forums, discussion groups and the like. You may
          provide content or material to this site by participating in forums,
          discussion groups and the like, or by using the site to create custom
          templates and the like. Furthermore, you agree that MDPortals, Inc.
          may use, disclose, market, license and sell such material or content,
          and that you have no interest in the information, or in the proceeds
          of any sale, license, or other commercialization thereof. You warrant
          and agree that any material you provide will not infringe on the
          intellectual property or other rights of others, and will not be
          otherwise unlawful, infringing, threatening, libelous, defamatory,
          obscene, pornographic, or in violation of any law.
        </Typography>
        <Typography variant="body1" paragraph>
          8. Individuals’ Rights
        </Typography>
        <Typography variant="body1" paragraph>
          You shall be solely responsible for affording individuals their rights
          with respect to Your Health Information, such as the rights of access
          and amendment. You will not undertake to afford an individual any
          rights with respect to any information in the System other than Your
          Health Information.
        </Typography>
        <Typography variant="body1" paragraph>
          9. Business Associate Provisions
        </Typography>
        <Typography variant="body1" paragraph>
          You shall be solely responsible for affording individuals their rights
          with respect to Your Health Information, such as the rights of access
          and amendment. You will not undertake to afford an individual any
          rights with respect to any information in the System other than Your
          Health Information.
        </Typography>
        <Typography variant="body1" paragraph>
          In maintaining, using and affording access to Your Health Information
          in accordance with this Agreement, we will:
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          9.1 Not use or further disclose the information except as permitted or
          required by this Agreement or as required by law;
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          9.2 Use appropriate safeguards to prevent use or disclosure of the
          information other than as provided for by this Agreement, including
          administrative, physical, and technical safeguards that reasonably and
          appropriately protect the confidentiality, integrity, and availability
          of the information;
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          9.3 Report to you any use or disclosure of the information not
          provided for by this Agreement of which we become aware, or any
          security incident as a result of which we determines that unauthorized
          access has been obtained to Your Health Information;
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          9.4 Ensure that any of our agents or subcontractors to whom we provide
          Your Health Information for purposes of assisting us in providing the
          System or the Services, agrees to the same restrictions and conditions
          that apply to us with respect to such information, including the
          obligation to implement reasonable and appropriate safeguards to
          protect it (it being understood that other Users of the System are not
          our agents or subcontractors);
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          9.5 Make available protected health information in accordance with §
          164.524 of the Privacy Rule;
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          9.6 Make available protected health information for amendment and
          incorporate any amendments to protected health information in
          accordance with Sect 164.526 of the Privacy Rule;
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          9.7 Make available the information required to provide an accounting
          of disclosures in accordance with Sect; 164.528 of the Privacy Rule;
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          9.8 Make our internal practices, books, and records relating to the
          use and disclosure of protected health information received from, or
          created or received by us on your behalf available to the Secretary of
          the United States Department of Health and Human Services for purposes
          of determining your compliance with the Privacy Rule; and
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          9.9 At termination of this Agreement we will provide you with a copy
          of Your Health Information in an electronic form that is accessible
          through commercially available hardware and software. You may have to
          purchase such hardware and software from third parties in order to
          access your data, and you may have to configure your systems in order
          to use your data in your practice. Upon termination we will, if
          feasible, return or destroy all protected health information received
          from, or created or received by us on your behalf that we still
          maintain in any form, and retain no copies of such information; or, if
          such return or destruction is not feasible, extend the protections of
          this Agreement to the information and limit further uses and
          disclosures to those purposes that make the return or destruction of
          the information infeasible. You acknowledge that it will likely be
          infeasible to segregate Your Health Information for removal from the
          System, and that if you have approved another user to have access to
          any of your on-line records through Universal Chart or Record Share,
          we will maintain a version of those records through the time you
          withdraw your approval, and we will continue to make those records
          available to the approved (or formerly approved) user. You acknowledge
          that you may have to purchase proprietary software in order to access
          such information.
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          9.10 HITECH Act. As required by the HITECH Act:
        </Typography>
        <Typography variant="body1" paragraph>
          (a) We will comply with the provisions of the HIPAA Security Rule that
          are made applicable to business associates by section 13401(a) of the
          HITECH Act, with the additional provisions of the HITECH Act relating
          to security that are made applicable to business associates and
          incorporated into business associate contracts by section 13401(a) of
          the HITECH Act, and with the additional provisions of the HITECH Act
          relating to privacy that are made applicable to business associates
          and incorporated into business associate contracts by section 13404(a)
          of the HITECH Act. (b) We will report to you the discovery of any
          breach of unsecured Protected Health Information that we access,
          maintain, retain, modify, record, store destroy or otherwise hold, use
          or disclose on your behalf, in compliance with the requirements of
          Section 13402 of the HITECH Act and the regulations promulgated
          thereunder (45 CFR Parts 160 and 164, Subpart D), and we will
          cooperate reasonably with you to investigate and mitigate any such
          breach, and to provide you with information you need to make any
          legally required notification to individuals.
        </Typography>
        <Typography variant="body1" paragraph>
          10. Computer Systems
        </Typography>
        <Typography variant="body1" paragraph>
          10.1 Your Systems. You will acquire, install, configure and maintain
          all hardware, software and communications systems necessary to access
          the System (your “Implementation”). Your Implementation will comply
          with the specifications from time to time established by us. You will
          ensure that your Implementation is compatible with the System and
          Services. If we notify you that your Implementation is incompatible
          with the System, you will eliminate the incompatibility, and we may
          suspend Services to you until you do so.
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          10.2 Assistance. Upon request, we may provide goods or services in
          connection with your Implementation. You will pay our then standard
          charges for such assistance, and our out-of-pocket costs.
        </Typography>
        <Typography variant="body1" paragraph>
          11. Third-Party Sites and Service Providers
        </Typography>
        <Typography variant="body1" paragraph>
          11.1 Third-Party Sites. The System may contain hyperlinks to Internet
          web sites operated by third parties, or to materials or information
          made available by third parties. Such third parties may offer goods or
          services for sale to you. Such links do not constitute or imply our
          endorsement of such third parties, or of the content of their sites,
          the quality or efficacy of their goods or services, or their
          information privacy or security practices, and we have no
          responsibility for information, goods or services offered or provided
          by such third parties, or for the manner in which they conduct their
          operations. Your use of third-party sites and the materials, goods and
          services offered by them is entirely at your own risk, and is subject
          to the terms of use of the third parties operating or providing them.
          You should assume that any Internet page or other material that does
          not bear the MDPortals, Inc. logo is provided by a third party.
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          11.2 MDPortals, Inc..
        </Typography>
        <Typography variant="body1" paragraph>
          11.2.1 Access and Uses. MDPortals, Inc. facilitates the transmission
          of electronic ordering information between participants in the
          MDPortals, Inc. Clinical Network, through which requisition benefit
          and history information, including eligibility, insurance coverage,
          and other information, is transmitted electronically between
          participants in the MDPortals, Inc. Marketplace from a Data Source (as
          described below) to a practitioner at the point of ordering, and
          through which ordering messages are routed electronically from a
          practitioner to the vendor of a patient’s choice. Practitioner hereby
          agrees and acknowledges that MDPortals, Inc. will use the services of
          MDPortals, Inc. to facilitate various features in the Product.
          Furthermore, Practitioner agrees to access and utilize the MDPortals,
          Inc. network only in accordance with the terms and conditions of this
          Agreement and in addition to the terms and conditions set forth in
          this section. Data Source shall mean (a) health benefit payor or ACO
          Group Administrator, or other similar entity which has entered into a
          written agreement with MDPortals, Inc. to allow access through the
          MDPortals, Inc. Clinical Network to information in its possession; and
          (b) a diagnostic laboratory, diagnostic laboratory chain, an entity
          that has contracted with MDPortals, Inc. to become either a vendor or
          practitioner aggregator and is designated as such by MDPortals, Inc.
          or other similar entity which has entered into a written agreement
          with MDPortals, Inc. to allow access through the MDPortals, Inc.
          Clinical Network to information in its possession.
        </Typography>
        <Typography variant="body1" paragraph>
          11.2.2 Confidentiality. You agree to keep confidential any and all of
          MDPortals, Inc. confidential information, as well as the confidential
          information of all entities that have contracted with MDPortals, Inc.
          to become either a vendor or practitioner aggregator and is designated
          as such by MDPortals, Inc. (certified aggregators), entities that have
          been designated by MDPortals, Inc. as a value-added reseller of the
          MDPortals, Inc. Services and connectivity to other entities that
          aggregate practitioners and/or vendors (certified VAR), Data Sources,
          practitioners, health care providers, or facilities, technology
          vendors, and other entities or individuals that have entered into a
          written agreement with MDPortals, Inc. either directly or indirectly,
          in order to access, provide, or communicate through the MDPortals,
          Inc. Clinical Network, whether explicitly marked confidential or
          reasonably believed to be confidential.
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          11.2.3 Compliance with Applicable Law. You are required to and must
          certify that you have obtained any and all necessary patient consents
          and authorizations required by applicable law including, without
          limitation, all federal, state, local, common law, rules, regulations,
          directives, and guidelines prior to using any of MDPortals, Inc.
          services, including, without limitation, its electronic ordering
          service. You hereby agree and provide assurances that all messages
          transmitted via the MDPortals, Inc. network originate from legally
          authorized locations. MDPortals, Inc. reserves the right to terminate
          use of the MDPortals, Inc. Clinical Network for any reason with or
          without notice.
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          11.2.4 Disclaimer. MDPortals, Inc. MAKES NO REPRESENTATION OR WARRANTY
          REGARDING THE AVAILABILITY THROUGH THE MDPortals, Inc. CLINICAL
          NETWORK OF ANY PARTICULAR DATA SOURCE OR OTHER PARTICIPANT IN THE
          MDPortals, Inc. CLINICAL NETWORK AT ANY TIME, DATA SOURCES AND OTHER
          PARTICIPANTS IN THE MDPortals, Inc. CLINICAL NETWORK AY BE ADDED OR
          DELETED WITHOUT PRIOR NOTICE. YOU ACKNOWLEDGE AND AGREE THAT AT ANY
          TIME AND WITHOUT PRIOR NOTICE, A DATA SOURCE (AS DESCRIBED ABOVE) MAY
          ELECT NOT TO RECEIVE REQUISITIONS AND/OR OTHER MESSAGES. YOU FURTHER
          AGREE THAT AS THE TREATING PHYSICIAN OR OTHER HEALTH CARE PROVIDER,
          YOU HAVE VERIFIED REQUISITION BENEFIT OR REQUISITION HISTORY
          INFORMATION WITH EACH PATIENT AND/OR THE PATIENT’S REPRESENTATIVES
          BEFORE SUCH INFORMATION IS RELIED UPON OR UTILIZED IN DIAGNOSING OR
          TREATING THE PATIENT. MDPortals, Inc. DOES NOT AND CANNOT
          INDEPENDENTLY VERIFY OR REVIEW THE INFORMATION TRANSMITTED THROUGH THE
          MDPortals, Inc. CLINICAL NETWORK FOR ACCURACY AND COMPLETENESS. THE
          MDPortals, Inc. CLINICAL NETWORK IS NOT INTENDED TO SERVE AS A
          REPLACEMENT FOR A WRITTEN REQUISITION WHERE NOT APPROVED AS SUCH BY
          THE APPROPRIATE GOVERNMENTAL AUTHORITIES OR WHERE SUCH WRITTEN
          REQUISITION IS REQUIRED FOR RECORD KEEPING PURPOSES, OR APPLICABLE
          REQUISITION DOCUMENTATION. USE OF THE MDPortals, Inc. CLINICAL NETWORK
          IS NOT A SUBSTITUTE FOR A HEATLH CARE PROVIDER’S STANDARD PRACTICE OR
          PROFESSIONAL JUDGMENT. ANY DECISION WITH REGARD TO THE APPROPRIATENESS
          OF TREATMENT, OR THE VALIDITY OR RELIABILITY OF INFORMATION, IS SOLELY
          YOUR RESPONSIBILITY.
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          11.2.5 No Warranty. THE MDPortals, Inc. MARKETPLACE IS PROVIDED “AS
          IS” AND WITHOUT WARRANTIES, EXCEPT AS SET FORTH BELOW. ALL OTHER
          WARRANTIES AND REPRESENTATIONS OF ANY KIND WITH REGARD TO THE
          MDPortals, Inc. CLINICAL NETWORK ARE HEREBY DISCLAIMED, INCLUDING THE
          IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
          PURPOSE. MDPortals, Inc. DOES NOT WARRANT THAT ITS NETWORK WILL MEET
          ANY REQUIREMENTS OR THAT IT WILL OPERATE WITHOUT INTERRUPTION OR BE
          ERROR FREE. MDPortals, Inc. agrees to use commercially reasonable
          efforts at all times to provide prompt and efficient service and to
          maintain its own equipment, proprietary systems and programs;
          MDPortals, Inc. however, makes no warranties or representations
          regarding the MDPortals, Inc. Clinical Network except as specifically
          stated in this Section 11.2. MDPortals, Inc. shall use all due care in
          processing all work submitted to it by MDPortals, Inc. and agrees that
          it will, at its expense, use commercially reasonable efforts to
          correct, as promptly as practicable, any errors to the extent that
          such errors are due to the malfunction of MDPortals, Inc. computers,
          operating systems, or programs or errors by MDPortals, Inc. employees
          or agents. Correction shall be limited to identifying errors and
          retransmitting the message or messages affected by any errors.
          MDPortals, Inc. shall not be responsible in any manner for errors or
          failures of proprietary systems and programs of third parties, nor
          shall MDPortals, Inc. be liable for errors or failures of MDPortals,
          Inc.’s software or operational systems not caused by the MDPortals,
          Inc. Marketplace. Should there be any failure in performance or errors
          or omissions with respect to the information being transmitted,
          MDPortals, Inc. responsibility shall be limited to using commercially
          reasonable efforts to correct such failure in performance or errors or
          omissions.
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          11.2.6 Indemnification. Except to the extent arising solely from the
          gross negligence or willful misconduct of MDPortals, Inc. and subject
          to the limitations set forth below, you shall indemnify and save
          harmless MDPortals, Inc. from and against any and all loss, damage, or
          expense (or claims of damage or liability) asserted against MDPortals,
          Inc. by third parties and arising directly out of any breach of this
          Agreement, any loss of connectivity to the MDPortals, Inc. Clinical
          Network due to acts or omissions inconsistent with the terms and
          conditions hereof, or information provided to MDPortals, Inc., you or
          to other third persons.
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          11.2.7 Force Majeure. Neither Party shall be liable or deemed in
          default for failure to fulfill any obligation under this Agreement due
          to causes beyond its reasonable control. Such causes or conditions
          shall include, but shall not be limited to, acts of God or of the
          public enemy, acts of the Government in either its sovereign or
          contractual capacity, fires, floods, epidemics, quarantine
          restrictions, strikes, shortages of labor or materials, freight
          embargoes, unusually severe weather, electrical power failures,
          telecommunication or internet backbone outages, failure of an internet
          access provider or other similar causes beyond the Parties’ control,
          and neither Party shall be liable for losses, expenses or damages,
          ordinary, special or consequential, resulting directly or indirectly
          from such causes.
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          11.2.8 Audit. You authorize MDPortals, Inc. to access, inspect, and/or
          audit your records relating to the use of the MDPortals, Inc. Clinical
          Network, MDPortals, Inc. data, and data or information provided by
          you.
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          11.2.9 Survey. By using MDPortals, Inc. and/or consenting to its
          terms, You authorize MDPortals, Inc. to contact you for survey and/or
          statistical purposes. You also agree that MDPortals, Inc. shall be
          entitled to disclose information received from You for the purpose of
          (and only to the extent necessary for) operating MDPortals, Inc.
          business and providing the MDPortals, Inc. services, including,
          without limitation, sharing Your information and/or data with other
          Data Sources to the extent necessary to fulfill the terms of this
          Agreement, but only in accordance with all applicable law, or pursuant
          to a valid order issued by a duly authorized court or Government
          authority.
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          11.2.10 HIPAA Compliance. MDPortals, Inc. may utilize, transfer, or
          disclose aggregated information, including, but not limited to,
          summary statistics, which has been de-identified in accordance with
          HIPAA at 45 CFR 164.514 such that it does not identify an individual
          and cannot be used to identify an individual for any purpose.
          Notwithstanding the foregoing, MDPortals, Inc.’s use of de-identified
          data shall be in accordance with its Notice of Privacy Policy at
          www.mdportals.com.
        </Typography>
        <Typography variant="body1" paragraph>
          12. Fees and Charges
        </Typography>
        <Typography variant="body1" paragraph>
          12.1 Service Fees. You will pay us a Service Fee for the use of our
          System, as described in Plans and Pricing section of this website..
          You also agree to pay, at our then current rates, for all goods or
          services that you request from us and that are not included in our
          standard services (“Miscellaneous Charges”). We will notify you of the
          Service Fee when you are granted access to a service, and we will
          notify you of the applicable Miscellaneous Charges before performing
          services to which a Miscellaneous Charge will apply. The Service Fee
          and Miscellaneous Charges may change from time to time.
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          12.2 Payment. The Service Fee and any Miscellaneous Charges shall be
          paid on receipt or within twenty (20) days of date of invoice at the
          address set forth under our name below, or such other address as may
          be set forth in our Policies and Procedures.
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          12.3 Late Charges. Fees not paid within ten (10) business days of the
          due date are subject to a late charge of five percent (5%) of the
          amount owing and interest thereafter at the rate of one and one-half
          percent (1/2%) per month on the outstanding balance, or the highest
          amount permitted by law, whichever is lower. Failure to pay fees
          within ten (10) days of the due date may result in termination of
          access to the System without notice. A reconnection fee equal to one
          (1) month’s Service Fee shall be assessed to re-establish connection
          after termination due to non-payment.
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          12.4 Taxes. All charges and fees shall be exclusive of all federal,
          state, municipal, or other government excise, sales, use,
          occupational, or like taxes now in force or enacted in the future, and
          you agree to pay any tax (excluding taxes on our net income) that we
          may be required to collect or pay now or at any time in the future and
          that are imposed upon the sale or delivery of items and services
          purchased under this Agreement.
        </Typography>
        <Typography variant="body1" paragraph>
          12.5 Other Charges. You are responsible for any charges you incur to
          use the System, such as telephone and equipment charges, and fees
          charged by third-party vendors of products and services.
        </Typography>
        <Typography variant="body1" paragraph>
          13. Confidential Information
        </Typography>
        <Typography variant="body1" paragraph>
          13.1 You may not disclose our Confidential Information to any other
          person, and you may not use any Confidential Information except for
          the purpose of this Agreement. Except as otherwise provided in this
          Agreement, you may not, without our prior written consent, at any
          time, during or after the Term of this Agreement, directly or
          indirectly, divulge or disclose Confidential Information for any
          purpose or use Confidential Information for its own benefit or for the
          purposes or benefit of any other person. You agree to hold all
          Confidential Information in strict confidence and to take all measures
          necessary to prevent unauthorized copying, use, or disclosure of
          Confidential Information, and to keep the Confidential Information
          from falling into the public domain or into the possession of persons
          not bound to maintain its confidentiality. You will disclose
          Confidential Information only to members of your Workforce who have a
          need to use it for the purposes of this Agreement. You will inform all
          such recipients of the confidential nature of Confidential Information
          and will instruct them to deal with Confidential Information in
          accordance with the terms of this Agreement. You will promptly advise
          us in writing of any improper disclosure, misappropriation, or misuse
          of the Confidential Information by any person, which may come to your
          attention.
        </Typography>
        <Typography variant="body1" paragraph>
          13.2 You agree that we will suffer irreparable harm if you fail to
          comply with its obligations set forth in this Section 13, and you
          further agree that monetary damages will be inadequate to compensate
          us for any such breach. Accordingly, you agree that we will, in
          addition to any other remedies available to us at law or in equity, be
          entitled to the issuance of injunctive relief to enforce the
          provisions hereof, immediately and without the necessity of posting a
          bond.
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          13.3 This Section 13 will survive the termination or expiration of
          this Agreement for any reason.
        </Typography>
        <Typography variant="body1" paragraph>
          14. Disclaimer, Exclusion of Warranties, and Limitation of Liability
        </Typography>
        <Typography variant="body1" paragraph>
          14.1 Carrier Lines. YOU ACKNOWLEDGE THAT ACCESS TO THE SYSTEM WILL BE
          PROVIDED OVER VARIOUS FACILITIES AND COMMUNICATIONS LINES, AND
          INFORMATION WILL BE TRANSMITTED OVER LOCAL EXCHANGE AND INTERNET
          BACKBONE CARRIER LINES AND THROUGH ROUTERS, SWITCHES, AND OTHER
          DEVICES (COLLECTIVELY, “CARRIER LINES”) OWNED, MAINTAINED, AND
          SERVICED BY THIRD-PARTY CARRIERS, UTILITIES, AND INTERNET SERVICE
          PROVIDERS, ALL OF WHICH ARE BEYOND OUR CONTROL. WE ASSUME NO LIABILITY
          FOR OR RELATING TO THE INTEGRITY, PRIVACY, SECURITY, CONFIDENTIALITY,
          OR USE OF ANY INFORMATION WHILE IT IS TRANSMITTED ON THE CARRIER
          LINES, OR ANY DELAY, FAILURE, INTERRUPTION, INTERCEPTION, LOSS,
          TRANSMISSION, OR CORRUPTION OF ANY DATA OR OTHER INFORMATION
          ATTRIBUTABLE TO TRANSMISSION ON THE CARRIER LINES. USE OF THE CARRIER
          LINES IS SOLELY AT YOUR RISK AND IS SUBJECT TO ALL APPLICABLE LOCAL,
          STATE, NATIONAL, AND INTERNATIONAL LAWS.
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          14.2 No Warranties. ACCESS TO THE SYSTEM AND THE INFORMATION CONTAINED
          ON THE SYSTEM IS PROVIDED “AS IS” AND “AS AVAILABLE” WITHOUT ANY
          WARRANTY OF ANY KIND, EXPRESSED OR IMPLIED, INCLUDING BUT NOT LIMITED
          TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
          PARTICULAR PURPOSE, AND NONINFRINGEMENT. YOU ARE SOLELY RESPONSIBLE
          FOR ANY AND ALL ACTS OR OMISSIONS TAKEN OR MADE IN RELIANCE ON THE
          SYSTEM OR THE INFORMATION IN THE SYSTEM, INCLUDING INACCURATE OR
          INCOMPLETE INFORMATION. IT IS EXPRESSLY AGREED THAT IN NO EVENT SHALL
          WE BE LIABLE FOR ANY SPECIAL, INDIRECT, CONSEQUENTIAL, OR EXEMPLARY
          DAMAGES, INCLUDING BUT NOT LIMITED TO, LOSS OF PROFITS OR REVENUES,
          LOSS OF USE, OR LOSS OF INFORMATION OR DATA, WHETHER A CLAIM FOR ANY
          SUCH LIABILITY OR DAMAGES IS PREMISED UPON BREACH OF CONTRACT, BREACH
          OF WARRANTY, NEGLIGENCE, STRICT LIABILITY, OR ANY OTHER THEORY OF
          LIABILITY, EVEN IF WE HAVE BEEN APPRISED OF THE POSSIBILITY OR
          LIKELIHOOD OF SUCH DAMAGES OCCURRING. WE DISCLAIM ANY AND ALL
          LIABILITY FOR ERRONEOUS TRANSMISSIONS AND LOSS OF SERVICE RESULTING
          FROM COMMUNICATION FAILURES BY TELECOMMUNICATION SERVICE PROVIDERS OR
          THE SYSTEM.
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          14.3 Conditions for Breach. We will not be deemed to be in violation
          of this Agreement unless you have first have given us written notice
          specifying the nature of the default, and we have failed within thirty
          (30) days of receipt of the notice either to cure the default or, if
          cure within such period is not practicable, to be diligently
          proceeding to cure the default.
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          14.4 Other Users. YOU ACKNOWLEDGE THAT OTHER USERS HAVE ACCESS TO THE
          SYSTEM AND ARE RECEIVING OUR SERVICES. SUCH OTHER USERS HAVE COMMITTED
          TO COMPLY WITH OUR POLICIES AND PROCEDURES CONCERNING USE OF THE
          SYSTEM; HOWEVER, THE ACTIONS OF SUCH OTHER USERS ARE BEYOND OUR
          CONTROL. ACCORDINGLY, WE DO NOT ASSUME ANY LIABILITY FOR OR RELATING
          TO ANY IMPAIRMENT OF THE PRIVACY, SECURITY, CONFIDENTIALITY,
          INTEGRITY, AVAILABILITY, OR RESTRICTED USE OF ANY INFORMATION ON THE
          SYSTEM RESULTING FROM ANY USER’S ACTIONS OR FAILURES TO ACT.
        </Typography>
        <Typography variant="body1" paragraph>
          14.5 Unauthorized Access; Lost or Corrupt Data. WE ARE NOT RESPONSIBLE
          FOR UNAUTHORIZED ACCESS TO YOUR DATA, FACILITIES OR EQUIPMENT BY
          INDIVIDUALS OR ENTITIES USING THE SYSTEM OR FOR UNAUTHORIZED ACCESS
          TO, ALTERATION, THEFT, CORRUPTION, LOSS OR DESTRUCTION OF YOUR DATA
          FILES, PROGRAMS, PROCEDURES, OR INFORMATION THROUGH THE SYSTEM,
          WHETHER BY ACCIDENT, FRAUDULENT MEANS OR DEVICES, OR ANY OTHER MEANS.
          YOU ARE SOLELY RESPONSIBLE FOR VALIDATING THE ACCURACY OF ALL OUTPUT
          AND REPORTS, AND FOR PROTECTING YOUR DATA AND PROGRAMS FROM LOSS BY
          IMPLEMENTING APPROPRIATE SECURITY MEASURES, INCLUDING ROUTINE BACKUP
          PROCEDURES. YOU HEREBY WAIVE ANY DAMAGES OCCASIONED BY LOST OR CORRUPT
          DATA, INCORRECT REPORTS, OR INCORRECT DATA FILES RESULTING FROM
          PROGRAMMING ERROR, OPERATOR ERROR, EQUIPMENT OR SOFTWARE MALFUNCTION,
          SECURITY VIOLATIONS, OR THE USE OF THIRD-PARTY SOFTWARE. WE ARE NOT
          RESPONSIBLE FOR THE CONTENT OF ANY INFORMATION TRANSMITTED OR RECEIVED
          THROUGH OUR PROVISION OF THE SERVICES.
        </Typography>
        <Typography variant="body1" paragraph>
          14.6 Limitation of Liability. NOTWITHSTANDING ANYTHING IN THIS
          AGREEMENT TO THE CONTRARY, OUR AGGREGATE LIABILITY UNDER THIS
          AGREEMENT, REGARDLESS OF THEORY OF LIABILITY, SHALL BE LIMITED TO THE
          AGGREGATE FEES ACTUALLY PAID BY YOU UNDER THIS AGREEMENT FOR THE SIX
          (6) MONTH PERIOD PRECEDING THE EVENT FIRST GIVING RISE TO THE CLAIM.
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          15. Insurance
        </Typography>
        <Typography variant="body1" paragraph>
          You will obtain and maintain such policies of general liability,
          errors and omissions, and professional liability insurance with
          reputable insurance companies as is usually carried by persons engaged
          in your business covering the Term of this Agreement.
        </Typography>
        <Typography variant="body1" paragraph>
          16. Term; Modification; Suspension; Termination
        </Typography>
        <Typography variant="body1" paragraph>
          16.1 Term. The initial term of this Agreement shall commence on the
          Effective Date and continue for a period of one (1) year, and
          thereafter until terminated as provided in this Section.
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          16.2 Termination upon Notice. We or you may terminate this Agreement
          at any time without cause upon thirty (30) days prior written notice
          to the other Party.
        </Typography>
        <Typography variant="body1" paragraph>
          16.3 Modification. We may update or change the Services and/or the
          terms set forth in this Agreement from time to time and recommend that
          you review the Agreement on a regular basis. You understand and agree
          that your continued use of the Services after the Agreement has been
          updated or changed constitutes your acceptance of the revised
          Agreement. Without limiting the foregoing, if we make a change to the
          Agreement that materially affects your use of the Services, we may
          post notice or notify you via email or our website(s) of any such
          change.
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          16.4 Termination, Suspension or Amendment as a Result of Government
          Regulation. Notwithstanding anything to the contrary in this
          Agreement, we have the right, on notice to you, immediately to
          terminate, suspend, or amend this Agreement, without liability: (a) to
          comply with any order issued or proposed to be issued by any
          governmental agency; (b) to comply with any provision of law, any
          standard of participation in any reimbursement program, or any
          accreditation standard; or (c) if performance of any term of this
          Agreement by either Party would cause it to be in violation of law, or
          would jeopardize its tax-exempt status.
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          16.5 Judicial or Administrative Procedures; Credentialing. We may
          terminate this Agreement immediately upon notice to you: (a) if you
          are named as a defendant in a criminal proceeding for a violation of
          federal or state law; (b) if a finding or stipulation is made or
          entered into that you have violated any standard or requirement of
          federal or state law relating to the privacy or security of health
          information is made in any administrative or civil proceeding; (c) you
          are excluded from participation in a federal or state health care
          program or (d) you cease to be qualified to provide services as a
          health care professional, or we are unable to verify your
          qualifications as such.
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          16.6 Suspension of Access. We may suspend access to the System or the
          Services by you or any member of your Workforce immediately pending
          your cure of any breach of this Agreement, or in the event we
          determine in our sole discretion that access to or use of the System
          by you or the member of your Workforce may jeopardize the System or
          the confidentiality, privacy, security, integrity or availability of
          information within the System, or that you or the member of your
          Workforce has violated or may violate this Agreement or our Policies
          and Procedures, or has jeopardized or may jeopardize the rights of any
          third party, or that any person is or may be making unauthorized use
          of the System with any User ID assigned to you or a member of your
          Workforce. We may terminate the access of any member of your
          Authorized Workforce upon termination or change in status of his or
          employment with you. Our election to suspend the Services shall not
          waive or affect our rights to terminate this Agreement as permitted
          under this Agreement.
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          16.7 Obligations After Termination. Upon termination of this
          Agreement, you will cease to use the System and we may terminate your
          access to the System. You will pay to us the Access Fee for the
          balance of the Term upon termination. Upon termination for any reason,
          you will remove all software provided under this Agreement from your
          computer systems, you will cease to have access to the System, and you
          will return to us all hardware, software and documentation provided by
          or on behalf of us.
        </Typography>
        <Typography variant="body1" paragraph>
          17. Applicable Law
        </Typography>
        <Typography variant="body1" paragraph>
          The interpretation of this Agreement and the resolution of any
          disputes arising under this Agreement shall be governed by the laws of
          the State of California. If any action or other proceeding is brought
          on or in connection with this Agreement, the venue of such action
          shall be exclusively in the City and County of San Francisco,
          California.
        </Typography>
        <Typography variant="body1" paragraph>
          18. ARBITRATION
        </Typography>
        <Typography variant="body1" paragraph>
          ANY DISPUTE, CLAIM OR CONTROVERSY ARISING OUT OF OR RELATING TO THIS
          NOTICE OR THE BREACH, TERMINATION, ENFORCEMENT, INTERPRETATION OR
          VALIDITY THEREOF, INCLUDING THE DETERMINATION OF THE SCOPE OR
          APPLICABILITY OF THIS AGREEMENT TO ARBITRATE, OR TO YOUR USE OF THIS
          SITE OR THE SYSTEMS OR INFORMATION TO WHICH IT GIVES ACCESS, SHALL BE
          DETERMINED BY ARBITRATION IN SAN FRANCISCO, CALIFORNIA, BEFORE A
          SINGLE ARBITRATOR. THE ARBITRATION SHALL BE ADMINISTERED BY JAMS
          PURSUANT TO ITS COMPREHENSIVE ARBITRATION RULES AND PROCEDURES.
          JUDGMENT ON THE AWARD MAY BE ENTERED IN ANY COURT HAVING JURISDICTION.
          THIS CLAUSE SHALL NOT PRECLUDE PARTIES FROM SEEKING PROVISIONAL
          REMEDIES IN AID OF ARBITRATION FROM A COURT OF APPROPRIATE
          JURISDICTION.
        </Typography>
        <Typography variant="body1" paragraph>
          19. Non-Assignability
        </Typography>
        <Typography variant="body1" paragraph>
          This Agreement may not be assigned or transferred by you without our
          prior written consent.
        </Typography>
        <Typography variant="body1" paragraph>
          20. Supervening Circumstances
        </Typography>
        <Typography variant="body1" paragraph>
          No Party to this Agreement shall be deemed in violation of this
          Agreement if it is prevented from performing any of the obligations
          under this Agreement by reason of: (a) severe weather and storms; (b)
          earthquakes or other natural occurrences; (c) strikes or other labor
          unrest; (d) power failures; (e) nuclear or other civil or military
          emergencies; (f) acts of legislative, judicial, executive, or
          administrative authorities; or (g) any other circumstances that are
          not within its reasonable control.
        </Typography>
        <Typography variant="body1" paragraph>
          21. Severability
        </Typography>
        <Typography variant="body1" paragraph>
          Any provision of this Agreement that shall prove to be invalid, void,
          or illegal, shall in no way affect, impair, or invalidate any other
          provision of this Agreement, and such other provisions shall remain in
          full force and effect.
        </Typography>
        <Typography variant="body1" paragraph>
          22. Notices
        </Typography>
        <Typography variant="body1" paragraph>
          Any and all notices required or permitted under this Agreement shall
          be sent by United States mail or fax transmission to the address
          provided below or to such other and different addresses as the Parties
          may designate in writing. If you supply us with an electronic mail
          address, we may give notice by email message addressed to such
          address; provided that if we receive notice that the email message was
          not delivered, we will give the notice by United States mail or fax.
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          To us: MDPortals, Inc., Inc.
        </Typography>
        <Typography variant="body1" paragraph>
          23. Waiver
        </Typography>
        <Typography variant="body1" paragraph>
          No term of this Agreement shall be deemed waived and no breach
          excused, unless such waiver or consent shall be in writing and signed
          by the Party claimed to have waived or consented. Any consent by any
          Party to, or waiver of a breach by the other, whether expressed or
          implied, shall not constitute a consent to, waiver of, or excuse for
          any other different or subsequent breach.
        </Typography>
        <Typography variant="body1" paragraph>
          24. Complete Understanding
        </Typography>
        <Typography variant="body1" paragraph>
          This Agreement contains the entire understanding of the Parties, and
          there are no other written or oral understandings or promises between
          the Parties with respect to the subject matter of this Agreement other
          than those contained or referenced in this Agreement. Except as
          otherwise provided in this Agreement (including Section 16.3), all
          modifications or amendments to this Agreement shall be in writing and
          signed by all Parties.
        </Typography>
        <Typography variant="body1" paragraph>
          25. No Third-Party Beneficiaries
        </Typography>
        <Typography variant="body1" paragraph>
          Nothing express or implied in this Agreement is intended to confer,
          nor shall confer, upon any person or entity other than the parties and
          their respective successors or assigns any rights, remedies,
          obligations, or liabilities whatsoever.
        </Typography>
        <Typography variant="body1" paragraph>
          26. Advice of Counsel
        </Typography>
        <Typography variant="body1" paragraph>
          Each Party acknowledges: (a) having fully read this Agreement in its
          entirety; (b) having had full opportunity to study and review this
          Agreement; (c) having been advised that counsel for us has acted
          solely on our behalf in connection with the negotiation, preparation,
          and execution of this Agreement; (d) having been advised that all
          parties have the right to consult and should consult independent
          counsel respecting their rights and duties under this Agreement; and
          (e) having had access to all such information as has been requested.
        </Typography>
        <Typography variant="body1" paragraph>
          27. Authority
        </Typography>
        <Typography variant="body1" paragraph>
          The individuals entering into this Agreement represent and warrant
          that they are competent and capable of entering into a binding
          contract, and that they are authorized to enter into this Agreement on
          behalf of the Parties.
        </Typography>
      </div>
    </PageTemplate>
  );
};

export default withStyles(TermsOfUse, styles);
