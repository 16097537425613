// @flow

import React from 'react';
import { useState } from 'react';
import { withStyles } from 'tss-react/mui';
import Modal from '@mui/material/Modal';
import axios from 'axios';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import MDPBackend from '../../../services/MDPBackend';

import styles from '../Styles/NewQuery.Style';
import { useDispatch } from 'react-redux';
import { showToastMsg } from 'features/toast-message-slice';

type Props = {
  classes: Object,
  fieldData: any,
  handleClose: () => void,
  reset: () => void,
  options: Object,
  advancedOptions: Boolean,
  acceptedFile: Object
};

const boxStyle = {
  position: 'absolute',
  maxWidth: 800,
  padding: '1rem',
  maxHeight: 800,
  maxWidth: 600,
  backgroundColor: 'white',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  overflowX: 'hidden',
  overflowY: 'auto'
};

const VerifyModal = (props: Props) => {
  const { classes, fieldData, handleClose, acceptedFile, reset } = props;
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  Object.keys(fieldData).forEach(
    (key) =>
      (!fieldData[key] ||
        (typeof fieldData[key] === 'object' && !fieldData[key].length) ||
        key === 'attachment_zip_file_s3key') &&
      delete fieldData[key]
  );

  console.log('[VerifyModal] fieldData: ', fieldData);

  /* eslint-disable react/prop-types */
  const FormattedField = ({ fieldKey, fieldValue }) => {
    return (
      <>
        {fieldKey && fieldValue ? (
          <div className={classes.row}>
            <div>{fieldKey}:</div>
            <div
              style={{
                fontWeight: 600,
                marginLeft: 5,
                wordBreak: 'break-word'
              }}
            >
              {typeof fieldValue === 'object'
                ? fieldValue.join(', ')
                : fieldValue}
            </div>
          </div>
        ) : null}
      </>
    );
  };

  const submitQuery = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);

    try {
      const requestPayload = fieldData;

      console.log('[submitQuery] requestPayload: ', requestPayload);

      if (acceptedFile) {
        console.log('Uploading attached file: ', acceptedFile);

        const fileName = acceptedFile.name;
        const mimeType = acceptedFile.type;
        console.log('[submitQuery] fileName: ', fileName);

        const response = await MDPBackend.getFileUploadUrl({
          fileName: fileName,
          mimeType: mimeType
        });

        const parsedResponse = JSON.parse(response.data.body);

        const uploadUrl = parsedResponse.url;
        const fileKey = parsedResponse.key;

        const uploadResponse = await axios.put(uploadUrl, acceptedFile, {
          headers: {
            'Content-Type': acceptedFile.type
          }
        });
        console.log('[submitQuery] uploadResponse: ', uploadResponse);

        if (uploadResponse.status !== 200) {
          throw new Error('Failed to upload file: ', uploadResponse);
        }

        requestPayload.attachment_zip_file_s3key = fileKey;
      }

      const response = await MDPBackend.submitPatientQuery(requestPayload);

      console.log('[submitQuery] response: ', response);

      setLoading(false);

      if (response.data.statusCode === 200) {
        const parsedResponse = JSON.parse(response.data.body);
        const responseMessage = parsedResponse?.message;

        dispatch(
          showToastMsg({
            open: true,
            message: responseMessage,
            level: 'success',
            duration: 5000
          })
        );

        handleClose();
        reset();
      } else {
        const parsedResponse = JSON.parse(response.data.body);
        const responseMessage = parsedResponse?.message;

        dispatch(
          showToastMsg({
            open: true,
            message: responseMessage ?? 'Something went wrong',
            level: 'error',
            duration: 15000
          })
        );
      }
    } catch (err) {
      console.log('[submitQuery] err: ', err);
      setLoading(false);
    }
  };

  return (
    <div>
      <Modal
        open={fieldData}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={fieldData}>
          <Box style={boxStyle}>
            <div className={classes.fieldsWrapper}>
              <div className={classes.fieldsToVerify}>
                <Typography
                  variant="h1"
                  className={classes.title}
                  style={{ marginBottom: 20 }}
                >
                  Summary:
                </Typography>
                {fieldData
                  ? Object.keys(fieldData).map((field) => (
                      <>
                        {fieldData[field] ? (
                          <FormattedField
                            fieldKey={field}
                            fieldValue={fieldData[field]}
                          />
                        ) : null}
                      </>
                    ))
                  : null}
                {acceptedFile ? (
                  <FormattedField
                    fieldKey="Attached File"
                    fieldValue={acceptedFile.name}
                  />
                ) : null}
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.submitBtn}
                  onClick={submitQuery}
                  disabled={loading}
                >
                  {loading ? 'Loading...' : 'Submit Query'}
                </Button>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default withStyles(VerifyModal, styles);
