import { styled } from '@mui/material';
import Box from '@mui/material/Box';

export const StyledBox = styled(Box)`
  position: absolute;
  width: 100%;
  max-width: 500px;
  padding: 1.5rem;
  background-color: white;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  outline: none;
  border-radius: 1rem;

  .searchPeriodDatePickerWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
  }

  .searchPeriodDatePicker,
  .batchFilter,
  .searchPeriodFilter {
    width: 100%;
    margin-bottom: 20px;
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .form-section {
    width: 100%;
    margin-bottom: 20px;
  }
`;
