// @flow

import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { ForwardRef } from 'components';

type Props = {
  id: String,
  hccId: String,
  onClick: Function,
  label: String
};

const PdfButton = ({ id, hccId, label, onClick }: Props) => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  const [searchParams] = useSearchParams();
  const [paramLoaded, setParamLoaded] = useState(false);

  const addQueryWithoutRerender = () => {
    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.set('hcc_id', hccId);
    currentUrl.searchParams.set('fact_name', id);
    window.history.replaceState({}, '', currentUrl);
  };

  const scrollToParent = useCallback((el: HTMLElement) => {
    const rect = el.getBoundingClientRect();
    if (rect) {
      window.scrollTo({
        top: rect.top + window.scrollY,
        behavior: 'smooth'
      });
    }
  }, []);

  const handleClick = () => {
    addQueryWithoutRerender();
    onClick();
  };

  useEffect(() => {
    const factName = searchParams.get('fact_name');
    if (!paramLoaded && buttonRef.current && factName === id) {
      const parent = document.getElementById(factName);
      if (parent) {
        scrollToParent(parent);
        buttonRef.current.click();
      }
    }
    setParamLoaded(true);
  }, [scrollToParent, searchParams, paramLoaded, id]);

  return (
    <Button
      variant="outlined"
      ref={buttonRef}
      id={`pdf-${id}`}
      className="!mr-2 !leading-none !py-[5px] !px-1 !text-[1.05rem] 
      !text-black !font-semibold rounded !border-mineShaft/40
       [&.Mui-disabled]:!bg-black/10 [&.Mui-disabled]:!text-gray-500 
       lg:text-[0.9rem] lg:py-[10px] lg:px-2 md:text-[0.8rem] md:py-1 md:px-2"
      onClick={handleClick}
    >
      <>{label}</>
      <ForwardRef>
        <FontAwesomeIcon icon={solid('external-link')} className="mx-1" />
      </ForwardRef>
    </Button>
  );
};

export default PdfButton;
