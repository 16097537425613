// @flow

const styles = (theme: Object) => ({
  container: {
    marginTop: '10vh',
    maxWidth: 960,
    margin: '0 auto',
    padding: `${theme.spacing(4)} ${theme.spacing(4)} ${theme.spacing(
      6
    )} ${theme.spacing(4)}`
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  deleteBtn: {
    cursor: 'pointer'
  },
  apiKeyList: {
    marginTop: theme.spacing(3)
  }
});

export default styles;
