// @flow

import React from 'react';
import { Link, useMatch } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import { useSelector } from 'react-redux';

const drawerWidthOpen = 173;
const paddingIconButton = 10;
const marginIconButton = 14;
const iconFontSize = 20;
const drawerWidthClose = {
  small: (paddingIconButton + marginIconButton) * 2 + iconFontSize,
  large: 173
};

type IconProps = {
  className: String
};

const ChecklistIcon = ({ className = '' }: IconProps) => {
  return <FontAwesomeIcon icon={solid('check-double')} className={className} />;
};

type Props = {
  hasCDA: Boolean,
  patientId: String
};

const Sidebar = (props: Props) => {
  const { hasCDA, patientId } = props;

  const match = useMatch('/patient/:patientId/*');
  const currentOrganization = useSelector((state) => state.currentOrganization);

  const theme = useTheme();

  const navbarList = [
    {
      icon: FormatListBulletedIcon,
      desc: 'HCC Summary',
      route: ''
    },
    {
      icon: ChecklistIcon,
      desc: 'Care Gaps',
      route: 'care-gaps'
    },
    {
      icon: LibraryBooksIcon,
      desc: 'Chart Files',
      route: 'files'
    }
  ].filter((page) =>
    !currentOrganization?.config?.enableCareGaps
      ? page.route !== 'care-gaps'
      : page
  );

  const isActive = (route) => {
    return match.params['*'] === route;
  };

  const drawerContent = (
    <>
      <List sx={{ marginTop: 12 }}>
        {navbarList.map((page) => (
          <Tooltip
            className="sm:hidden"
            key={page.desc}
            title={page.desc}
            placement={'right'}
            componentsProps={{
              tooltip: {
                sx: {
                  backgroundColor: 'gray',
                  color: 'white',
                  marginLeft: '22px !important',
                  boxShadow: '0px 0px 22px -2px rgba(0,0,0,0.20)'
                }
              }
            }}
          >
            <Link
              to={page.route}
              className={`menu-item-link ${
                isActive(page.route) ? 'active' : ''
              }`}
            >
              <ListItemButton
                className={`list-item-btn ${
                  isActive(page.route)
                    ? '!bg-mediumPurple/80'
                    : '!bg-transparent'
                }`}
              >
                <ListItemIcon sx={{ minWidth: '46px' }}>
                  <page.icon className="icon" />
                </ListItemIcon>
              </ListItemButton>
            </Link>
          </Tooltip>
        ))}
        {navbarList.map((page) => (
          <Link
            key={page.desc}
            to={page.route}
            className="hidden m-0 text-black no-underline sm:block"
          >
            <ListItemButton
              className={`hidden m-0 md:inline no-underline text-black ${
                isActive(page.route) ? '!bg-mediumPurple/80' : '!bg-transparent'
              }`}
            >
              <ListItemIcon sx={{ minWidth: '46px' }}>
                <page.icon className="icon" />
              </ListItemIcon>
              <ListItemText
                disableTypography
                primary={page.desc}
                className="text-[13px] m-0 overflow-x-hidden whitespace-nowrap min-w-[126px]"
              />
            </ListItemButton>
          </Link>
        ))}
        {hasCDA && patientId ? (
          <Tooltip
            title={'C-CDA'}
            placement={'right'}
            componentsProps={{
              tooltip: {
                sx: {
                  backgroundColor: 'gray',
                  color: 'white',
                  marginLeft: '22px !important',
                  boxShadow: '0px 0px 22px -2px rgba(0,0,0,0.20)'
                }
              }
            }}
          >
            <ListItemButton
              className="list-item-btn"
              onClick={() => {
                window.open(`/viewer/${patientId}?type=cda`, '_blank');
              }}
            >
              <ListItemIcon sx={{ minWidth: '46px' }}>
                <ArticleOutlinedIcon className="icon" />
              </ListItemIcon>
              <ListItemText primary={'C-CDA'} className="list-item-text" />
            </ListItemButton>
          </Tooltip>
        ) : null}
      </List>
    </>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <Drawer
        variant="permanent"
        className=""
        sx={{
          zIndex: 3,
          width: { xl: drawerWidthOpen },
          transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
          }),
          '& .MuiDrawer-paper': {
            position: 'absolute',
            paddingTop: '185px',
            justifyContent: 'left',
            overflowX: 'hidden',
            width: {
              xs: drawerWidthClose.small,
              sm: drawerWidthClose.large,
              xl: drawerWidthOpen
            },
            borderRight: '0px',
            boxShadow: theme.shadows[4],
            backgroundColor: 'white',
            transition: theme.transitions.create('width', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen
            })
          }
        }}
      >
        {drawerContent}
      </Drawer>
    </Box>
  );
};

export default Sidebar;
