// @flow

const styles = (theme: Object) => ({
  patientHccWrapper: {
    marginTop: theme.spacing(3)
  },
  center: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  icdAccordionWrapper: {
    '&.Mui-expanded': {
      background: '#E9ECEF',
      background: 'rgba(183, 213, 212, 0.2)',
      boxShadow: '0px 1px 1px rgba(183, 213, 212, 0.8)',
      borderRadius: 4
    },
    '&.Mui-collapsed': {
      background: '#E9ECEF',
      background: 'rgba(183, 213, 212, 0.2) !important',
      boxShadow: '0px 1px 1px rgba(183, 213, 212, 0.8)',
      borderRadius: 4
    },

    background: 'rgba(183, 213, 212, 0.2) !important',
    boxShadow: '0px 1px 1px rgba(183, 213, 212, 0.8) !important',
    borderRadius: 4
  },

  hccAccordionWrapper: {
    '&.Mui-expanded': {
      borderRadius: 4
    },

    '&.MuiPaper-root': {
      boxShadow: 'none'
    },

    '&.MuiButtonBase-root': {
      border: 0
    },

    borderRadius: 4
  },
  hccAccordionHeader: {
    minHeight: 70,
    maxHeight: 70,
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)'
    }
  },
  accordionHeaderContent: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  icdAccordionHeader: {
    minHeight: 60,

    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)'
    }
  },
  icdAccordionHeaderContent: {
    width: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  hccLabel: {
    fontWeight: 800,
    color: '#AC92EC',
    width: '100%',
    marginLeft: 10
  },
  icdCode: {
    fontWeight: 800,
    color: theme.palette.secondary.main,
    width: 70,
    marginLeft: 10
  },
  icdCodeDesc: {
    fontWeight: 600,
    overFlowWrap: 'normal',
    marginLeft: 20,
    marginRight: 20
  },
  verificationStatus: {
    fontWeight: 600,
    '&.known': {
      color: 'grey'
    },
    '&.identified': {
      color: theme.palette.primary.main
    },
    marginRight: 20
  },
  accordionBody: {
    padding: `${theme.spacing(4)} ${theme.spacing(2)}`,
    border: 'none!important',
    display: 'block',
    background: '#FFF'
  },
  bodyHeading: {
    display: 'flex',
    alignItems: 'top',
    justifyContent: 'space-between'
  },
  lastUpdatedWrapper: { width: 300, textAlign: 'right' },
  lastUpdatedDt: {
    fontSize: '0.8rem'
  },
  bodyHeader: {
    fontWeight: 600,
    fontSize: '1.2rem'
  },
  subHeader: {
    fontWeight: 600,
    color: theme.palette.secondary.main,
    marginTop: theme.spacing(1)
  },
  reasonWrapper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    width: '100%',
    justifyContent: 'space-between'
  },
  reason: {
    fontWeight: '700!important',
    marginBottom: theme.spacing(1)
  },
  reasonStr: {
    fontWeight: '800 !important',
    color: theme.palette.secondary.main
  },
  confidenceScore: {
    fontWeight: '700 !important'
  },
  confidenceScoreStr: {
    fontWeight: '800 !important',
    color: theme.palette.secondary.main
  },
  source: { marginBottom: theme.spacing(5) },
  sourcesHeader: {
    fontWeight: '700 !important',
    fontSize: '1.2rem !important'
  },
  sourceHeader: {
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center'
  },
  excerpt: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    fontStyle: 'italic'
  },
  sourceHeaderHeading: {
    textDecoration: 'underline',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center'
  },
  icon: { marginLeft: theme.spacing(1) },
  factValueWrapper: { display: 'flex', alignItems: 'center' },
  factValue: {},
  dateWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1)
  },
  sourceTypeWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  date: {
    fontSize: '1.5rem!important'
  },
  factName: {
    fontSize: '1.2rem',
    marginBottom: theme.spacing(1)
  },
  flexRow: {
    display: 'flex',
    alignItems: 'center'
  },
  sectionTitleHeaderCtr: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  sectionTitleHeader: {
    textTransform: 'uppercase',
    fontWeight: 700,
    fontSize: '2rem',
    color: 'rgba(44, 44, 44, 0.6)',
    fontFamily: 'DM Sans, sans-serif',
    lineHeight: '50px',
    letterSpacing: '-0.03em'
  },
  sourceKey: {
    minWidth: 200
  },
  flex: {
    display: 'flex',
    alignItems: 'center'
  },
  hccCategory: {
    fontWeight: 600,
    marginRight: 50,
    color: theme.palette.secondary.main
  },
  nlpSource: {
    fontWeight: 600,
    marginRight: 50,
    color: 'grey',
    fontSize: '0.9rem',
    borderRadius: '45%'
  },
  hccAccordingDesc: {
    display: 'flex',
    alignItems: 'center'
  },
  sourceCard: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    border: '1px solid rgba(44, 44, 44, 0.2)',
    borderRadius: 4,
    padding: '0.5rem'
  },
  sourcesSection: {
    marginTop: `${theme.spacing(4)} !important`
  },
  annotations: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    width: '100%',
    marginTop: `${theme.spacing(3)} !important`
  },
  annotation: {
    marginRight: 10
  },
  externalLinkBtn: {
    marginRight: '1rem!important',
    color: 'black',
    borderColor: 'rgba(44, 44, 44, 0.2)'
  },

  saveEvidenceBtn: {
    marginRight: '1rem!important',
    color: 'black',
    borderColor: 'green'
  },

  addSourceBtn: {
    marginRight: '1rem!important',
    color: 'black',
    fontSize: '0.85rem',
    marginLeft: '12px',
    borderColor: 'rgba(44, 44, 44, 0.2)'
  },
  actionBtn: {
    marginRight: '1rem!important',
    padding: '12px 16px',
    fontSize: '1rem',
    borderRadius: 4,

    '&.Mui-disabled': {
      background: 'rgba(0, 0, 0, 0.1) !important',
      color: 'grey !important'
    },

    [theme.breakpoints.down('lg')]: {
      fontSize: '0.9rem',
      padding: '10px 16px'
    },

    [theme.breakpoints.down('md')]: {
      fontSize: '0.8rem',
      padding: '8px 16px'
    }
  },
  actionBtnSubmit: {},
  query_provider: {
    background: 'rgba(34, 133, 61, 0.4) !important',
    border: '0.5px solid #22853D',
    color: 'black !important',
    fontWeight: '700 !important',
    textTransform: 'none !important',
    whiteSpace: 'nowrap'
  },
  confirm: {
    background: 'rgba(74, 178, 137, 0.4) !important',
    border: '0.5px solid #4AB289',
    color: 'black !important',
    fontWeight: '700 !important',
    textTransform: 'none !important',
    whiteSpace: 'nowrap'
  },
  back_to_coder: {
    background: 'rgba(172, 146, 236, 0.4) !important',
    border: '0.5px solid #AC92EC',
    color: 'black !important',
    fontWeight: '700 !important',
    textTransform: 'none !important',
    whiteSpace: 'nowrap'
  },
  deny: {
    background: 'rgba(204, 55, 51, 0.4) !important',
    border: '0.5px solid #CC3733',
    color: 'black !important',
    fontWeight: '700 !important',
    textTransform: 'none !important',
    whiteSpace: 'nowrap'
  },
  addInternalNote: {
    background: 'rgba(255, 206, 84, 0.4) !important',
    border: '0.5px solid #FFCE54',
    color: 'black !important',
    fontWeight: '700 !important',
    textTransform: 'none !important',
    whiteSpace: 'nowrap'
  },
  sourceHistoryBtn: {
    cursor: 'pointer',
    display: 'inline-flex',
    alignItems: 'center'
  },
  factValueWrapper: {
    marginTop: `${theme.spacing(2)} !important`,
    marginBottom: `${theme.spacing(2)} !important`
  },
  sourceHistory: {},
  actionModal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    maxWidth: 900,
    background: 'white',
    borderRadius: 10,
    boxShadow: 24,
    padding: '1rem',
    margin: '1rem'
  },
  actionModalHeader: {
    marginBottom: `${theme.spacing(2)} !important`
  },
  actionModalText: {
    marginBottom: `${theme.spacing(2)} !important`,
    '& .MuiInputBase-input': {
      overflow: 'auto'
    }
  },
  internalNotesWrapper: {
    marginBottom: theme.spacing(4)
  },
  internalNoteCard: {
    padding: theme.spacing(2),
    background: 'rgba(255, 206, 84, 0.4)',
    border: '0.5px solid #FFCE54',
    borderRadius: 8,
    marginBottom: theme.spacing(2)
  },
  internalNoteHeader: {
    textTransform: 'uppercase',
    fontWeight: 800,
    fontSize: '0.9rem',
    color: 'rgba(44, 44, 44, 0.4)'
  },
  internalNote: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    whiteSpace: 'pre-wrap'
  },
  internalNoteMetaData: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '0.9rem'
  },
  noteIcon: {
    marginRight: theme.spacing(1)
  },
  dot: {
    marginRight: theme.spacing(1)
  },
  internalNoteAuthor: {
    marginRight: theme.spacing(1)
  },
  cancelBtn: {
    color: 'black'
  },
  hccCategory: {
    color: 'rgba(172, 146, 236, 1)'
  },
  icdCodeDescription: {
    color: 'rgba(51, 122, 94, 1)'
  },
  inputLabel: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    color: 'black'
  },
  dosWrapper: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(1)
  },
  confirmOptions: {
    display: 'flex'
  },
  confirmMetadata: {
    marginBottom: theme.spacing(2),
    fontStyle: 'italic',
    fontSize: '0.95rem'
  },
  moreOptionsBtn: {
    marginLeft: theme.spacing(1)
  }
});

export default styles;
