const HAL_BENCHMARK_PROD = 'HAL Benchmark';
const HAL_BENCHMARK_LOCAL = 'Optimal Vitality Health Innovations';

const getHalBenchmarkOrgName = () => {
  return process.env.REACT_APP_ENV === 'production'
    ? HAL_BENCHMARK_PROD
    : HAL_BENCHMARK_LOCAL;
};

export { HAL_BENCHMARK_PROD, HAL_BENCHMARK_LOCAL, getHalBenchmarkOrgName };
