// @flow

import React from 'react';
import * as R from 'ramda';
import { withStyles } from 'tss-react/mui';
import Header from 'layout/_Header';
import Footer from 'layout/Footer';
import styles from './Styles/PageTemplate.Style';

type Props = {
  classes: Object,
  backgroundColor: String,
  children: Object // menu items injected by HOC
};

const PageTemplate = (props: Props) => {
  const { classes, children, backgroundColor } = props;

  return (
    <div
      className={classes.pageRoot}
      style={{ background: backgroundColor ?? 'inherit' }}
    >
      <Header {...R.omit(['children'], props)} />
      <div className={classes.body}>{children}</div>
      <Footer />
    </div>
  );
};

export default (withStyles(PageTemplate, styles): any);
