"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.exportSection = exports.exportRows = void 0;

var _cheerio = _interopRequireDefault(require("cheerio"));

var exportSection = function exportSection(rawDocument, sectionTitle) {
  if (!rawDocument || !sectionTitle) return;

  var $ = _cheerio.default.load(rawDocument, {
    xml: {
      xmlMode: true
    }
  });

  var $ClinicalDocument = $('ClinicalDocument');
  var $structuredBody = $('structuredBody');
  $structuredBody.find('component').each(function (i, component) {
    var title = $(component).find('title').text();

    if (sectionTitle !== title) {
      $(component).remove();
    }
  });
  $ClinicalDocument.find('component').each(function (i, component) {
    var nonXMLBody = $(component).find('nonXMLBody');

    if (nonXMLBody.length > 0) {
      $(component).remove();
    }
  });
  var exportedSection = $.xml();
  return exportedSection;
};

exports.exportSection = exportSection;

var exportRows = function exportRows(rawDocument, rowsToExport) {
  if (!rawDocument || !rowsToExport) return;
  console.log('[exportRows] rowsToExport: ', rowsToExport);

  var $ = _cheerio.default.load(rawDocument, {
    xml: {
      xmlMode: true
    }
  });

  var $ClinicalDocument = $('ClinicalDocument');
  var $structuredBody = $('structuredBody');
  var linkedAttachmentIds = [];
  $structuredBody.find('component').each(function (i, component) {
    var title = $(component).find('title').text();
    var section = rowsToExport.find(function (el) {
      return el.sectionTitle === title;
    });

    if (section) {
      var tableBody = $(component).find('tbody');
      $(tableBody).find('> tr').each(function (i, row) {
        var rowText = $(row).children().text();
        var hasSelectedRow = false;
        section.rows.forEach(function (selectedRow) {
          var stringArr = Object.keys(selectedRow).map(function (col) {
            if (typeof selectedRow[col] === 'object' && selectedRow[col].length) {
              // let mergedStringArr = [];
              selectedRow[col].map(function (el) {
                // // return underlying evidence rows as mergesstring
                // mergedStringArr.push('Context');
                // mergedStringArr.push('Location');
                // mergedStringArr.push(el.context);
                // mergedStringArr.push(el.location);
                // add linked attachments to array
                var attachmentId = el.ref.split('+')[1];
                var hasAttachment = linkedAttachmentIds.includes(attachmentId);

                if (!hasAttachment) {
                  linkedAttachmentIds.push(attachmentId);
                }
              }); // return mergedStringArr.join('');
            } else {
              return selectedRow[col];
            }
          });
          var joinedString = stringArr.join('');
          var trimmedRowText = rowText.replace(/\s+/g, '');
          var trimmedjoinedString = joinedString.replace(/\s+/g, '');

          if (trimmedRowText.startsWith(trimmedjoinedString)) {
            hasSelectedRow = true;
          }
        });

        if (!hasSelectedRow) {
          var referenceId = $(row).find('[ID]').attr('ID');
          $(component).find('entry').each(function (i, etry) {
            var refValue = $(etry).find('reference[value]').attr('value');

            if (refValue && refValue.includes(referenceId)) {
              $(etry).remove();
            }
          });
          var $row = $(row);
          $row.remove();
        }
      });
    } else {
      $(component).remove();
    }
  });
  $ClinicalDocument.find('component').each(function (i, component) {
    var nonXMLBody = $(component).find('nonXMLBody');
    $(nonXMLBody).find('text').each(function (i, attachment) {
      var attachmentId = $(attachment).attr('ID');

      if (!linkedAttachmentIds.includes(attachmentId)) {
        $(attachment).remove();
      }
    });
  });
  var exportedRows = $.xml();
  return exportedRows;
};

exports.exportRows = exportRows;