// @flow

const styles = (theme: Object) => ({
  innerbox: {
    marginBottom: '10px',
    padding: '10px'
  },
  center: {
    textAlign: 'center'
  },
  left: {
    textAlign: 'left'
  },
  box: {
    display: 'flex',
    flexFlow: 'column nowrap'
  },
  boxRow: {
    flex: '0 0 auto',
    borderStyle: 'dashed',
    borderColor: '#eeeeee',
    cursor: 'pointer'
  },
  uploadIcon: {},
  fileHeader: {
    fontWeight: 800
  },
  fileDescription: {
    fontWeight: 300,
    fontSize: '1rem'
  },
  rowActionBtn: {
    borderRadius: 0,
    '& button': {
      margin: theme.spacing(2)
    }
  },
  link: {
    // color: '#FFFFFF'
    color: '#64b4f6'
  },
  confirmationBox: {
    marginLeft: 20,
    marginBottom: 40,
    backgroundColor: '#EEE',
    padding: theme.spacing(4)
  },
  confirmationBtn: {
    marginTop: theme.spacing(2)
  }
});

export default styles;
