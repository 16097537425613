// @flow

const styles = (theme: Object) => ({
  paperRoot: {
    marginTop: 75,
    width: '100%'
  },
  eventsTable: {
    marginTop: theme.spacing(5)
  },
  loadingCentered: {
    marginTop: theme.spacing(5),
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  filterItem: {
    marginRight: 20
  },
  searchPeriodDatePickerWrapper: {
    display: 'flex',
    width: '100%'
  },
  searchPeriodFilter: {
    minWidth: 250
  },
  resetBtn: {
    marginLeft: 10
  },
  icon: {
    color: theme.palette.secondary.dark,
    cursor: 'pointer',
    fontSize: '1.2rem',
    marginLeft: 5,

    '&.grey': { color: '#C6C6C6' }
  },
  rafBtn: {
    cursor: 'pointer',
    color: theme.palette.secondary.dark,
    textDecoration: 'underline',
    border: 'none',
    background: 'transparent'
  },
  eventsTable: {
    marginTop: theme.spacing(2),
    '.rdt_TableCol_Sortable': {
      div: {
        whiteSpace: 'normal!important'
      }
    }
  }
});

export default styles;
