// @flow

import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';

type MoreOptionsProps = {
  hcc: Object,
  classes: Object,
  openHipaaLog: Function
};

const MoreOptions = (props: MoreOptionsProps) => {
  const { hcc, openHipaaLog, classes } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-label="hcc-more-options-button"
        id={`hcc-more-options-button-${hcc._id}`}
        aria-controls={open ? 'long-menu' : null}
        aria-expanded={open ? 'true' : null}
        aria-haspopup="true"
        onClick={handleClick}
        className={classes.moreOptionsBtn}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id={`hcc-more-options-${hcc._id}`}
        MenuListProps={{
          'aria-labelledby': 'long-button'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: '20ch'
          }
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <MenuItem
          key="show-hipaa-log"
          onClick={(e) => {
            handleClose(e);
            openHipaaLog(e, hcc);
          }}
        >
          HIPAA Log
        </MenuItem>
      </Menu>
    </>
  );
};

export default MoreOptions;
