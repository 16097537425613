// @flow
import React from 'react';
import Typography from '@mui/material/Typography';
import { withStyles } from 'tss-react/mui';
import styles from './InputError.Styles';

type Props = {
  text: string,
  classes: Object
};

const InputError = (props: Props) => {
  const { text, classes } = props;

  return (
    <Typography variant="body1" className={classes.inputError}>
      {text}
    </Typography>
  );
};

export default withStyles(InputError, styles);
