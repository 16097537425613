import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isUserHalOrganization: false,
  isVectorSearchEnabled: false,
  isLoadingVectorResults: false,
  isAddingEvidence: false
};

const halOrganizationSlice = createSlice({
  name: 'halOrganization',
  initialState,
  reducers: {
    setIsAddingEvidence(state, action) {
      state.isAddingEvidence = action.payload;
    },
    setIsUserHalOrganization(state, action) {
      state.isUserHalOrganization = action.payload;
    },
    setVectorSearchEnabled(state, action) {
      console.log('Setting vector search enabled');
      state.isVectorSearchEnabled = action.payload;
    },
    setIsLoadingVectorResults(state, action) {
      console.log('Setting isLoadingVectorResults');
      state.isLoadingVectorResults = action.payload;
    }
  }
});

export const {
  setIsAddingEvidence,
  setIsUserHalOrganization,
  setVectorSearchEnabled,
  setIsLoadingVectorResults
} = halOrganizationSlice.actions;
export default halOrganizationSlice.reducer;
