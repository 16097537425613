"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault").default;

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.Section = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/esm/taggedTemplateLiteral"));

var _styledComponents = _interopRequireWildcard(require("styled-components"));

var _templateObject, _templateObject2;

var GlobalStyle = (0, _styledComponents.createGlobalStyle)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n* {\n    margin: 0;\n    padding: 0;\n    box-sizing: border-box;\n    font-family: 'Lato', sans-serif;\n    letter-spacing: .01rem;\n}\n\nbody {\n    background-color: #F8F8F8;\n    font-family: 'Lato', sans-serif;\n}\n\nbody.modal-open {\n  overflow: hidden;\n}\n\n.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {\n    font-family: 'Railway', sans-serif;\n    font-weight: 500;\n    line-height: 1.2;\n    color: #5e5873;\n}\n\ntable {\n    width: 100%;\n    height: 100%;\n    color: #6e6b7b;\n}\n\n.modal-overlay {\n  position: fixed;\n  top: 0;\n  left: 0;\n  z-index: 9999;\n  width: 100vw;\n  height: 100vh;\n  background-color: #000;\n  opacity: 0.5;\n}\n\n.modal-wrapper {\n  position: fixed;\n  top: 0;\n  left: 0;\n  z-index: 9999;\n  width: 100%;\n  height: 100%;\n  overflow-x: hidden;\n  overflow-y: hidden;\n  outline: 0;\n  padding: .5rem;\n}\n\n.modal {\n  z-index: 9999;\n  background: white;\n  position: relative;\n  overflow:hidden;\n  margin: auto;\n  border-radius: 0.428rem;\n  max-width: 800px;\n  padding: 1rem;\n  height: 100%;\n}\n\n.modal-header {\n  display: flex;\n  justify-content: flex-end;\n}\n\n.modal-close-button {\n  font-size: 1.4rem;\n  font-weight: 700;\n  line-height: 1;\n  color: #000;\n  opacity: 0.3;\n  cursor: pointer;\n  border: none;\n  background: transparent;\n}\n\n.modal-container {\n  height: 100%;\n}\n\n.section-title {\n  padding: 1.5rem .5rem;\n  font-size: 1.2rem;\n  font-weight: 500;\n  border-bottom: 1px solid #ebe9f1;\n  display: grid;\n  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));\n  align-items: center;\n  row-gap: 1rem;\n\n  justify-items: left;\n\n  @media(min-width: 432px) {\n    button {\n      margin-left: auto;\n    }\n  }\n}\n"])));

var Section = _styledComponents.default.div(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\n  margin-bottom: 1rem;\n  background-color: #ffffff;\n  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);\n  border-radius: 0.428rem;\n  width: 100%;\n  overflow: auto;\n"])));

exports.Section = Section;
var _default = GlobalStyle;
exports.default = _default;