// @flow

import React, { useState } from 'react';
import { format, parseISO } from 'date-fns';
import enLocale from 'date-fns/locale/en-US';
import Select from '@mui/material/Select';
import Alert from '@mui/material/Alert';
import dayjs from 'dayjs';
import OutlinedInput from '@mui/material/OutlinedInput';
import ExpandMoreIcon from '@mui/icons-material//ArrowForwardIosSharp';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import FormControl from '@mui/material/FormControl';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { withStyles } from 'tss-react/mui';
import { useDispatch } from 'react-redux';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { WORKFLOW_ACTION_SLUG_SUFFIXES, getButtonClass } from '../PatientHCCs';
import { log } from 'utils/jsUtils';
import MDPBackend from 'services/MDPBackend';
import styles from '../../../Styles/PatientHCCs.Style';
import { showToastMsg } from 'features/toast-message-slice';

const treatmentPlanOptions = [
  'Lab',
  'Follow-up Visit',
  'Imaging',
  'Special Study',
  'Referral',
  'Other (please specify)'
];
const progressionOptions = ['Improving', 'Stable', 'Worsening'];

const getDefaultMatchedSnomedCode = (snomedCodes) => {
  return snomedCodes.find((snomedCode) => snomedCode.defaultMatch);
};

type ActionModalProps = {
  classes: Object,
  data: Object,
  pickOpenEncounterToAcceptICD: Boolean,
  encounters: Array<Object>,
  handleClose: () => void,
  patientHccRefetch: () => void
};

const ActionModal = (props: ActionModalProps) => {
  const {
    classes,
    data,
    pickOpenEncounterToAcceptICD,
    encounters,
    handleClose,
    patientHccRefetch
  } = props;

  const dispatch = useDispatch();

  const { hcc } = data;
  const [loading, setLoading] = useState(false);
  const [dos, setDos] = useState(format(new Date(), 'yyyy-MM-dd'));
  const [treatmentPlan, setTreatmentPlan] = useState('');
  const [progression, setProgression] = useState('');
  const [encounterId, setEncounterId] = useState(
    encounters.length ? encounters[0].encounterId : ''
  );
  const [snomedCode, setSnomedCode] = useState(() => {
    if (!data?.hcc?.snomedCodes?.length) return '';

    if (data.hcc.snomedCodes.length === 1) {
      return data.hcc.snomedCodes[0].code;
    }

    const defaultMatchedSnomedCode = getDefaultMatchedSnomedCode(
      data.hcc.snomedCodes
    );

    return defaultMatchedSnomedCode ? defaultMatchedSnomedCode.code : '';
  });
  const defaultMatchedSnomedCode = getDefaultMatchedSnomedCode(
    data?.hcc?.snomedCodes || []
  );
  const [nonDefaultMatchedSnomedCode, setNonDefaultMatchedSnomedCode] =
    useState(defaultMatchedSnomedCode?.code || '');
  const [
    nonDefaultMatchedSnomedCodesOpen,
    setNonDefaultMatchedSnomedCodesOpen
  ] = useState(false);
  const [internalNotes, setInternalNotes] = useState('');
  const snomedCodeRequired =
    data?.hcc?.snomedCodes && data.hcc.snomedCodes.length > 0;

  const getSnomedCodeDescription = (snomedCode) => {
    const snomedCodes = hcc?.snomedCodes || [];

    for (let i = 0; i < snomedCodes.length; i++) {
      if (snomedCodes[i].code === snomedCode) return snomedCodes[i].description;
    }

    return '';
  };

  const setIcdCode = async () => {
    setLoading(true);

    const payload = {
      hccId: hcc._id,
      slug: data.slug,
      notes: internalNotes,
      encounterId
    };

    let effectiveSnomedCode = null;

    if (snomedCodeRequired) {
      effectiveSnomedCode = nonDefaultMatchedSnomedCodesOpen
        ? nonDefaultMatchedSnomedCode
        : snomedCode;
      payload.snomedCode = effectiveSnomedCode;
      payload.snomedCodeDescription =
        getSnomedCodeDescription(effectiveSnomedCode);
    }

    if (dos) {
      payload.dateOfService = dos;
    }
    if (treatmentPlan) {
      payload.treatmentPlan = treatmentPlan;
    }
    if (progression) {
      payload.progression = progression;
    }

    console.log('payload:: ', payload);

    try {
      if (!internalNotes || (snomedCodeRequired && !effectiveSnomedCode)) {
        throw new Error('Required fields missing');
      }

      const response = await MDPBackend.setIcdCode(payload);

      const parsedResponse = JSON.parse(response.data.body);
      log('[setIcdCode] parsedResponse: ', parsedResponse);

      if (!parsedResponse?.success) {
        throw new Error(parsedResponse);
      }

      dispatch(
        showToastMsg({
          open: true,
          message: 'Code updated successfully',
          level: 'success',
          duration: 5000
        })
      );

      handleClose();
      await patientHccRefetch();
    } catch (error) {
      log('[setIcdCode] error: ', error);

      dispatch(
        showToastMsg({
          open: true,
          message:
            error?.response?.body ?? error.message ?? 'Something went wrong',
          level: 'error',
          duration: 5000
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setInternalNotes(e.target.value);
  };

  const getSnomedInputCodeField = (hcc) => {
    if (
      !snomedCodeRequired ||
      !hcc ||
      !hcc?.snomedCodes ||
      !hcc.snomedCodes.length
    ) {
      return null;
    }

    if (hcc.snomedCodes.length === 1) {
      return (
        <div className="mb-1">
          <div className="hidden">
            <TextField
              type="hidden"
              id="snomedCode"
              value={snomedCode}
              InputProps={{
                readOnly: true
              }}
            />
          </div>
          <Typography variant="body">
            SNOMED: {hcc.snomedCodes[0].code} - {hcc.snomedCodes[0].description}
          </Typography>
        </div>
      );
    }

    if (!defaultMatchedSnomedCode) {
      return (
        <div className="mb-1">
          <FormControl disabled={loading} fullWidth required={true}>
            <InputLabel id="snomedCode-label">
              Find Matching SNOMED Code
            </InputLabel>
            <Select
              required={true}
              label="Find Matching SNOMED Code"
              id="snomedCode"
              value={snomedCode}
              onChange={(e) => setSnomedCode(e.target.value)}
              input={
                <OutlinedInput
                  id="select-multiple-chip"
                  label="Find Matching SNOMED Code"
                />
              }
            >
              {hcc.snomedCodes.map((snomedCode) => (
                <MenuItem key={snomedCode.code} value={snomedCode.code}>
                  {snomedCode.code} - {snomedCode.description}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      );
    }

    const accordionClassName =
      '[&.Mui-expanded]:rounded [&.MuiPaper-root]:shadow-none [&.MuiButtonBase-root]:border-0';
    const accordionSummaryClassName =
      '!p-0 flex-row-reverse [&_.MuiAccordionSummary-expandIconWrapper.Mui-expanded]:rotate-90';

    return (
      <div className="mb-1">
        <Accordion
          className={accordionClassName}
          onChange={(e, expanded) =>
            setNonDefaultMatchedSnomedCodesOpen(expanded)
          }
        >
          <AccordionSummary
            className={accordionSummaryClassName}
            expandIcon={<ExpandMoreIcon fontSize="10" />}
            aria-controls="alternative-icd-snomed-maps-content"
            id={`alternative-icd-snomed-maps-content${defaultMatchedSnomedCode.code}`}
          >
            <div>
              <div className="hidden">
                <TextField
                  type="hidden"
                  id="snomedCode"
                  value={defaultMatchedSnomedCode.code}
                  InputProps={{
                    readOnly: true
                  }}
                />
              </div>
              <Typography variant="body" className="pl-1">
                SNOMED: {defaultMatchedSnomedCode.code} -{' '}
                {defaultMatchedSnomedCode.description}
              </Typography>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <FormControl disabled={loading} fullWidth>
              <InputLabel id="snomedCode-label">
                Select other matching SNOMED
              </InputLabel>
              <Select
                label="Select other matching SNOMED"
                id="snomedCode"
                value={nonDefaultMatchedSnomedCode}
                onChange={(e) => setNonDefaultMatchedSnomedCode(e.target.value)}
                input={
                  <OutlinedInput
                    id="select-multiple-chip"
                    label="Select other matching SNOMED"
                  />
                }
              >
                {hcc.snomedCodes.map((snomedCode) => (
                  <MenuItem key={snomedCode.code} value={snomedCode.code}>
                    {snomedCode.code} - {snomedCode.description}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </AccordionDetails>
        </Accordion>
      </div>
    );
  };

  const getEncounterOptions = () => {
    if (!pickOpenEncounterToAcceptICD) return null;

    if (!encounters.length) {
      return (
        <Alert severity="error" className="mb-1">
          No open encounter found
        </Alert>
      );
    }

    return (
      <div className="mt-2 mb-2">
        <FormControl disabled={loading} fullWidth required={true}>
          <InputLabel id="encounter-label">
            Select Matching Encounter
          </InputLabel>
          <Select
            required={true}
            label="Select Matching Encounter"
            id="encounter"
            value={encounterId}
            onChange={(e) => setEncounterId(e.target.value)}
            input={
              <OutlinedInput
                id="select-multiple-chip-encounter"
                label="Select Matching Encounter"
              />
            }
          >
            {encounters.map((encounter) => (
              <MenuItem
                key={encounter.encounterId}
                value={encounter.encounterId}
              >
                {dayjs(encounter.encounterDate).format('MM-DD-YYYY')} -{' '}
                {encounter.type} - {encounter.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
  };

  return (
    <Modal open={!!data} onClose={handleClose} aria-labelledby="actions-modal">
      <Box className={classes.actionModal}>
        <div className={classes.actionModalHeader}>
          <Typography
            id="actions-modal"
            variant="h2"
            component="h2"
            className={classes.hccCategory}
          >
            HCC {hcc.hccCategory}
            {hcc.hccCategoryDescription
              ? ` (${hcc.hccCategoryDescription})`
              : ''}
          </Typography>
          <Typography id="actions-modal" variant="h2" component="h2">
            <span className={classes.icdCodeDescription}>{hcc.icdCode}</span> -{' '}
            {hcc.icdCodeDescription}
          </Typography>
        </div>
        {data.slug.endsWith(WORKFLOW_ACTION_SLUG_SUFFIXES.CONFIRM) ? (
          <div>
            <div className={classes.dosWrapper}>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={enLocale}
              >
                <DatePicker
                  label="Date of Service (default today)"
                  value={parseISO(dos)}
                  onChange={(value) => setDos(format(value, 'yyyy-MM-dd'))}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      type="outlined"
                      error={false}
                      className={classes.searchPeriodDatePicker}
                    />
                  )}
                  disabled={loading}
                />
              </LocalizationProvider>
            </div>
            <div className={classes.confirmOptions}>
              <FormControl
                disabled={loading}
                fullWidth
                style={{ marginRight: '8px' }}
              >
                <InputLabel variant="outlined" id="treatmentPlan-label">
                  Treatment Plan
                </InputLabel>
                <Select
                  variant="outlined"
                  label="treatmentPlan"
                  id="treatmentPlan"
                  value={treatmentPlan}
                  onChange={(e) => setTreatmentPlan(e.target.value)}
                  fullWidth
                  input={
                    <OutlinedInput
                      id="select-multiple-chip"
                      label="treatmentPlan-label"
                    />
                  }
                >
                  {treatmentPlanOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl disabled={loading} fullWidth>
                <InputLabel variant="outlined" id="progression-label">
                  Progression
                </InputLabel>
                <Select
                  variant="outlined"
                  label="progression"
                  id="progression"
                  value={progression}
                  onChange={(e) => setProgression(e.target.value)}
                  fullWidth
                  input={
                    <OutlinedInput
                      id="select-multiple-chip"
                      label="progression-label"
                    />
                  }
                >
                  {progressionOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div>
              <InputLabel
                className={classes.inputLabel}
                htmlFor="notes"
                required
              >
                Treament Plan Notes
              </InputLabel>
              <TextField
                multiline
                fullWidth
                required
                className={classes.actionModalText}
                value={internalNotes}
                onChange={handleChange}
                minRows={5}
                maxRows={5}
              />
            </div>
            {getSnomedInputCodeField(data?.hcc)}
            {getEncounterOptions()}
          </div>
        ) : (
          <div>
            <InputLabel className={classes.inputLabel} htmlFor="notes" required>
              Additional Notes
            </InputLabel>
            <TextField
              multiline
              fullWidth
              required
              className={classes.actionModalText}
              value={internalNotes}
              onChange={handleChange}
              minRows={11}
              maxRows={11}
            />
          </div>
        )}
        <div>
          {data?.hcc?.snomedCodes && !data.hcc.snomedCodes.length && (
            <Alert severity="error" className="mb-1">
              Warning: Missing SNOMED Code. This ICD cannot be written back to
              your EHR. Please contact us at{' '}
              <a href="mailto:someone@example.com">support@mdportals.com</a>
            </Alert>
          )}
          <Button
            key={data.slug}
            disabled={
              loading || !internalNotes || (snomedCodeRequired && !snomedCode)
            }
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setIcdCode();
            }}
            className={`${classes.actionBtnSubmit} ${
              classes.actionBtn
            } ${getButtonClass(data.slug, classes)}`}
          >
            {loading ? 'Loading...' : data.label}
          </Button>
          <Button
            disabled={loading}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleClose();
            }}
            className={classes.cancelBtn}
          >
            Cancel
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default withStyles(ActionModal, styles);
