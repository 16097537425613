// @flow

import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const boxStyle = {
  position: 'absolute',
  width: '100%',
  maxWidth: 1200,
  padding: '2rem 2rem',
  height: '100%',
  maxHeight: 800,
  backgroundColor: 'white',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  overflow: 'auto',
  outline: 'none',
  borderRadius: '1rem'
};

const RafModal = (props: Props) => {
  const { handleClose, open, rafScores } = props;

  if (!rafScores || !rafScores.length) return <div></div>;

  let totalRafScore = 0.0;
  rafScores.forEach((raf) => {
    totalRafScore += raf.rafScore;
  });

  totalRafScore = totalRafScore.toFixed(3);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box style={boxStyle}>
          <Typography variant="h1" style={{ marginBottom: 20 }}>
            Total RAF: {totalRafScore}
          </Typography>
          {rafScores && rafScores.length ? (
            <Table style={{ marginBottom: 20 }}>
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: 600 }}>ICD Code</TableCell>
                  <TableCell style={{ fontWeight: 600 }} align="right">
                    RAF
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rafScores.map((raf, idx) => (
                  <TableRow key={idx}>
                    <TableCell>{raf.icdCode}</TableCell>
                    <TableCell align="right">{raf.rafScore}</TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell style={{ fontStyle: 'italic' }}>Total</TableCell>
                  <TableCell style={{ fontStyle: 'italic' }} align="right">
                    {totalRafScore}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          ) : null}
        </Box>
      </Modal>
    </div>
  );
};

export default RafModal;
