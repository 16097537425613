// @flow

import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { withStyles } from 'tss-react/mui';
import axios from 'axios';

import AppLoadingSpinner from 'layout/AppLoadingSpinner';
import MDPBackend from 'services/MDPBackend';

import styles from './Styles/FileDrop.Style';
import { setIsLoading } from 'features/loading-slice';
import { showToastMsg } from 'features/toast-message-slice';

type Props = {
  classes: Object
};

const baseStyle = {
  flex: '0 1 auto',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '10% 20%',
  margin: '10px',
  borderWidth: 0,
  borderRadius: 0,
  borderColor: '#eeeeee',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

const Dropzone = (props: Props) => {
  const { classes } = props;

  const dispatch = useDispatch();

  const [fileDropSuccess, setFileDropSuccess] = useState(false);
  const [acceptedFile, setAcceptedFile] = useState('');

  const loadModule = async () => {
    if (!acceptedFile) return;
    dispatch(
      setIsLoading({
        loadingModule: 'fileUpload',
        isLoading: true
      })
    );

    console.log('acceptedFile: ', acceptedFile);

    const fileName = acceptedFile.name;
    const mimeType = acceptedFile.type;

    try {
      const response = await MDPBackend.uploadFile({ fileName, mimeType });

      const parsedResponse = JSON.parse(response.data.body);

      const uploadUrl = parsedResponse.uploadUrl;

      const uploadResponse = await axios.put(uploadUrl, acceptedFile, {
        headers: {
          'Content-Type': acceptedFile.type
        }
      });
      console.log('[uploadDemopgraphics] uploadResponse: ', uploadResponse);

      if (uploadResponse.status !== 200) {
        throw new Error('Failed to upload file: ', uploadResponse);
      } else {
        dispatch(
          setIsLoading({
            loadingModule: 'fileUpload',
            isLoading: false
          })
        );
        setFileDropSuccess(true);
      }
    } catch (error) {
      dispatch(
        showToastMsg({
          open: true,
          message: error.message ?? 'Something went wrong',
          level: 'error',
          duration: 5000
        })
      );
    } finally {
      dispatch(
        setIsLoading({
          loadingModule: '',
          isLoading: false
        })
      );
    }
  };

  useEffect(() => {
    loadModule();
    // MDPBackend.uploadFile(acceptedFile)
    //   .then((response) => {
    //     log('[uploadFile] response: ', response);
    //     dispatch({
    //       type: 'ACTION_SET_IS_LOADING_MODULE',
    //       module: 'fileUpload',
    //       loading: false
    //     });
    //     setFileDropSuccess(true);
    //   })
    //   .catch((err) => {
    //     log('[handleUploadTenantFile] error: ', err);
    //     dispatch({
    //       type: 'ACTION_SET_IS_LOADING_MODULE',
    //       module: 'fileUpload',
    //       loading: false
    //     });
    //     log('[uploadFile] err: ', err);
    //     dispatch({
    //       type: 'ACTION_SET_TOAST_MSG',
    //       snackMessage: {
    //         message: err, // 'Network error. Please contact us if the error persists.',
    //         level: 'error',
    //         duration: 5000
    //       }
    //     });
    // });
  }, [acceptedFile]);

  const onDrop = useCallback((addedFile) => {
    console.log('[handleUploadTenantFile]', addedFile[0].size);

    setAcceptedFile(addedFile[0]);
  });

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    multiple: false,
    onDrop,
    accept:
      /* eslint-disable max-len */
      'text/csv, .csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  return (
    <div>
      {/* // TODO: move this spinner to the header (include this in every header cpt) */}
      <AppLoadingSpinner />
      {fileDropSuccess ? (
        <div className={classes.confirmationBox}>
          <Typography className={classes.fileHeader} variant="h5">
            {'You have successfully uploaded the file!'}
          </Typography>
        </div>
      ) : (
        <div className={classes.box}>
          <div className={classes.boxRow}>
            <div {...getRootProps({ style })}>
              <input {...getInputProps()} />

              <div className={classes.innerbox}>
                <Grid className={classes.center}>
                  <Typography className={classes.fileHeader} variant="h5">
                    {
                      'Click here to upload or drag-and-drop your list of patients.'
                    }
                  </Typography>
                  <Typography className={classes.fileHeader} variant="h6">
                    {'(Please upload a CSV or Excel file.)'}
                  </Typography>
                  <CloudUploadIcon className={classes.uploadIcon} />
                </Grid>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default withStyles(Dropzone, styles);
