// @flow

import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import * as R from 'ramda';
import Tooltip from '@mui/material/Tooltip';
import { IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SearchIcon from '@mui/icons-material/Search';
import { withStyles } from 'tss-react/mui';
import RafDetailsModal from './RafDetailsModal';
import styles from './Styles/PatientSearch.Style';
import { useSelector } from 'react-redux';
import { ForwardRef } from 'components';

type Props = {
  classes: Object,
  loading: Boolean,
  patientData: Array<Object>,
  totalRows: Number,
  page: Number,
  perPage: Number,
  sortBy: Number,
  sortByOrder: Number,
  setPerPage: () => void,
  setPage: () => void,
  setSortBy: () => void,
  setSortByOrder: () => void
};

const PatientTable = (props: Props): React.Node => {
  const {
    classes,
    loading,
    patientData,
    totalRows,
    page,
    perPage,
    sortBy,
    sortByOrder,
    setPerPage,
    setPage,
    setSortBy,
    setSortByOrder
  } = props;

  const [rafModalData, setRafModalData] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedPatientId, setSelectedPatientId] = useState(null);
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const currentOrganization = useSelector((state) => state.currentOrganization);

  const showRafDetails = (row) => {
    setRafModalData(row.rafScoreDeltaCalculated);
  };

  const handleMenuClick = (event, patientId) => {
    setAnchorEl(event.currentTarget);
    setSelectedPatientId(patientId);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedPatientId(null);
  };

  if (!patientData || !patientData.length) {
    return (
      <div className={classes.loadingCentered}>
        <div>No Patients found</div>
      </div>
    );
  }

  const columns = [
    ...Object.keys(
      R.omit(['icdCodesDeltaArr', '_id', 'cdaFile'], patientData[0])
    ).map((col) => {
      switch (col) {
        case 'id':
          return {
            id: col,
            name: 'MDP Patient ID',
            selector: (row) => row[col],
            sortable: true,
            wrap: true,
            cell: (row) => <div>{row.id}</div>
          };
        case 'orgSourcePatientId':
          return {
            id: col,
            name: 'Source Patient ID',
            selector: (row) => row[col],
            sortable: true,
            wrap: true,
            cell: (row) => <div>{row.orgSourcePatientId}</div>
          };
        case 'patientFirstName':
          return {
            id: col,
            name: 'First Name',
            selector: (row) => row[col],
            sortable: true,
            wrap: true
          };
        case 'patientLastName':
          return {
            id: col,
            name: 'Last Name',
            selector: (row) => row[col],
            sortable: true,
            wrap: true
          };
        case 'patientDob':
          return {
            id: col,
            name: 'Date of Birth',
            selector: (row) => row[col],
            sortable: true,
            wrap: true
          };
        case 'patientCity':
          return {
            id: col,
            name: 'City',
            selector: (row) => row[col],
            sortable: true,
            wrap: true
          };
        case 'patientState':
          return {
            id: col,
            name: 'State',
            selector: (row) => row[col],
            sortable: true,
            wrap: true
          };
        case 'patientZip':
          return {
            id: col,
            name: 'Zip code',
            selector: (row) => row[col],
            sortable: true,
            wrap: true
          };
        case 'providerName':
          return {
            id: col,
            name: 'Provider Group',
            selector: (row) => row[col],
            sortable: true,
            wrap: true
          };
        case 'totalChartFilesFound':
          return {
            id: col,
            name: '# Chart Files',
            selector: (row) => row[col],
            sortable: true,
            wrap: true,
            omit: user?.role === 'payer',
            // eslint-disable-next-line react/display-name
            cell: (row) => {
              return (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ width: 35 }}>{row.totalChartFilesFound}</div>
                  {row.totalChartFilesFound ? (
                    <Tooltip title="Browse chart files">
                      <Link to={`/patient/${row.id}/files`}>
                        <SearchIcon className={classes.icon} />
                      </Link>
                    </Tooltip>
                  ) : null}
                </div>
              );
            }
          };
        case 'rafScoreDeltaCalculated':
          return {
            id: col,
            name: 'Total RAF Delta',
            selector: (row) => row[col],
            sortable: true,
            wrap: true,
            cell: (row) => {
              return (
                <Tooltip title={'RAF Details'}>
                  <button
                    onClick={() => showRafDetails(row)}
                    className={classes.rafBtn}
                  >
                    {row.rafScoreDeltaCalculated.rafScore}
                  </button>
                </Tooltip>
              );
            }
          };
        case 'newIcdCodesCount':
          return {
            id: col,
            name: 'New ICD Codes Count',
            selector: (row) => row[col],
            sortable: true,
            wrap: true,
            cell: (row) => {
              return (
                <div style={{ maxHeight: 70, overflowY: 'auto' }}>
                  {row.newIcdCodesCount}
                </div>
              );
            }
          };
        case 'knownIcdCodes':
          return {
            id: col,
            name: 'Known ICD Codes',
            selector: (row) => row[col],
            sortable: true,
            wrap: true,
            cell: (row) => {
              return (
                <div style={{ maxHeight: 70, overflowY: 'auto' }}>
                  {row.knownIcdCodes}
                </div>
              );
            }
          };
        case 'icdCodesDelta':
          return {
            id: col,
            name: 'Newly Identified ICD Codes',
            selector: (row) => row[col],
            sortable: true,
            wrap: true,
            cell: (row) => {
              return (
                <div style={{ maxHeight: 70, overflowY: 'auto' }}>
                  {row.icdCodesDelta}
                </div>
              );
            }
          };
        case 'icdCodesQueried':
          return {
            id: col,
            name: 'Queried ICD Codes',
            selector: (row) => row[col],
            sortable: true,
            wrap: true,
            cell: (row) => {
              return (
                <div style={{ maxHeight: 70, overflowY: 'auto' }}>
                  {row.icdCodesQueried}
                </div>
              );
            }
          };
        case 'icdCodesConfirmed':
          return {
            id: col,
            name: 'Confirmed ICD Codes',
            selector: (row) => row[col],
            sortable: true,
            wrap: true,
            cell: (row) => {
              return (
                <div style={{ maxHeight: 70, overflowY: 'auto' }}>
                  {row.icdCodesConfirmed}
                </div>
              );
            }
          };
        case 'cdaFile':
          return {
            id: col,
            name: 'C-CDA',
            selector: (row) => row[col],
            sortable: true,
            wrap: true,
            cell: (row) => (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                {row.cdaFile && (
                  <Tooltip title="View CDA online">
                    <div
                      target="_blank"
                      rel="noreferrer"
                      onClick={() => window.open(row.cdaFile)}
                    >
                      <FontAwesomeIcon
                        icon={solid('file-medical')}
                        className={classes.icon}
                      />
                    </div>
                  </Tooltip>
                )}
              </div>
            )
          };
        case 'compendium':
          return {
            id: col,
            name: 'Compendium',
            selector: (row) => row[col],
            sortable: true,
            wrap: true,
            // eslint-disable-next-line react/display-name
            cell: (row) => (
              <>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  {row.compendium && (
                    <Tooltip title="View Compendium">
                      <Link to={`/patient/${row._id}`}>
                        <ForwardRef>
                          <FontAwesomeIcon
                            icon={solid('file-invoice')}
                            className={classes.icon}
                          />
                        </ForwardRef>
                      </Link>
                    </Tooltip>
                  )}
                </div>
              </>
            )
          };
        default:
          return {
            id: col,
            name: col,
            selector: (row) => row[col],
            sortable: true,
            wrap: true
          };
      }
    }),
    {
      id: 'actions',
      name: 'Actions',
      sortable: false,
      wrap: true,
      cell: (row) => {
        return (
          <>
            <Tooltip title="Actions">
              <IconButton
                onClick={(e) => handleMenuClick(e, row.id)}
                className={classes.icon}
              >
                <MoreVertIcon />
              </IconButton>
            </Tooltip>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl) && selectedPatientId === row.id}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={handleMenuClose}>
                <Link
                  to="/queries/new"
                  className="no-underline"
                  state={{ patient: row }}
                >
                  Query this patient
                </Link>
              </MenuItem>
              <MenuItem onClick={handleMenuClose}>
                <Link to={`/patient/${row._id}`} className="no-underline">
                  HCC Summary
                </Link>
              </MenuItem>
              {currentOrganization?.config?.enableCareGaps && (
                <MenuItem onClick={handleMenuClose}>
                  <Link
                    to={`/patient/${row._id}/care-gaps`}
                    className="no-underline"
                  >
                    Care Gaps
                  </Link>
                </MenuItem>
              )}
              <MenuItem onClick={handleMenuClose}>
                <Link to={`/patient/${row._id}/files`} className="no-underline">
                  Charts List
                </Link>
              </MenuItem>
            </Menu>
          </>
        );
      }
    }
  ];

  const onChangeRowsPerPage = (newPerPage) => {
    setPerPage(newPerPage);
  };

  const onChangePage = (page) => {
    setPage(page);
  };

  const onSort = (column, sortDirection) => {
    if (column.id) setSortBy(column.id);
    setSortByOrder(sortDirection);
  };

  return (
    <>
      <DataTable
        columns={columns}
        data={patientData}
        paginationRowsPerPageOptions={[10, 20, 50]}
        paginationDefaultPage={page}
        paginationPerPage={perPage}
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onChangePage={onChangePage}
        sortServer
        onSort={onSort}
        defaultSortAsc={sortByOrder === 'asc'}
        defaultSortFieldId={sortBy}
        progressPending={loading}
        noHeader
        pagination
        paginationServer
        persistTableHead
        responsive
        highlightOnHover
        className={classes.patientTable}
      />
      {rafModalData && (
        <RafDetailsModal
          data={rafModalData}
          handleClose={() => setRafModalData(null)}
        />
      )}
    </>
  );
};

export default withStyles(PatientTable, styles);
