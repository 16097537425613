// @flow

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import formatDistance from 'date-fns/formatDistance';
import MDPBackend from 'services/MDPBackend';
import {
  Typography,
  Card,
  CardContent,
  Grid,
  CircularProgress
} from '@mui/material';
import styles from './Styles/PatientStats.Style';
import { showToastMsg } from 'features/toast-message-slice';

const getHaveChartFilesPercentage = (data) => {
  if (!data || !data.totalPatients) return '-';

  return (
    ((data.patientsWithChartFiles * 100) / data.totalPatients).toFixed(2) + '%'
  );
};

const getHaveCompendiumPercentage = (data) => {
  if (!data || !data.totalPatients) return '-';

  return (
    ((data.patientsWithCompendiums * 100) / data.totalPatients).toFixed(2) + '%'
  );
};

const getFoundRAFDeltaPercentage = (data) => {
  if (!data || !data.totalPatients) return '-';

  return (
    (
      (data.patientsWithRafScoreDeltaCalculated * 100) /
      data.totalPatients
    ).toFixed(2) + '%'
  );
};

const getAvgPotentialRafUplift = (data) => {
  if (!data || !data.patientsWithRafScoreDeltaCalculated) return '-';

  return (
    data.totalRafScoreDeltaCalculated / data.patientsWithRafScoreDeltaCalculated
  ).toFixed(3);
};

const getNewICDCodesFound = (data) => {
  if (!data || !data.totalPatients) return '-';

  return (
    ((data.patientsWithNewIcdCodes * 100) / data.totalPatients).toFixed(2) + '%'
  );
};

type Props = {
  classes: Object,
  searchValue: String,
  states: Array,
  city: String,
  zip: String,
  provider: String
};

type StatsCardProps = {
  title: String,
  value: String,
  subValue: String,
  loading: Boolean
};

const PatientStats = (props: Props): React.Node => {
  const { classes, searchValue, states, city, zip, provider } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const { user } = useSelector((state) => state.user);
  const currentOrganization = useSelector((state) => state.currentOrganization);

  useEffect(() => {
    if (!currentOrganization) return;

    if (
      !user?.restrictPatientAccessByClinic &&
      searchValue === '' &&
      !states.length &&
      city === '' &&
      zip === '' &&
      provider === ''
    ) {
      handleRenderGlobalStats();
    } else {
      handleRenderPatientStats();
    }
  }, [currentOrganization]);

  const handleRenderGlobalStats = () => {
    setLoading(false);
    setData(currentOrganization.patientStats);
  };

  const handleRenderPatientStats = async () => {
    setLoading(true);

    try {
      const searchString = new URLSearchParams({
        q: searchValue,
        state: states,
        city: city,
        zip: zip,
        provider: provider
      });
      const res = await MDPBackend.getPatientStats(searchString);
      const parsedResponse = JSON.parse(res.data.body);
      delete parsedResponse._id;
      delete parsedResponse.success;
      setData(parsedResponse);
    } catch (error) {
      dispatch(
        showToastMsg({
          open: true,
          message:
            error?.response?.body ?? error.message ?? 'Something went wrong',
          level: 'error',
          duration: 5000
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const StatsCard = (props: StatsCardProps) => {
    const { title, value, subValue, loading } = props;

    return (
      <Grid item xs={12} sm={6} md={2}>
        <Card className={classes.cardWrapper}>
          <CardContent className={classes.cardContent}>
            <Typography variant="h5" component="h2" align="center">
              {loading ? (
                <CircularProgress size={30} />
              ) : value && value !== '0.00%' ? (
                value
              ) : (
                '-'
              )}
            </Typography>
            <Typography variant="body2" component="p" align="center">
              {title} <br />
              {subValue ? `(${subValue})` : null}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    );
  };

  return (
    <div className="mt-3 mb-3">
      <Grid container className={classes.root} spacing={2}>
        <StatsCard
          title="Total Active Patients"
          value={data?.totalPatients}
          loading={loading}
        />
        <StatsCard
          title="Have Chart Files"
          value={getHaveChartFilesPercentage(data)}
          subValue={data?.patientsWithChartFiles}
          loading={loading}
        />
        <StatsCard
          title="Have Compendium"
          value={getHaveCompendiumPercentage(data)}
          subValue={data?.patientsWithCompendiums}
          loading={loading}
        />
         <StatsCard
          title="New ICD Codes Found"
          value={getNewICDCodesFound(data)}
          subValue={data?.patientsWithNewIcdCodes}
          loading={loading}
        />
        <StatsCard
          title="Found RAF Delta"
          value={getFoundRAFDeltaPercentage(data)}
          subValue={data?.patientsWithRafScoreDeltaCalculated}
          loading={loading}
        />
        <StatsCard
          title="Avg. Potential RAF Uplift"
          value={getAvgPotentialRafUplift(data)}
          loading={loading}
        />
      </Grid>
      {data?.lastCalculatedDt && (
        <Typography
          variant="body2"
          color="gray"
          align="center"
          noWrap={true}
          sx={{ fontSize: '1rem', mt: 1 }}
        >
          Last Refreshed:{' '}
          {formatDistance(new Date(), new Date(data.lastCalculatedDt), {
            includeSeconds: true
          })}{' '}
          ago
        </Typography>
      )}
    </div>
  );
};

export default withStyles(PatientStats, styles);
