// @flow

const styles = (theme: Object) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 120,
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1)
    }
  },
  avgRafUplift: {
    fontSize: '1.1rem'
  },
  cardWrapper: {
    width: '100%',
    maxWidth: 200,
    height: '100%',
    marginRight: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#fdfdfd'
  }
});

export default styles;
