/* eslint-disable react/display-name */
// @flow

import React from 'react';

type Props = {
  children: Object
};
const ForwardRef = React.forwardRef((props: Props, ref) => (
  <div {...props} ref={ref}>
    {props.children}
  </div>
));

export default ForwardRef;
