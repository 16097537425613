"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ButtonWrapper = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/esm/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _templateObject;

var ButtonWrapper = _styledComponents.default.button(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  cursor: ", ";\n  box-shadow: none;\n\n  border-color: #7367f0;\n  background-color: #7367f0;\n  color: #ffffff;\n  border: 1px solid #7367f0;\n  padding: 0.7rem 1rem;\n  border-radius: 0.358rem;\n  text-align: ", ";\n  margin: 0;\n  font-weight: 500;\n  font-size: 1rem;\n  line-height: 1;\n  user-select: none;\n  outline: none;\n  width: ", ";\n  transition: all 0.5s ease;\n\n  opacity: ", ";\n  pointer-events: ", ";\n\n  &:hover:not(.outline, .grey) {\n    box-shadow: 0 8px 25px -8px #7367f0;\n  }\n\n  svg {\n    width: 1rem;\n    height: 1rem;\n    margin-left: 0.5rem;\n  }\n\n  &.outline {\n    background-color: transparent;\n    color: #7367f0;\n  }\n\n  &.grey {\n    background-color: transparent;\n    border: 1px solid #82868b;\n    color: #82868b;\n\n    &:hover {\n      background-color: rgba(130, 134, 139, 0.04);\n    }\n  }\n\n  &.flex {\n    display: flex;\n    align-items: center;\n    justify-content: left;\n  }\n\n  &.green {\n    border: 1px solid #28c76f;\n    color: #28c76f;\n\n    &:hover {\n      background-color: rgba(40, 199, 111, 0.08);\n    }\n  }\n\n  &.lightblue {\n    border: 1px solid #00cfe8;\n    color: #00cfe8;\n\n    &:hover {\n      background-color: rgba(0, 207, 232, 0.08);\n    }\n  }\n\n  &.small {\n    font-size: 0.85rem;\n    padding: 0.486rem 1rem;\n\n    svg {\n      width: 0.85rem;\n      height: 0.85rem;\n      margin-left: 0.5rem;\n    }\n  }\n\n  &.sub {\n    border: none;\n    margin: 15px 10px;\n    padding: 0;\n  }\n"])), function (_ref) {
  var disabled = _ref.disabled;
  return disabled ? 'default' : 'pointer';
}, function (_ref2) {
  var align = _ref2.align;
  return align ? align : 'center';
}, function (_ref3) {
  var fullWidth = _ref3.fullWidth;
  return fullWidth ? '100%' : 'auto';
}, function (_ref4) {
  var disabled = _ref4.disabled;
  return disabled ? '0.5' : '1';
}, function (_ref5) {
  var disabled = _ref5.disabled;
  return disabled ? 'none' : 'auto';
});

exports.ButtonWrapper = ButtonWrapper;