// @flow

import React, { useEffect, useState } from 'react';
import { Auth, Amplify } from 'aws-amplify';
import { Helmet } from 'react-helmet-async';
import queryString from 'query-string';
import Paper from '@mui/material/Paper';
import { withStyles } from 'tss-react/mui';
import PageTemplate from 'layout/PageTemplate';
import { AMPLIFY_CONFIG } from 'settings/aws-config';
import { useDispatch } from 'react-redux';
import { log } from 'utils/jsUtils';
import styles from './Styles/Activate.Styles';
import { showToastMsg } from 'features/toast-message-slice';

Amplify.configure(AMPLIFY_CONFIG);

type Props = {
  classes: Object,
  location: Object
};

const Activate = (props: Props): React.Node => {
  const { classes } = props;

  const [isActivated, setIsActivated] = useState(false);

  const parsed = queryString.parse(location.search);

  const { data, code } = parsed;
  let parsedData;
  if (typeof data !== 'undefined') {
    const parsedDataStr = window.atob(data);
    parsedData = JSON.parse(parsedDataStr);
  }
  const { email } =
    typeof parsedData !== 'undefined' ? parsedData : { email: '' };

  const dispatch = useDispatch();

  const confirmSignUp = async () => {
    if (email !== '' && code !== '') {
      try {
        await Auth.confirmSignUp(email, code);
        setIsActivated(true);
      } catch (e) {
        log('[Activate] Error: ', e);
        const confirmedErrorMessage =
          'User cannot be confirmed. Current status is CONFIRMED';
        let errorMessage = e.message;

        if (errorMessage === confirmedErrorMessage) {
          errorMessage = 'Your email is already confirmed';
          setIsActivated(true);
        }

        dispatch(
          showToastMsg({
            open: true,
            message: errorMessage,
            level: 'error',
            duration: 5000
          })
        );
      }
    }
  };

  useEffect(() => {
    confirmSignUp();
  }, [email, code]);

  return (
    <PageTemplate simple>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Activate Account</title>
      </Helmet>
      <Paper className={classes.container}>
        {isActivated ? (
          <div>
            Your account is activated! <br />
            <a href="/login">Click here to login</a>
          </div>
        ) : (
          <div>
            We were unable to activate your account.
            <br />
            <br />
            Please contact{' '}
            <a href="mailto:support@mdportals.com">support@mdportals.com</a> for
            assistance.
          </div>
        )}
      </Paper>
    </PageTemplate>
  );
};

export default withStyles(Activate, styles);
