// @flow

const styles = (theme: Object) => ({
  root: {
    justifyContent: 'center',
    marginTop: 0
  },
  avgRafUplift: {
    fontSize: '1.1rem'
  },
  cardWrapper: {
    width: '100%',
    height: '100%',
    marginRight: theme.spacing(1),
    display: 'block',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#fdfdfd',
    '& h2': {
      fontSize: '2rem',
      fontWeight: 600
    }
  },
  cardContent: {
    height: '100%'
  }
});

export default styles;
