/* eslint-disable max-len */
// @flow

import React from 'react';
import Typography from '@mui/material/Typography';
import { withStyles } from 'tss-react/mui';

import PageTemplate from 'layout/PageTemplate';
import styles from './Styles/publicPages.Style';

type Props = {
  classes: Object
};

const HIPAA = (props: Props) => {
  const { classes } = props;

  return (
    <PageTemplate>
      <div className={classes.body}>
        <Typography variant="h2" paragraph align="center">
          Privacy Policy
        </Typography>
        <Typography variant="h4" paragraph>
          Effective: April 19, 2021
        </Typography>
        <Typography variant="p" paragraph>
          Updated: November 3, 2023
        </Typography>
        <Typography variant="h1" paragraph>
          Introduction
        </Typography>
        <Typography variant="body1" paragraph>
          Our mission is to drive better and more affordable healthcare through
          innovative software and information solutions for providers throughout
          the care continuum, their patients, and health researchers. Our
          solutions include our electronic health record, patient health records
          software, medical organizations health records software, our care
          coordination, and clinical data transmission services. MDPortals, Inc
          is dedicated to protecting the privacy of the users of any of our
          products or services and of the individuals whose health information
          is stored or transmitted by our system. The MDPortals, Inc Clinical
          Network is developed for the highest levels of security and
          performance.
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          This Privacy Policy (this “Policy”) applies to the software and
          information services we offer through our website located at
          www.mdportals.com, our cloud-based electronic health record and
          medical organizations management solutions, and web-enabled emails and
          in-app notifications sent as part of, in connection with, or relating
          to such software and information services (collectively, our
          “Services”). This Policy does not apply to any other services.
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          Maintaining your trust is important to us, and we strongly encourage
          you to read this Policy in full.
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          The purpose of this Policy is to describe how we and our partners
          collect, use, and share information about you. This Policy may
          incidentally describe how our Services gather and use information
          about other individuals or information about you that may be submitted
          by another user. This Privacy Policy, however, only applies to how we
          and our partners collect, use, and share information about you with
          respect to the Services covered by our MDPortals, Inc Terms of Use or
          our Healthcare Provider User Agreement (“User Agreement”), and not to
          any other service we may offer to any other individual or customer.
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          Some of our users – such as healthcare providers – are subject to laws
          and regulations governing the use and disclosure of health information
          they create or receive, including the Health Insurance Portability and
          Accountability Act of 1996, as amended from time to time, together
          with the regulations adopted thereunder (“HIPAA”). When we store,
          process or transmit “individually identifiable health information” (as
          defined by HIPAA) on behalf of a healthcare provider who has entered
          into a Healthcare Provider User Agreement, we do so as its “business
          associate” (as also defined by HIPAA). Under this agreement, we cannot
          use or disclose individually identifiable health information in a way
          that the provider itself may not. We are also required to, among other
          things, apply reasonable and appropriate measures to safeguard the
          confidentiality, integrity, and availability of the individually
          identifiable health information we store and process on behalf of such
          providers. For the purpose of this Policy, the term “healthcare
          provider” means any user who is a “health care provider” (as defined
          by HIPAA) or any user who is a member of such health care provider’s
          “workforce” (as also defined by HIPAA). For additional information
          regarding our business associate obligations, please see Sections
          4.1.8 and 9 of our Healthcare Provider User Agreement.
        </Typography>
        <Typography variant="h5" paragraph>
          Revisions, Changes, and Updates
        </Typography>
        <Typography variant="body1" paragraph>
          MDPortals, Inc may revise and update the Privacy Policy at any time,
          without notice to you. We encourage you to periodically reread this
          Privacy Policy, to see if there have been any changes to our policies
          that may affect you.
        </Typography>
        <Typography variant="h5" paragraph>
          Users’ Personal Information
        </Typography>
        <Typography variant="body1" paragraph>
          We understand the importance of protecting you from the unauthorized
          use of information you provide in the course of doing business with
          us. Except as disclosed in this policy or our User Agreement, we will
          not give away, sell or otherwise disclose any information that
          personally identifies you. We may, however, obtain, use and disclose
          personal information about you for the purpose of verifying your
          identity and practice credentials, and we may provide personal
          information to payers with which you contract to provide health care
          services. We may also disclose personal information about you if we
          are compelled to do so by law or by valid legal process. We may
          disclose personal information if we have your express permission to do
          so, or the disclosure is to our service providers to assist us in
          providing our services, or for user verification purposes. We may also
          use IP addresses to analyze trends, administer the site and gather
          broad demographic information for aggregate use. IP addresses are not
          linked to personally identifiable information. We have the right to
          remove personal identifiers from your personal information so that it
          cannot reasonably be used to identify you.
        </Typography>
        <Typography variant="h5" paragraph>
          Protected Health Information
        </Typography>
        <Typography variant="body1" paragraph>
          Our User Agreement sets forth our obligations as our users’ business
          associate under the administrative simplification provisions of the
          Health Insurance Portability and Accountability Act of 1996 and its
          privacy and security regulations (HIPAA), and under the privacy and
          security provisions of the Technology for Economic and Clinical Health
          Act of 2009 (the HITECH Act). We will comply with the business
          associate provisions of our User Agreement. We will also comply with
          provisions of the HIPAA Security Rule that apply to business
          associates under the HITECH Act, and the privacy and security
          provisions of the HITECH Act that are applicable to business
          associates.
        </Typography>
        <Typography variant="h5" paragraph>
          Permitted Uses of Protected Health Information
        </Typography>
        <Typography variant="body1" paragraph>
          The User Agreement sets forth the ways in which we may use or disclose
          protected health information we receive from you or create or receive
          on your behalf. Among other permitted uses, we may:
        </Typography>
        <Typography variant="body1" paragraph>
          Allow access to your health information to you and your workforce for
          whatever purpose you require it.
        </Typography>
        <Typography variant="body1" paragraph>
          Allow access to your health information by other health care providers
          and their business associates for treatment. This includes disclosing
          health information for related applications that you select, such as
          clinical laboratory reporting, billing, and e-prescribing, as well as
          to other health care providers to whom you agree to give access to
          your protected health information for treatment. You agree to be bound
          by the terms of any licensing and other agreements relating to such
          third-party products and services.
        </Typography>
        <Typography variant="body1" paragraph>
          Allow access to health information to your patients through our
          patient portal. You control which of your patients have access to
          their health records, and what kind of information they can see.
        </Typography>
        <Typography variant="body1" paragraph>
          De-identify your health information. In the User Agreement, you
          transfer and assign to us all right, title and interest in and to all
          de-identified information that we make from your health information,
          and you agree that we may use, disclose, market, license and sell such
          de-Identified information for any purpose without restriction, and
          that you have no interest in such de-identified information, or in the
          proceeds of any sale, license, or other commercialization thereof. We
          will, however, maintain the confidentiality and security of the
          original health information as required by the User Agreement.
        </Typography>
        <Typography variant="h5" paragraph>
          Apple Health, Healthkit
        </Typography>
        <Typography variant="body1" paragraph>
          MDPortals, Inc will not use or disclose to third parties data gathered
          in the health, fitness, and medical research context—including from
          the HealthKit API, Motion and Fitness, or health-related human subject
          research—for advertising or other use-based data mining purposes other
          than improving health management, or for the purpose of health
          research, and then only with permission.
        </Typography>
        <Typography variant="body1" paragraph>
          MDPortals, Inc will not write false or inaccurate data into HealthKit
          or any other medical research or health management apps, and may not
          store personal health information in iCloud.
        </Typography>
        <Typography variant="h5" paragraph>
          Your Obligations
        </Typography>
        <Typography variant="body1" paragraph>
          You agree that you will use other persons’ information available on or
          through this site (whether or not protected health information)
          strictly in accordance with applicable laws and regulations, and you
          will ensure that others under your control who have access to such
          information also comply with applicable laws and regulations. You are
          solely responsible for obtaining and maintaining all patient consents
          and authorizations necessary for your use of the site and the systems
          to which it provides access.
        </Typography>
        <Typography variant="h5" paragraph>
          Account Access
        </Typography>
        <Typography variant="body1" paragraph>
          To access your account, you must provide the identifier we provided
          you. With this information, we can verify your identity and permit you
          to view data in our system. We log and audit system use in order to
          ensure that users are using the system appropriately. If we have
          questions about your use of the system, we may contact you. We may
          also disclose your identity to others to assist in the investigation
          of suspected misuse of our systems, and otherwise to ensure the proper
          operation of our systems.
        </Typography>
        <Typography variant="h5" paragraph>
          Surveys, Questionnaires, and Polls
        </Typography>
        <Typography variant="body1" paragraph>
          MDPortals, Inc may ask you to participate in use surveys,
          questionnaires, or polls, to facilitate feedback and input from our
          users. When you respond to surveys, questionnaires or polls related to
          our site, this information is collected only as anonymous, aggregated
          information and is used for statistical purposes.
        </Typography>
        <Typography variant="h5" paragraph>
          Internet Cookies
        </Typography>
        <Typography variant="body1" paragraph>
          Each time you visit one of the Sites, MDPortals, Inc collects the
          limited information that your browser makes available whenever you
          visit any website. Additionally, MDPortals, Inc may place internet
          “cookies” on the computer hard drives of visitors to this website.
          Information we obtain from cookies helps us to tailor our site to be
          more helpful and efficient for our visitors. The cookie consists of a
          unique identifier that does not contain information about you or your
          health history. We use two types of cookies, “session” cookies and
          “persistent” cookies.
        </Typography>
        <Typography variant="body1" paragraph>
          A session cookie is temporary and expires after you end a session and
          close your web browser. We use session cookies to help customize your
          experience on our site and maintain your signed-on status as you
          navigate through the features.
        </Typography>
        <Typography variant="body1" paragraph>
          Persistent cookies remain on your hard drive after you’ve exited from
          our site until you erase them or they expire. Persistent cookies will
          not contain any personal information about you. Marketing Products and
          Services We will NOT place advertisements of any type on the interface
          to our services and we will NOT use or disclose to any third party any
          information that identifies you to enable the third party to market
          products or services to you directly.
        </Typography>
        <Typography variant="h5" paragraph>
          Security
        </Typography>
        <Typography variant="body1" paragraph>
          Portions of this site require a valid user name, e-mail address, code
          or password (or a combination of the foregoing) to access and use
          services or materials on the site. You are solely responsible for (1)
          maintaining the strict confidentiality of any user name, e-mail
          address, code or password (collectively, “User IDs”) assigned to you,
          (2) not allowing another person to use your User IDs to access the
          site, (3) any damages or losses that may be incurred or suffered as a
          result of your failure to maintain the strict confidentiality of your
          User IDs, and (4) promptly informing MDPortals, Inc in writing of any
          need to deactivate a User ID due to potential or actual security
          breaches. MDPortals, Inc is not liable for any harm related to the
          theft of your IDs, your disclosure of your User IDs, or your
          authorization to allow another person or entity to access and use the
          site using your User IDs. You agree to immediately notify MDPortals,
          Inc in writing of any unauthorized use of any of your User IDs.
        </Typography>
        <Typography variant="h5" paragraph>
          User Forums
        </Typography>
        <Typography variant="body1" paragraph>
          We may offer forums for the exchange of information among our users.
          You agree to assume all responsibility for your use of such forums. In
          particular, you understand that we do not assure the accuracy,
          reliability, confidentiality or security of information made available
          through the use of our forums. You agree not to disclose individually
          identifiable health information through our forums.
        </Typography>
        <Typography variant="h5" paragraph>
          Clinical Support
        </Typography>
        <Typography variant="body1" paragraph>
          We may provide information to assist you in clinical decision-making.
          This may include information and reminders concerning lab tests, drug
          interactions, allergies, dosages, as well as general health-care
          related information and resources. We may also provide forums for our
          users to exchange information. The information and materials available
          through this site are for informational and educational purposes only
          and are not intended to constitute professional advice, diagnosis or
          treatment, or to substitute for your professional judgment.
          Information may be placed on this site by MDPortals, Inc and by third
          parties beyond the control of MDPortals, Inc. MDPortals, Inc is not
          responsible for the accuracy or completeness of information available
          from or through this site. You assume full risk and responsibility for
          the use of information you obtain from or through this site, and you
          agree that MDPortals, Inc. is not responsible or liable for any claim,
          loss, or liability arising from the use of the information. MDPortals,
          Inc does not recommend or endorse any provider of health care or
          health-related products, items or services, and the appearance of
          materials on this site relating to any such products, items or
          services is not an endorsement or recommendation of them.
        </Typography>
        <Typography variant="h5" paragraph>
          Additional Material
        </Typography>
        <Typography variant="body1" paragraph>
          You may provide content or material to this site by participating in
          forums, discussion groups and the like, or by using the site to create
          custom templates and the like. You agree that any information,
          material or work product you provide to this site, other than
          protected health information that identifies a patient or personal
          information that identifies you, is the exclusive property of
          MDPortals, Inc., and by submitting such content or material you assign
          to MDPortals, Inc., all intellectual property rights in such content
          or material. Furthermore, you agree that MDPortals, Inc may use,
          disclose, market, license and sell such material or content and that
          you have no interest in the information, or in the proceeds of any
          sale, license, or other commercialization thereof. You warrant and
          agree that any material you provide will not infringe on the
          intellectual property or other rights of others, and will not be
          otherwise unlawful, infringing, threatening, libelous, defamatory,
          obscene, pornographic, or in violation of any law. You should bear in
          mind that any information you post in a forum or discussion group is
          available to the public, and may result in your receiving
          communications from others outside this site. You are responsible for
          safeguarding the privacy of your and your patients’ personal
          information when you participate in forums, discussion groups and the
          like.
        </Typography>
        <Typography variant="h5" paragraph>
          Links to Other Materials
        </Typography>
        <Typography variant="body1" paragraph>
          This site may provide links to sites operated by third parties.
          MDPortals, Inc. has no control over the content of such linked sites
          and is not responsible for it, or for the effect of your accessing a
          site through a link on our site. You should assume that any
          information that does not bear the MDPortals, Inc logo is operated by
          a third party, and you should read the site’s privacy notice before
          using it.
        </Typography>
        <Typography variant="h5" paragraph>
          Applicable Law
        </Typography>
        <Typography variant="body1" paragraph>
          Any claim relating to the use of this site or the systems or
          information to which it gives access shall be governed by the internal
          substantive laws of the State of California.
        </Typography>
        <Typography variant="h5" paragraph>
          Other Matters
        </Typography>
        <Typography variant="body1" paragraph>
          We have no intention of accepting any information from individuals
          under the age of 18. If you are not yet 18 years of age, please leave
          this site immediately. Parents are urged to monitor and supervise
          their children’s on-line activity. We reserve the right to make
          changes to our privacy policy at any time without prior notice and to
          apply the changes to information received by us prior to the effective
          date of the change. Please be sure to check this page periodically for
          updates to this policy.
        </Typography>
        <Typography variant="h5" paragraph>
          Questions, Complaints, and Contacts
        </Typography>
        <Typography variant="body1" paragraph>
          If you have any questions about this Privacy Statement, our policies
          and practice, your rights under this statement, send an email to
          support@mdportals.com.
        </Typography>
      </div>
    </PageTemplate>
  );
};

export default withStyles(HIPAA, styles);
