// @flow

const styles = () => ({
  body: {
    maxWidth: 800,
    margin: 'auto',
    marginTop: 100
  }
});

export default styles;
