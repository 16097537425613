// @flow

import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

type Props = {
  open: Boolean,
  onClose: Function,
  requestApiKeys: Function
};

const RequestApiKeyDialog = ({ open, onClose, requestApiKeys }: Props) => {
  const [text, setText] = useState('');

  const handleTextChange = (e) => {
    setText(e.target.value);
  };

  const handleConfirm = () => {
    requestApiKeys({ text });
    setText('');
  };

  const handleClose = () => {
    setText('');
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{ style: { minHeight: '250px' } }}
    >
      <DialogTitle>Request API Keys</DialogTitle>
      <DialogContent>
        <DialogContentText>
          What is your expected use case for this API?
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          name="text"
          onChange={handleTextChange}
          value={text}
          label="Use case"
          className="!min-w-[300px] md:!min-w-[500px]"
          multiline
          rows={2}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button disabled={text.length < 1} onClick={handleConfirm}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RequestApiKeyDialog;
