// @flow

import React, { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import CircularProgress from '@mui/material/CircularProgress';
import MDPBackend from 'services/MDPBackend';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import ModalBox from 'components/ModalBox';
import dayjs from 'dayjs';
import { capitalizeFirstLetter } from 'utils/jsUtils';
import { useDispatch } from 'react-redux';
import { showToastMsg } from 'features/toast-message-slice';

type HipaaLogModalProps = {
  open: Boolean,
  hcc: Object,
  handleClose: () => void
};

const USER_TYPE_USER = 'user';
const USER_TYPE_BOT = 'bot';
const USER_TYPE_API = 'API';

const getFullName = (row) => {
  return row.user?.firstName + ' ' + row.user?.lastName;
};

const getTitle = (hcc) => {
  return hcc ? hcc.icdCode + ' - ' + hcc.icdCodeDescription : '';
};

const HipaaLogModal = (props: HipaaLogModalProps) => {
  const { open, hcc, handleClose } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [hccChangelogCompendiums, setHccChangelogCompendiums] = useState([]);

  const getChangelogCompendiumsByHccId = async () => {
    setLoading(true);

    try {
      const response = await MDPBackend.getChangelogCompendiumsByHccId(hcc._id);
      const parsedResponse = JSON.parse(response.data.body);
      console.log(parsedResponse?.data);
      setHccChangelogCompendiums(parsedResponse?.data);
    } catch (error) {
      dispatch(
        showToastMsg({
          open: true,
          message: 'Something went wrong',
          level: 'error',
          duration: 3000
        })
      );
      console.log('[getSourceHistory] error: ', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open) getChangelogCompendiumsByHccId();
  }, [hcc]);

  const hipaaLogColumns = [
    {
      headerName: 'Date',
      field: 'createdDt',
      flex: 1,
      maxWidth: 210,
      valueGetter: (params) => {
        return params.value
          ? dayjs(params.value).format('MM-DD-YYYY hh:mmA')
          : '';
      }
    },
    {
      headerName: 'User',
      field: 'user',
      flex: 1,
      maxWidth: 270,
      valueGetter: (params) => {
        switch (params.row.userType) {
          case USER_TYPE_USER:
            return getFullName(params.row);
          case USER_TYPE_BOT:
            return USER_TYPE_BOT;
          case USER_TYPE_API:
            return USER_TYPE_API;
          default:
            return 'N/A';
        }
      }
    },
    {
      headerName: 'Description',
      field: 'description',
      flex: 1,
      valueGetter: (params) => {
        const fullName = getFullName(params.row);
        let description = params.row.description;

        if (description.startsWith(fullName)) {
          description = description.replace(fullName, '');

          if (description[0] === ' ') {
            description = description.replace(' ', '');
          }

          description = capitalizeFirstLetter(description);
        }

        return description;
      }
    }
  ];

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalBox
          closeButton={
            <Button
              className="!absolute right-2 top-2 !text-red-600"
              onClick={handleClose}
            >
              <CloseIcon />
            </Button>
          }
        >
          {loading ? (
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <div>
              <h3>{getTitle(hcc)}</h3>
              {!hccChangelogCompendiums || !hccChangelogCompendiums.length ? (
                <div>No HIPAA Logs available</div>
              ) : (
                <DataGrid
                  columns={hipaaLogColumns}
                  rows={hccChangelogCompendiums}
                  autoHeight
                  rowThreshold={0}
                  getRowId={(row) => row._id}
                  slots={{ toolbar: GridToolbar }}
                  paginationDefaultPage={1}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 50
                      }
                    }
                  }}
                  pageSizeOptions={[10, 20, 30, 50, 100, 200]}
                  expandableRows={true}
                  expandOnRowClicked={true}
                  noHeader
                  pagination
                />
              )}
            </div>
          )}
        </ModalBox>
      </Modal>
    </div>
  );
};

export default HipaaLogModal;
