import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import parse from 'html-react-parser';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import SendIcon from '@mui/icons-material/Send';
import ModalBox from './ModalBox';
import { useAddUserClinicConsentMutation, useLazyGetUserQuery } from 'api/api';
import { useDispatch } from 'react-redux';
import { setUser } from 'features/user-slice';
import { showToastMsg } from 'features/toast-message-slice';

const Consent = () => {
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [getUser] = useLazyGetUserQuery();
  const [consentField, setConsentField] = useState({
    fullName: '',
    accept: false
  });
  const [addUserClinicConsent, { isLoading }] =
    useAddUserClinicConsentMutation();
  const [shouldDisplayConsent, setShouldDisplayConsent] = useState(false);
  const [documentViewedDate, setDocumentViewedDate] = useState();
  const [consentGivenDate, setConsentGivenDate] = useState();

  const unConsentedClinicId = useMemo(
    () =>
      user?.clinics?.filter(
        (clinicId) => !user?.consentedClinics?.includes(clinicId)
      )[0],
    [user]
  );

  const handleChange = (event) => {
    setConsentField((field) => ({
      ...field,
      [event.target.name]: event.target.value
    }));
  };

  const handleCheck = (event) => {
    setConsentField((field) => ({
      ...field,
      [event.target.name]: event.target.checked
    }));

    if (event.target.checked) {
      setConsentGivenDate(new Date());
    }
  };

  const handleGetUser = async () => {
    try {
      const { data } = await getUser();
      dispatch(setUser(data));
    } catch (error) {}
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await addUserClinicConsent({
        body: {
          clinicId: unConsentedClinicId,
          userId: user._id,
          fullName: consentField.fullName,
          userAgent: navigator.userAgent,
          documentViewedDate,
          consentGivenDate,
          signatureAppliedDate: new Date()
        }
      });
      setShouldDisplayConsent(false);
      setConsentField({});
      dispatch(
        showToastMsg({
          open: true,
          message: 'Consent updated',
          level: 'success',
          duration: 3000
        })
      );
      handleGetUser();
    } catch (error) {
      dispatch(
        showToastMsg({
          open: true,
          message: error?.message ?? 'Consent failed',
          level: 'error',
          duration: 3000
        })
      );
    }
  };

  useEffect(() => {
    if (user && user?.consents) {
      setShouldDisplayConsent(true);
      setDocumentViewedDate(new Date());
    } else {
      setShouldDisplayConsent(false);
    }
  }, [user]);

  return (
    <Modal
      open={shouldDisplayConsent}
      onClose={() => {}}
      aria-labelledby="pdf-viewer-modal"
      aria-describedby="pdf-viewer-modal"
      sx={{
        '& .MuiBackdrop-root': {
          backgroundColor: 'rgba(0, 0, 0, 0.9)'
        }
      }}
    >
      <ModalBox className="!max-h-[800px]">
        <Container maxWidth="md">
          <Typography className="!my-2" variant="h1" gutterBottom>
            {user?.consents?.[0]?.consent?.subject}
          </Typography>
          <Typography paragraph>
            {parse(
              user && user.consents ? user?.consents?.[0]?.consent?.body : ''
            )}
          </Typography>

          <Box
            component="form"
            sx={{ mt: 3 }}
            onSubmit={handleSubmit}
            className="flex justify-end"
          >
            <Box className="!w-1/2">
              <FormControlLabel
                required
                checked={consentField.accept}
                control={<Checkbox onChange={handleCheck} name="accept" />}
                label="Accept"
              />
              <TextField
                fullWidth
                variant="outlined"
                margin="normal"
                id="provider-name"
                label="Name"
                name="fullName"
                onChange={handleChange}
                value={consentField.fullName}
              />

              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={
                  !consentField.fullName || !consentField.accept || isLoading
                }
                className=""
                startIcon={
                  isLoading ? <CircularProgress size={20} /> : <SendIcon />
                }
              >
                Submit
              </Button>
            </Box>
          </Box>
        </Container>
      </ModalBox>
    </Modal>
  );
};

export default Consent;
