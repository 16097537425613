"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/esm/slicedToArray"));

var _react = _interopRequireDefault(require("react"));

var useWindowSize = function useWindowSize() {
  var isClient = typeof window === 'object';

  var getSize = _react.default.useCallback(function () {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined
    };
  }, [isClient]);

  var _React$useState = _react.default.useState(getSize),
      _React$useState2 = (0, _slicedToArray2.default)(_React$useState, 2),
      size = _React$useState2[0],
      setSize = _React$useState2[1];

  _react.default.useEffect(function () {
    if (!isClient) {
      return false;
    }

    var onHandleResize = function onHandleResize() {
      setSize(getSize);
    };

    window.addEventListener('resize', onHandleResize);
    return function () {
      return window.removeEventListener('resize', onHandleResize);
    };
  }, [getSize, isClient]);

  return size;
};

var _default = useWindowSize;
exports.default = _default;