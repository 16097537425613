// @flow

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { ForwardRef } from 'components';
import MDPBackend from 'services/MDPBackend';
import { showToastMsg } from 'features/toast-message-slice';

type InternalNotesProps = {
  notes: Array,
  loading: Boolean,
  setLoading: Function,
  getIcdSourcesAndNotes: Function,
  classes: Object
};

const InternalNotes = (props: InternalNotesProps) => {
  const dispatch = useDispatch();
  const { notes, getIcdSourcesAndNotes, loading, setLoading, classes } = props;
  const [promptNoteDelete, setPromptNoteDelete] = useState(false);
  const [noteToDelete, setNoteToDelete] = useState('');

  const handleDeleteNote = (noteId) => {
    setNoteToDelete(noteId);
    setPromptNoteDelete(true);
  };

  const handleClosePromptDeleteNote = () => {
    setNoteToDelete('');
    setPromptNoteDelete(false);
  };

  const deleteIcdNote = async () => {
    setLoading(true);
    setPromptNoteDelete(false);
    try {
      const response = await MDPBackend.deleteIcdNote(noteToDelete);
      const parsedResponse = JSON.parse(response.data.body);

      console.log('[deleteIcdNote] parsedResponse: ', parsedResponse);
      if (parsedResponse) {
        dispatch(
          showToastMsg({
            open: true,
            message: 'Note deleted successfully.',
            level: 'info',
            duration: 5000
          })
        );
        getIcdSourcesAndNotes();
      }
    } catch (error) {
      console.log('[deleteIcdNote] error: ', error);
    } finally {
      setNoteToDelete('');
    }
  };

  return (
    <>
      {loading ? (
        <CircularProgress style={{ marginTop: 20 }} size={25} />
      ) : (
        <div className={classes.internalNotesWrapper}>
          {notes.map((note, i) => (
            <div className={classes.internalNoteCard} key={`${note.note}${i}`}>
              <div
                className={classes.internalNoteHeader}
              >{`${note.title}${i}`}</div>
              <div className={classes.internalNote}>{note.note}</div>
              {note.confirmMetaData ? (
                <div className={classes.confirmMetadata}>
                  {note.confirmMetaData}
                </div>
              ) : null}
              <div className={classes.internalNoteMetaData}>
                <div className={classes.noteIcon}>
                  <ForwardRef>
                    <FontAwesomeIcon
                      style={{ color: 'black', height: '15px' }}
                      icon={regular('comment-alt')}
                    />
                  </ForwardRef>
                </div>

                <div className={classes.internalNoteAuthor}>
                  {note.userName}
                </div>
                <div className={classes.dot}>•</div>
                <div className={classes.internalNoteDate}>{note.date}</div>
                {note.actions.canDelete && (
                  <>
                    <div className="mx-2">•</div>
                    <Button
                      className="!min-w-[24px] !p-0"
                      onClick={() => handleDeleteNote(note.id)}
                    >
                      <DeleteIcon color="error" />
                    </Button>
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      )}

      <Dialog
        open={promptNoteDelete}
        onClose={handleClosePromptDeleteNote}
        aria-labelledby="archive-organization"
        aria-describedby="prompt-archive-organization"
      >
        <DialogContent>
          <DialogContentText id="prompt-archive-organization">
            Are you sure you want to delete this note?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePromptDeleteNote}>No</Button>
          <Button onClick={deleteIcdNote} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default InternalNotes;
