import {
  SignupAAHCM,
  Signup,
  Login,
  ResetPassword,
  ActivateAccount,
  UploadDemMain,
  PatientSearch,
  ApiKeys,
  Privacy,
  Hipaa,
  TermsOfUse,
  ViewerAdapter,
  ApiDocs,
  HccCompendium,
  Queries,
  NewQuery,
  PatientPage,
  Events
} from 'containers';

import {
  SIGNUP,
  SIGNUP_AAHCM,
  LOGIN,
  VIEWER,
  RESET_PASSWORD,
  UPLOAD_DEMOGRAPHICS,
  API_KEYS,
  PATIENT_SEARCH,
  TERMS_OF_USE,
  PRIVACY_POLICY,
  HIPAA_COMPLIANCE,
  ACTIVATE_ACCOUNT,
  API_DOCS,
  HCC_COMPENDIUM,
  QUERIES,
  NEW_QUERY,
  PATIENT_PAGE,
  EVENTS
} from 'settings/constants';

const routes = [
  {
    path: SIGNUP,
    component: Signup,
    exact: true,
    private: false
  },
  {
    path: SIGNUP_AAHCM,
    component: SignupAAHCM,
    exact: true,
    private: false
  },
  {
    path: LOGIN,
    component: Login,
    exact: true,
    private: false
  },
  {
    path: VIEWER,
    component: ViewerAdapter,
    exact: true,
    private: true
  },
  {
    path: RESET_PASSWORD,
    component: ResetPassword,
    exact: true,
    private: false
  },
  {
    path: UPLOAD_DEMOGRAPHICS,
    component: UploadDemMain,
    exact: true,
    private: true
  },
  {
    path: API_KEYS,
    component: ApiKeys,
    exact: true,
    private: true
  },
  {
    path: PATIENT_SEARCH,
    component: PatientSearch,
    exact: true,
    private: true
  },
  {
    path: TERMS_OF_USE,
    component: TermsOfUse,
    exact: true,
    private: false
  },
  {
    path: PRIVACY_POLICY,
    component: Privacy,
    exact: true,
    private: false
  },
  {
    path: HIPAA_COMPLIANCE,
    component: Hipaa,
    exact: true,
    private: false
  },
  {
    path: ACTIVATE_ACCOUNT,
    component: ActivateAccount,
    exact: true,
    private: false
  },
  {
    path: API_DOCS,
    component: ApiDocs,
    exact: true,
    private: true
  },
  {
    path: HCC_COMPENDIUM,
    component: HccCompendium,
    exact: true,
    private: true
  },
  {
    path: QUERIES,
    component: Queries,
    exact: true,
    private: true
  },
  {
    path: NEW_QUERY,
    component: NewQuery,
    exact: true,
    private: true
  },
  {
    path: PATIENT_PAGE,
    component: PatientPage,
    exact: false,
    private: true
  },
  {
    path: EVENTS,
    component: Events,
    exact: true,
    private: true
  }
];

export default routes;
