// @flow

import React, { useState, useEffect } from 'react';
import * as R from 'ramda';
import { useNavigate } from 'react-router-dom';
import { Auth, Amplify } from 'aws-amplify';
import { useForm, Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { withStyles } from 'tss-react/mui';

import { AutoComplete, InputError } from 'components';
import PageTemplate from 'layout/PageTemplate';
import MDPBackend from 'services/MDPBackend';
import { useDispatch, useSelector } from 'react-redux';
import { AMPLIFY_CONFIG } from 'settings/aws-config';
import { UPLOAD_DEMOGRAPHICS } from 'settings/constants';
import { log, emailRegex } from 'utils/jsUtils';

import styles from '../Styles/Signup.Styles';

import { setLogin, setUserConfirmed } from 'features/login-slice';
import { showToastMsg } from 'features/toast-message-slice';

Amplify.configure(AMPLIFY_CONFIG);

type Props = {
  classes: Object,
  location: Object
};

const Signup = (props: Props): React.Node => {
  const { classes } = props;
  const {
    register,
    watch,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm();
  const [signupEmail, setSignupEmail] = useState('');
  const [showConfirmSignup, setShowConfirmSignup] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { shouldBeLoggedIn, userConfirmed } = useSelector(
    (state) => state.login
  );

  const checkIfLoggedIn = async () => {
    if (shouldBeLoggedIn && signupEmail && !userConfirmed) {
      // load the email from localStorage
      setSignupEmail(window.localStorage.getItem('mdp-email'));
      setShowConfirmSignup(true);
    }

    const currentSession = await Auth.currentSession();
    if (currentSession) {
      navigate(UPLOAD_DEMOGRAPHICS);
    }
  };

  useEffect(() => {
    checkIfLoggedIn();
  }, []);

  const signUp = async (data) => {
    if (!data) return;

    setLoading(true);

    const { organization, email, password } = data;

    try {
      MDPBackend.registerNewUser(R.omit(['password', 'passwordRetyped'], data))
        .then(async (response) => {
          const { data: backendData } = response;
          window.localStorage.setItem('shouldBeLoggedIn', true);

          dispatch(
            showToastMsg({
              open: true,
              message: backendData.message,
              level: backendData.success ? 'success' : 'error',
              duration: 5000
            })
          );

          // Cognito signup
          const res = await Auth.signUp({
            username: email,
            password: password,
            attributes: {
              email: email,
              'custom:organization': organization
            }
          });

          const { userConfirmed } = res;
          // Persist shouldBeLoggedIn state in local storage
          // TODO: rename local storage variable to mdp-uconf
          window.localStorage.setItem('userConfirmed', userConfirmed);
          setSignupEmail(email);
          window.localStorage.setItem('mdp-email', email);

          // Update the store with shouldBeLoggedIn State
          dispatch(setLogin());
          dispatch(setUserConfirmed(userConfirmed));

          if (!userConfirmed) {
            setShowConfirmSignup(true);
          }

          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          log('[registerNewUser] error: ', error);
          if (typeof error.response !== 'undefined') {
            log('error confirming sign up', error.response.data);
            dispatch(
              showToastMsg({
                open: true,
                message: error.response.data,
                level: 'error',
                duration: 5000
              })
            );
          } else if (typeof error === 'string') {
            dispatch(
              showToastMsg({
                open: true,
                message: error.message,
                level: 'error',
                duration: 5000
              })
            );
          } else {
            dispatch(
              showToastMsg({
                open: true,
                message: 'Network error. Please try again.',
                level: 'error',
                duration: 5000
              })
            );
          }
        });
    } catch (error) {
      log('error signing up:', error);
      setLoading(false);
    }
  };

  return (
    <PageTemplate simple>
      <div className={classes.container}>
        {!showConfirmSignup ? (
          <form
            key="signup"
            className={classes.formCtr}
            noValidate
            onSubmit={handleSubmit(signUp)}
          >
            <Typography variant="h1">AAHCM Members Signup</Typography>
            <Typography variant="body1" className={classes.formHint}>
              Please fill out the form and register in order to upload Patient
              Demographics Data
            </Typography>
            <Typography variant="body1" className={classes.formSubtitle}>
              AAHCM Reference
            </Typography>
            <Controller
              name="code"
              defaultValue=""
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="AAHCM code"
                  variant="outlined"
                  className={classes.txtField}
                />
              )}
            />
            {errors.code && <InputError text="Code is required" />}
            <Controller
              name="aahcmMember"
              defaultValue=""
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Name of AAHCM member"
                  variant="outlined"
                  className={classes.txtField}
                />
              )}
            />
            {errors.aahcmMember && (
              <InputError text="Name of AAHCM Member is required" />
            )}
            <Typography variant="body1" className={classes.formSubtitle}>
              Provider Contact
            </Typography>
            <Controller
              name="organization"
              defaultValue=""
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Organization name"
                  variant="outlined"
                  className={classes.txtField}
                />
              )}
            />
            {errors.organization && (
              <InputError text="Organization name is required" />
            )}
            <Controller
              name="firstName"
              defaultValue=""
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="First name"
                  variant="outlined"
                  className={classes.txtField}
                />
              )}
            />
            {errors.firstName && <InputError text="First name is required" />}
            <Controller
              name="lastName"
              defaultValue=""
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Last name"
                  variant="outlined"
                  className={classes.txtField}
                />
              )}
            />
            {errors.lastName && <InputError text="Last name is required" />}
            <Controller
              name="directEmail"
              defaultValue=""
              control={control}
              rules={{
                required: true,
                pattern: {
                  value: emailRegex,
                  message: 'Please enter a valid HISP Direct Mail address'
                }
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="HISP Direct Mail"
                  variant="outlined"
                  className={classes.txtField}
                />
              )}
            />
            {errors.directEmail && (
              <InputError
                text={
                  errors.directEmail.message
                    ? errors.directEmail.message
                    : 'Direct Email Address is required'
                }
              />
            )}
            <Controller
              name="phone"
              defaultValue=""
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Phone"
                  type="number"
                  variant="outlined"
                  className={classes.txtField}
                />
              )}
            />
            {errors.phone && <InputError text="Phone is required" />}
            <AutoComplete control={control} className={classes.txtField} />
            {errors.address && (
              <InputError text="Street, suite, city, state, zip is required" />
            )}
            <Controller
              name="numberOfPatients"
              defaultValue=""
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Number of patients for pilot"
                  variant="outlined"
                  type="number"
                  className={classes.txtField}
                />
              )}
            />
            {errors.numberOfPatients && (
              <InputError text="Number of patients for pilot is required" />
            )}

            <Typography variant="body1" className={classes.formSubtitle}>
              Login credentials
            </Typography>
            <Controller
              name="email"
              defaultValue=""
              control={control}
              rules={{
                required: true,
                pattern: {
                  value: emailRegex,
                  message: 'Please enter a valid email address'
                }
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Email"
                  variant="outlined"
                  className={classes.txtField}
                />
              )}
            />
            {errors.email && (
              <InputError
                text={
                  errors.email.message
                    ? errors.email.message
                    : 'Email address is required'
                }
              />
            )}
            <Controller
              name="password"
              defaultValue=""
              control={control}
              rules={{
                required: true,
                minLength: {
                  value: 8,
                  message: 'Password must have at least 8 characters'
                }
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="New password"
                  variant="outlined"
                  type="password"
                  className={classes.txtField}
                />
              )}
              {...register('password')}
            />
            {errors.password && (
              <InputError
                text={
                  errors.password.message
                    ? errors.password.message
                    : 'Password is required'
                }
              />
            )}
            <Controller
              name="passwordRetyped"
              defaultValue=""
              control={control}
              rules={{
                required: true,
                minLength: {
                  value: 8,
                  message: 'Password must have at least 8 characters'
                }
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Retype password"
                  variant="outlined"
                  type="password"
                  className={classes.txtField}
                />
              )}
              {...register('passwordRetyped', {
                validate: (value) => {
                  return value === watch('password') || 'Passwords must match';
                }
              })}
            />
            {errors.passwordRetyped && (
              <InputError
                text={
                  errors.passwordRetyped.message
                    ? errors.passwordRetyped.message
                    : 'Password is required'
                }
              />
            )}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.submitBtn}
              disabled={loading}
            >
              {loading ? 'Loading...' : 'Register'}
            </Button>
            <Typography
              align="center"
              variant="body1"
              className={classes.formFooter}
            >
              Already registered? <a href="/login">Click here to login</a>
            </Typography>
          </form>
        ) : (
          <Paper className={classes.activateCtr}>
            <Typography variant="h1">
              {/*  eslint-disable-next-line quotes */}
              {"Let's activate your account"}
            </Typography>
            <Typography variant="body1" className={classes.formSubtitle}>
              Please check your email (check spam also) and click the link
              included to activate your account.
            </Typography>
          </Paper>
        )}
      </div>
    </PageTemplate>
  );
};

export default withStyles(Signup, styles);
