// @flow

const styles = () => ({
  messageCentered: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
});

export default styles;
