/* eslint-disable max-len */
// @flow

import React from 'react';
import Typography from '@mui/material/Typography';
import { withStyles } from 'tss-react/mui';

import PageTemplate from 'layout/PageTemplate';
import styles from './Styles/publicPages.Style';

type Props = {
  classes: Object
};

const HIPAA = (props: Props) => {
  const { classes } = props;

  return (
    <PageTemplate>
      <div className={classes.body}>
        <Typography variant="h2" align="center">
          HIPAA Compliance
        </Typography>
        <Typography variant="h1">Statement of HIPAA Compliance</Typography>
        <Typography variant="body1" paragraph>
          THIS STATEMENT OF HIPAA COMPLIANCE (“Compliance Statement”) is dated
          4.19.2021 by MDPortals, Inc. (“Company”). ‍{' '}
        </Typography>
        <Typography variant="body1" paragraph>
          The Company is committed to protecting the privacy of Protected Health
          Information in accordance with HIPAA. The HIPAA regulations applicable
          to Company include the Standards for the Privacy of Individually
          Identifiable Health Information (the “Privacy Standards”), the
          Security Standards for the Protection of Electronic Protected Health
          Information (the “Security Standards”) and the requirements for Breach
          Notification for Unsecured Protected Health Information (the “Breach
          Notification Standards”). All of these regulations establish
          requirements with respect to Company’s use and disclosure of Protected
          Health Information.
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          HIPAA is not the only law governing Company with respect to patient
          privacy. Company’s policy is also to comply with state laws and other
          federal laws governing patient privacy, to the extent those laws are
          not preempted by HIPAA and to the extent applicable to Company.
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          Company has adopted this HIPAA Compliance Program (the “Program”),
          consisting of approximately thirty one (31) policies and procedures
          (collectively, the “Privacy Policies”) and an additional and separate
          forty two (42) policies and procedures relative to security of EPHI
          (collectively, the “Security Policies”), to assure its compliance with
          the Privacy Standards, the Security Standards and the Breach
          Notification Standards and applicable state laws governing privacy, to
          the extent such state laws are not preempted by HIPAA. Recognizing
          that conducting the Program is an evolving process, Company may, from
          time to time, implement other policies and procedures, and may modify
          existing policies and procedures, to reflect its commitment to patient
          privacy and compliance with HIPAA.
        </Typography>
        <Typography variant="body1" paragraph>
          The Program is a detailed and specific set of policies and procedures
          with which all Company personnel who use, disclose or access Protected
          Health Information must comply. The Program, and other information
          pertaining to Company’s protection of patient privacy, may be subject
          to inspection by the Secretary of HHS for the purpose of monitoring
          compliance with HIPAA.
        </Typography>
        ‍ ‍
        <Typography variant="body1" paragraph>
          Our Workforce members are trained to comply with the Program, will
          immediately report any potential violation of the Program to the
          Privacy Officer, and assist other Workforce members and authorized
          outside personnel to investigate any alleged violations. Questions
          about this program should be directed to support@mdportals.com.
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          IN WITNESS WHEREOF, the Company has executed this Compliance Statement
          on the date first set forth above.
        </Typography>
        ‍
        <Typography variant="body1" paragraph>
          MDPortals, Inc.
        </Typography>
      </div>
    </PageTemplate>
  );
};

export default withStyles(HIPAA, styles);
