// @flow

import React, { useState, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import enLocale from 'date-fns/locale/en-US';
import { format, parseISO, isValid } from 'date-fns';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import MDPBackend from 'services/MDPBackend';
import ChartFilesTable from './ChartFilesTable';
import { showToastMsg } from 'features/toast-message-slice';

type Props = {
  patientId: String
};

const ChartFiles = (props: Props) => {
  const { patientId } = props;
  /* eslint-disable-next-line no-unused-vars */
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const [files, setFiles] = useState(null);
  const [hasFiles, setHasFiles] = useState(false);

  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);

  const [searchParams, setSearchParams] = useSearchParams();

  const params = useMemo(
    () =>
      Array.from(searchParams).reduce(
        (acc, [key, value]) => ({ ...acc, [key]: value }),
        {}
      ),
    [searchParams]
  );

  const handleFilter = (params) => {
    setSearchParams((prev) => {
      Object.keys(params).forEach((key) => {
        prev.set(key, params[key]);
      });
      return prev;
    });
  };

  const documentTypes = useMemo(
    () =>
      files && files.length
        ? [
            ...new Set(
              files
                .filter((file) => file.documentType)
                .map((file) => file.documentType)
            )
          ]
        : [],
    [hasFiles]
  );
  const documentTitles = useMemo(
    () =>
      files && files.length
        ? [
            ...new Set(
              files
                .filter((file) => file.documentTitle)
                .map((file) => file.documentTitle)
            )
          ]
        : [],
    [hasFiles]
  );
  const encounterTypes = useMemo(
    () =>
      files && files.length
        ? [
            ...new Set(
              files
                .filter((file) => file.encounterType)
                .map((file) => file.encounterType)
            )
          ]
        : [],
    [hasFiles]
  );
  const encounterOrganizations = useMemo(
    () =>
      files && files.length
        ? [
            ...new Set(
              files
                .filter((file) => file.encounterOrganization)
                .map((file) => file.encounterOrganization || [])
            )
          ]
        : [],
    [hasFiles]
  );

  const organizations = useMemo(
    () =>
      files && files.length
        ? [
            ...new Set(
              files
                .filter((file) => file.organization)
                .map((file) => file.organization)
            )
          ]
        : [],
    [hasFiles]
  );
  const authorSoftwares = useMemo(
    () =>
      files && files.length
        ? [
            ...new Set(
              files
                .filter((file) => file.authorSoftware)
                .map((file) => file.authorSoftware)
            )
          ]
        : [],
    []
  );
  const sourceNames = useMemo(
    () =>
      files && files.length
        ? [
            ...new Set(
              files
                .filter((file) => file.sourceName)
                .map((file) => file.sourceName)
            )
          ]
        : [],
    [hasFiles]
  );

  const reset = () => {
    setSearchParams({});
  };

  const handleServiceToPickerChange = (value) => {
    if (!isValid(value)) return;
    const serviceTo = format(value, 'yyyy-MM-dd');

    handleFilter({
      serviceTo
    });
  };

  const handleServiceFromPickerChange = (value) => {
    if (!isValid(value)) return;
    const serviceFrom = format(value, 'yyyy-MM-dd');

    handleFilter({
      serviceFrom
    });
  };

  const handleCreatedDtPickerChange = (paramString) => (value) => {
    if (!isValid(value)) return;
    const createdDt = format(value, 'yyyy-MM-dd');

    handleFilter({
      [paramString]: createdDt
    });
  };

  const handleSelectChange = (event) => {
    console.log('event.target.name: ', event.target.name);
    handleFilter({
      [event.target.name]: event.target.value
    });
  };

  const getPatientChartFiles = async (patientId) => {
    try {
      setLoading(true);
      const response = await MDPBackend.getPatientChartFiles(
        patientId,
        searchParams.toString()
      );

      const parsedData = JSON.parse(response.data.body);
      console.log('[getPatientChartFiles] parsedData: ', parsedData);

      if (
        !parsedData ||
        (!parsedData.data && response.data.statusCode !== 200)
      ) {
        const responseMsg = response.data.body;
        dispatch(
          showToastMsg({
            open: true,
            message: responseMsg ? responseMsg : 'Network Error',
            level: 'error',
            duration: 5000
          })
        );
      }

      const total = parsedData.total;
      const files = parsedData.data;

      setTotal(total);
      setFiles(files);

      if (files?.length) {
        setHasFiles(true);
      }
    } catch (error) {
      console.log('[getPatientChartFiles] error: ', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPatientChartFiles(patientId, params);
  }, [
    patientId,
    params.serviceFrom,
    params.serviceTo,
    params.documentType,
    params.documentTitle,
    params.encounterType,
    params.encounterOrganization,
    params.organization,
    params.sourceName,
    params.authorSoftware,
    params.createdFrom,
    params.createdTo
  ]);

  type SelectFilterProps = {
    name: String,
    label: String,
    array: Array
  };

  const SelectFilter = ({ name, label, array }: SelectFilterProps) => (
    <>
      {array.length > 0 && (
        <Grid item sm={6} md={3}>
          <TextField
            className="w-full"
            name={name}
            select
            value={params[name]}
            label={label}
            onChange={handleSelectChange}
          >
            {array.map((el) => (
              <MenuItem key={el} value={el}>
                {el}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      )}
    </>
  );

  const ServiceDateFromFilter = () => (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enLocale}>
      <DatePicker
        disableFuture
        label={'Service Date From'}
        value={parseISO(params.serviceFrom)}
        onChange={handleServiceFromPickerChange}
        renderInput={(params) => (
          <TextField
            {...params}
            type="outlined"
            error={false}
            className="w-full"
          />
        )}
        disabled={loading}
      />
    </LocalizationProvider>
  );

  const ServiceDateToFilter = () => (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enLocale}>
      <DatePicker
        disableFuture
        label={'Service Date Until'}
        value={parseISO(params.serviceTo)}
        onChange={handleServiceToPickerChange}
        renderInput={(params) => (
          <TextField
            {...params}
            type="outlined"
            error={false}
            className="w-full"
          />
        )}
        disabled={loading}
      />
    </LocalizationProvider>
  );

  const DateAddedFromFilter = () => (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enLocale}>
      <DatePicker
        disableFuture
        label={'Date Added From'}
        value={parseISO(params.createdFrom)}
        onChange={handleCreatedDtPickerChange('createdFrom')}
        renderInput={(rParams) => (
          <TextField
            {...rParams}
            type="outlined"
            error={false}
            className="w-full"
          />
        )}
        disabled={loading}
      />
    </LocalizationProvider>
  );

  const DateAddedToFilter = () => (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enLocale}>
      <DatePicker
        disableFuture
        label={'Date Added Until'}
        value={parseISO(params.createdTo)}
        onChange={handleCreatedDtPickerChange('createdTo')}
        renderInput={(rParams) => (
          <TextField
            {...rParams}
            type="outlined"
            error={false}
            className="w-full"
          />
        )}
        disabled={loading}
      />
    </LocalizationProvider>
  );

  return (
    <Container
      maxWidth="none"
      className={`${
        user && user.role !== 'payer' ? '!pl-[86px] sm:!pl-[193px]' : ''
      } mt-5`}
    >
      <Grid container spacing={2}>
        <Grid item sm={6} md={3}>
          <ServiceDateFromFilter />
        </Grid>
        <Grid item sm={6} md={3}>
          <ServiceDateToFilter />
        </Grid>
        <SelectFilter
          name="documentType"
          label="Document Type"
          array={documentTypes}
        />
        <SelectFilter
          name="documentTitle"
          label="Document Title"
          array={documentTitles}
        />
        <SelectFilter
          name="encounterType"
          label="Encounter Type"
          array={encounterTypes}
        />
        <SelectFilter
          name="encounterOrganization"
          label="Encounter Organization"
          array={encounterOrganizations}
        />
        <SelectFilter
          name="organization"
          label="Organization Name"
          array={organizations}
        />
        <SelectFilter
          name="sourceName"
          label="Source Name"
          array={sourceNames}
        />
        <SelectFilter
          name="authorSoftware"
          label="Author Software"
          array={authorSoftwares}
        />
        <Grid item sm={6} md={3}>
          <DateAddedFromFilter />
        </Grid>
        <Grid item sm={6} md={3}>
          <DateAddedToFilter />
        </Grid>
      </Grid>
      <div className="flex items-center mt-3 mr-3">
        <Typography variant="body1">
          {total} Chart file{total === 1 ? '' : 's'} found
        </Typography>
        <Button
          className="!ml-2"
          color="secondary"
          variant="outlined"
          startIcon={<RestartAltIcon />}
          onClick={reset}
          disabled={loading}
        >
          Reset Filters
        </Button>
      </div>
      {loading ? (
        <div className="justify-center w-full">
          <CircularProgress />
        </div>
      ) : (
        <ChartFilesTable
          files={files}
          handleFilter={handleFilter}
          patientId={patientId}
        />
      )}
    </Container>
  );
};

export default ChartFiles;
