import AppBar from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';

export const StyledAppBar = styled(AppBar)`
  background: white;
  z-index: 999;

  .menu-btn {
    margin-right: 10px;
    margin-top: 2px;
    color: black;
  }

  .active {
    color: #ac92ec;
    font-weight: 700;
    background-color: rgba(25, 118, 210, 0.04);
  }

  .organizationSwitch {
    font-size: 0.9rem;

    &:before {
      border: none;
    }
  }

  .settingsButton {
    margin-right: -35px;
  }
`;
