// @flow

const styles = () => ({
  inputError: {
    width: '100%',
    textAlign: 'left',
    color: 'red'
  }
});

export default styles;
