// @flow

import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Provider } from 'react-redux';
import theme from 'styles/theme';
import RouterApp from './RouterApp';
import reduxStore from 'app/redux-store';
import ToastMsg from 'components/ToastMsg';
import Consent from 'components/Consent';

const App = (): React.Node => {
  return (
    <Provider store={reduxStore}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <HelmetProvider>
          <Helmet>
            <meta charSet="utf-8" />
            <title>MDPortals EHR</title>
            <meta name="apple-mobile-web-app-title" content="mdportals.com" />
            <meta name="application-name" content="mdportals.com" />
            <link
              rel="apple-touch-icon"
              sizes="180x180"
              href="../assets/apple-touch-icon.png"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="32x32"
              href="../assets/favicon-32x32.png"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="16x16"
              href="../assets/favicon-16x16.png"
            />
            <link rel="manifest" href="../assets/manifest.json" />
            <meta name="theme-color" content="#ffffff" />
          </Helmet>

          <RouterApp />
        </HelmetProvider>
        <ToastMsg />
        <Consent />
      </ThemeProvider>
    </Provider>
  );
};

export default App;
