"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var R = _interopRequireWildcard(require("ramda"));

var _ = require("..");

var createSearchData = function createSearchData(body) {
  var searchData = [];
  body.map(function (section) {
    var sectionGroup = {
      groupTitle: '',
      data: []
    };
    var sectionTitle = section.title;
    sectionGroup['groupTitle'] = sectionTitle;
    section.data.map(function (d) {
      if (d.type === 'paragraph' || d.type === 'raw' || d.type === 'caption' || d.type === 'footnote' || d.type === 'list') {
        sectionGroup['data'].push({
          type: 'text',
          section: sectionTitle,
          text: d.content,
          rowId: (0, _.normalizeString)(d.content)
        });
      } else if (d.type === 'table') {
        d.content.data.map(function (row, rowIndex) {
          var tableRow = row.nestedContent ? R.omit(['nestedContent'], row) : row;
          var text = Object.values(tableRow).join(' ');
          var rowId = (0, _.normalizeString)(text);
          sectionGroup.data.push({
            type: 'table',
            rowId: rowId,
            text: text
          });
        });
      }
    });
    searchData.push(sectionGroup);
  }); // Section Titles

  var sectionsData = {
    groupTitle: 'Sections',
    data: []
  };
  body.map(function (section) {
    return sectionsData['data'].push(section.title);
  });
  searchData.push(sectionsData);
  return searchData;
};

var _default = createSearchData;
exports.default = _default;