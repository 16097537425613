"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TableWrapper = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/esm/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _templateObject;

var TableWrapper = _styledComponents.default.table(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  margin-bottom: 1rem;\n\n  thead {\n    tr {\n      background-color: #fff;\n      border-color: #ebe9f1;\n      min-height: 0;\n    }\n\n    th {\n      text-align: left;\n      padding: 1rem 0.5rem;\n      color: #6e6b7b;\n      font-weight: bold;\n      font-size: 0.9rem;\n      letter-spacing: 0.5px;\n      width: 100%;\n      position: relative;\n\n      border-right: 1px solid rgba(0, 0, 0, 0.04);\n      :last-child {\n        border-right: 0;\n      }\n\n      .resizer {\n        display: inline-block;\n        right: 0;\n        background: rgba(0, 0, 0, 0.12);\n        width: 2px;\n        height: 100%;\n        position: absolute;\n        top: 0;\n        z-index: 10;\n        touch-action: none;\n\n        &.isResizing {\n          background: #7367f0;\n        }\n      }\n    }\n  }\n\n  tbody {\n    td {\n      color: black;\n      border-top: 1px solid rgba(0, 0, 0, 0.12);\n      border-right: 1px solid rgba(0, 0, 0, 0.04);\n      padding: 0.5rem 0.5rem;\n      white-space: pre-line;\n      word-break: break-word;\n      align-items: center;\n      min-width: 100px;\n      font-size: 0.87rem;\n\n      :last-child {\n        border-right: 0;\n      }\n    }\n\n    tr {\n      &.isMarked {\n        background-color: #7367f0;\n        td {\n          color: white;\n        }\n      }\n\n      &.selected {\n        background-color: #e3f2fd;\n      }\n    }\n  }\n"])));

exports.TableWrapper = TableWrapper;