// @flow

import React, { useState } from 'react';
import fileDownload from 'js-file-download';
import axios from 'axios';
import { parseISO, format, isValid } from 'date-fns';
import * as R from 'ramda';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import enLocale from 'date-fns/locale/en-US';
import { useDispatch } from 'react-redux';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { dates, searchPeriods } from '../Patient/pages/HCC/HCCFilter';
import { StyledBox } from './Styles/ExportHCCModal.Style';
import MDPBackend from 'services/MDPBackend';
import { showToastMsg } from 'features/toast-message-slice';

type Props = {
  handleClose: () => void,
  exportModalOpen: Boolean,
  batchNames: Array<Object>
};

const ExportHCCModalRev = (props: Props) => {
  const { handleClose, exportModalOpen, batchNames } = props;
  const [loading, setLoading] = useState(false);
  const [evidenceQueryPeriod, setEvidenceQueryPeriod] = useState({
    evidenceFrom: '',
    evidenceTo: '',
    periodName: ''
  });
  const [lastUpdatedDtFilter, setLastUpdatedDtFilter] = useState({
    lastUpdatedFrom: '',
    lastUpdatedTo: ''
  });
  const [batchNameFilter, setBatchNameFilter] = useState('');
  const [exportHCCsWithoutDates, setExportHCCsWithoutDates] = useState(false);

  /* eslint-disable-next-line no-unused-vars */
  const dispatch = useDispatch();

  const tryGetExportedHCCSummary = async (requestId, retries) => {
    console.log('[tryGetExportedHCCSummary] retry: ', retries);
    await new Promise((resolve) => setTimeout(resolve, 10 * 1000)); // wait 10 seconds

    try {
      const response = await MDPBackend.getPatientHCCExport(requestId);
      const { body } = response.data;
      const parsedBody = JSON.parse(body);

      console.log('[tryGetExportedHCCSummary] parsedBody: ', parsedBody);

      const { url: signedUrl } = parsedBody;

      if (signedUrl) {
        return parsedBody;
      } else if (!signedUrl && retries > 0) {
        return tryGetExportedHCCSummary(requestId, retries - 1);
      } else {
        throw new Error('Max number of attempt reached');
      }
    } catch (error) {
      console.log('[tryGetExportedHCCSummary] error: ', error);
      return null;
    }
  };

  const exportHCCs = async () => {
    setLoading(true);

    const searchParams = {
      ...R.omit(['periodName'], evidenceQueryPeriod),
      ...lastUpdatedDtFilter,
      batchName: batchNameFilter,
      exportHCCsWithoutDates: exportHCCsWithoutDates,
      revTeam: true
    };

    const searchString = new URLSearchParams(searchParams);

    try {
      const response = await MDPBackend.exportPatientHCCsByOrg(searchString);
      const { body } = response.data;
      const parsedBody = JSON.parse(body);

      const requestId = parsedBody.requestId;

      console.log('[exportHCCs] requestId: ', requestId);

      if (requestId) {
        dispatch(
          showToastMsg({
            open: true,
            message:
              // eslint-disable-next-line max-len
              'Exporting HCCs. This can take up to 10 minutes. File will download automatically when ready. Do not exit this page.',
            level: 'success',
            duration: 5000
          })
        );

        const exportedHCCResponse = await tryGetExportedHCCSummary(
          requestId,
          60
        );
        console.log('[exportHCCs] exportedHCCResponse: ', exportedHCCResponse);

        const { url: signedUrl } = exportedHCCResponse;

        const signedUrlResponse = await axios.get(signedUrl, {
          responseType: 'blob'
        });

        const blob = signedUrlResponse.data;

        const fileName = 'patient_hccs.csv';

        fileDownload(blob, fileName);

        setLoading(false);
      } else {
        setLoading(false);
        throw new Error(`Failed to create request: ${response}`);
      }
    } catch (error) {
      setLoading(false);
      console.log('[exportHCCs] error: ', error);
      dispatch(
        showToastMsg({
          open: true,
          message:
            'Something went wrong. Please contact support at support@mdportals.com',
          level: 'error',
          duration: 5000
        })
      );
    }
  };

  const onEvidenceFromPickerChange = (value) => {
    const evidenceFrom = isValid(value) ? format(value, 'yyyy-MM-dd') : '';

    setExportHCCsWithoutDates(false);
    setEvidenceQueryPeriod({
      ...evidenceQueryPeriod,
      periodName: '',
      evidenceFrom: evidenceFrom
    });
  };

  const onEvidenceToPickerChange = (value) => {
    const evidenceTo = isValid(value) ? format(value, 'yyyy-MM-dd') : '';
    setExportHCCsWithoutDates(false);
    setEvidenceQueryPeriod({ ...evidenceQueryPeriod, evidenceTo: evidenceTo });
  };

  const onLastUpdatedFromPickerChange = (value) => {
    const lastUpdatedFrom = isValid(value) ? format(value, 'yyyy-MM-dd') : '';
    setLastUpdatedDtFilter({
      ...lastUpdatedDtFilter,
      lastUpdatedFrom: lastUpdatedFrom
    });
  };

  const onLastUpdatedToPickerChange = (value) => {
    const lastUpdatedTo = isValid(value) ? format(value, 'yyyy-MM-dd') : '';
    setLastUpdatedDtFilter({
      ...lastUpdatedDtFilter,
      lastUpdatedTo: lastUpdatedTo
    });
  };

  const handleBatchNameFilter = (e) => {
    const batchName = e.target.value;
    // console.log('[handleHasOutputFilterChange] batchName: ', batchName);

    const batch = batchNames.find((b) => b.batchName === batchName);

    setBatchNameFilter(batch.batchName);
  };

  const resetFilters = () => {
    setEvidenceQueryPeriod({
      evidenceFrom: '',
      evidenceTo: '',
      periodName: ''
    });
    setLastUpdatedDtFilter({
      lastUpdatedFrom: '',
      lastUpdatedTo: ''
    });
    setBatchNameFilter('');
    setExportHCCsWithoutDates(false);
  };

  const handleExportHCCsWithoutFilterChange = (e) => {
    const isChecked = e.target.checked;
    console.log('[handleExportHCCsWithoutFilterChange] isChecked: ', isChecked);

    if (isChecked) {
      setEvidenceQueryPeriod({
        evidenceFrom: '',
        evidenceTo: '',
        periodName: ''
      });
    }

    setExportHCCsWithoutDates(isChecked);
  };

  const setDatesBasedOnPeriodName = (periodName) => {
    const selectedPeriod = R.find(R.propEq('name', periodName))(searchPeriods);
    console.log(
      '[setDatesBasedOnPeriodName] selectedPeriod:::: ',
      selectedPeriod
    );
    if (typeof selectedPeriod !== 'undefined' && selectedPeriod !== null) {
      const evidenceFrom = dates[selectedPeriod.value].from;
      const evidenceTo = dates[selectedPeriod.value].to;
      console.log(
        '[setDatesBasedOnPeriodName] evidenceFrom:::: ',
        evidenceFrom
      );
      console.log('[setDatesBasedOnPeriodName] evidenceTo:::: ', evidenceTo);
      setEvidenceQueryPeriod({
        periodName,
        evidenceFrom,
        evidenceTo
      });
      setExportHCCsWithoutDates(false);
    }
  };

  const onCreatedInputChange = (e) => {
    setDatesBasedOnPeriodName(e.target.value);
  };

  return (
    <Modal open={!!exportModalOpen} onClose={handleClose}>
      <StyledBox>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          <div className={'searchPeriodDatePickerWrapper'}>
            <Typography variant="h4">[REV Team] Export HCCs to CSV</Typography>
            <Typography style={{ marginBottom: 20, fontSize: 14 }}>
              (No filters will export all your data)
            </Typography>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={enLocale}
            >
              <div className="form-section">
                <Typography style={{ marginBottom: 10, fontSize: 16 }}>
                  Source date
                </Typography>
                <FormControl
                  className={'searchPeriodFilter'}
                  disabled={loading}
                >
                  <InputLabel variant="outlined" id="period-label">
                    Source date within
                  </InputLabel>
                  <Select
                    labelId="period-label"
                    variant="outlined"
                    id="search"
                    value={evidenceQueryPeriod.periodName}
                    onChange={onCreatedInputChange}
                    input={
                      <OutlinedInput
                        id="select-multiple-chip"
                        label="period-label"
                      />
                    }
                  >
                    {searchPeriods.map((period, idx) => (
                      <MenuItem key={idx} value={period.name}>
                        {period.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <DatePicker
                  disableFuture
                  label={'Source date from'}
                  value={
                    evidenceQueryPeriod.evidenceFrom
                      ? parseISO(evidenceQueryPeriod.evidenceFrom)
                      : null
                  }
                  onChange={onEvidenceFromPickerChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      type="outlined"
                      error={false}
                      className={'searchPeriodDatePicker'}
                    />
                  )}
                  disabled={loading}
                />
                <DatePicker
                  label="Source date to"
                  value={
                    evidenceQueryPeriod.evidenceTo
                      ? parseISO(evidenceQueryPeriod.evidenceTo)
                      : null
                  }
                  onChange={onEvidenceToPickerChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      type="outlined"
                      error={false}
                      className={'searchPeriodDatePicker'}
                    />
                  )}
                  disabled={loading}
                />
              </div>
              <div className="form-section">
                <Typography style={{ marginBottom: 10, fontSize: 16 }}>
                  HCC Last updated
                </Typography>
                <DatePicker
                  label="HCC Last updated date from"
                  value={
                    lastUpdatedDtFilter.lastUpdatedFrom
                      ? parseISO(lastUpdatedDtFilter.lastUpdatedFrom)
                      : null
                  }
                  onChange={onLastUpdatedFromPickerChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      type="outlined"
                      error={false}
                      className={'searchPeriodDatePicker'}
                    />
                  )}
                  disabled={loading}
                />
                <DatePicker
                  label="HCC Last updated date to"
                  value={
                    lastUpdatedDtFilter.lastUpdatedTo
                      ? parseISO(lastUpdatedDtFilter.lastUpdatedTo)
                      : null
                  }
                  onChange={onLastUpdatedToPickerChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      type="outlined"
                      error={false}
                      className={'searchPeriodDatePicker'}
                    />
                  )}
                  disabled={loading}
                />
              </div>
              <FormControl className={'batchFilter'} disabled={loading}>
                <InputLabel variant="outlined" id="output-label">
                  Batch name
                </InputLabel>
                <Select
                  variant="outlined"
                  id="output"
                  value={batchNameFilter}
                  onChange={handleBatchNameFilter}
                  input={
                    <OutlinedInput
                      id="select-multiple-chip"
                      label="output-label"
                    />
                  }
                >
                  {batchNames
                    ? batchNames.map((batch, idx) => (
                        <MenuItem key={idx} value={batch.batchName}>
                          {batch.batchName}
                        </MenuItem>
                      ))
                    : null}
                </Select>
              </FormControl>
            </LocalizationProvider>
          </div>
          <div className="form-section">
            <FormGroup>
              <FormControlLabel
                control={<Checkbox />}
                checked={exportHCCsWithoutDates}
                onChange={handleExportHCCsWithoutFilterChange}
                label="Export HCCs with no Source Date"
              />
            </FormGroup>
          </div>
          <div className="buttons">
            <Button
              style={{ marginTop: 25 }}
              onClick={() => exportHCCs()}
              variant="contained"
              disabled={loading}
            >
              {loading ? 'Exporting, please wait...' : 'Export HCCs to CSV'}
            </Button>
            <Button
              style={{ marginTop: 25 }}
              onClick={() => resetFilters()}
              variant="outlined"
              disabled={loading}
            >
              Reset filters
            </Button>
          </div>
        </div>
      </StyledBox>
    </Modal>
  );
};

export default ExportHCCModalRev;
