"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.trimStr = exports.normalizeString = exports.hashString = void 0;

var hashString = function hashString(str) {
  var hash = 0,
      i,
      chr;
  if (str.length === 0) return hash;

  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }

  return String(hash);
};

exports.hashString = hashString;

var trimStr = function trimStr(str) {
  return str.trim().replace(/\s+/g, ' ');
};

exports.trimStr = trimStr;

var normalizeString = function normalizeString(str) {
  return str.toLowerCase().replace(/\s+/g, '');
};

exports.normalizeString = normalizeString;