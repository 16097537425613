"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SpinnerWrapper = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/esm/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _templateObject;

var SpinnerWrapper = _styledComponents.default.div(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  overflow: hidden;\n\n  .loading {\n    display: inline-block;\n    width: 80px;\n    height: 80px;\n  }\n\n  .loading:after {\n    content: ' ';\n    display: block;\n    width: 40px;\n    height: 40px;\n    margin: 8px;\n    border-radius: 50%;\n    border: 3px solid rgba(121, 97, 249, 1);\n    border-color: rgba(121, 97, 249, 1) transparent rgba(121, 97, 249, 1)\n      transparent;\n    animation: loading 1.2s linear infinite;\n  }\n\n  @keyframes loading {\n    0% {\n      transform: rotate(0deg);\n    }\n    100% {\n      transform: rotate(360deg);\n    }\n  }\n"])));

exports.SpinnerWrapper = SpinnerWrapper;