"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.extractMetaDataFromCDA = void 0;

var _cheerio = _interopRequireDefault(require("cheerio"));

var _dayjs = _interopRequireDefault(require("dayjs"));

var _lodash = _interopRequireDefault(require("lodash"));

var splitAndJoinStringByWhiteSpace = function splitAndJoinStringByWhiteSpace(str) {
  if (typeof str !== 'string') return '';
  return str.split(/\s+/g).join(' ');
};

var parseDateString = function parseDateString(str) {
  if (typeof str !== 'string') return '';
  return str.split(/[+-]/g)[0];
};

var getDocumentId = function getDocumentId(ccd) {
  var $ = _cheerio.default.load(ccd, {
    xmlMode: true
  });

  var $ClinicalDocument = $('ClinicalDocument');
  var documentId = $ClinicalDocument.find('> id').attr('root');
  return documentId;
};

var getDocumentSetId = function getDocumentSetId(ccd) {
  var $ = _cheerio.default.load(ccd, {
    xmlMode: true
  });

  var $ClinicalDocument = $('ClinicalDocument');
  var documentSetId = $ClinicalDocument.find('> setId').attr('root');
  return documentSetId;
};

var getDocumentVersionNumber = function getDocumentVersionNumber(ccd) {
  var $ = _cheerio.default.load(ccd, {
    xmlMode: true
  });

  var $ClinicalDocument = $('ClinicalDocument');
  var documentVersionNumber = $ClinicalDocument.find('> versionNumber').attr('value');
  return documentVersionNumber;
};

var getDocumentTitle = function getDocumentTitle(ccd) {
  var $ = _cheerio.default.load(ccd, {
    xmlMode: true
  });

  var $ClinicalDocument = $('ClinicalDocument');
  var title = $ClinicalDocument.find('> title').text();
  return title;
};

var getDocumentEffectiveTime = function getDocumentEffectiveTime(ccd) {
  var $ = _cheerio.default.load(ccd, {
    xmlMode: true
  });

  var $ClinicalDocument = $('ClinicalDocument');
  var effectiveTime = $ClinicalDocument.find('> effectiveTime').attr('value');
  var effectiveTimeParsed = parseDateString(effectiveTime);
  var effectiveTimeFormatted = (0, _dayjs.default)(effectiveTimeParsed).format('YYYY-MM-DD');
  return effectiveTimeFormatted;
};

var getDocumentConfidentiality = function getDocumentConfidentiality(ccd) {
  var $ = _cheerio.default.load(ccd, {
    xmlMode: true
  });

  var $ClinicalDocument = $('ClinicalDocument');
  var confidentiality = $ClinicalDocument.find('> confidentialityCode').attr('displayName');
  return confidentiality;
};

var getDocumentActRelationshipDocuments = function getDocumentActRelationshipDocuments(ccd) {
  var $ = _cheerio.default.load(ccd, {
    xmlMode: true
  });

  var $ClinicalDocument = $('ClinicalDocument');
  var $relatedDocument = $ClinicalDocument.find('relatedDocument');

  if (!$relatedDocument.length) {
    return {};
  }

  var relatedDocument = {};
  var parentDocuments = [];
  var type = $relatedDocument.attr('typeCode');
  relatedDocument.type = type;
  $relatedDocument.find('parentDocument').each(function (i, el) {
    var $parentDocument = $(el);
    var parentDocumentId = $parentDocument.find('> id').attr('root');
    var parentDocumentSetId = $parentDocument.find('> setId').attr('root');
    var parentDocumentVersionNumber = $parentDocument.find('> versionNumber').attr('value');
    parentDocuments.push({
      id: parentDocumentId,
      setId: parentDocumentSetId,
      versionNumber: parentDocumentVersionNumber
    });
  });
  relatedDocument.parentDocuments = parentDocuments;
  return relatedDocument;
};

var getServiceEventTime = function getServiceEventTime(ccd) {
  var $ = _cheerio.default.load(ccd, {
    xmlMode: true
  });

  var $ClinicalDocument = $('ClinicalDocument');
  var $serviceEvent = $ClinicalDocument.find('> documentationOf > serviceEvent');
  var type, low, high;
  type = $serviceEvent.attr('classCode');
  var lowValue = $serviceEvent.find('effectiveTime > low').attr('value');
  var highValue = $serviceEvent.find('effectiveTime > high').attr('value');

  if (lowValue) {
    var lowParsed = parseDateString(lowValue);
    var lowFormatted = (0, _dayjs.default)(lowParsed).format('YYYY-MM-DD');
    low = lowFormatted;
  }

  if (highValue) {
    var highParsed = parseDateString(highValue);
    var highFormatted = (0, _dayjs.default)(highParsed).format('YYYY-MM-DD');
    high = highFormatted;
  }

  return {
    type: type,
    low: low,
    high: high
  };
};

var getPerformers = function getPerformers(ccd) {
  var $ = _cheerio.default.load(ccd, {
    xmlMode: true
  });

  var $ClinicalDocument = $('ClinicalDocument');
  var performers = [];
  $ClinicalDocument.find('> documentationOf > serviceEvent > performer').each(function (i, el) {
    var $performer = $(el); // Functional Role

    var functionRole = $performer.find('> functionCode').attr('displayName'); // Performance time

    var time = {};
    var timeLow = $performer.find('> time > low').attr('value');
    var timeHigh = $performer.find('> time > high').attr('value');

    if (timeLow) {
      var timeLowParsed = parseDateString(timeLow);
      var timeLowFormatted = (0, _dayjs.default)(timeLowParsed).format('YYYY-MM-DD');
      time.low = timeLowFormatted;
    }

    if (timeHigh) {
      var timeHighParsed = parseDateString(timeHigh);
      var timeHighFormatted = (0, _dayjs.default)(timeHighParsed).format('YYYY-MM-DD');
      time.high = timeHighFormatted;
    } // Code - Specialty?


    var code = $performer.find('> assignedEntity > code').text().trim(); // assignedPerson

    var assignedPerson = {
      name: splitAndJoinStringByWhiteSpace($performer.find('> assignedEntity > assignedPerson > name').first().text().trim())
    }; // Telecom

    var telecom = [];
    $performer.find('> assignedEntity > telecom').each(function (i, el) {
      var value = $(el).attr('value');
      telecom.push(value);
    }); // Address

    var $address = $performer.find('> assignedEntity > addr');
    var street = $address.find('streetAddressLine').text().trim();
    var city = $address.find('city').text().trim();
    var state = $address.find('state').text().trim();
    var postalCode = $address.find('postalCode').text().trim();
    var address = {
      street: street,
      city: city,
      state: state,
      postalCode: postalCode
    }; // Represented organization

    var representedOrganization = {};
    var orgTelecom = $performer.find('> assignedEntity > representedOrganization > telecom').attr('value');
    var orgAddress = $performer.find('> assignedEntity > representedOrganization > addr').attr('value');

    if (orgTelecom) {
      representedOrganization.telecom = orgTelecom;
    }

    if (orgAddress) {
      representedOrganization.address = orgAddress;
    }

    performers.push({
      functionRole: functionRole,
      time: time,
      code: code,
      assignedPerson: assignedPerson,
      address: address,
      telecom: telecom,
      representedOrganization: representedOrganization
    });
  });
  return performers;
};

var getEncompassingEncounter = function getEncompassingEncounter(ccd) {
  var $ = _cheerio.default.load(ccd, {
    xmlMode: true
  });

  var $ClinicalDocument = $('ClinicalDocument');
  var $encompassingEncounter = $ClinicalDocument.find('componentOf > encompassingEncounter');
  if (!$encompassingEncounter.length) return {};
  var type = $encompassingEncounter.find('> code > originalText').text();
  var time = {};
  var timeLow = $encompassingEncounter.find('> effectiveTime > low').attr('value');
  var timeHigh = $encompassingEncounter.find('> effectiveTime > high').attr('value');

  if (timeLow) {
    var timeLowParsed = parseDateString(timeLow);
    var timeLowFormatted = (0, _dayjs.default)(timeLowParsed).format('YYYY-MM-DD');
    time.low = timeLowFormatted;
  }

  if (timeHigh) {
    var timeHighParsed = parseDateString(timeHigh);
    var timeHighFormatted = (0, _dayjs.default)(timeHighParsed).format('YYYY-MM-DD');
    time.high = timeHighFormatted;
  } // Responsible Part


  var $responsibleParty = $encompassingEncounter.find('> responsibleParty');
  var $rpAddress = $responsibleParty.find('> assignedEntity > addr');
  var responsibleParty = {
    address: {
      street: $rpAddress.find('streetAddressLine').text().trim(),
      city: $rpAddress.find('city').text().trim(),
      state: $rpAddress.find('state').text().trim(),
      postalCode: $rpAddress.find('postalCode').text().trim()
    },
    telecom: [],
    assignedPerson: {
      name: splitAndJoinStringByWhiteSpace($responsibleParty.find('> assignedEntity > assignedPerson > name').first().text().trim())
    },
    representedOrganization: $responsibleParty.find('> assignedEntity > representedOrganization > name').text().trim()
  };
  $responsibleParty.find('> assignedEntity > telecom').each(function (i, el) {
    var value = $(el).attr('value');
    responsibleParty.telecom.push(value);
  }); // Encounter Participant

  var $encounterParticipant = $encompassingEncounter.find('> encounterParticipant');
  var participant = {
    type: $encounterParticipant.attr('typeCode'),
    time: (0, _dayjs.default)(parseDateString($encounterParticipant.find('> time').attr('value'))).format('YYYY-MM-DD'),
    code: $encounterParticipant.find('> assignedEntity > code > originalText').text().trim(),
    address: {
      street: $encounterParticipant.find('> assignedEntity > addr > streetAddressLine').text().trim(),
      city: $encounterParticipant.find('> assignedEntity > addr > city').text().trim(),
      state: $encounterParticipant.find('> assignedEntity > addr > state').text().trim(),
      postalCode: $encounterParticipant.find('> assignedEntity > addr > postalCode').text().trim()
    },
    telecom: [],
    assignedPerson: {
      name: splitAndJoinStringByWhiteSpace($encounterParticipant.find('> assignedEntity > assignedPerson > name > given').first().text().trim())
    }
  };
  var $healthCareFacility = $encompassingEncounter.find('> location > healthCareFacility');
  var healthCareFacility = {
    location: {
      street: $healthCareFacility.find('> location > addr > streetAddressLine').text().trim(),
      city: $healthCareFacility.find('> location > addr > city').text().trim(),
      state: $healthCareFacility.find('> location > addr > state').text().trim(),
      postalCode: $healthCareFacility.find('> location > addr > postalCode').text().trim()
    },
    serviceProviderOrganization: {
      name: $healthCareFacility.find('> serviceProviderOrganization > name').text().trim(),
      address: {
        street: $healthCareFacility.find('> serviceProviderOrganization > addr > streetAddressLine').text().trim(),
        city: $healthCareFacility.find('> serviceProviderOrganization > addr > city').text().trim(),
        state: $healthCareFacility.find('> serviceProviderOrganization > addr > state').text().trim(),
        postalCode: $healthCareFacility.find('> serviceProviderOrganization > addr > postalCode').text().trim()
      },
      asPartOfOrganization: $healthCareFacility.find('> serviceProviderOrganization > asOrganizationPartOf > wholeOrganization > name').text().trim()
    }
  };
  return {
    type: type,
    time: time,
    responsibleParty: responsibleParty,
    participant: participant,
    healthCareFacility: healthCareFacility
  };
};

var getPatient = function getPatient(ccd) {
  var $ = _cheerio.default.load(ccd, {
    xmlMode: true
  });

  var $ClinicalDocument = $('ClinicalDocument');
  var $patientRole = $ClinicalDocument.find('> recordTarget > patientRole');
  var gender = $('patient > administrativeGenderCode').attr('code');

  if (gender) {
    gender = gender === 'M' ? 'Male' : gender === 'F' ? 'Female' : null;
  }

  var patient = {
    name: splitAndJoinStringByWhiteSpace($patientRole.find('> patient > name').first().text().trim()),
    address: {
      street: $patientRole.find('> addr > streetAddressLine').text().trim(),
      city: $patientRole.find('> addr > city').text().trim(),
      state: $patientRole.find('> addr > state').text().trim(),
      postalCode: $patientRole.find('> addr > postalCode').text().trim()
    },
    telecom: [],
    gender: gender,
    dob: (0, _dayjs.default)(parseDateString($patientRole.find('> patient > birthTime').attr('value'))).format('YYYY-MM-DD'),
    maritalStatus: $patientRole.find('> patient > maritalStatusCode').attr('displayName'),
    ethnicity: $patientRole.find('> patient > ethnicGroupCode').attr('displayName')
  };
  $patientRole.find('> telecom').each(function (i, el) {
    var value = $(el).attr('value');
    patient.telecom.push(value);
  });
  return patient;
};

var getProviderOrganization = function getProviderOrganization(ccd) {
  var $ = _cheerio.default.load(ccd, {
    xmlMode: true
  });

  var $ClinicalDocument = $('ClinicalDocument');
  var $providerOrg = $ClinicalDocument.find('> recordTarget > patientRole > providerOrganization');
  var providerOrganization = {
    name: $providerOrg.find('> name').text().trim(),
    address: {
      street: $providerOrg.find('> addr > streetAddressLine').text().trim(),
      city: $providerOrg.find('> addr > city').text().trim(),
      state: $providerOrg.find('> addr > state').text().trim(),
      postalCode: $providerOrg.find('> addr > postalCode').text().trim()
    }
  };
  return providerOrganization;
};

var getAuthor = function getAuthor(ccd) {
  var $ = _cheerio.default.load(ccd, {
    xmlMode: true
  });

  var $ClinicalDocument = $('ClinicalDocument');
  var $author = $ClinicalDocument.find('> author');
  var author = {
    time: (0, _dayjs.default)(parseDateString($author.find('> time').attr('value'))).format('YYYY-MM-DD'),
    device: {
      model: $author.find('> assignedAuthor > assignedAuthoringDevice > manufacturerModelName').text().trim(),
      software: $author.find('> assignedAuthor > assignedAuthoringDevice > softwareName').text().trim()
    },
    representedOrganization: {
      name: $author.find('> assignedAuthor > representedOrganization > name').text().trim(),
      address: {
        street: $author.find('> assignedAuthor > representedOrganization > addr > streetAddressLine').text().trim(),
        city: $author.find('> assignedAuthor > representedOrganization > addr > city').text().trim(),
        state: $author.find('> assignedAuthor > representedOrganization > addr > state').text().trim(),
        postalCode: $author.find('> assignedAuthor > representedOrganization > addr > postalCode').text().trim()
      }
    }
  };
  return author;
};

var getDocumentType = function getDocumentType(ccd) {
  var $ = _cheerio.default.load(ccd, {
    xmlMode: true
  });

  var $ClinicalDocument = $('ClinicalDocument');
  var type = {
    code: $ClinicalDocument.find('> code').attr('code'),
    codeSystem: $ClinicalDocument.find('> code').attr('codeSystemName'),
    name: $ClinicalDocument.find('> code').attr('displayName')
  };
  return type;
};

var extractMetaDataFromCDA = function extractMetaDataFromCDA(xmlStr) {
  try {
    if (!xmlStr || typeof xmlStr !== 'string') {
      throw new Error('[parseCDAMetaData] argument must be a valid CDA string');
    } // const confidentiality = getDocumentConfidentiality(xmlStr);
    // const relatedDocument = getDocumentActRelationshipDocuments(xmlStr);
    // const id = getDocumentId(xmlStr);
    // const setId = getDocumentSetId(xmlStr);
    // const versionNumber = getDocumentVersionNumber(xmlStr);
    // const providerOrganization = getProviderOrganization(xmlStr);


    var encompassingEncounter = getEncompassingEncounter(xmlStr);
    var type = getDocumentType(xmlStr);
    var title = getDocumentTitle(xmlStr);
    var effectiveTime = getDocumentEffectiveTime(xmlStr);
    var serviceEventTime = getServiceEventTime(xmlStr);
    var performers = getPerformers(xmlStr);
    var patient = getPatient(xmlStr);
    var author = getAuthor(xmlStr);
    var performerAdresses = [];
    performers.forEach(function (p) {
      var addressValues = Object.values(p.address);
      if (addressValues.length && addressValues.join('') !== '') performerAdresses.push(addressValues.join(', '));
    });
    var performerAddress = performerAdresses.length ? performerAdresses.join(', ') : '';
    return {
      'Patient Name': patient.name.replace(/([A-Z])/g, ' $1').trim(),
      'Patient DOB': patient.dob,
      'Patient Gender': patient.gender,
      'Patient Address': Object.values(patient.address).join(', '),
      'Patient Contact': patient.telecom.join(', '),
      'Document Title': title,
      'Document Type': type.name,
      'Document Type LOINC': type.code,
      'Document Created at': "".concat((0, _dayjs.default)(effectiveTime).format('MM-DD-YYYY hh:mmA')),
      'Service Event Time': "From ".concat(serviceEventTime.low).concat(serviceEventTime.high ? " to ".concat(serviceEventTime.high) : ''),
      'Encounter Type': encompassingEncounter.type,
      'Author Device': Object.values(author.device).join(', '),
      Performer: performers.length ? performers.map(function (p) {
        return p.assignedPerson.name ? "".concat(p.assignedPerson.name, " ").concat(p.functionRole ? "(".concat(p.functionRole, ")") : '') : '';
      }).join(', ') : '',
      'Performer Address': performerAddress
    };
  } catch (error) {
    console.log('[parseCDAMetaData] error: ', error);
  }
};

exports.extractMetaDataFromCDA = extractMetaDataFromCDA;