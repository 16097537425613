// @flow

const styles = (theme: Object) => ({
  paperRoot: {
    marginTop: theme.spacing(2),
    width: '100%'
  },
  searchField: {
    width: '100%'
  },
  table: {},
  patientRow: {
    alignItems: 'center'
  },
  searchResult: {
    padding: theme.spacing(2),
    minHeight: 400
  },
  stateSelect: {
    padding: '18.5px 14px'
  },

  stateFilter: {
    width: '100%',
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      margin: `${theme.spacing(1)} 0`,
      maxWidth: '100%'
    }
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '0'
  },
  chip: {
    color: 'white',
    border: 'none',
    backgroundColor: '#16C9BC',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    borderRadius: 16,
    padding: '0 10px',
    fontSize: '0.8rem'
  },
  infoSecondary: {
    width: 150,
    fontSize: '0.95rem',
    fontWeight: 300,
    textTransform: 'capitalize'
  },
  loading: {
    height: 64,
    paddingLeft: theme.spacing(2),
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center'
  },
  searchBtn: {
    marginTop: theme.spacing(2),
    width: '100%',
    padding: 12
  },
  patientLink: {
    display: 'inline-table',
    width: '100%'
  },
  patientTable: {
    marginTop: theme.spacing(2),

    '.rdt_TableCol_Sortable': {
      div: {
        whiteSpace: 'normal!important'
      }
    }
  },
  loadingCentered: {
    marginTop: theme.spacing(5),
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  icon: {
    color: theme.palette.secondary.dark,
    cursor: 'pointer',
    fontSize: '1.2rem',
    '&.delete': {
      color: 'grey'
    }
  },
  searchIcon: { marginLeft: 10, opacity: 0.7 },
  flex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
  },
  resetBtn: {
    minWidth: 120
  },
  actionBtns: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: `${theme.spacing(2)} 0`
  },
  rafBtn: {
    cursor: 'pointer',
    color: theme.palette.secondary.dark,
    textDecoration: 'underline',
    border: 'none',
    background: 'transparent'
  }
});

export default styles;
