// @flow

import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { useDispatch } from 'react-redux';
import { log } from 'utils/jsUtils';
import MDPBackend from 'services/MDPBackend';
import { setUser } from 'features/user-slice';
import { useSelector } from 'react-redux';
import ConsentPage from 'containers/ConsentPage';

const USER_CHECK_INTERVAL = 1000 * 60 * 10; // 10 minutes

type Props = {
  component: React.ElementType<Component>,
  forceLogout: Boolean
};

const PrivateRoute = (props: Props) => {
  const { component: Component, forceLogout } = props;
  const [isLoggedIn, setIsLoggedIn] = useState({});
  const { user } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const isAuthenticated = async () => {
    try {
      const currentSession = await Auth.currentSession();
      return currentSession;
    } catch (error) {
      log('[privateRoute] Error: ', error);
    }
  };

  const validateAuthentication = async () => {
    const currentSession = await isAuthenticated();
    setIsLoggedIn(currentSession);

    if (currentSession) {
      const currentUserResponse = await MDPBackend.getUser();

      if (currentUserResponse && currentUserResponse.data.statusCode === 500) {
        Auth.signOut({ global: true }).then(() => {
          setIsLoggedIn(false);
        });

        return;
      }

      const body = JSON.parse(currentUserResponse?.data?.body);
      const user = body?.data || {};

      if (user.forceLogout) {
        MDPBackend.clearUserForceLogout();
      } else {
        dispatch(setUser(user));
      }
    }
  };

  const createUserCheckInterval = () => {
    setInterval(async () => {
      const currentSession = await isAuthenticated();
      if (!currentSession) return;
      const currentUserResponse = await MDPBackend.getUser();
      const body = JSON.parse(currentUserResponse?.data?.body);

      if (body?.data.forceLogout) {
        Auth.signOut({ global: true }).then(() => {
          setIsLoggedIn(false);
        });
      }
    }, USER_CHECK_INTERVAL);
  };

  useEffect(() => {
    validateAuthentication();
    createUserCheckInterval();
  }, [forceLogout]);

  return isLoggedIn ? (
    <>
      {user && (
        <>
          {user?.consents ? (
            <ConsentPage {...props} />
          ) : (
            <Component {...props} />
          )}
        </>
      )}
    </>
  ) : (
    <Navigate
      to={`/login?redirect=${decodeURIComponent(window.location.href)}`}
    />
  );
};

export default PrivateRoute;
