// @flow

import React from 'react';
import { Helmet } from 'react-helmet-async';
import fileDownload from 'js-file-download';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { withStyles } from 'tss-react/mui';
import Container from '@mui/material/Container';
import { log, binaryToBlob } from 'utils/jsUtils';
import MDPBackend from 'services/MDPBackend';
import PageTemplate from 'layout/PageTemplate';
import TemplateImg from 'assets/aahcm_screen.png';

import Dropzone from './FileDrop';
import FileUploadHistory from './FileUploadHistory';
import styles from './Styles/UploadDem.Styles';

type Props = {
  classes: Object
};

const UploadDem = (props: Props): React.Node => {
  const { classes } = props;

  const downloadFile = async (e) => {
    e.preventDefault();
    const res = await MDPBackend.downloadTemplateFile(
      'mdp_demographics_template.xlsx'
    );
    log('[downloadFile] res: ', res);
    const { data } = res;
    log('[downloadFile] data: ', data);
    const { body } = data;
    log('[downloadFile] body: ', body);
    const jsonFile = JSON.parse(body);
    log('[downloadFile] jsonFile: ', jsonFile);

    const blob = await binaryToBlob(jsonFile.attachment, jsonFile.ContentType);

    fileDownload(blob, 'mdp_patients_template.xlsx');
  };

  return (
    <PageTemplate>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Patient Roster Upload Page</title>
      </Helmet>
      <Container className={classes.container}>
        <Typography variant="h1" className={classes.title}>
          Welcome to the Patient Roster Upload Page
        </Typography>
        <Dropzone />
        <div className={classes.hints}>
          <Typography variant="h5" className={classes.title}>
            How to structure your list of patients
          </Typography>
          <a onClick={downloadFile}>
            <img
              className={classes.aahcmImage}
              src={TemplateImg}
              alt="aahcm_example"
            />
          </a>
          <Typography variant="body1" className={classes.hints}>
            You can download an Excel file template here:
          </Typography>
          <Button onClick={downloadFile} className={classes.templateButton}>
            <CloudDownloadIcon className={classes.downloadIcon} />
            Download Excel Template
          </Button>
        </div>
        <FileUploadHistory />
      </Container>
    </PageTemplate>
  );
};

export default (withStyles(UploadDem, styles): any);
