// @flow

import axios from 'axios';
import React, { useState, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import * as R from 'ramda';
import DataTable from 'react-data-table-component';
import queryString from 'query-string';
import fileDownload from 'js-file-download';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import Tooltip from '@mui/material/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import { withStyles } from 'tss-react/mui';
import RafDetailsModal from 'containers/PatientLookup/RafDetailsModal';

import { log } from 'utils/jsUtils';
import { ForwardRef } from 'components';
import MDPBackend from 'services/MDPBackend';

import styles from './Events.Style';
import { useDispatch } from 'react-redux';
import { showToastMsg } from 'features/toast-message-slice';

type Props = {
  events: Array<Object>,
  classes: Object,
  eventFilters: Object,
  setEventFilters: Function,
  setUrlQueryParams: Function,
  loading: Boolean,
  eventFilters: Object,
  totalRows: Number
};

const EventsTable = (props: Props): React.Node => {
  const {
    events,
    classes,
    loading,
    eventFilters,
    setEventFilters,
    totalRows,
    setUrlQueryParams
  } = props;
  const [loadingCSV, setLoadingCSV] = useState(false);
  const [rafModalData, setRafModalData] = useState(false);
  const location = useLocation();

  const dispatch = useDispatch();

  const { sortBy = '', sortByOrder = '' } = useMemo(
    () => queryString.parse(location.search),
    [location.search]
  );

  const exportEventsToCSV = async ({
    sortBy = 'eventDate',
    sortByOrder = '',
    eventType = '',
    from = '',
    to = ''
  } = {}) => {
    setLoadingCSV(true);

    const searchParams = {
      sortBy,
      sortByOrder,
      eventType,
      from,
      to
    };

    const searchString = new URLSearchParams(searchParams);

    try {
      const response = await MDPBackend.exportEventsToCSV(searchString);
      log('[exportEventsToCSV] response: ', response);

      const parsedBody = JSON.parse(response.data.body);

      const requestId = parsedBody.requestId;

      if (requestId) {
        dispatch(
          showToastMsg({
            open: true,
            message:
              // eslint-disable-next-line max-len
              'Exporting Events. This can take up to 5 minutes. File will download automatically when ready. Do not exit this page.',
            level: 'success',
            duration: 5000
          })
        );

        const exportedPatientsResponse = await tryGetExportedEvents(
          requestId,
          60
        );

        console.log(
          '[exportPatientsToCSV] exportedPatientsResponse: ',
          exportedPatientsResponse
        );

        const { url: signedUrl } = exportedPatientsResponse;

        const signedUrlResponse = await axios.get(signedUrl, {
          responseType: 'blob'
        });

        const blob = signedUrlResponse.data;

        const fileName = 'events.csv';

        fileDownload(blob, fileName);
      } else {
        throw new Error(`Failed to create request: ${response}`);
      }
    } catch (error) {
      log('[exportEventsToCSV] error: ', error);
    } finally {
      setLoadingCSV(false);
    }
  };

  const tryGetExportedEvents = async (requestId, retries) => {
    console.log('[tryGetExportedEvents] retry: ', retries);
    await new Promise((resolve) => setTimeout(resolve, 5 * 1000)); // wait 5 seconds

    try {
      const response = await MDPBackend.getEventsExport(requestId);
      const { body } = response.data;
      const parsedBody = JSON.parse(body);

      console.log('[tryGetExportedEvents] parsedBody: ', parsedBody);

      const { url: signedUrl } = parsedBody;

      if (signedUrl) {
        return parsedBody;
      } else if (!signedUrl && retries > 0) {
        return tryGetExportedEvents(requestId, retries - 1);
      } else {
        throw new Error('Max number of attempt reached');
      }
    } catch (error) {
      console.log('[tryGetExportedEvents] error: ', error);
      return null;
    }
  };

  const showRafDetails = (row) => {
    setRafModalData(row.rafScoreDeltaCalculated);
  };

  if (!events || !events.length) {
    return (
      <div className={classes.loadingCentered}>
        <div>No Events found</div>
      </div>
    );
  }

  const columns = Object.keys(R.omit(['icdCodesDeltaArr'], events[0])).map(
    (col) => {
      switch (col) {
        case 'eventDate':
          return {
            id: col,
            name: 'Event Date',
            selector: (row) => row[col],
            sortable: true,
            wrap: true
          };
        case 'type':
          return {
            id: col,
            name: 'Event Type',
            selector: (row) => row[col],
            sortable: true,
            wrap: true
          };
        case 'patientId':
          return {
            id: col,
            name: 'Patient ID',
            selector: (row) => row[col],
            sortable: true,
            wrap: true
          };
        case 'orgSourcePatientId':
          return {
            id: col,
            name: 'Org Source Patient ID',
            selector: (row) => row[col],
            sortable: true,
            wrap: true
          };
        case 'patientFirstName':
          return {
            id: col,
            name: 'First Name',
            selector: (row) => row[col],
            sortable: true,
            wrap: true
          };
        case 'patientLastName':
          return {
            id: col,
            name: 'Last Name',
            selector: (row) => row[col],
            sortable: true,
            wrap: true
          };
        case 'patientDob':
          return {
            id: col,
            name: 'DOB',
            selector: (row) => row[col],
            sortable: true,
            wrap: true
          };
        case 'totalChartFilesFound':
          return {
            id: col,
            name: 'Total Chart Files',
            selector: (row) => row[col],
            sortable: true,
            wrap: true
          };
        case 'icdCodesDelta':
          return {
            id: col,
            name: 'ICD Codes Delta',
            selector: (row) => row[col],
            sortable: true,
            wrap: true,
            // eslint-disable-next-line react/display-name
            cell: (row) => {
              return (
                <div style={{ maxHeight: 70, overflowY: 'auto' }}>
                  {row.icdCodesDelta}
                </div>
              );
            }
          };
        case 'icdCodesQueried':
          return {
            id: col,
            name: 'Queried ICD Codes',
            selector: (row) => row[col],
            sortable: true,
            wrap: true,
            // eslint-disable-next-line react/display-name
            cell: (row) => {
              return (
                <div style={{ maxHeight: 70, overflowY: 'auto' }}>
                  {row.icdCodesQueried}
                </div>
              );
            }
          };
        case 'icdCodesConfirmed':
          return {
            id: col,
            name: 'Confirmed ICD Codes',
            selector: (row) => row[col],
            sortable: true,
            wrap: true,
            // eslint-disable-next-line react/display-name
            cell: (row) => {
              return (
                <div style={{ maxHeight: 70, overflowY: 'auto' }}>
                  {row.icdCodesConfirmed}
                </div>
              );
            }
          };
        case 'compendium':
          return {
            id: col,
            name: 'Compendium',
            selector: (row) => row[col],
            sortable: true,
            wrap: true,
            // eslint-disable-next-line react/display-name
            cell: (row) => {
              return (
                <>
                  {row.compendium ? (
                    <>
                      <Tooltip title="Open Compendium">
                        <div
                          target="_blank"
                          rel="noreferrer"
                          onClick={() => {
                            window.open(row.compendium);
                          }}
                        >
                          <ForwardRef>
                            <FontAwesomeIcon
                              icon={solid('file-invoice')}
                              className={classes.icon}
                            />
                          </ForwardRef>
                        </div>
                      </Tooltip>
                    </>
                  ) : (
                    <div></div>
                  )}
                </>
              );
            }
          };
        case 'rafScoreDeltaCalculated':
          return {
            id: col,
            name: 'RAF Delta',
            selector: (row) => row[col],
            sortable: true,
            wrap: true,
            // eslint-disable-next-line react/display-name
            cell: (row) => {
              return (
                <Tooltip title={'RAF Details'}>
                  <button
                    onClick={() => showRafDetails(row)}
                    className={classes.rafBtn}
                  >
                    {row.rafScoreDeltaCalculated.rafScore}
                  </button>
                </Tooltip>
              );
            }
          };
        case 'providerName':
          return {
            id: col,
            name: 'Provider Group',
            selector: (row) => row[col],
            sortable: true,
            wrap: true
          };
        case 'lastUploadedDt':
          return {
            id: col,
            name: 'Last Attachment Upload',
            selector: (row) => row[col],
            sortable: true,
            wrap: true
          };
        default:
          return {
            id: col,
            name: col,
            selector: (row) => row[col],
            sortable: true,
            wrap: true
          };
      }
    }
  );

  const handlePageChange = (page) => {
    const newFilters = {
      ...eventFilters,
      page
    };

    setEventFilters(newFilters);
    setUrlQueryParams(newFilters);
  };

  const handleSort = (column, sortDirection) => {
    const newFilters = {
      ...eventFilters,
      sortBy: column.id,
      sortByOrder: sortDirection
    };

    setEventFilters(newFilters);
    setUrlQueryParams(newFilters);
  };

  const handleRowsPerPage = (newPerPage) => {
    const newFilters = {
      ...eventFilters,
      page: 1,
      perPage: newPerPage
    };

    setEventFilters(newFilters);
    setUrlQueryParams(newFilters);
  };

  return (
    <div className={classes.eventsTable}>
      <div>
        <Button
          disabled={loadingCSV}
          onClick={() => exportEventsToCSV(eventFilters)}
        >
          Export to CSV{' '}
          {loadingCSV ? (
            <CircularProgress
              style={{ width: 15, height: 15, marginLeft: 10 }}
            />
          ) : null}
        </Button>
      </div>
      <DataTable
        columns={columns}
        data={events}
        paginationRowsPerPageOptions={[10, 20, 50, 100]}
        paginationPerPage={eventFilters.perPage}
        paginationTotalRows={totalRows}
        paginationDefaultPage={eventFilters.page}
        onChangeRowsPerPage={handleRowsPerPage}
        onChangePage={handlePageChange}
        sortServer
        onSort={handleSort}
        defaultSortAsc={sortByOrder === 'asc'}
        defaultSortField={sortBy}
        progressPending={loading}
        defaultSortFieldId={sortBy}
        noHeader
        pagination
        paginationServer
        persistTableHead
        responsive
        highlightOnHover
        className={classes.eventsTable}
      />
      {rafModalData && (
        <RafDetailsModal
          data={rafModalData}
          handleClose={() => setRafModalData(null)}
        />
      )}
    </div>
  );
};

export default withStyles(EventsTable, styles);
