// @flow

import React from 'react';
import { withStyles } from 'tss-react/mui';
import { Typography, Card, CardContent, CircularProgress } from '@mui/material';
import styles from './Styles/QueryStats.Style';

type Props = {
  classes: Object,
  queryStats: Object,
  loading: Boolean
};

type StatsCardProps = {
  title: String,
  value: String,
  subValue: String,
  loading: Boolean
};

const QueryStats = (props: Props): React.Node => {
  const { classes, queryStats, loading } = props;

  const {
    avgRafUplift,
    totalCompendiums,
    totalCompendiumsPercentage,
    totalCompletedQueries,
    totalCompletedQueriesPercentage,
    totalChartsPercentage,
    totalCharts
  } = queryStats;

  const StatsCard = (props: StatsCardProps) => {
    const { title, value, subValue, loading } = props;

    return (
      <Card className={classes.cardWrapper}>
        <div>
          <CardContent>
            <Typography variant="h5" component="h2" align="center">
              {loading ? (
                <CircularProgress size={20} />
              ) : value && value !== '0.00%' ? (
                value
              ) : (
                '-'
              )}
            </Typography>
            <Typography variant="body2" component="p" align="center">
              {title} {subValue ? `(${subValue})` : null}
            </Typography>
          </CardContent>
        </div>
      </Card>
    );
  };

  return (
    <div className={classes.root}>
      <StatsCard
        title="Avg. potential RAF Uplift"
        value={avgRafUplift}
        loading={loading}
      />
      <StatsCard
        title="Found Chart Files"
        value={totalChartsPercentage}
        subValue={totalCharts}
        loading={loading}
      />
      <StatsCard
        title="Have Compendium"
        value={totalCompendiumsPercentage}
        subValue={totalCompendiums}
        loading={loading}
      />
      <StatsCard
        title="Completed Queries"
        value={totalCompletedQueriesPercentage}
        subValue={totalCompletedQueries}
        loading={loading}
      />
    </div>
  );
};

export default withStyles(QueryStats, styles);
