// @flow

type Props = {
  children: Object,
  closeButton: Object,
  className: String
};

import React, { forwardRef } from 'react';

const ModalBox = forwardRef(
  ({ children, closeButton, className = '' }: Props, ref) => {
    return (
      <div
        ref={ref}
        tabIndex={-1}
        className={`absolute w-full max-w-screen-lg p-2 h-full max-h-[1000px] 
bg-white left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 
overflow-hidden outline-none rounded-2xl ${className}`}
      >
        {closeButton}
        <div className="w-full h-full overflow-auto">{children}</div>
      </div>
    );
  }
);

ModalBox.displayName = 'ModalBox';

export default ModalBox;
