"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.pageTransition = void 0;
var pageTransition = {
  left: {
    opacity: 0,
    x: -300
  },
  in: {
    opacity: 1,
    x: 0
  },
  right: {
    opacity: 0,
    x: 300
  },
  transition: {
    duration: 1000
  }
};
exports.pageTransition = pageTransition;