// @flow
import React, { useEffect, useState } from 'react';
import {
  Button,
  CircularProgress,
  Container,
  Paper,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField
} from '@mui/material';
import { useSelector } from 'react-redux';
import { useGetPatientCareGapsQuery, useUpdateCareGapsMutation } from 'api/api';
import { useParams, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { showToastMsg } from 'features/toast-message-slice';
import { useDispatch } from 'react-redux';

const capitalize = (str: string) =>
  str ? `${str.slice(0, 1).toUpperCase()}${str.slice(1)}` : '';
const actions = ['document', 'refer', 'refuse', 'error'];
const actionColors = [
  '!bg-success-main/40 !text-black',
  '!bg-mediumPurple/40 !text-black',
  '!bg-error-main/40 !text-black',
  '!bg-error-main/40 !text-black'
];

const CareGaps = () => {
  const currentOrganization = useSelector((state) => state.currentOrganization);
  const { patientId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    data: careGaps,
    error: dataError,
    refetch,
    isLoading
  } = useGetPatientCareGapsQuery(patientId);
  const [updateStatus, { isLoading: updateLoading }] =
    useUpdateCareGapsMutation();

  // Dialog state
  const [openDialog, setOpenDialog] = useState(false);
  const [openUndoDialog, setOpenUndoDialog] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedCareGapId, setSelectedCareGapId] = useState(null);
  const [note, setNote] = useState(''); // Stores both status change notes and undo reasons

  const getColorForStatus = (status) => {
    const index = actions.indexOf(status);
    return actionColors[index] || '';
  };

  const handleOpenDialog = (careGapId, status) => {
    setSelectedStatus(status);
    setSelectedCareGapId(careGapId);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedStatus('');
    setSelectedCareGapId(null);
    setNote('');
  };

  const handleSubmitStatusChange = () => {
    handleStatusChange(selectedCareGapId, selectedStatus, note);
  };

  const handleStatusChange = async (careGapId, status, note = '') => {
    try {
      await updateStatus({
        patientId,
        organizationId: currentOrganization._id,
        careGapId,
        status,
        note
      }).unwrap();
      refetch(patientId);
      handleCloseDialog();
      handleCloseUndoDialog();
    } catch (error) {
      dispatch(
        showToastMsg({
          open: true,
          message: error?.data?.errorMessage || 'Update failed',
          level: 'error',
          duration: 5000
        })
      );
    }
  };

  const handleOpenUndoDialog = (careGapId, status) => {
    setSelectedCareGapId(careGapId);
    setSelectedStatus(status);
    setOpenUndoDialog(true);
  };

  const handleCloseUndoDialog = () => {
    setOpenUndoDialog(false);
    setSelectedCareGapId(null);
    setSelectedStatus('');
    setNote(''); // Reset the note when closing the undo dialog
  };

  const handleUndoStatusChange = () => {
    handleStatusChange(selectedCareGapId, 'open', note);
  };

  useEffect(() => {
    if (currentOrganization && !currentOrganization?.config?.enableCareGaps) {
      navigate(`/patient/${patientId}`);
    }
  }, [patientId, currentOrganization]);

  if (isLoading) {
    return (
      <div className="flex justify-center p-3 min-h-[300px] w-100">
        <CircularProgress />
      </div>
    );
  }

  if (dataError) {
    return (
      <div className="flex justify-center p-3 w-100 min-h-[300px]">
        <Typography variant="body1" className="text-error-dark">
          Error: {dataError?.message}
        </Typography>
      </div>
    );
  }

  if (!careGaps || (careGaps && careGaps.length === 0)) {
    return (
      <div className="flex justify-center p-3 w-100 min-h-[300px]">
        <Typography variant="body1">No Care gaps available</Typography>
      </div>
    );
  }

  return (
    <Container
      maxWidth={false}
      className="!pl-[86px] sm:!pl-[193px] overflow-x-hidden"
    >
      <div className="grid my-3 gap-y-2">
        {careGaps
          .filter((careGap) => careGap.exposeOnCompendium)
          .map((careGap) => (
            <Paper key={careGap._id} className="grid p-3 gap-y-2">
              <div className="grid justify-between grid-flow-col">
                <Typography variant="h2">
                  {careGap.measureOnCompendium}
                </Typography>
                {careGap.lastLoadedDt && (
                  <Typography variant="h3">
                    Last loaded:{' '}
                    {format(new Date(careGap.lastLoadedDt), 'yyyy-MM-dd')}
                  </Typography>
                )}
              </div>

              <Typography variant="body1">
                {careGap.messageOnCompendium}
              </Typography>
              {careGap.status === 'open' ? (
                <div className="grid grid-flow-col gap-x-2">
                  {actions.map((action, i) => (
                    <Button
                      className={actionColors[i]}
                      variant="contained"
                      key={action}
                      onClick={() => handleOpenDialog(careGap._id, action)}
                    >
                      {capitalize(action)}
                    </Button>
                  ))}
                </div>
              ) : (
                <>
                  {careGap.user && (
                    <Typography variant="body1">
                      Marked as <strong>{capitalize(careGap.status)}</strong> by{' '}
                      <strong>{`${careGap.user.firstName} ${careGap.user.lastName}`}</strong>{' '}
                      on{' '}
                      {careGap.updatedDt
                        ? format(new Date(careGap.updatedDt), 'yyyy-MM-dd')
                        : format(new Date(), 'yyyy-MM-dd')}
                    </Typography>
                  )}
                  {careGap.note && (
                    <Typography variant="body1">
                      <strong>Note: </strong>
                      {careGap.note}
                    </Typography>
                  )}
                  <Button
                    color="error"
                    className="justify-self-end"
                    onClick={() =>
                      handleOpenUndoDialog(careGap._id, careGap.status)
                    }
                  >
                    Undo {capitalize(careGap.status)}
                  </Button>
                </>
              )}
            </Paper>
          ))}
      </div>

      <Dialog
        open={openDialog}
        onClose={updateLoading && handleCloseDialog}
        PaperProps={{
          style: { width: '100%', maxWidth: '500px' }
        }}
      >
        <DialogTitle>{capitalize(selectedStatus)} Note</DialogTitle>
        <DialogContent>
          <TextField
            label="Note (optional)"
            fullWidth
            multiline
            minRows={3}
            margin="normal"
            value={note}
            onChange={(e) => setNote(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} disabled={updateLoading}>
            Cancel
          </Button>
          <Button
            startIcon={updateLoading && <CircularProgress size={12} />}
            onClick={handleSubmitStatusChange}
            variant="contained"
            disabled={updateLoading}
            className={getColorForStatus(selectedStatus)}
          >
            {capitalize(selectedStatus)}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openUndoDialog}
        onClose={handleCloseUndoDialog}
        PaperProps={{
          style: { width: '100%', maxWidth: '500px' }
        }}
      >
        <DialogTitle>Undo Care Gap: {capitalize(selectedStatus)}</DialogTitle>
        <DialogContent>
          <TextField
            label="Reason (required)"
            fullWidth
            multiline
            minRows={3}
            margin="normal"
            value={note}
            onChange={(e) => setNote(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseUndoDialog} disabled={updateLoading}>
            Cancel
          </Button>
          <Button
            startIcon={updateLoading && <CircularProgress size={12} />}
            onClick={handleUndoStatusChange}
            variant="contained"
            color="error"
            disabled={updateLoading || !note}
          >
            Undo {capitalize(selectedStatus)}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default CareGaps;
