// @flow

import React, { useEffect, useState, useMemo } from 'react';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import * as R from 'ramda';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import dayjs from 'dayjs';
import fileDownload from 'js-file-download';
import CircularProgress from '@mui/material/CircularProgress';
import { withStyles } from 'tss-react/mui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { log } from 'utils/jsUtils';
import MDPBackend from 'services/MDPBackend';

import styles from './Styles/UploadDem.Styles';

const boxStyle = {
  position: 'absolute',
  width: '100%',
  maxWidth: 1200,
  padding: '2rem 2rem',
  height: '100%',
  maxHeight: 800,
  backgroundColor: 'white',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  overflow: 'auto',
  outline: 'none',
  borderRadius: '1rem'
};

const BatchDetailsModal = (props: Props) => {
  const { handleClose, classes, batchName } = props;
  const [data, setData] = useState(null);
  const [metaData, setMetaData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingCSV, setLoadingCSV] = useState(false);

  const labels = {
    createdDt: 'Date Created',
    error: 'Number of Errors',
    excluded: 'Number of Excluded',
    pending: 'Number of Pending',
    submitted: 'Number of Submitted'
  };

  const exportErrorQueries = async () => {
    setLoadingCSV(true);

    try {
      const response = await MDPBackend.exportFailedQueries(batchName);
      log('[exportFailedQueries] response: ', response);

      const parsedBody = JSON.parse(response.data.body);

      const signedUrl = parsedBody.data;

      const signedUrlResponse = await axios.get(signedUrl, {
        responseType: 'blob'
      });

      const blob = signedUrlResponse.data;

      fileDownload(blob, `${batchName}-errors.csv`);
      log('[exportPatientsToCSV] parsedBody: ', parsedBody);
    } catch (error) {
      log('[exportPatientsToCSV] error: ', error);
    } finally {
      setLoadingCSV(false);
    }
  };

  const tableData = useMemo(() => {
    return !data
      ? []
      : data.map((q) => ({
          'First name': q.data.patient_first_name,
          'Last name': q.data.patient_last_name,
          DOB: q.data.patient_dob,
          Sex: q.data.patient_sex,
          Status: q.status,
          'Status Details': q.error ? q.error : '',
          'Date Created': q.createdDt
            ? dayjs(q.createdDt).format('YYYY-MM-DD hh:mmA')
            : ''
        }));
  }, [data]);

  const setWidth = (col) => {
    switch (col) {
      case 'Status Details':
        return '350px';
      case 'Date Created':
        return '200px';
      case 'Sex':
        return '70px';
      default:
        return 'auto';
    }
  };

  let columns = useMemo(() => {
    return !tableData || !tableData[0]
      ? []
      : Object.keys(tableData[0]).map((col) => {
          console.log('coll', col);
          return {
            name: col,
            selector: (row) => row[col],
            sortable: true,
            center: true,
            wrap: true,
            width: setWidth(col),
            maxWidth: '350px'
          };
        });
  }, [tableData]);

  useEffect(() => {
    setLoading(true);
    const loadData = async () => {
      try {
        const response = await MDPBackend.getBatchRequests(batchName);

        const parsedBody = JSON.parse(response.data.body);
        console.log('Batch details::', parsedBody);
        setData(parsedBody.data);
        setMetaData(parsedBody.metaData);
      } catch (error) {
        log('[batch details] error: ', error);
      } finally {
        setLoading(false);
      }
    };
    loadData();
  }, [batchName, setData, setMetaData]);

  return (
    <div>
      <Modal
        open={true}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box style={boxStyle}>
          <Box>
            <Typography
              variant="h1"
              style={{
                marginBottom: 20,
                paddingBottom: 10,
                borderBottom: '1px solid black',
                fontWeight: '700'
              }}
            >
              {batchName}
            </Typography>
            <Button onClick={handleClose} className={classes.closeButton}>
              <FontAwesomeIcon
                icon={solid('xmark')}
                className={classes.closeIcon}
              />
            </Button>
          </Box>
          {loading && (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress size={30} />
            </div>
          )}
          {data &&
            Object.keys(R.omit(['id', 'data'], data[0])).map((key) => (
              <Box key={key}>
                {labels[key] && (
                  <div style={{ marginBottom: 10 }}>
                    <strong>{labels[key]}:</strong> {data[0][key]}
                  </div>
                )}
              </Box>
            ))}
          {metaData &&
            Object.keys(metaData).map((key) => (
              <Box key={key}>
                {labels[key] && (
                  <div style={{ marginBottom: 10 }}>
                    <strong>{labels[key]}:</strong> {metaData[key]}
                  </div>
                )}
              </Box>
            ))}
          {/* data && (
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  <strong>Patient Details</strong>
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ display: 'block' }}>
                {Object.keys(R.omit(['id'], data.data)).map((key) => (
                  <Box key={key}>
                    {dataLabels[key] && (
                      <Typography style={{ marginBottom: 10 }}>
                        <strong>{dataLabels[key]}:</strong> {data.data[key]}
                      </Typography>
                    )}
                  </Box>
                ))}
              </AccordionDetails>
            </Accordion>
          )*/}
          {metaData && metaData.error && (
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                disabled={loadingCSV}
                style={{ marginTop: 20 }}
                onClick={() => exportErrorQueries()}
              >
                Export Failed Queries
                {loadingCSV ? (
                  <CircularProgress
                    style={{ width: 15, height: 15, marginLeft: 10 }}
                  />
                ) : null}
              </Button>
            </div>
          )}

          {tableData && tableData.length ? (
            <DataTable
              style={{ textAlign: 'center' }}
              title={<h3>Preview of Failed Queries</h3>}
              data={tableData}
              columns={columns}
              persistTableHead
              pagination
              paginationRowsPerPageOptions={[10, 20, 50, 100, 200, 500]}
              responsive
              className={classes.dataTable}
              highlightOnHover
              heD
            />
          ) : (
            <div style={{ marginTop: 30 }}>No queries found</div>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default withStyles(BatchDetailsModal, styles);
